const dimensions = [
  { name: "engaja_job_meaning_pillars", label: "Significado do Trabalho" },
  {
    name: "engaja_good_management_practices_pillars",
    label: "Boas práticas de gestão",
  },
  {
    name: "engaja_positive_work_environment_pillars",
    label: "Ambiente de trabalho positivo",
  },
  {
    name: "engaja_growth_opportunity_pillars",
    label: "Oportunidade de crescimento",
  },
  {
    name: "engaja_trust_in_top_leadership_pillars",
    label: "Confiança na Alta Liderança",
  },
  { name: "engaja_compensation_and_benefits_pillars", label: "Remuneração" },
];

export { dimensions };
