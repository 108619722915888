import { trpc } from "@api/client";
import {
  Breadcrumbs,
  Skeleton,
  Tab,
  dayjs,
} from "@flash-tecnologia/hros-web-ui-v2";
import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  ButtonWithIcon,
  CarouselCards,
  PageContainer,
  StatisticRow,
} from "../../components";
import { Divider } from "../../components/v2";
import { alert } from "../../utils";
import {
  Detail,
  Header,
  OccurrenceDataGrid,
  PeopleDataGrid,
} from "./components";

export default () => {
  const { id = "" } = useParams();
  const [loading, setLoading] = useState(false);

  const {
    data: engagement,
    isFetching: getEngagementByIdLoading,
    refetch: getEngagementById,
  } = trpc.getEngagementById.useQuery(
    {
      id: id,
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
      onError: (e) => {
        alert("Erro ao buscar pesquisa, tente novamente mais tarde.");
        console.log("err: ", e);
      },
    }
  );

  // const {
  //   data: employees,
  //   isFetching: getEmployeeMonitoringsByEngagementIdLoading,
  // } = trpc?.getEmployeeMonitoringsByEngagementId.useQuery(
  //   { id },
  //   {
  //     retry: false,
  //     refetchOnWindowFocus: false,
  //     onSuccess: (e) => setData(e),
  //     onError: (e) => {
  //       alert("Erro ao buscar destinatários, tente novamente mais tarde.");
  //       console.log("err: ", e);
  //     },
  //   }
  // );

  const {
    data: employees,
    isFetching: getEmployeeMonitoringsByEngagementIdLoading,
  } = trpc?.getRecurrenceEmployees?.useQuery(
    { id },
    {
      retry: false,
      refetchOnWindowFocus: false,
      onSuccess: (e) => setData(e),
      onError: (e) => {
        alert("Erro ao buscar destinatários, tente novamente mais tarde.");
        console.log("err: ", e);
      },
    }
  );

  const { data: occurrences, isFetching: getRecurrenceByIdLoading } =
    trpc?.getRecurrenceById.useQuery(
      {
        id,
      },
      {
        retry: false,
        refetchOnWindowFocus: false,
        onError: (e: any) => {
          alert(
            "Erro ao buscar ocorrências da recorrência, tente novamente mais tarde."
          );
          console.log("err: ", e);
        },
      }
    );

  const { data: insights, isFetching: getSurveyInsightsLoading } =
    trpc?.getRecurrenceSurveyInsights.useQuery(
      { id },
      {
        retry: false,
        refetchOnWindowFocus: false,
        onError: (e) => {
          alert("Erro ao buscar insights, tente novamente mais tarde.");
          console.log("err: ", e);
        },
      }
    );

  const updateRecurrenceStatus = trpc?.updateRecurrenceStatus.useMutation({
    onSuccess: (e) => {
      if (e?.recurrence?.status) getEngagementById();
      alert("Recorrência atualizada com sucesso.", "success");
      setLoading(!loading);
    },
    onError: (e) => {
      console.log(e);
      alert("Erro ao atualizar recorrência, tente novamente mais tarde.");
      setLoading(!loading);
    },
  });

  const [data, setData] = useState<typeof employees>([]);

  const renderEndRecurrence = () => {
    if (engagement?.recurrence) {
      if (engagement?.recurrence?.endDate)
        return dayjs(engagement?.recurrence?.endDate)?.format("DD/MM/YYYY");
      if (engagement?.recurrence?.occurrenceLimit === -1) return "Nunca";
      if (
        engagement?.recurrence?.occurrenceLimit &&
        engagement?.recurrence?.occurrenceLimit > 0
      )
        return `Depois de ${engagement?.recurrence?.occurrenceLimit} envios`;
    }

    return "-";
  };

  if (
    getEngagementByIdLoading ||
    getEmployeeMonitoringsByEngagementIdLoading ||
    getRecurrenceByIdLoading ||
    getSurveyInsightsLoading
  )
    return <Skeleton variant="rectangular" width="100%" height="100%" />;

  return (
    <PageContainer>
      <Breadcrumbs
        separator=">"
        breadcrumbs={[
          <Link to="/engagement/survey">Gerenciar pesquisas</Link>,
          <Link to={`/engagement/survey/${id}`}>Detalhe da pesquisa</Link>,
        ]}
      />
      <Header
        refetch={getEngagementById}
        data={
          engagement
            ? {
                ...engagement,
                notifications: {
                  email: engagement?.notifications?.email || false,
                  push: engagement?.notifications?.push || false,
                  reminder: {
                    enabled:
                      engagement?.notifications?.reminder?.enabled || false,
                    minRate: engagement?.notifications?.reminder?.minRate || 0,
                  },
                },
                createdAt: engagement?.createdAt
                  ? new Date(engagement?.createdAt)
                  : undefined,
                updatedAt: engagement?.updatedAt
                  ? new Date(engagement?.updatedAt)
                  : undefined,
                sendDate: engagement?.sendDate
                  ? new Date(engagement?.sendDate)
                  : undefined,
              }
            : undefined
        }
        recurrence
        name={engagement?.name || ""}
        status={
          engagement?.status === "draft"
            ? "draft"
            : engagement?.recurrence?.status || ""
        }
      />
      <Divider spacing="s3" />
      <CarouselCards
        cards={[
          {
            title: "Envios",
            icon: "IconSend",
            value: insights?.surveys || 0,
          },
          {
            title: "Perguntas",
            icon: "IconQuestionMark",
            value: insights?.questions || 0,
          },
          {
            title: "Pessoas no próximo envio",
            icon: "IconUser",
            value:
              insights?.selectedEmployees?.length ||
              engagement?.employees?.length ||
              0,
          },
          {
            title: "Taxa de visualização",
            icon: "IconEye",
            value: insights?.viewRate || 0,
            subValue: insights?.views,
          },
          {
            title: "Taxa de resposta",
            icon: "IconGraph",
            value: insights?.answerRate || 0,
            subValue: insights?.answers,
          },
        ]}
      />
      <Divider spacing="s3" />
      <StatisticRow
        data={[
          {
            label: "Criado em",
            value: engagement?.createdAt
              ? dayjs(engagement?.createdAt)?.format("DD/MM/YYYY")
              : "-",
          },
          {
            label: "Início em",
            value: engagement?.sendDate
              ? dayjs(engagement?.startDate?.date)?.format("DD/MM/YYYY")
              : "-",
          },
          {
            label: "Término em",
            value: renderEndRecurrence(),
          },
        ]}
        rightContent={
          engagement?.status !== "draft" &&
          ["progress", "paused"]?.includes(
            engagement?.recurrence?.status || ""
          ) ? (
            engagement?.recurrence?.status !== "paused" ? (
              <ButtonWithIcon
                loading={loading}
                icon={{
                  name: "IconPlayerPause",
                  position: "right",
                  style: { fill: "transparent" },
                }}
                variant="secondary"
                variantType="error"
                size="medium"
                onClick={() => {
                  setLoading(!loading);
                  updateRecurrenceStatus.mutate({ id, status: "paused" });
                }}
              >
                Pausar recorrência
              </ButtonWithIcon>
            ) : (
              <ButtonWithIcon
                loading={loading}
                icon={{
                  name: "IconPlayerPlay",
                  position: "right",
                  style: { fill: "transparent" },
                }}
                variant="secondary"
                size="medium"
                onClick={() => {
                  setLoading(!loading);
                  updateRecurrenceStatus.mutate({ id, status: "progress" });
                }}
              >
                Retomar recorrência
              </ButtonWithIcon>
            )
          ) : null
        }
      />
      <Divider spacing="s3" />
      <Tab
        tabItens={[
          {
            label: "Envios",
            component: (
              <OccurrenceDataGrid engagementId={id} data={occurrences || []} />
            ),
          },
          {
            label: "Pessoas",
            component: (
              <PeopleDataGrid
                selectedEmployees={insights?.selectedEmployees || []}
                engagementId={id}
                hideColumns={["view", "status", "profile"]}
                data={data || []}
                anonymous={engagement?.anonymous}
                onSearch={(e) =>
                  setData(
                    employees?.filter(({ name }) => name?.includes(e)) || []
                  )
                }
              />
            ),
          },
          {
            label: "Detalhe",
            component: engagement ? (
              <Detail
                data={{
                  ...engagement,
                  notifications: {
                    email: engagement?.notifications?.email || false,
                    push: engagement?.notifications?.push || false,
                    reminder: {
                      enabled:
                        engagement?.notifications?.reminder?.enabled || false,
                      minRate:
                        engagement?.notifications?.reminder?.minRate || 0,
                    },
                  },
                  createdAt: engagement?.createdAt
                    ? new Date(engagement?.createdAt)
                    : undefined,
                  updatedAt: engagement?.updatedAt
                    ? new Date(engagement?.updatedAt)
                    : undefined,
                  sendDate: engagement?.sendDate
                    ? new Date(engagement?.sendDate)
                    : undefined,
                }}
              />
            ) : (
              <></>
            ),
          },
        ]}
      />
    </PageContainer>
  );
};
