import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 14px;
  color: var(--color-feedback-error-40);
  font-size: 20px;
  font-weight: normal;
  line-height: 20px;
`;

export { Container };
