import { TextField } from "@flash-tecnologia/hros-web-ui-v2";
import { Container } from "./styles";

const DatePreview = () => (
  <Container>
    <TextField label="DD/MM/AAAA" value="" disabled={true} />
  </Container>
);

export { DatePreview };
