import { useState } from "react";

import {
  FlexColumn,
  PageDescription,
  PageInfo,
  PageSubTitle,
  PreviewButton,
  StyledTab,
} from "./styles";
import { IStepReview } from "./types";

import {
  BasicInfoTab,
  DispatchTab,
  PreviewTab,
  RecipientTab,
  SettingsTab,
} from "./components/Tabs";
import { Grid } from "@mui/material";
import { trpc } from "@api/client";
import { Icons } from "@flash-tecnologia/hros-web-ui-v2";

const StepReview = ({
  hide,
  disabled,
  formik,
  employees,
  setStep,
  onPreviewClick,
}: IStepReview) => {
  const [selectedTab, setSelectedTab] = useState<number>(0);

  if (hide) return <></>;

  const tabs = {
    [0]: <PreviewTab data={formik?.values} onNavigate={() => setStep(0)} />,
    [1]: <BasicInfoTab data={formik?.values} onNavigate={() => setStep(0)} />,
    [2]: <DispatchTab data={formik?.values} onNavigate={() => setStep(1)} />,
    [3]: <SettingsTab data={formik?.values} onNavigate={() => setStep(2)} />,
    [4]: (
      <RecipientTab
        data={formik?.values}
        employees={employees}
        onNavigate={() => setStep(3)}
      />
    ),
  };

  return (
    <Grid container>
      <Grid item sm={12} md={5} lg={4}>
        <FlexColumn style={{ paddingRight: "24px" }}>
          <PageInfo style={{ marginBottom: "16px" }}>
            <PageSubTitle variant="headline7">Revisão</PageSubTitle>
            <PageDescription variant="body3">
              Confira a prévia da sua comunicação e revise antes de enviá-la.
            </PageDescription>
          </PageInfo>
          <PreviewButton type="button" onClick={() => onPreviewClick()}>
            Pré-visualizar comunicação{" "}
            <Icons name="IconFileSearch" style={{ marginLeft: "8px" }} />
          </PreviewButton>
          <StyledTab
            selected={selectedTab}
            onTabChanged={setSelectedTab}
            variant="secondary"
            tabItens={[
              { label: "Comunicação", component: undefined },
              { label: "Informações básicas", component: undefined },
              { label: "Envio", component: undefined },
              { label: "Configurações", component: undefined },
              { label: "Destinatários", component: undefined },
            ]}
            type="fullWidth"
          />
        </FlexColumn>
      </Grid>
      <Grid item sm={12} md={7} lg={8} marginBottom={"40px"}>
        {tabs[selectedTab || 0]}
      </Grid>
    </Grid>
  );
};

export { StepReview };
