import { TotalReactions } from "@components/TotalReactions";
import { DataCard } from "../DataCard";
import {
  FlexBetween,
  ImagePreview,
  PreviewSectionContainer,
  TextWithHtml,
} from "./styled";

import { trpc } from "@api/client";
import { Divider } from "@components/Divider";
import {
  LinkButton,
  Skeleton,
  Typography,
  dayjs,
} from "@flash-tecnologia/hros-web-ui-v2";
import { sanitizeHtml } from "@utils";
import { useTheme } from "styled-components";

interface PreviewTabProps {
  data: any;
  onNavigate: React.MouseEventHandler<HTMLButtonElement> | undefined;
}

export const PreviewTab = ({ data, onNavigate }: PreviewTabProps) => {
  const theme = useTheme() as any;
  const { title, message, link, like, imageUrl, pdfFile } = data;

  const { data: preSignedImageUrl, isFetching: isGettingImage } =
    trpc.getS3File.useQuery(
      { path: imageUrl?.path },
      {
        enabled: !!!imageUrl?.signedUrl && !!imageUrl?.path,
        retry: 1,
        refetchOnWindowFocus: false,
      }
    );

  const { data: preSignedPdfUrl, isFetching: isGettingPdf } =
    trpc.getS3File.useQuery(
      { path: pdfFile?.path },
      {
        enabled: !!!pdfFile?.signedUrl && !!pdfFile?.path,
        retry: 1,
        refetchOnWindowFocus: false,
      }
    );

  const imageSource = preSignedImageUrl || imageUrl?.signedUrl || "";

  return (
    <DataCard
      title="Pré-visualização"
      subTitle="Confira a prévia da sua comunicação."
      editable
      onEditClick={onNavigate}
      sections={[]}
    >
      {isGettingImage || isGettingPdf ? (
        <Skeleton variant="rounded" width={"100%"} height={500} />
      ) : (
        <PreviewSectionContainer>
          <FlexBetween>
            <Typography
              variant="body4"
              variantColor={theme.colors.neutral[30]}
              style={{ fontWeight: 700 }}
              tag="span"
            >
              {title}
            </Typography>

            <Typography
              variant="caption"
              variantColor={theme.colors.neutral[30]}
              style={{ fontWeight: 600 }}
              tag="span"
            >
              {dayjs().format("DD/MM/YYYY HH:mm")}
            </Typography>
          </FlexBetween>

          <TextWithHtml
            dangerouslySetInnerHTML={{ __html: sanitizeHtml(message) }}
          />

          <FlexBetween style={{ marginTop: "16px" }}>
            {link?.url ? (
              <LinkButton
                variant="default"
                onClick={() => {
                  window.open(`${link?.url}`, "_blank");
                }}
              >
                <Typography
                  variant="caption"
                  variantColor={theme.colors.primary}
                  style={{ fontWeight: 700 }}
                >
                  {"Acesse agora"}
                </Typography>
              </LinkButton>
            ) : null}
            {preSignedPdfUrl || pdfFile?.signedUrl ? (
              <LinkButton
                variant="default"
                onClick={async () => {
                  const lastIndexName = pdfFile.path?.lastIndexOf("/") || "";

                  const nameOfArchive =
                    pdfFile.path && lastIndexName
                      ? pdfFile.path.substring(lastIndexName + 1)
                      : "Nome não identificado";

                  const url = preSignedPdfUrl || pdfFile?.signedUrl;

                  const readFile = await fetch(url, {
                    mode: "cors",
                    cache: "no-cache",
                    credentials: "same-origin",
                  });
                  const fileToArrayBuffer = await readFile.arrayBuffer();
                  const res = URL.createObjectURL(
                    new Blob([fileToArrayBuffer])
                  );
                  const a = document.createElement("a");
                  a.href = res;
                  a.download = nameOfArchive;
                  a.click();
                }}
              >
                <Typography
                  variant="caption"
                  variantColor={theme.colors.primary}
                  style={{ fontWeight: 700 }}
                >
                  Visualizar PDF
                </Typography>
              </LinkButton>
            ) : null}
          </FlexBetween>

          {imageSource ? (
            <>
              <Divider />
              <ImagePreview url={imageSource} />
            </>
          ) : null}
          {like ? (
            <>
              <Divider size="medium" />
              <TotalReactions
                total={100}
                reactionsCount={[4, 3, 2, 1]}
                hiddeLikes
              />
            </>
          ) : null}
        </PreviewSectionContainer>
      )}
    </DataCard>
  );
};
