import styled from "styled-components";

const MainContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 40px 0;
`;

const LeftContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
`;

const RightContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  gap: 24px;
`;

const StatusContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export { MainContainer, LeftContainer, RightContainer, StatusContainer };
