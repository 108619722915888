import { BlankCardInterface } from "./types";
import { BlankContainer, TitleContainer } from "./styles";
import { ShapeIcon } from "@flash-tecnologia/hros-web-ui-v2";
import { Typography } from "@components/Typography";

const BlankCard = ({
  model,
  icon,
  title,
  onUse,
  disabled,
}: BlankCardInterface) => (
  <BlankContainer onClick={() => (disabled ? null : onUse(model))}>
    <ShapeIcon
      name={icon}
      variant="default"
      size={48}
      color="var(--color-secondary-50)"
    />
    <TitleContainer>
      <Typography
        variant="body2"
        fontWeight={600}
        color="var(--color-secondary-50)"
      >
        {title}
      </Typography>
    </TitleContainer>
  </BlankContainer>
);

export { BlankCard };
