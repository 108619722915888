import { Carousel, ShapeIcon } from "@flash-tecnologia/hros-web-ui-v2";
import { Typography } from "@components/index";
import {
  Container,
  BigNumberContainer,
  BigNumberContentContainer,
  CarouselContainer,
} from "./styles";
import { InsightsInteface } from "./types";

export default ({
  recipients = 0,
  answers = 0,
  viewRate = 0,
  answerRate = 0,
}: InsightsInteface) => (
  <Container>
    <Typography
      variant="headline8"
      color="var(--color-neutral-30)"
      fontWeight={700}
    >
      Insights
    </Typography>
    <CarouselContainer>
      <Carousel
        slides={[
          <BigNumberContainer>
            <ShapeIcon
              name="IconUser"
              variant={"default"}
              color="var(--color-secondary-50)"
              size={48}
            />
            <BigNumberContentContainer>
              <Typography variant="body3" color={"var(--color-neutral-50)"}>
                Total de destinatários
              </Typography>
              <Typography variant="headline5" color={"var(--color-neutral-30)"}>
                {recipients}
              </Typography>
            </BigNumberContentContainer>
          </BigNumberContainer>,
          <BigNumberContainer>
            <ShapeIcon
              name="IconMessage"
              variant={"default"}
              color="var(--color-secondary-50)"
              size={48}
            />
            <BigNumberContentContainer>
              <Typography variant="body3" color={"var(--color-neutral-50)"}>
                Respostas
              </Typography>
              <Typography variant="headline5" color={"var(--color-neutral-30)"}>
                {answers}
              </Typography>
            </BigNumberContentContainer>
          </BigNumberContainer>,
          <BigNumberContainer>
            <ShapeIcon
              name="IconEye"
              variant={"default"}
              color="var(--color-secondary-50)"
              size={48}
            />
            <BigNumberContentContainer>
              <Typography variant="body3" color={"var(--color-neutral-50)"}>
                Taxa de visualização
              </Typography>
              <Typography variant="headline5" color={"var(--color-neutral-30)"}>
                {viewRate ? `${Math.round(viewRate)}%` : Math.round(viewRate)}
              </Typography>
              {/* <TooltipContainer>
              <Tooltip
                placement="top"
                title={
                  <>
                    <TooltipText variant="body4" strong={true}>
                      Taxa média de visualização
                    </TooltipText>
                    <TooltipText variant="body4">
                      A taxa é calculada a partir do total de campanhas enviadas
                      dividido pelo total de destinatários que acessaram a área
                      de notificações e visualizaram as mensagens no feed.
                    </TooltipText>
                  </>
                }
              >
                <div>
                  <Icons
                    size={18}
                    name={"IconInfoCircle"}
                    fill={"transparent"}
                    color={"var(--color-neutral-dark3)"}
                  />
                </div>
              </Tooltip>
            </TooltipContainer> */}
            </BigNumberContentContainer>
          </BigNumberContainer>,
          <BigNumberContainer>
            <ShapeIcon
              name="IconGraph"
              variant={"default"}
              color="var(--color-secondary-50)"
              size={48}
            />
            <BigNumberContentContainer>
              <Typography variant="body3" color={"var(--color-neutral-50)"}>
                Taxa de resposta
              </Typography>
              <Typography variant="headline5" color={"var(--color-neutral-30)"}>
                {answerRate
                  ? `${Math.round(answerRate)}%`
                  : Math.round(answerRate)}
              </Typography>
              {/* <TooltipContainer>
              <Tooltip
                placement="top"
                title={
                  <>
                    <TooltipText variant="body4" strong={true}>
                      Taxa média de resposta
                    </TooltipText>
                    <TooltipText variant="body4">
                      A taxa é calculada a partir do total de pesquisas enviadas
                      dividido pelo total de destinatários que responderam.
                    </TooltipText>
                  </>
                }
              >
                <div>
                  <Icons
                    size={18}
                    name={"IconInfoCircle"}
                    fill={"transparent"}
                    color={"var(--color-neutral-dark3)"}
                  />
                </div>
              </Tooltip>
            </TooltipContainer> */}
            </BigNumberContentContainer>
          </BigNumberContainer>,
        ]}
        gap={24}
        arrows={"bottom"}
      />
    </CarouselContainer>
  </Container>
);
