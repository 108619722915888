import { LinkButton } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const PreviewContainer = styled.div<{ variant?: string }>`
  display: grid;
  grid-template-columns: 45px 1fr max-content;
  align-items: center;
  gap: 10px;
  padding: 32px 40px;
  border: 1px solid
    ${({ variant, theme }) =>
      variant == "secondary"
        ? theme.colors.secondary[70]
        : theme.colors.neutral[90]};
  border-radius: 12px;
  overflow: scroll;
`;

export const PreviewImage = styled.img`
  width: 45px;
  height: 45px;
  object-fit: contain;
`;

export const StyledLinkButton = styled(LinkButton)`
  && {
    &.MuiButtonBase-root {
      padding: 0px;
      color: ${({ theme }) => theme.colors.neutral[40]};

      &::before {
        border-bottom: 2px solid ${({ theme }) => theme.colors.neutral[40]} !important;
      }
    }
  }
`;
