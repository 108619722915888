import { useParams } from "react-router-dom";
import { Form } from "../../components/EngagementForm";

export default ({
  type,
  forceEngaja,
}: {
  type: "communication" | "survey";
  forceEngaja?: boolean;
}) => {
  const { id } = useParams();
  return <Form id={id} type={type} forceEngaja={forceEngaja || false} />;
};
