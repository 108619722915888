import { Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  max-width: 674px;
  border-radius: 8px;
  border: 1px solid var(--Neutral-Neutral-90, #ebe6e9);
  padding: 64px 48px;
  position: relative;
`;

const Title = styled(Typography)`
  color: var(--color-primary);
  margin: 10px 0 5px 0;
  font-weight: 700 !important;
`;

const Description = styled(Typography)`
  color: var(--color-neutral-dark4);
  font-weight: 400 !important;
`;

export { Container, Title, Description };
