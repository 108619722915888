import styled from "styled-components";
import { Typography } from "@flash-tecnologia/hros-web-ui-v2";

const Header = styled.header<{ alertMode?: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  > :first-child {
    position: absolute;
    top: -40px;
    background-color: ${({ alertMode }) =>
      alertMode
        ? "var(--color-feedback-error-light1)"
        : "var(--color-secondary-light2)"};
    padding: 0 !important;
    height: 80px;
    width: 80px;
  }

  > :nth-child(2) {
    position: absolute;
    top: 20px;
    right: 20px;
  }
`;

const Title = styled(Typography)<{ alertMode?: boolean }>`
  color: ${({ alertMode }) =>
    alertMode ? "var(--color-feedback-error-dark1)" : "var(--color-primary)"};
  max-width: 390px;
  text-align: center;
  padding-top: 50px;
`;

const Body = styled.div`
  width: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
`;

const Message = styled(Typography)<{ alertMode?: boolean }>`
  color: var(--color-neutral-dark5);
  max-width: 390px;
  text-align: center;
`;

const Footer = styled.footer`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 25px;

  button {
    margin: 0 15px;
  }
`;

const Gap = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  gap: 12px;
`;

export { Body, Header, Title, Message, Footer, Gap };
