import { Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  padding: 39px;
  border: 1px solid var(--color-neutral-light2);
  border-radius: 12px;
  width: 100%;
`;

const HeaderChildrenContainer = styled.div`
  margin-right: 10px;
`;

const Header = styled.header<{ margin?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: ${({ margin }) => (margin ? "32px" : 0)};
`;

const Title = styled(Typography)`
  color: var(--color-neutral-dark3);
  margin-bottom: 4px;
`;

const Message = styled(Typography)`
  color: var(--color-neutral-dark5);
`;

export { Container, Header, HeaderChildrenContainer, Title, Message };
