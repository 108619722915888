import { Icons } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

const StyledPOneLine = styled.p<{ numberOfLines: number }>`
  display: block;
  display: -webkit-box;
  line-height: ${({ numberOfLines }) => numberOfLines};
  -webkit-line-clamp: ${({ numberOfLines }) => numberOfLines};
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
`;

export const StyledPreviewProgress = styled.div`
  width: 100%;
  height: 4px;
  background: #fe67af;
  border-radius: 12px;
  margin-top: 10px;

  animation-duration: 6s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: wave;
  animation-timing-function: linear;
  background: linear-gradient(to right, #fe67af 50%, #da0b6d 80%);
`;

export const PreviewLoading = () => {
  return (
    <div
      style={{
        pointerEvents: "none",
        userSelect: "none",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "32px 40px",
          border: "1px solid #EBE6E9",
          borderRadius: "10px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            marginRight: "7px",
          }}
        >
          <div
            style={{
              marginRight: "7px",
              borderRadius: "4px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Icons name={"IconFile"} style={{ fill: "transparent" }} />
          </div>
          <div
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "column",
            }}
          >
            <span
              style={{
                fontWeight: "700",
                fontSize: "16px",
                lineHeight: "22px",
                color: "#3B2E37",
              }}
            >
              <StyledPOneLine numberOfLines={1}>
                Carregando o arquivo, por favor, aguarde!
              </StyledPOneLine>
            </span>
            <StyledPreviewProgress />
          </div>
        </div>
      </div>
    </div>
  );
};
