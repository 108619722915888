import styled from "styled-components";

export const Header = styled.header`
  background: rgb(255, 255, 255);
  display: flex;
  width: 100%;
  flex-direction: row;
  -webkit-box-pack: center;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  padding: 14px 24px;
`;

export const Container = styled.div`
  display: flex;
  padding-bottom: 40px;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  background: #f8f6f8;
  min-height: 100%;
`;

export const Card = styled.div`
  display: flex;
  padding: 24px 24px 0;
  gap: 16px;
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.06);

  flex-direction: column;

  max-width: 648px;
  align-items: flex-start;

  @media screen and (min-width: 768px) {
    min-width: 648px;
    align-items: stretch;
  }
`;

export const Signature = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const Logo = styled.img`
  width: 56px;
  height: 20px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 24px;
`;

export const ImageContainer = styled.div<{ uri: string }>`
  min-height: 360px;
  width: 100%;
  background-color: blueviolet;
  background-image: url(${({ uri }) => uri});
  background-size: contain;
  background-color: red;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #ffffff;
`;

export const ReactionsContainer = styled.div`
  width: 100%;
  padding-bottom: 40px;
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  margin-left: 12px;
  max-width: calc(100% - 60px);
`;

export const TextWithHtml = styled.div`
  color: var(--color-neutral-dark4);
  font-size: calc(1rem * 0.875);
  width: 100%;

  ul,
  ol {
    padding-left: 0.5em;
  }

  ul li,
  ol li {
    list-style-position: outside;
    margin-left: 1em;
  }
`;
