import { PageContainer, Skeleton } from "@flash-tecnologia/hros-web-ui-v2";
import { Container, Row } from "./styles";

const LaodingScreen = () => (
  <PageContainer>
    <Container>
      <Skeleton
        height="58px"
        width="35%"
        style={{ borderRadius: "12px", margin: "24px 0" }}
      />
      <Row>
        <Skeleton height="130px" width="23%" style={{ borderRadius: "12px" }} />
        <Skeleton height="130px" width="23%" style={{ borderRadius: "12px" }} />
        <Skeleton height="130px" width="23%" style={{ borderRadius: "12px" }} />
        <Skeleton height="130px" width="23%" style={{ borderRadius: "12px" }} />
      </Row>
      <Skeleton
        height="58px"
        width="100%"
        style={{ borderRadius: "12px", margin: "24px 0" }}
      />
      <Skeleton
        height="calc(100vh - 450px)"
        width="100%"
        style={{ borderRadius: "12px" }}
      />
    </Container>
  </PageContainer>
);

export default LaodingScreen;
