import styled from "styled-components";

const Container = styled.div`
  width: 100%;

  > div {
    margin-bottom: 40px;
  }

  > :last-child {
    margin-bottom: 0;
  }
`;

const TableContainer = styled.div`
  width: 100%;
`;

const Table = styled.table`
  width: 100%;
`;

const Th = styled.th`
  text-align: left;
`;

const Td = styled.td`
  padding: 16px 0;
  border-bottom: 1px solid var(--color-neutral-90);
  text-align: left;
`;

export { Container, TableContainer, Table, Th, Td };
