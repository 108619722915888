import styled from "styled-components";
import {
  Typography,
  LinkButton as LinkButtonUi,
} from "@flash-tecnologia/hros-web-ui-v2";

const LinkButton = styled(LinkButtonUi)<{ greyMode?: boolean }>`
  color: ${({ greyMode }) =>
    greyMode
      ? "var(--color-neutral-50)"
      : "var(--color-secondary-50)"} !important;

  :before {
    border-color: ${({ greyMode }) =>
      greyMode
        ? "var(--color-neutral-50)"
        : "var(--color-secondary-50)"} !important;
  }

  :hover {
    color: var(--color-secondary-50) !important;

    :before {
      border-color: var(--color-secondary-50) !important;
    }
  }
`;

const LinkButtonsContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;

  button:not(:last-child) {
    margin-right: 20px;
  }
`;

const ReviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid var(--color-neutral-light2);
  border-radius: 8px;
  padding: 25px;
  width: 100%;
`;

const ReviewContainerBetween = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ReviewSeparator = styled.div`
  display: block;
  width: 100%;
  height: 2px;
  background-color: var(--color-neutral-light2);
  margin: 15px 0 20px 0;
`;

const ReviewDataContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

const ReviewTitle = styled(Typography)`
  color: var(--color-neutral-dark3);
  font-weight: 700 !important;
`;

const ReviewText = styled(Typography)`
  color: var(--color-neutral-dark4);
`;

const ScaleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const InfoContainer = styled.div`
  width: 100%;
  margin-bottom: 10px;
  margin-right: auto;
`;

const InfoText = styled(Typography)`
  color: var(--color-neutral-dark4);
  margin-left: 10px;
`;

const ScaleItem = styled.div<{
  fullWidth?: boolean;
  width?: string;
  height?: string;
}>`
  height: ${({ height }) => (height ? height : "100px")};
  width: ${({ fullWidth, width }) => (fullWidth ? "100%" : width || "100px")};
  border-radius: 4px;
  background-color: var(--color-neutral-light2);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0 5px;
`;

const ButtonStepsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const CellContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const ToogleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 8px;
`;

const ToggleTextContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  margin-left: 15px;
`;

const EmptyStageMessageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 250px;
`;

const FormContainer = styled.div`
  width: 100%;
  height: calc(100vh - 171px);
  padding: 0 25px;
  overflow-x: auto;
`;

const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid var(--color-neutral-light2);
  height: 73px;
  padding: 0 25px;
`;

const CropperModalHeader = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  width: 400px;
  margin: 0 auto;
  padding-top: 25px;

  > :nth-child(1) {
    position: absolute;
    top: 20px;
    right: 20px;
  }
`;

const CropperModalFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 400px;
  margin: 0 auto;
  padding-bottom: 25px;
`;

const CropperModalTitle = styled(Typography)`
  color: var(--color-neutral-dark2);
`;

const CropperModalSubTitle = styled(Typography)`
  color: var(--color-neutral-dark5);
`;

const ImagePreview = styled.img`
  border-radius: 8px;
  width: 400px;
`;

const DropzoneContainer = styled.div`
  .dropzone-text-container {
    text-align: center !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }
`;

const TransferListContainer = styled.div`
  max-width: 100%;
  height: 800px;
  .transfer-list-filter-search-field-container {
    :first-child,
    > div > div {
      width: 100%;
    }
    input {
      width: 100% !important;
    }
  }
`;

const PreviewTextFieldContainer = styled.div`
  div > div {
    height: 200px !important;
  }
`;

const TagContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
`;

const FormRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const InputContainer = styled.div`
  width: 100%;
`;

const PeriodLabel = styled(Typography)`
  color: var(--color-neutral-dark3);
  font-weight: 400;
  min-width: max-content;
`;

const AccordionList = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  width: 100%;

  > :nth-child(1),
  > :nth-child(3) {
    width: 100% !important;
  }
`;

const AccordionTitle = styled(Typography)`
  color: var(--color-neutral-dark3);
  font-weight: 700;
  min-width: max-content;
`;

const AccordionDescription = styled(Typography)`
  color: var(--color-neutral-dark5);
  font-weight: 400;
  min-width: max-content;
`;

export {
  ReviewContainer,
  ReviewSeparator,
  ReviewDataContainer,
  ReviewContainerBetween,
  ReviewTitle,
  ReviewText,
  ScaleContainer,
  ScaleItem,
  ButtonStepsContainer,
  CellContainer,
  ToogleContainer,
  ToggleTextContainer,
  EmptyStageMessageContainer,
  FormContainer,
  Header,
  CropperModalFooter,
  CropperModalHeader,
  CropperModalTitle,
  CropperModalSubTitle,
  ImagePreview,
  DropzoneContainer,
  TransferListContainer,
  PreviewTextFieldContainer,
  InfoContainer,
  InfoText,
  TagContainer,
  LinkButton,
  LinkButtonsContainer,
  TitleContainer,
  FormRow,
  InputContainer,
  PeriodLabel,
  AccordionList,
  AccordionTitle,
  AccordionDescription,
};
