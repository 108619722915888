import styled from "styled-components";

const handleWidth = (size?: string) => {
  if (size === "xs") return "2px";
  if (size === "sm") return "4px";
  if (size === "md") return "8px";
  if (size === "lg") return "16px";
  if (size === "xl2") return "32px";
};

const Space = styled.div<{ size: "xs" | "sm" | "md" | "lg" | "xl2" }>`
  display: inline;
  height: auto;
  background-color: red;
  min-width: ${({ size }) => handleWidth(size)};
`;

export { Space };
