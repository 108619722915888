import { CardInterface } from "./types";
import {
  Container,
  ButtonsContainer,
  ContentContainer,
  SubtitleContainer,
  IconsWrapper,
} from "./styles";
import {
  Button,
  Icons,
  ShapeIcon,
  Spinner,
  Tag,
} from "@flash-tecnologia/hros-web-ui-v2";
import { Typography } from "@components/Typography";
import { Divider } from "@components/v2";
import { FloatContent } from "./components";

const Card = ({
  loading,
  model,
  icon,
  title,
  subtitle,
  open,
  onUse,
  onDetail,
  disabled,
  modelTitle,
  modelDescription,
  showNewTag,
  innerCardProps,
}: CardInterface) => (
  <Container>
    <FloatContent
      modelTitle={modelTitle}
      open={open}
      onClose={(e) => onDetail(e)}
      onClick={() => (disabled ? null : onUse(model))}
      modelDescription={modelDescription}
      innerCardProps={innerCardProps}
      disabled={disabled}
    />
    <ContentContainer>
      <IconsWrapper>
        <ShapeIcon
          name={icon}
          variant="default"
          size={48}
          color="var(--color-secondary-50)"
        />
        {showNewTag && (
          <Tag variant="primary" as="span">
            <Icons name="IconSparkles" size={16} />
            Novidade
          </Tag>
        )}
      </IconsWrapper>
      <Divider spacing="xxs3" />
      <Typography
        variant="headline7"
        fontWeight={700}
        color="var(--color-neutral-30)"
      >
        {title}
      </Typography>
      <SubtitleContainer>
        <Typography
          variant="body3"
          fontWeight={400}
          color="var(--color-neutral-40)"
        >
          {subtitle}
        </Typography>
      </SubtitleContainer>
    </ContentContainer>
    <ButtonsContainer>
      <Button size="medium" variant="secondary" onClick={() => onDetail(!open)}>
        Detalhes
      </Button>
      <Button
        size="medium"
        variant="primary"
        disabled={disabled}
        onClick={() => (disabled ? null : onUse(model))}
      >
        {loading ? <Spinner variant="primary" size={24} /> : "Usar"}
      </Button>
    </ButtonsContainer>
  </Container>
);

export { Card };
