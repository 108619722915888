import { Icons, GenericProfilePicture } from "@flash-tecnologia/hros-web-ui-v2";
import { Typography } from "../../../../Typography";
import { Container, EmailContainer, TextContainer } from "./styles";
import { EmployeeRowInterface } from "./types";

const EmployeeRow = ({ id, name, email, onRemove }: EmployeeRowInterface) => (
  <Container>
    <GenericProfilePicture name={name} size={40} />
    <TextContainer>
      <Typography
        variant="body3"
        fontWeight={600}
        color="var(--color-neutral-40)"
      >
        {name?.length > 20 ? `${name?.slice(0, 20)}...` : name}
      </Typography>
      {onRemove ? null : email?.length ? (
        <EmailContainer>
          <Icons size={14} name={"IconMail"} color="var(--color-neutral-60)" />
          <Typography
            variant="body4"
            fontWeight={600}
            color="var(--color-neutral-50)"
          >
            {(email || "")?.length > 60 ? `${email?.slice(0, 60)}...` : email}
          </Typography>
        </EmailContainer>
      ) : (
        <EmailContainer>
          <Icons size={14} name={"IconAlertTriangle"} color="#C96C01" />
          <Typography variant="body4" fontWeight={600} color="#C96C01">
            E-mail corp. não cadastrado
          </Typography>
        </EmailContainer>
      )}
    </TextContainer>
    {onRemove ? (
      <Icons
        name="IconTrash"
        color="var(--color-neutral-50)"
        onClick={() => onRemove(id)}
        style={{ cursor: "pointer" }}
      />
    ) : null}
  </Container>
);

export { EmployeeRow };
