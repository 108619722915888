import styled from "styled-components";

const SectionContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const RightHeaderContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
`;

const QuestionContainer = styled.div`
  width: 100%;
  margin-bottom: 40px;
`;

const TagContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export {
  SectionContainer,
  Header,
  RightHeaderContent,
  QuestionContainer,
  TagContainer,
};
