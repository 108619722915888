import { Checkbox } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

const TextAreaContainer = styled.div`
  * {
    color: var(--color-neutral-40) !important;

    textarea {
      color: rgb(83, 70, 79) !important;
      outline: none !important;

      :focus {
        box-shadow: 0px 0px 0px 1px var(--color-secondary-70) !important;
        border-color: var(--color-secondary-70) !important;
      }
    }
  }
`;

const ErrorAsterisk = styled.span`
  color: ${({ theme }) => theme.colors.feedback.error[40]};
`;

const SectionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const SectionSubContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const TermsConditionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const StyledCheckbox = styled(Checkbox)`
  padding: 0;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 0px ${({ theme }) => theme.spacings.xs2};

  top: -16px;
  position: relative;
  right: 0;
`;

export {
  TextAreaContainer,
  SectionContainer,
  SectionSubContainer,
  ErrorAsterisk,
  TermsConditionsContainer,
  StyledCheckbox,
  InfoWrapper,
};
