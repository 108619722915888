import styled from "styled-components";
import { Typography } from "@flash-tecnologia/hros-web-ui-v2";

const Container = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  width: 100%;
`;

const Row = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
`;

const Label = styled(Typography)`
  color: var(--color-neutral-50) !important;
  font-weight: 700 !important;
`;

const Value = styled(Typography)`
  color: var(--color-neutral-40) !important;
  font-weight: 600 !important;
`;

export { Container, Row, Label, Value };
