import styled from "styled-components";

const SelectsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 16px;
  border: 1px solid var(--color-neutral-90);
  border-radius: 12px;
  margin-bottom: 16px;
`;

const ChartSeparator = styled.div`
  width: 100%;
  height: 1px;
  display: block;
  background-color: var(--color-neutral-90);
  margin: 24px 0;
`;

const VerticalBarChartContainer = styled.div`
  margin-left: -33px;
`;

const ChartContainer = styled.div<{ hideBorder?: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid var(--color-neutral-90);
  border-color: ${({ hideBorder }) =>
    hideBorder ? "var(--color-neutral-100)" : "var(--color-neutral-90)"};
  border-radius: 12px;
  padding: ${({ hideBorder }) => (hideBorder ? "0" : "32px")};
`;

const StrategiesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const GraphContainer = styled.div<{ hideBorder?: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid var(--color-neutral-90);
  border-color: ${({ hideBorder }) =>
    hideBorder ? "var(--color-neutral-100)" : "var(--color-neutral-90)"};
  border-radius: 12px;
`;

const GraphHeader = styled.div`
  padding: 12px 24px;
  border-bottom: 1px solid var(--Neutral-Neutral-90, #ebe6e9);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const TagsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

const GraphDescription = styled.div`
  display: flex;
  padding: 24px 24px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
`;

const GraphImpactTextsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0px 85px 24px 120px;
`;

export {
  SelectsContainer,
  ChartSeparator,
  VerticalBarChartContainer,
  ChartContainer,
  StrategiesContainer,
  GraphContainer,
  GraphHeader,
  TagsWrapper,
  GraphDescription,
  GraphImpactTextsWrapper,
};
