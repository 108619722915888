import styled from "styled-components";

const MainContainer = styled.div<{ open: boolean }>`
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  flex-direction: column;
  position: fixed;
  top: 0;
  right: ${({ open }) => (open ? 0 : "-100%")};
  height: 100vh;
  width: 100%;
  z-index: 4;
  transition: 0.5s ease-in-out;
`;

const Shadow = styled.div`
  position: fixed;
  top: 0;
  right: left;
  width: 200%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);
  transition: 0;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  height: 100vh;
  width: 500px;
  background-color: #ffffff;
  border-left: 1px solid var(--color-neutral-90);
  z-index: 5;
`;

const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 102px;
  padding: 32px 40px 32px 40px;
  gap: 24px;
  border-bottom: 1px solid var(--color-neutral-90);
`;

const CloseButton = styled.div`
  height: 40px;
  width: 40px;
  border-radius: 40px;
  border: 1px solid var(--color-neutral-80);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  cursor: pointer;
`;

const ContentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: 24px;
  padding: 32px 40px 32px 40px;
  overflow: auto;
`;

const Footer = styled.footer`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 94px;
  padding: 32px 40px 32px 40px;
  border-top: 1px solid var(--color-neutral-90);
`;

export {
  MainContainer,
  Container,
  Header,
  CloseButton,
  ContentContainer,
  Shadow,
  Footer,
};
