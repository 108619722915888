import { Modal } from "@components/v2";
import { segment } from "@utils";

export const WarningEmployeesModal = ({
  loading,
  employeesWithoutCorporateEmailCount,
  showCorporateEmailModal,
  setShowCorporateEmailModal,
  onSubmit,
}) => {
  const handleSubmitButtonClick = async () => {
    const trackType = "communication";

    segment({
      track: `company_engagement_${trackType}review_create${trackType}_clicked`,
    });

    onSubmit();
  };

  return (
    <Modal
      title="Atenção!"
      subTitle={`${employeesWithoutCorporateEmailCount} destinatário(s) não possuem <br/> e-mail corporativo <br/> cadastrado`}
      description="Ao continuar, você está ciente de que essas pessoas não <br/> receberão a notificação por e-mail."
      onClose={() => setShowCorporateEmailModal(!showCorporateEmailModal)}
      closeButton
      iconStatus="alert"
      open={showCorporateEmailModal}
      iconName="IconAlertCircle"
      footer={{
        closeButton: {
          label: "Cancelar",
          onClick: () => setShowCorporateEmailModal(!showCorporateEmailModal),
          variant: "neutral",
          disabled: loading,
        },
        submitButton: {
          label: "Continuar",
          onClick: () => handleSubmitButtonClick(),
          size: "large",
          variant: "secondary",
          variantType: "error",
          disabled: loading,
        },
      }}
    />
  );
};
