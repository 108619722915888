import styled from "styled-components";

const getSize = (size?: string) => {
  if (size === "medium") return "30px";
  if (size === "large") return "50px";
  if (size === "tiny") return "16px";
  return "24px";
};

const Divider = styled.div<{ size?: "tiny" | "small" | "medium" | "large" }>`
  height: ${({ size }) => getSize(size)};
  width: 100%;
`;

export { Divider };
