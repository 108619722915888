import styled from "styled-components";

const TableContainer = styled.div`
  max-width: 100% !important;
  width: 100% !important;
  overflow-y: auto !important;
`;

const DataGridContainer = styled.div`
  .MuiDataGrid-columnHeaders {
    background-color: var(--color-neutral-light1) !important;
  }

  .MuiDataGrid-root {
    border-color: var(--color-neutral-light2) !important;
  }

  .MuiDataGrid-columnSeparator,
  .MuiDataGrid-menuIcon {
    display: none !important;
  }

  .data-grid-table-body-td:not(:last-child) {
    min-width: 250px !important;
  }

  table {
    tr {
      td:last-child {
        width: 95px !important;
        max-width: 250px !important;
      }
    }

    tr {
      th:last-child {
        width: 95px !important;
        max-width: 250px !important;
      }
    }
  }

  .data-grid-table-container {
    &.sticky {
      .data-grid-table-header-container {
        position: relative !important;
      }
    }
  }
`;

const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export { DataGridContainer, TableContainer, FilterContainer };
