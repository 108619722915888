import { Icons, Tooltip, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { ReactNode } from "react";
import { useTheme } from "styled-components";

interface DataItemProps {
  title?: string;
  description?: string | ReactNode;
  content?: ReactNode;
  tooltip?: string;
}

export const DataItem = ({
  title,
  description,
  content,
  tooltip,
}: DataItemProps) => {
  const theme = useTheme() as any;

  const renderDescription = (description: string | ReactNode) => {
    const isString = typeof description === "string";

    if (isString)
      return (
        <Typography
          variant="body3"
          style={{
            fontSize: 16,
            color: theme.colors.neutral[40],
            fontWeight: 600,
          }}
          children={description}
        />
      );

    return description;
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "4px" }}>
      {title && (
        <div style={{ display: "flex", flexDirection: "row", gap: "4px" }}>
          <Typography
            variant="caption"
            variantColor={theme.colors.neutral[50]}
            style={{ fontWeight: 700 }}
          >
            {title}
          </Typography>
          {tooltip && (
            <Tooltip
              title="O criador é um responsável que não pode ser removido da lista de responsáveis e suas permissões não podem ser modificadas."
              placement="top-start"
            >
              <button>
                <Icons
                  name="IconInfoCircle"
                  fill="transparent"
                  color="#83727D"
                  size={16}
                />
              </button>
            </Tooltip>
          )}
        </div>
      )}
      {description && renderDescription(description)}
      {content}
    </div>
  );
};
