import { useEffect, useState } from "react";

import { Button, dayjs, Icons, Tag } from "@flash-tecnologia/hros-web-ui-v2";
import { getAccessTokenPayloadSync } from "@flash-tecnologia/hros-web-utility";
import { useNavigate } from "react-router-dom";
import { trpc } from "@api/client";
import {
  TitleContainer,
  Title,
  PageContainer,
  DataGrid,
  Divider,
  CardSkeleton,
  DataGridSkeleton,
  CarouselCards,
} from "../../components";
import { alert, segment } from "../../utils";
import { FiltersType } from "../../components/TableFilter/types";

export default () => {
  const navigate = useNavigate();
  const { employeeId } = getAccessTokenPayloadSync("hrosAccessToken");

  const filterConfig: FiltersType = [
    {
      id: "status",
      type: "multi-select",
      label: "Status",
      valueInLabel: true,
      options: [
        {
          label: "Disponível",
          value: "available",
          checked: false,
        },
        {
          label: "Não concluído",
          value: "started",
          checked: false,
        },
        {
          label: "Concluído",
          value: "done",
          checked: false,
        },
        {
          label: "Encerrado",
          value: "closed",
          checked: false,
        },
      ],
    },
    {
      id: "anonymous",
      type: "boolean",
      label: "Anonimato",
      valueInLabel: true,
      options: [
        {
          label: "Anônimo",
          value: "true",
          checked: false,
        },
        {
          label: "Não anônimo",
          value: "false",
          checked: false,
        },
      ],
    },
    {
      id: "sendDate",
      type: "date",
      label: "Data de envio",
      valueInLabel: true,
    },
    {
      id: "interactionDeadline",
      type: "date",
      label: "Prazo resposta",
      valueInLabel: true,
    },
  ];

  const [filters, setFilters] = useState<FiltersType>(filterConfig);
  const [search, setSearch] = useState<any>(null);
  const [data, setData] = useState<typeof surveys>([]);

  const { data: surveys, isFetching: getMyEngagementsByEmployeeIdLoading } =
    trpc.getMyEngagementsByEmployeeId.useQuery(
      {
        id: employeeId,
        type: "survey",
      },
      {
        retry: false,
        refetchOnWindowFocus: false,
        onSuccess: (e) => setData(e),
        onError: (e: any) => {
          alert("Ocorreu um erro, tente novamente mais tarde.");
          console.log("err: ", e);
        },
      }
    );

  useEffect(() => {
    let result = surveys;

    if (search?.length) {
      result =
        result?.filter(({ title = "" }) =>
          title?.match(RegExp(search, "ig"))
        ) || [];
    }

    const statusFilter = filters[0]?.options
      ?.filter(({ checked }) => checked)
      ?.map(({ value }) => value);
    if (statusFilter?.length) {
      result = result?.filter(({ monitoring, interactionDeadline }) => {
        const status = getSurveyStatus(monitoring, interactionDeadline);

        if (statusFilter?.includes(status)) return true;
        return false;
      });
    }

    const anonymousFilter = filters[1]?.options
      ?.filter(({ checked }) => checked)
      ?.map(({ value }) => value);
    if (anonymousFilter?.length) {
      result = result?.filter(
        ({ anonymous }) =>
          (anonymous && anonymousFilter[0] === "true") ||
          (!anonymous && anonymousFilter[0] === "false")
      );
    }

    const sendDateFilter = filters[2]?.dateRange;
    if (sendDateFilter?.from) {
      result = result?.filter(({ sendDate }) => {
        const from = sendDateFilter?.from;
        const to = sendDateFilter?.to;

        if (from) {
          if (dayjs(from)?.diff(dayjs(sendDate)?.format("YYYY-MM-DD")) <= 0) {
            if (
              to &&
              dayjs(to)?.diff(dayjs(sendDate)?.format("YYYY-MM-DD")) < 0
            )
              return false;
            return true;
          }

          return false;
        }
      });
    }

    const interactionDeadlineFilter = filters[3]?.dateRange;
    if (interactionDeadlineFilter?.from) {
      result = result?.filter(({ interactionDeadline }) => {
        const from = interactionDeadlineFilter?.from;
        const to = interactionDeadlineFilter?.to;

        if (from) {
          if (dayjs(from)?.diff(interactionDeadline) <= 0) {
            if (to && dayjs(to)?.diff(interactionDeadline) < 0) return false;
            return true;
          }

          return false;
        }
      });
    }

    setData([...(result || [])]);
  }, [filters, search, surveys]);

  const getSurveyStatus = (monitoring: any, interactionDeadline?: string) => {
    if (monitoring?.sections?.length && monitoring?.draft === false)
      return "done";

    if (dayjs(interactionDeadline)?.diff(dayjs()?.format("YYYY-MM-DD")) < 0)
      return "closed";

    if (monitoring?.sections?.length) return "started";
    return "available";
  };

  if (getMyEngagementsByEmployeeIdLoading)
    return (
      <PageContainer>
        <CardSkeleton />
        <Divider />
        <DataGridSkeleton />
      </PageContainer>
    );

  return (
    <PageContainer>
      <TitleContainer>
        <Title>Minhas pesquisas</Title>
      </TitleContainer>
      <Divider size="large" />
      <CarouselCards
        cards={[
          {
            title: "Total de pesquisas",
            icon: "IconCheckbox",
            value: surveys?.length || 0,
          },
          {
            title: "Pesquisas concluídas",
            value:
              surveys?.filter((s) => s?.monitoring?.draft === false)?.length ||
              0,
            icon: "IconCheckupList",
            toolTip: {
              title: "Pesquisas concluídas",
              description: "São as pesquisas que foram respondidas.",
            },
          },
          {
            title: "Pesquisas disponíveis",
            value:
              surveys?.filter(
                (s) =>
                  getSurveyStatus(s?.monitoring, s?.interactionDeadline) ===
                  "available"
              )?.length || 0,
            icon: "IconClipboard",
            toolTip: {
              title: "Pesquisas disponíveis",
              description:
                "São as pesquisas que estão dentro do prazo de resposta, mas ainda não foram respondidas.",
            },
          },
          {
            title: "Pesquisas não concluídas",
            value:
              surveys?.filter(
                (s) =>
                  getSurveyStatus(s?.monitoring, s?.interactionDeadline) ===
                  "started"
              )?.length || 0,
            icon: "IconClipboardX",
            toolTip: {
              title: "Pesquisas não concluídas",
              description:
                " São as pesquisas que estão dentro do prazo de resposta e começaram a ser preenchidas, mas as respostas ainda não foram enviadas.",
            },
          },
        ]}
      />
      <Divider size="medium" />
      <DataGrid
        emptyState={{
          isFiltered: true,
          children: <>Aguarde até que novas pesquisas sejam publicadas.</>,
          message: "Você ainda não tem pesquisas para responder.",
        }}
        loading={getMyEngagementsByEmployeeIdLoading}
        searchField={{
          value: search || "",
          onSearch: (value: string) => setSearch(value),
          placeholder: "Buscar por pesquisa",
        }}
        filterTags={{
          filters: filters,
          onFilter: (e) => setFilters(e),
          onClear: (e) => setFilters(e),
        }}
        columns={[
          {
            Header: "Pesquisas",
            accessor: "title",
          },
          {
            id: "status",
            Header: "Status",
            accessor: "monitoring",
            Cell: ({ value, row }) => {
              const status = getSurveyStatus(
                value,
                row?.original?.interactionDeadline
              );

              if (status === "available") {
                return <Tag variant="blue">Disponivel</Tag>;
              } else if (status === "started") {
                return <Tag variant="yellow">Não concluído</Tag>;
              } else if (status === "closed") {
                return <Tag variant="gray">Encerrado</Tag>;
              } else {
                return <Tag variant="success">Concluído</Tag>;
              }
            },
          },
          {
            id: "anonymous",
            Header: "Resposta Anônima",
            accessor: "anonymous",
            Cell: ({ value }) => (
              <Tag variant={value ? "success" : "yellow"}>
                {value ? "Sim" : "Não"}
              </Tag>
            ),
          },
          {
            Header: "Data de Envio",
            accessor: "sendDate",
            Cell: ({ value }) => (
              <>{value ? dayjs(value)?.format("DD/MM/YYYY") : "-"}</>
            ),
          },
          {
            Header: "Prazo de resposta",
            accessor: "interactionDeadline",
            Cell: ({ value }) => (
              <>{value?.length ? dayjs(value)?.format("DD/MM/YYYY") : "-"}</>
            ),
          },
          {
            disableSortBy: true,
            sticky: "right",
            accessor: "monitoring",
            Cell: ({ value, row }) => {
              const status = getSurveyStatus(
                value,
                row?.original?.interactionDeadline
              );

              let label = "";

              if (status === "available") {
                label = "Responder";
              } else if (status === "started") {
                label = "Continuar";
              } else if (status === "closed") {
                segment({
                  track:
                    "people_strategic_hr_engagement_employee_surveys_answer_clicked",
                });
                label = "Responder";
              } else {
                label = "Ver resposta";
              }

              return (
                <Button
                  variant={status === "done" ? "secondary" : "primary"}
                  size="medium"
                  onClick={() =>
                    label === "Ver resposta"
                      ? navigate(
                          `/engagement/employee/${employeeId}/survey/${row?.original?._id}/answer`
                        )
                      : window.open(
                          `/engagement/survey/answer/0/${row?.original?._id}`,
                          "_blank"
                        )
                  }
                  style={{ width: 194 }}
                  disabled={status === "closed"}
                >
                  {label}
                  <Icons name="IconArrowRight" />
                </Button>
              );
            },
          },
        ]}
        data={data || []}
      />
    </PageContainer>
  );
};
