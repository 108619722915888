import styled from "styled-components";
import { Typography } from "@flash-tecnologia/hros-web-ui-v2";

const Container = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  position: relative;
  border: 1px solid var(--color-neutral-light2);
  padding: 40px;
  border-radius: 12px;
  width: 100%;
`;

const Title = styled(Typography)`
  color: var(--color-neutral-30) !important;
  font-weight: 700 !important;
`;

const Subtitle = styled(Typography)`
  color: var(--color-neutral-50) !important;
  font-weight: 400 !important;
`;

const Label = styled(Typography)`
  color: var(--color-neutral-50) !important;
  font-weight: 700 !important;
`;

const Value = styled(Typography)`
  color: var(--color-neutral-40) !important;
  font-weight: 600 !important;
`;

const Row = styled.div`
  width: 100%;
`;

export { Container, Title, Subtitle, Row, Label, Value };
