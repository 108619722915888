import styled from "styled-components";

const Dot = styled.div`
  background-color: var(--color-neutral-dark5);
  height: 5px;
  width: 5px;
  border-radius: 100%;
  margin: 0 10px;
`;

export { Dot };
