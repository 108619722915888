import styled, { css } from "styled-components";

import { TextEditor } from "@flash-tecnologia/hros-web-ui-v2";

export const CharCountContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 4px 16px;
`;

const EditorErrorCss = css`
  & .custom-ql-toolbar {
    border: 1px solid ${({ theme }) => theme.colors.feedback.error[50]} !important;
  }

  & .quill .ql-container {
    border: 1px solid ${({ theme }) => theme.colors.feedback.error[50]} !important;
    border-top: none !important;
    background: ${({ theme }) => theme?.colors?.feedback.error[90]};
  }
`;

export const StyledTextEditor = styled(TextEditor)`
  ${({ onError }) => onError && EditorErrorCss}
`;

export const EditorContainer = styled.div`
  width: 100%;

  & #helper-text {
    font-size: 12px;
    line-height: normal;
    letter-spacing: normal;
    margin: 4px 0px 0px 16px;
    font-family: var(--font-family-secondary);
  }
`;
