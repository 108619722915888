import { Button, Icons } from "@flash-tecnologia/hros-web-ui-v2";
import { Typography } from "../../../../../..";
import { Divider } from "@components/v2";
import { Container } from "./styles";
import { EmptyStateInterface } from "./types";

const EmptyState = ({ onClick }: EmptyStateInterface) => {
  return (
    <Container>
      <svg
        width="106"
        height="126"
        viewBox="0 0 106 126"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M53.0034 117.409C81.9484 117.409 105.413 93.9445 105.413 64.9995C105.413 36.0544 81.9484 12.5898 53.0034 12.5898C24.0583 12.5898 0.59375 36.0544 0.59375 64.9995C0.59375 93.9445 24.0583 117.409 53.0034 117.409Z"
          fill="var(--color-neutral-90)"
        />
        <g filter="url(#filter0_d_6896_91435)">
          <path
            d="M83.0513 42.6387H22.9549C21.0252 42.6387 19.4609 44.203 19.4609 46.1326V119.506C19.4609 121.436 21.0252 123 22.9549 123H83.0513C84.981 123 86.5453 121.436 86.5453 119.506V46.1326C86.5453 44.203 84.981 42.6387 83.0513 42.6387Z"
            fill="white"
          />
        </g>
        <path
          d="M46.0151 53.1191H27.8464C26.6886 53.1191 25.75 54.0577 25.75 55.2155C25.75 56.3733 26.6886 57.3119 27.8464 57.3119H46.0151C47.1729 57.3119 48.1114 56.3733 48.1114 55.2155C48.1114 54.0577 47.1729 53.1191 46.0151 53.1191Z"
          fill="var(--color-neutral-80)"
        />
        <path
          d="M58.5934 62.2031H27.8464C26.6886 62.2031 25.75 63.1417 25.75 64.2995C25.75 65.4573 26.6886 66.3959 27.8464 66.3959H58.5934C59.7512 66.3959 60.6898 65.4573 60.6898 64.2995C60.6898 63.1417 59.7512 62.2031 58.5934 62.2031Z"
          fill="var(--color-neutral-90)"
        />
        <path
          d="M46.0151 71.9883H27.8464C26.6886 71.9883 25.75 72.9269 25.75 74.0847C25.75 75.2425 26.6886 76.1811 27.8464 76.1811H46.0151C47.1729 76.1811 48.1114 75.2425 48.1114 74.0847C48.1114 72.9269 47.1729 71.9883 46.0151 71.9883Z"
          fill="var(--color-neutral-80)"
        />
        <path
          d="M58.5934 81.0723H27.8464C26.6886 81.0723 25.75 82.0108 25.75 83.1687C25.75 84.3265 26.6886 85.265 27.8464 85.265H58.5934C59.7512 85.265 60.6898 84.3265 60.6898 83.1687C60.6898 82.0108 59.7512 81.0723 58.5934 81.0723Z"
          fill="var(--color-neutral-90)"
        />
        <path
          d="M46.0151 90.8555H27.8464C26.6886 90.8555 25.75 91.7941 25.75 92.9519C25.75 94.1097 26.6886 95.0482 27.8464 95.0482H46.0151C47.1729 95.0482 48.1114 94.1097 48.1114 92.9519C48.1114 91.7941 47.1729 90.8555 46.0151 90.8555Z"
          fill="var(--color-neutral-80)"
        />
        <path
          d="M58.5934 99.9395H27.8464C26.6886 99.9395 25.75 100.878 25.75 102.036C25.75 103.194 26.6886 104.132 27.8464 104.132H58.5934C59.7512 104.132 60.6898 103.194 60.6898 102.036C60.6898 100.878 59.7512 99.9395 58.5934 99.9395Z"
          fill="var(--color-neutral-90)"
        />
        <g filter="url(#filter1_d_6896_91435)">
          <path
            d="M83.0513 7H22.9549C21.0252 7 19.4609 8.56431 19.4609 10.494V31.4578C19.4609 33.3875 21.0252 34.9518 22.9549 34.9518H83.0513C84.981 34.9518 86.5453 33.3875 86.5453 31.4578V10.494C86.5453 8.56431 84.981 7 83.0513 7Z"
            fill="var(--color-neutral-60)"
          />
        </g>
        <path
          d="M46.0151 14.6875H27.8464C26.6886 14.6875 25.75 15.6261 25.75 16.7839C25.75 17.9417 26.6886 18.8803 27.8464 18.8803H46.0151C47.1729 18.8803 48.1114 17.9417 48.1114 16.7839C48.1114 15.6261 47.1729 14.6875 46.0151 14.6875Z"
          fill="var(--color-neutral-80)"
        />
        <path
          d="M58.5934 23.7715H27.8464C26.6886 23.7715 25.75 24.7101 25.75 25.8679C25.75 27.0257 26.6886 27.9643 27.8464 27.9643H58.5934C59.7512 27.9643 60.6898 27.0257 60.6898 25.8679C60.6898 24.7101 59.7512 23.7715 58.5934 23.7715Z"
          fill="var(--color-neutral-90)"
        />
        <defs>
          <filter
            id="filter0_d_6896_91435"
            x="15.2682"
            y="36.3495"
            width="75.4715"
            height="88.7469"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="-2.09639" />
            <feGaussianBlur stdDeviation="2.09639" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.788235 0 0 0 0 0.803922 0 0 0 0 0.85098 0 0 0 0.349 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_6896_91435"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_6896_91435"
              result="shape"
            />
          </filter>
          <filter
            id="filter1_d_6896_91435"
            x="15.2682"
            y="0.710844"
            width="75.4715"
            height="36.3367"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="-2.09639" />
            <feGaussianBlur stdDeviation="2.09639" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.788235 0 0 0 0 0.803922 0 0 0 0 0.85098 0 0 0 0.349 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_6896_91435"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_6896_91435"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
      <Divider spacing="xs1" />
      <Typography
        variant="body4"
        color="var(--color-neutral-50)"
        fontWeight={600}
      >
        Você ainda não adicionou nenhuma seção
      </Typography>
      <Divider spacing="xs1" />
      <Button variant="secondary" size="medium" onClick={onClick}>
        <Icons name="IconPlus" />
        Adicionar nova seção
      </Button>
    </Container>
  );
};

export { EmptyState };
