import { useEffect, useState } from "react";
import {
  Checkbox,
  DataGrid,
  SearchField,
  Tag,
  LinkButton,
} from "@flash-tecnologia/hros-web-ui-v2";
import { Typography } from "../../Typography";
import {
  EmployeeRow,
  FilterButton,
  pageSizeOptions,
  Filter,
} from "./components";
import { SelectedList } from "./components/SelectedList";
import {
  NameColumn,
  Container,
  DataGridContainer,
  DataGridTitleContainer,
  NameColumnActionContainer,
  DataGridSearchFieldContainer,
} from "./styles";
import { Data, TransferListInterface } from "./types";

const TransferList = ({
  data,
  selected: selectedData,
  loading,
  onChange,
  filter,
  disabled,
}: TransferListInterface) => {
  const [open, setOpen] = useState(false);
  const [checkedPageIds, setCheckedPageIds] = useState<number[]>([]);
  const [pageSize, setPageSize] = useState<number>(5);
  const [pageIndex, setPageIndex] = useState<number>(0);
  const [selected, setSelected] = useState<Data>(selectedData || []);
  const [search, setSearch] = useState<string>("");

  const getDataByCurrentPageIndex = () => {
    const start = pageIndex * pageSize;
    const end = start + pageSize;
    return data?.slice(start, end);
  };

  const onCheck = (newData: Data) => {
    if (disabled) return;
    setSelected(newData);
    onChange(newData);
  };

  const onPageCheck = (checked: boolean) => {
    if (disabled) return;
    const employees = getDataByCurrentPageIndex();

    const newData = checked
      ? data?.filter(
          (e) =>
            employees?.some(({ id }) => id === e?.id) ||
            selected?.some(({ id }) => id === e?.id)
        )
      : selected?.filter((e) => !employees?.some(({ id }) => id === e?.id));

    setSelected(newData);
    onChange(newData);
  };

  const pageIsChecked = () => {
    const employees = getDataByCurrentPageIndex();

    const totalChecked = employees?.filter((e) =>
      selected?.some((s) => s?.id === e?.id)
    )?.length;

    return employees?.length === totalChecked;
  };

  useEffect(() => {
    const employees = getDataByCurrentPageIndex();

    const totalChecked = employees?.filter((e) =>
      selected?.some((s) => s?.id === e?.id)
    )?.length;

    const checked = employees?.length === totalChecked && totalChecked;

    if (checked) {
      if (!checkedPageIds?.includes(pageIndex))
        setCheckedPageIds([...checkedPageIds, pageIndex]);
    } else {
      if (checkedPageIds?.includes(pageIndex))
        setCheckedPageIds(checkedPageIds?.filter((i) => i !== pageIndex));
    }
  }, [pageIndex, selected, filter?.sections]);

  if (loading) return <>Carregando...</>;

  return (
    <Container>
      <DataGridContainer>
        <DataGridTitleContainer>
          <Typography
            variant="headline8"
            color="var(--color-neutral-30)"
            fontWeight={700}
          >
            Pessoas da empresa
          </Typography>
          <Tag variant="gray">{data?.length || 0} itens</Tag>
        </DataGridTitleContainer>
        <DataGridSearchFieldContainer>
          <SearchField
            label="Buscar"
            onChange={(e) => {
              setSearch(e?.target?.value || "");
              setPageIndex(0);
            }}
          />
          <FilterButton onClick={() => setOpen(!open)} />
        </DataGridSearchFieldContainer>
        <DataGrid
          hasPagination
          pageSizeOptions={pageSizeOptions}
          initialState={{
            pageIndex: pageIndex,
            pageSize: pageSize,
          }}
          fetchData={({ pageIndex, sortBy: sBy, pageSize: pgSize }) => {
            if (pgSize && pgSize !== pageSize) {
              setPageSize(pgSize);
              return;
            }

            setPageIndex(pageIndex || 0);
          }}
          columns={[
            {
              disableSortBy: true,
              sticky: true,
              Header: search?.length ? (
                <></>
              ) : (
                <Checkbox
                  disabled={disabled || false}
                  defaultChecked={false}
                  checked={checkedPageIds?.includes(pageIndex)}
                  onChange={(e) => onPageCheck(e?.target?.checked || false)}
                  // disabled={disabled}
                />
              ),
              accessor: "id",
              Cell: (info: any) => {
                const checked = selected?.some(
                  ({ id }) => id === info?.row?.original?.id
                );

                return (
                  <Checkbox
                    disabled={disabled || false}
                    checked={checked}
                    onChange={() =>
                      checked
                        ? onCheck(
                            selected?.filter(
                              ({ id }) => id !== info?.row?.original?.id
                            )
                          )
                        : onCheck([
                            ...selected,
                            {
                              id: info?.row?.original?.id,
                              name: info?.row?.original?.name,
                              corporateEmail:
                                info?.row?.original?.corporateEmail,
                            },
                          ])
                    }
                    // disabled={disabled}
                  />
                );
              },
            },
            {
              disableSortBy: true,
              Header: (
                <NameColumn>
                  {selected?.length || pageIsChecked() ? null : (
                    <Typography
                      variant="body3"
                      color="var(--color-neutral-40)"
                      fontWeight={700}
                    >
                      Nome
                    </Typography>
                  )}
                  <NameColumnActionContainer>
                    {selected?.length ? (
                      <Tag variant="primary">
                        {selected?.length} pessoas selecionadas
                      </Tag>
                    ) : null}
                    {pageIsChecked() ? (
                      <LinkButton
                        variant="default"
                        onClick={() =>
                          selected?.length === data?.length
                            ? onCheck([])
                            : onCheck(data)
                        }
                      >
                        {selected?.length === data?.length
                          ? "Limpar seleção"
                          : "Selecionar todas as páginas"}
                      </LinkButton>
                    ) : null}
                  </NameColumnActionContainer>
                </NameColumn>
              ),
              accessor: "name",
              Cell: (info: any) => {
                return (
                  <EmployeeRow
                    id={info?.row?.original?.id}
                    name={info?.row?.original?.name}
                    email={info?.row?.original?.corporateEmail}
                  />
                );
              },
            },
          ]}
          data={data?.filter(({ name }) => name?.match(RegExp(search, "ig")))}
        />
      </DataGridContainer>
      <SelectedList
        data={selected}
        onRemove={(e) => onCheck(selected?.filter(({ id }) => id !== e))}
      />
      <Filter
        open={open}
        onClose={() => setOpen(false)}
        sections={filter?.sections || []}
        onChange={(e) => filter?.onChange(e)}
        onReset={(e) => {
          filter?.onReset(e);
          setOpen(false);
        }}
      />
    </Container>
  );
};

export { TransferList };
