import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 0 37px;

  > :first-child {
    height: calc(100% - 160px) !important;
    width: 1366px !important;
    max-width: 100% !important;
  }
`;

export { Container };
