import { dayjs } from "@flash-tecnologia/hros-web-ui-v2";
import { DataCard } from "../DataCard";

interface SettingsTabProps {
  data: any;
  onNavigate: React.MouseEventHandler<HTMLButtonElement> | undefined;
}

export const DispatchTab = ({ data, onNavigate }: SettingsTabProps) => {
  const { startDate } = data;
  const periodDescription = {
    morning: "Manhã",
    afternoon: "Tarde",
    night: "Noite",
  };

  return (
    <DataCard
      title="Envio"
      subTitle="Confira quando sua comunicação será enviada."
      editable
      onEditClick={onNavigate}
      sections={[
        {
          items: [
            {
              title: "Data de envio",
              description: dayjs(startDate?.date).isValid()
                ? dayjs(startDate?.date).format("DD/MM/YYYY")
                : "-",
            },
            {
              title: "Período",
              description: periodDescription?.[startDate?.period] || "-",
            },
          ],
        },
      ]}
    />
  );
};
