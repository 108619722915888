import { Typography } from "@components/Typography";
import { Divider } from "@components/v2";
import {
  Accordion,
  BoxAdornment,
  Toggle,
} from "@flash-tecnologia/hros-web-ui-v2";

import { AccordionContainer, FlexColumn } from "../styles";
import { segment } from "@utils";

const SectionInteraction = ({ disabled, formik }) => {
  const { like } = formik?.values;

  return (
    <AccordionContainer>
      <Accordion
        customHeader={
          <FlexColumn style={{ alignItems: "flex-start" }}>
            <Typography
              variant="headline7"
              color="var(--color-neutral-30)"
              fontWeight={700}
            >
              Interações
            </Typography>
            <Divider spacing="quark" />
            <Typography
              variant="body4"
              color="var(--color-neutral-50)"
              fontWeight={400}
            >
              Habilite o botão abaixo caso deseje que a as pessoas possam
              interagir com a comunicação.
            </Typography>
          </FlexColumn>
        }
        children={
          <BoxAdornment
            title={"Permitir reações"}
            description={
              "Selecione esta opção para permitir que as pessoas reajam a essa comunicação."
            }
            leftAdornment={
              <Toggle
                disabled={disabled}
                checked={!!like}
                onChange={(e) => {
                  const checked = e.target.checked;

                  if (checked) {
                    segment({
                      track:
                        "people_strategic_hr_engagement_company_communication_configuration_allowreacts_selected",
                    });
                  }

                  formik.handleChange({
                    target: {
                      id: "like",
                      value: checked,
                    },
                  });
                }}
              />
            }
            style={{ width: "100%" }}
          />
        }
        variant={"default"}
        defaultExpanded
      />
    </AccordionContainer>
  );
};

export { SectionInteraction };
