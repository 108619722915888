import { Content } from "./components/Content";
import { Footer } from "./components/Footer";
import SadFaceSVG from "../../assets/sad-face.svg";
import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  align-items: center;
`;

interface IError {
  title: string;
  description: string;
  buttonLabel: string;
  buttonCallback: () => void;
}

export const Error = ({ ...props }: IError) => {
  return (
    <Container>
      <Content
        headerElement={
          <SadFaceSVG style={{ position: "absolute", top: "-50px" }} />
        }
        {...props}
      />
      <Footer />
    </Container>
  );
};
