import { trpc } from "@api/client";
import { OwnersMultiSelect } from "@components/OwnersMultiSelect";
import {
  Button,
  LinkButton,
  Modal,
  Radio,
} from "@flash-tecnologia/hros-web-ui-v2";
import { RadioBox, RadioContainer } from "./styled";
import { useEffect, useState } from "react";
import { OwnersNotificationCard } from "@components/OwnersNotificationCard";
import { dispatchToast } from "@utils";
import { EngagementModel } from "@models";

interface IEditOwnersModal {
  open: boolean;
  onClose: () => void;
  engagement?: EngagementModel;
  onSuccess: () => void;
}

export const EditOwnersModal = ({
  open,
  onClose,
  engagement,
  onSuccess,
}: IEditOwnersModal) => {
  const initialOwners = engagement?.owners;

  const [selectedOwners, setSelectedOwners] = useState<
    EngagementModel["owners"] | null
  >(null);

  const [responsibilityMode, setResponsibilityMode] = useState<
    "everyone" | "specific"
  >("everyone");

  const { isLoading: upsertEngagementLoading, mutate: patchEngagementOwners } =
    trpc?.patchEngagementOwners?.useMutation({
      onError: (e: any) => {
        dispatchToast({
          type: "error",
          content:
            "Sentimos muito, ocorreu um erro ao atualizar os responsáveis. O erro já foi reportado ao sistema.",
        });
      },
      onSuccess: () => {
        onClose();
        onSuccess();
      },
    });

  const onSubmit = () => {
    dispatchToast({
      type: "warning",
      content: "Atualizando lista de responsáveis...",
    });
    patchEngagementOwners(
      {
        engagementId: engagement?._id ?? "",
        owners: selectedOwners ?? [{ id: "*", name: "*" }],
      },
      {
        onSuccess: () => {
          dispatchToast({
            type: "success",
            content: "Edição realizada com sucesso!",
          });
        },
      }
    );
  };

  useEffect(() => {
    setSelectedOwners(initialOwners ?? null);
    setResponsibilityMode(
      !!initialOwners && initialOwners?.[0]?.id != "*" ? "specific" : "everyone"
    );
  }, [engagement]);

  return (
    <Modal.Root open={open} onClose={onClose} size="md">
      <>
        <Modal.Header
          title="Editar responsáveis"
          description="Adicione ou remova abaixo quem terá permissão para acessar, visualizar dados e realizar ações. Administradores com permissão geral terão acesso independente da seleção feita."
        />
        <Modal.Content
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "24px",
            justifyContent: "center",
          }}
        >
          <RadioContainer>
            <RadioBox>
              <Radio
                checked={responsibilityMode === "everyone"}
                onChange={() => {
                  setResponsibilityMode("everyone");
                  setSelectedOwners([{ id: "*", name: "*" }]);
                }}
              />
              Todos com acesso ao módulo
            </RadioBox>
            <RadioBox>
              <Radio
                checked={responsibilityMode === "specific"}
                onChange={() => {
                  setResponsibilityMode("specific");
                  setSelectedOwners(null);
                }}
              />
              Selecionar pessoas
            </RadioBox>
          </RadioContainer>
          {responsibilityMode === "specific" && (
            <>
              <OwnersNotificationCard />
              <OwnersMultiSelect
                onChange={(values) =>
                  setSelectedOwners(
                    values.map((v) => ({ name: v.label, id: v.value }))
                  )
                }
                owners={selectedOwners?.map((o) => ({
                  label: o.name,
                  value: o.id,
                }))}
              />
            </>
          )}
        </Modal.Content>
        <Modal.Footer>
          <div>
            <LinkButton variant="default" onClick={() => onClose()}>
              Cancelar
            </LinkButton>
          </div>
          <Button
            size="large"
            variant="primary"
            onClick={onSubmit}
            loading={upsertEngagementLoading}
            disabled={!selectedOwners?.length}
          >
            Salvar edições
          </Button>
        </Modal.Footer>
      </>
    </Modal.Root>
  );
};
