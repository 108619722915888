import { useState } from "react";
import {
  dayjs,
  Table,
  tableControllers,
  Tag,
} from "@flash-tecnologia/hros-web-ui-v2";
import { PaginationState } from "@flash-tecnologia/hros-web-ui-v2/dist/components/Table/components/Pagination";
import { Avatar } from "../index";
import { AnswerTabInterface, TableDataType, AnswerFormatType } from "./types";
import { Cell, TagContainer, TagsContainer } from "./styles";

export default ({ data, engagement }: AnswerTabInterface) => {
  const [pagination, setPagination] = useState<PaginationState>({
    pageNumber: 1,
    pageSize: 5,
  });

  const { pageNumber, pageSize } = pagination;

  const count = data.length;

  const finalData = data.slice(
    (pageNumber - 1) * pageSize,
    pageNumber * pageSize
  );

  const getTableColumns = (): any[] => {
    const allQuestions: {
      id: string;
      title: string;
      sectionId: string;
      options?: {
        id?: string;
        value?: string;
      }[];
      answerFormat: AnswerFormatType;
    }[] = [];
    engagement?.sections?.forEach((s) => {
      s?.questions?.forEach((q) =>
        allQuestions?.push({
          id: q?.id || "",
          title: q?.title || "",
          sectionId: s?.id || "",
          answerFormat: q?.answerFormat,
          options: q?.options,
        })
      );
    });

    const columns =
      allQuestions?.map((question, i) => ({
        header: question?.title || "",
        accessorKey: `question-${question?.id}-${i}`,
        minSize: question?.title?.length * 10,
        cell: (e) => {
          const answerFormat: AnswerFormatType = question?.answerFormat;

          const sections = e?.row?.original?.answer;
          const answer = sections
            ?.find((s) => s?.id === question?.sectionId)
            ?.questions?.find((q) => q?.id === question?.id);

          const value = answer?.value;
          const justification = answer?.justification;

          if (answerFormat === "date")
            return <Cell>{dayjs(value)?.format("DD/MM/YYYY")}</Cell>;

          if (
            answerFormat === "multiple-choice" ||
            answerFormat === "dropdown"
          ) {
            if (justification) return <Cell>{justification}</Cell>;
            return (
              <Cell>
                {question?.options?.find((o) => o?.id === value)?.value}
              </Cell>
            );
          }

          if (answerFormat === "select-box") {
            if (
              ["select-box"]?.includes(answerFormat || "") &&
              Array.isArray(value)
            )
              return (
                <TagsContainer>
                  {value?.map((v, i) => {
                    const optionValue = question?.options?.find(
                      (o) => o?.id === v
                    )?.value;

                    if (optionValue)
                      return (
                        <TagContainer key={`tag-container-${i}`}>
                          <Tag variant="gray">{optionValue}</Tag>
                        </TagContainer>
                      );
                    return <Cell></Cell>;
                  })}
                  {justification ? (
                    <TagContainer key={`tag-container-${i}`}>
                      <Tag variant="gray">{justification}</Tag>
                    </TagContainer>
                  ) : null}
                </TagsContainer>
              );
          }

          return <Cell>{value}</Cell>;
        },
      })) || [];

    return columns;
  };

  const table = tableControllers.useTableColumns<TableDataType>({
    defaultColumn: {
      minSize: 200,
    },
    total: count,
    columns: [
      {
        header: "Pessoas",
        accessorKey: "name",
        sticky: "left",
        minSize: 300,
        cell: (e) => <Avatar name={e?.row?.original?.name || "Anônimo"} />,
      },
      ...(getTableColumns() || []),
    ],
    data: finalData,
    pagination: pagination,
    onPaginationChange: setPagination,
  });

  return (
    <Table.Root>
      <Table.Grid.Root loading={false}>
        <Table.Grid.Header getHeaderGroups={table.getHeaderGroups} />
        {table.rows.map((row, index) => (
          <Table.Grid.Row key={index + row.id} row={row} />
        ))}
      </Table.Grid.Root>
      <Table.Pagination
        count={count}
        onPaginationChange={({ pageSize, pageNumber }) =>
          setPagination({
            ...pagination,
            pageSize,
            pageNumber: pagination?.pageSize !== pageSize ? 1 : pageNumber,
          })
        }
        pagination={pagination}
        pageSizeOptions={[
          {
            label: "5 itens",
            value: 5,
          },
          {
            label: "10 itens",
            value: 10,
          },
          {
            label: "25 itens",
            value: 25,
          },
        ]}
      />
    </Table.Root>
  );
};
