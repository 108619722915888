import { Tab, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StyledTab = styled(Tab)`
  width: 100%;

  > div {
    flex: 1;
  }

  > div button div {
    margin-right: 0px;
  }
`;

const PageTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[20]};
  font-weight: 700;
  margin-bottom: 56px;
`;

const PageInfo = styled.div``;

const PageSubTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.secondary[50]};
  margin-bottom: 16px;
  font-weight: 700;
`;

const PageDescription = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[50]};
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
`;

const PreviewButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #ffffff;
  color: var(--color-secondary-50);
  border: 1px solid var(--color-secondary-50);
  border-radius: 10px;
  padding: 10px 20px;
  font-weight: 700;
  margin-bottom: 16px;
`;

export {
  FlexColumn,
  StyledTab,
  PageTitle,
  PageDescription,
  PageSubTitle,
  PageInfo,
  PreviewButton,
};
