import styled from "styled-components";

import { Breadcrumbs, Stepper } from "@flash-tecnologia/hros-web-ui-v2";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  padding: 20px 24px;
  border-bottom: 1px solid var(--color-neutral-90);

  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  scrollbar-width: none;

  ::-webkit-scrollbar {
    display: none;
  }

  @media screen and (max-width: 715px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const StyledBreadcrumbs = styled(Breadcrumbs)`
  margin-left: 15px;
  min-width: 220px;
`;

export const LeftContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;
`;

export const StyledStepper = styled(Stepper)`
  margin-left: 20px;
  @media screen and (max-width: 715px) {
    margin-top: 15px;
    :first-child {
      margin-left: 55px;
    }
  }
`;
