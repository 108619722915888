import { Icons } from "@flash-tecnologia/hros-web-ui-v2";

import {
  BackButton,
  Container,
  ContinueButton,
  StyledLinkButton,
  Wrapper,
} from "./styled";

type ButtonProps = {
  title?: React.ReactNode;
  callback?: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  loading?: boolean;
  hasToShow?: boolean;
  width?: string;
};

export type FooterProps = {
  cancelProps: ButtonProps;
  goBackProps?: ButtonProps;
  confirmProps?: ButtonProps;
  skipProps?: ButtonProps;
  draftProps?: ButtonProps;
  disable?: boolean;
};

export const Footer = ({
  cancelProps,
  goBackProps,
  confirmProps,
  skipProps,
  draftProps,
}: FooterProps) => {
  return (
    <Container>
      <Wrapper>
        {cancelProps && cancelProps.hasToShow !== false && (
          <StyledLinkButton
            variant="neutral"
            // loading={cancelProps.loading || false}
            disabled={cancelProps.disabled || cancelProps.loading || false}
            onClick={cancelProps.callback}
          >
            {cancelProps.title ? cancelProps.title : "Cancelar"}
          </StyledLinkButton>
        )}
        {draftProps && draftProps.hasToShow !== false && (
          <StyledLinkButton
            variant="neutral"
            // loading={draftProps.loading || false}
            disabled={draftProps.disabled || draftProps.loading || false}
            onClick={draftProps.callback}
          >
            {draftProps.title ? draftProps.title : "Sair e salvar rascunho"}
          </StyledLinkButton>
        )}
        {skipProps && skipProps.hasToShow !== false && (
          <StyledLinkButton
            variant="neutral"
            // loading={skipProps.loading || false}
            disabled={skipProps.disabled || skipProps.loading || false}
            onClick={skipProps.callback}
          >
            {skipProps.title ? skipProps.title : "Pular e finalizar"}
          </StyledLinkButton>
        )}
      </Wrapper>
      <Wrapper>
        {goBackProps && goBackProps.hasToShow !== false && (
          <BackButton
            size="medium"
            variant="secondary"
            loading={goBackProps.loading || false}
            disabled={goBackProps.disabled || goBackProps.loading || false}
            onClick={goBackProps.callback}
            style={{ width: goBackProps?.width }}
          >
            {goBackProps.title ? (
              goBackProps.title
            ) : (
              <>
                <Icons name="IconArrowLeft" />
                Voltar
              </>
            )}
          </BackButton>
        )}
        {confirmProps && confirmProps.hasToShow !== false && (
          <ContinueButton
            size="medium"
            variant="primary"
            loading={confirmProps?.loading || false}
            disabled={confirmProps?.disabled || confirmProps?.loading || false}
            onClick={confirmProps?.callback}
            style={{ width: confirmProps?.width }}
          >
            {confirmProps?.title
              ? confirmProps.title
              : confirmProps && (
                  <>
                    Concluir <Icons name="IconCheck" fill="transparent" />
                  </>
                )}
          </ContinueButton>
        )}
      </Wrapper>
    </Container>
  );
};
