const fontColors = {
  like: "var(--color-feedback-info-dark1)",
  heart: "var(--color-tertiary-40)",
  support: "var(--color-feedback-success-dark1)",
  congrats: "var(--color-feedback-error-dark1)",
};

const backgroundColors = {
  like: "var(--color-feedback-info-light1)",
  heart: "var(--color-tertiary-90)",
  support: "var(--color-feedback-success-light1)",
  congrats: "var(--color-feedback-error-light1)",
};

const icons = {
  like: "IconThumbUp",
  heart: "IconHeart",
  support: "IconHeartHandshake",
  congrats: "IconConfetti",
};

export { fontColors, backgroundColors, icons };
