import styled from "styled-components";
import { Typography } from "@flash-tecnologia/hros-web-ui-v2";

const MainContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid var(--color-neutral-light4);
  padding: 10px;
  min-width: 122px;
  border-radius: 12px;
`;

const Number = styled(Typography)`
  color: var(--color-neutral-dark4);
  font-weight: 600;
  margin: 0 10px;
  min-width: max-content;
`;

const Label = styled(Typography)`
  color: var(--color-neutral-dark3);
  font-weight: 400;
  min-width: max-content;
`;

const LeftLabel = styled(Label)`
  margin-right: 10px;
`;

const RightLabel = styled(Label)`
  margin-left: 10px;
`;

const Button = styled.button<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 40px;
  border: 1px solid
    ${({ disabled }) =>
      disabled ? "var(--color-neutral-light1)" : "var(--color-neutral-light3)"};
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
  background-color: ${({ disabled }) =>
    disabled ? "var(--color-neutral-light1)" : "var(--color-neutral-pure)"};
`;

export {
  MainContainer,
  Container,
  Number,
  Button,
  Label,
  LeftLabel,
  RightLabel,
};
