import { Icons } from "@flash-tecnologia/hros-web-ui-v2";
import { Typography } from "@components/Typography";
import { Container, Picture } from "./styles";
import { AvatarInterface } from "./types";

const Avatar = ({ name, picture }: AvatarInterface) => (
  <Container>
    {picture ? (
      <Picture source={picture} />
    ) : (
      <Picture>
        <Icons name="IconUser" color="var(--color-secondary-50)" size={18} />
      </Picture>
    )}
    <Typography
      variant="body3"
      color="var(--color-neutral-40)"
      fontWeight={600}
    >
      {name}
    </Typography>
  </Container>
);

export default Avatar;
