import { Tag, dayjs } from "@flash-tecnologia/hros-web-ui-v2";
import { Typography } from "../Typography";
import { Container, QuestionCol, QuestionContainer } from "./styles";
import { QuestionFeedInterface } from "./types";

const QuestionFeed = ({
  questionTitle,
  monitorings,
  anonymous,
}: QuestionFeedInterface) => {
  const getDataGroups = () => {
    const result: {
      value: string;
      count: number;
      date: string;
      employeeName?: string;
    }[] = [];

    monitorings?.forEach(({ value, date, employeeName }) => {
      const index = result?.findIndex(({ value: v }) => v === value);

      if (index !== -1) {
        result[index]["count"] = result[index]["count"] + 1;
      } else {
        result?.push({ value, date, count: 1, employeeName });
      }
    });

    return result;
  };

  return (
    <Container>
      <Typography variant="headline8" color={"var(--color-neutral-30)"}>
        {questionTitle}
      </Typography>
      {getDataGroups()?.map(({ value, date, count, employeeName }, i) => (
        <QuestionContainer key={i}>
          <QuestionCol>
            <Typography
              variant="body3"
              color={"var(--color-neutral-40)"}
              fontWeight={600}
            >
              {!value?.includes("-") ||
              dayjs(value)?.format("DD/MM/YYYY") === "Invalid Date"
                ? value
                : dayjs(value)?.format("DD/MM/YYYY")}
            </Typography>
            <Typography
              variant="body4"
              color={"var(--color-neutral-60)"}
              fontWeight={400}
            >
              {`${!anonymous ? `${employeeName} | ` : ""} ${dayjs(date)?.format(
                "DD/MM/YYYY - HH:mm"
              )}`}
            </Typography>
          </QuestionCol>
          {count > 1 ? (
            <QuestionCol>
              <Tag variant="primary">{count} respostas</Tag>
            </QuestionCol>
          ) : null}
        </QuestionContainer>
      ))}
    </Container>
  );
};

export { QuestionFeed };
