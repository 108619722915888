import { Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";
import { CardInfoInterface } from "./types";

const Container = styled.div`
  position: relative;
  padding: 40px;
  border-radius: 12px;
  border: 1px solid var(--color-neutral-light2);
  width: 100%;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const Content = styled.div<{ direction?: CardInfoInterface["direction"] }>`
  display: flex;
  align-items: ${({ direction }) =>
    direction === "column" ? "flex-start" : "center"};
  justify-content: ${({ direction }) =>
    direction === "column" ? "center" : "flex-start"};
  flex-direction: ${({ direction }) =>
    direction === "column" ? "column" : "row"};

  width: 100%;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const Title = styled(Typography)`
  color: var(--color-neutral-30) !important;
  font-weight: 700 !important;
`;

const Label = styled(Typography)`
  color: var(--color-neutral-50) !important;
  font-weight: 700 !important;
`;

const Value = styled(Typography)`
  color: var(--color-neutral-40) !important;
  font-weight: 600 !important;
`;

const BorderIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  border: 1px solid var(--color-neutral-light4);
  margin-right: 4px;
  height: 20px;
  width: 20px;
`;

const Text = styled(Typography)`
  color: var(--color-neutral-dark4);
  font-weight: 400;
`;

const Image = styled.img`
  border-radius: 8px;
  width: 400px;
  margin: 0 auto;
`;

const Date = styled(Typography)`
  color: var(--color-neutral-dark3);
  font-weight: 600;
`;

const TextWithHtml = styled.div`
  color: var(--color-neutral-dark4);
  font-size: calc(1rem * 0.875);
  width: 100%;

  ul,
  ol {
    padding-left: 0.5em;
  }

  ul li,
  ol li {
    list-style-position: outside;
    margin-left: 1em;
  }
`;

export {
  BorderIcon,
  Container,
  Content,
  Date,
  Image,
  Label,
  Row,
  Text,
  TextWithHtml,
  Title,
  TitleContainer,
  Value,
};
