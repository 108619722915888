import { Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  padding: 16px 24px;

  border: 1px solid var(--color-neutral-light3);
  border-radius: 8px;
`;

const AdornmentContainer = styled.div`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  align-self: flex-start;
`;

const AdornmentFlex = styled.div`
  display: flex;
  margin-right: 20px;
`;

const AdornmentFlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  word-break: break-word;
`;

const AdornmentTitle = styled(Typography)`
  color: 1px solid var(--color-neutral-dark3);
  font-weight: 600 !important;
`;

const AdornmentDescription = styled(Typography)`
  color: 1px solid var(--color-neutral-dark5);
  font-weight: 400;
`;

export {
  Container,
  AdornmentContainer,
  AdornmentFlex,
  AdornmentFlexColumn,
  AdornmentTitle,
  AdornmentDescription,
};
