import {
  Modal as ModalWebUi,
  Button,
  DatePicker,
  dayjs,
  BoxAdornment,
  Icons,
} from "@flash-tecnologia/hros-web-ui-v2";
import { Dayjs } from "@flash-tecnologia/hros-web-ui-v2/dist/utils";
import { BorderIcon as BIcon, Text, LineSeparator } from "../";
import { Divider } from "../v2";
import { InteractionDeadlineModalFormInterface } from "./types";
const { Root: Modal } = ModalWebUi;
import {
  BoxAdornmentDescription,
  BoxAdornmentTitle,
  ContentContainer,
  Footer,
  Header,
  IconContainer,
} from "./styles";

const InteractionDeadlineModalForm = ({
  visible,
  onClick,
  onClose,
  onFinish,
  onChange,
  hideMode,
  value,
  showAlert,
  loading,
}: InteractionDeadlineModalFormInterface) => {
  return (
    <>
      {hideMode ? null : (
        <Button variant="primary" size="large" onClick={() => onClick()}>
          Editar prazo de resposta
        </Button>
      )}
      <Modal
        open={visible}
        onClose={() => onClose()}
        footer={
          <Footer>
            <Button
              variant="secondary"
              size="large"
              onClick={() => onClose()}
              loading={loading}
            >
              Cancelar
            </Button>
            <Button
              variant="primary"
              size="large"
              onClick={() => onFinish()}
              loading={loading}
            >
              Salvar edição
            </Button>
          </Footer>
        }
        header={
          <Header>
            <BIcon
              onClick={() => onClose()}
              name={"IconX"}
              fill="var(--color-neutral-dark4)"
              color="var(--color-neutral-dark4)"
              size={15}
            />
          </Header>
        }
      >
        <ContentContainer>
          <Text variant={"headline6"} color="var(--color-neutral-20)">
            Editar prazo de resposta
          </Text>
          <Text variant="body3" color="var(--color-neutral-50)">
            Ao editar, a pesquisa será encerrada na nova data definida.
          </Text>
          {value ? (
            <>
              <Divider spacing={"xs1"} />
              <Text variant="body3" color="var(--color-neutral-50)">
                Data atual do prazo de resposta:{" "}
                {dayjs(value)?.format("DD/MM/YYYY")}
              </Text>
            </>
          ) : (
            <></>
          )}
          <Divider spacing={"xs1"} />
          <LineSeparator />
          <Divider spacing={"xs1"} />
          {showAlert ? (
            <>
              <BoxAdornment
                style={{
                  width: "100%",
                  borderColor: "var(--color-feedback-error-light2)",
                }}
                description={
                  <BoxAdornmentDescription variant="body4">
                    {
                      "A alteração não impacta os próximos envios. Para alterar os envios futuros, vá em Editar pesquisa > Envio e modifique o prazo de resposta da recorrência."
                    }
                  </BoxAdornmentDescription>
                }
                leftAdornment={
                  <IconContainer>
                    <Icons
                      name="IconAlertTriangle"
                      color="#C96C01"
                      fill="transparent"
                    />
                  </IconContainer>
                }
                title={
                  <BoxAdornmentTitle variant="body3">
                    {"Atenção!"}
                    <Divider spacing={"xs1"} />
                  </BoxAdornmentTitle>
                }
              />
            </>
          ) : null}
          <DatePicker
            className="date-picker"
            fromDate={dayjs(new Date())}
            label="Prazo de resposta"
            onDateChange={(value: Dayjs | undefined) => {
              if (value) {
                onChange(value?.format("YYYY-MM-DD"));
              }
            }}
            value={value ? dayjs(value) : undefined}
          />
        </ContentContainer>
      </Modal>
    </>
  );
};

export { InteractionDeadlineModalForm };
