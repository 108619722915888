import { Button, Icons } from "@flash-tecnologia/hros-web-ui-v2";
import { Divider } from "../v2/Divider";
import { Container } from "./styles";
import { ButtonWithIconInterface } from "./types";

const ButtonWithIcon = ({
  icon: { position, ...iconRest },
  ...rest
}: ButtonWithIconInterface) => (
  <Container>
    <Button {...rest}>
      <Container>
        {position === "left" ? (
          <>
            <Icons {...iconRest} />
            <Divider type="horizontal" spacing="quark" />
          </>
        ) : null}
        {rest?.children}
        {position === "right" ? (
          <>
            <Divider type="horizontal" spacing="quark" />
            <Icons {...iconRest} />
          </>
        ) : null}
      </Container>
    </Button>
  </Container>
);

export { ButtonWithIcon };
