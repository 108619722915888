import {
  Origin,
  Container,
  Bar,
  Column,
  Content,
  ThirdColumn,
  Pointer,
  PointerLeftBorder,
  PointerRightBorder,
  Axle,
  LeftLabel,
  RightLabel,
  TextContainer,
} from "./styles";
import { Typography } from "..";

const GaugeChart = ({ value }: { value: number }) => (
  <Container>
    <Origin>
      <Bar>
        <Column
          width="calc(50% + 3px)"
          height="135px"
          bgColor="#F45A55"
          style={{ borderRight: "3px solid white" }}
        >
          <LeftLabel>
            <Typography
              variant="body4"
              fontWeight={700}
              color="var(--color-neutral-50)"
            >
              -100
            </Typography>
          </LeftLabel>
        </Column>
        <div
          style={{
            position: "relative",
            backgroundColor: "#6AA01D",
            width: "50%",
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "flex-start",
          }}
        >
          <ThirdColumn />
          <Column width="100%" height="135px" bgColor="#F8B81C" />
          <Column width="60%" height="135px" bgColor="#22894B">
            <RightLabel>
              <Typography
                variant="body4"
                fontWeight={700}
                color="var(--color-neutral-50)"
              >
                +100
              </Typography>
            </RightLabel>
          </Column>
        </div>
      </Bar>
      <Content>
        <Pointer value={(value * 90) / 100}>
          <PointerLeftBorder />
          <PointerRightBorder />
          <Axle />
        </Pointer>
        <TextContainer>
          <Typography
            variant="headline6"
            fontWeight={700}
            color="var(--color-neutral-30)"
          >
            {((num: number) => {
              const value = `${num}`;
              if (!value?.includes(".")) return value;
              const [int, decimal] = value?.split(".");
              return `${int}.${decimal.slice(0, 2)}`;
            })(value)}
          </Typography>
          <Typography
            variant="body3"
            fontWeight={400}
            color="var(--color-neutral-30)"
          >
            Pontuação eNPS
          </Typography>
        </TextContainer>
      </Content>
    </Origin>
  </Container>
);

export { GaugeChart };
