import { NotificationCard, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import Box from "@mui/material/Box/Box";
import { useTheme } from "styled-components";

export const OwnersNotificationCard = () => {
  const theme = useTheme() as any;

  return (
    <NotificationCard.Root type={"info"} variant={"contained"}>
      <NotificationCard.Icon iconName={"IconInfoCircle"} />
      <NotificationCard.WrapperTexts>
        <NotificationCard.Title>
          <Box display="flex" flexDirection="column" gap={2}>
            <Box>
              <Typography
                variant="body4"
                style={{
                  color: theme.colors.feedback.info[10],
                  fontWeight: "bold",
                }}
              >
                Permissão de acesso de visualização ao Módulo de Engajamento
              </Typography>
              <Typography
                variant="body4"
                style={{ color: theme.colors.feedback.info[30] }}
              >
                Para visualizar a tela e realizar alterações, é essencial que as
                pessoas adicionadas como responsáveis tenham permissão para
                visualizar o módulo de engajamento. Essa configuração pode ser
                encontrada na área de configurações da plataforma.
              </Typography>
            </Box>
          </Box>
        </NotificationCard.Title>
      </NotificationCard.WrapperTexts>
    </NotificationCard.Root>
  );
};
