import { Icons } from "@flash-tecnologia/hros-web-ui-v2";
import { Container, ButtonsContainer, Btn } from "./styles";
import { TabButtonInterface } from "./types";

const TabButton = ({ tabs, selected, style, onChange }: TabButtonInterface) => (
  <Container style={style}>
    <ButtonsContainer>
      {tabs?.map(({ icon, name }, i) => (
        <Btn
          key={`selected-${i}`}
          selected={selected === i}
          onClick={() => onChange(i)}
        >
          <Icons size={16} name={icon} style={{ marginRight: "4px" }} />
          {name}
        </Btn>
      ))}
    </ButtonsContainer>
  </Container>
);

export { TabButton };
