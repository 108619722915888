import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 10px;
`;

const TextContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  flex-direction: column;
`;

const Avatar = styled.div`
  height: 50px;
  width: 50px;
  border-radius: 100%;
  background-color: pink;
`;

const EmailContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
`;

export { Container, TextContainer, Avatar, EmailContainer };
