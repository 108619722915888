import styled from "styled-components";
import { Typography } from "@flash-tecnologia/hros-web-ui-v2";

const Container = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid var(--color-neutral-light2);
  height: 72px;
  padding: 0 24px;

  @media (max-width: 1440px) {
    .survey-form-stepper {
      display: none !important;
    }
  }
`;

const BreadcrumbText = styled(Typography)<{ linkMode?: boolean }>`
  color: var(--color-neutral-40) !important;
  font-weight: 700 !important;
  cursor: pointer;
  cursor: ${({ linkMode }) => (linkMode ? "pointer" : "default")};
  border-bottom: ${({ linkMode }) =>
    linkMode ? "2px solid var(--color-neutral-40)" : "none"};
`;

export { Container, BreadcrumbText };
