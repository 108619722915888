export const strategiesText = {
  engaja_job_meaning_actions_autonomy_projects:
    "Projetos de Autonomia relacionados à Empresa: iniciativas além do cargo que oferecem liderança em projetos para que os colaboradores exerçam autonomia e tomem decisões significativas. ",
  engaja_job_meaning_actions_inspiring_stories:
    "A empresa regularmente compartilha histórias inspiradoras e conquistas dos colaboradores através da intranet ou e-mails internos, aumentando meu engajamento e senso de pertencimento. ",
  engaja_job_meaning_actions_self_managed_teams:
    "Equipes autogerenciadas: projetos em que os colaboradores podem exercer autonomia e tomar decisões significativas.",
  engaja_job_meaning_actions_social_impact_programs:
    "Programas de Impacto Social/ Voluntariado: implementação de programas que permitam trabalhar em iniciativas que tenham um impacto social positivo.",
  engaja_job_meaning_actions_idea_programs:
    "Programas de ideias: contribuições individuais ou coletivas para melhorias na empresa.",
  engaja_job_meaning_actions_personal_projects_time:
    "Tempo para Projetos Pessoais: iniciativas de redução de jornada sem diminuição do salário.",

  engaja_good_management_practices_actions_continuous_feedback_program:
    "Programa de Feedback Contínuo: sistema de feedback estruturado, regular e bidirecional entre gestores e equipes, como cultura da organização.",
  engaja_good_management_practices_actions_coaching_program:
    "Programa de Coaching: sistema que permita apoio, troca de experiências e conhecimentos entre diferentes níveis hierárquicos, como cultura da organização.",
  engaja_good_management_practices_actions_psychologically_prepared_leaders:
    "Líderes preparados psicologicamente, com tempo e vontade de auxiliar e desenvolver a equipe.",
  engaja_good_management_practices_actions_personal_professional_development:
    "Desenvolvimento pessoal e profissional: oferecer capacitação contínua para os profissionais.",
  engaja_good_management_practices_actions_performance_evaluations:
    "Avaliações de Desempenho: avaliações de desempenho focadas em desenvolvimento contínuo, não apenas no atingimento de metas e resultados.",
  engaja_good_management_practices_actions_improvement_suggestion_system:
    "Sistema de Sugestões de Melhoria: criação de um sistema em que os colaboradores podem propor melhorias e são reconhecidos por suas contribuições.",

  engaja_positive_work_environment_actions_team_building_activities:
    "Atividades de Team Building: organização de atividades em grupo, com viés de conexão, para aumentar a conexão e os laços entre times.",
  engaja_positive_work_environment_actions_in_person_events:
    "Eventos presenciais: realização de eventos presenciais (show de calouros, festa junina, festa de Halloween, entre outros) para incentivar o fortalecimento de relacionamento entre os colaboradores.",
  engaja_positive_work_environment_actions_flexible_hours:
    "Horário flexível: incentivar a autonomia dos funcionários, permitindo que eles definam os horários de início e fim do seu expediente, baseando o seu desempenho em entregas e não horas trabalhadas.",
  engaja_positive_work_environment_actions_short_friday:
    "Short Friday: incentivar o equilíbrio entre vida pessoal e profissional, permitindo que o colaborador possa encerrar o expediente mais cedo às sextas-feiras.",
  engaja_positive_work_environment_actions_remote_hybrid_work_model:
    "Modelo remoto ou híbrido de trabalho: oferta do modelo de trabalho remoto para os colaboradores, que podem trabalhar de onde quiserem.",
  engaja_positive_work_environment_actions_birthday_achievement_celebrations:
    "Day off de aniversário: conceder um dia de folga para o funcionário em ocasião do seu aniversário.",

  engaja_growth_opportunity_actions_internal_recruitment:
    "Recrutamento interno: identificação e seleção de colaboradores com as habilidades necessárias para ocupar vagas abertas na empresa, com a criação de canais internos para divulgação de vagas e priorização do recrutamento interno antes de abertura para o mercado.",
  engaja_growth_opportunity_actions_career_plan:
    "Plano de Carreira: desenvolvimento de um plano de carreira que alinha as aspirações dos colaboradores com os objetivos da empresa.",
  engaja_growth_opportunity_actions_education_scholarships:
    "Bolsas de Estudo e Subsídios Educacionais: suporte financeiro para cursos e formações que contribuam para o crescimento profissional dos colaboradores.",
  engaja_growth_opportunity_actions_sabbatical_program:
    "Programa de sabático com licença não remunerada ou remunerada, mantendo plano de saúde.",
  engaja_growth_opportunity_actions_training_development:
    "Treinamentos e capacitações: realização de treinamentos periódicos com consultores internos ou externos para o desenvolvimento de competências emocionais ou técnicas.",
  engaja_growth_opportunity_actions_corporate_university:
    "Universidade corporativa: criação de estruturas ou plataformas com trilhas de aprendizagem com conteúdos relacionados ao desenvolvimento de competências emocionais e/ou técnicas visando a formação contínua dos colaboradores.",

  engaja_trust_in_top_leadership_actions_coffee_with_executives:
    "Cafés com a diretoria: organização de reuniões entre pequenos grupos de colaboradores com o C-Level da empresa em que há a possibilidade de fazer perguntas e ter uma proximidade com a alta liderança.",
  engaja_trust_in_top_leadership_actions_pulse_surveys:
    "Pesquisas pulse: realização de pesquisas periódicas, em momentos estratégicos, para calibrar a percepção dos colaboradores (sobre modelos de trabalho, benefícios, implementação de novas práticas, processos de mudança).",
  engaja_trust_in_top_leadership_actions_company_purpose_awareness:
    "Conscientização sobre o propósito da companhia: ações de definição de um propósito claro para a companhia e cascateamento de como ele se conecta com todas as áreas e cargos da organização.",
  engaja_trust_in_top_leadership_actions_reverse_mentorship:
    "Mentoria Reversa: implementação de um programa em que os colaboradores mais jovens ou menos experientes aconselham os líderes mais experientes.",
  engaja_trust_in_top_leadership_actions_strategic_committee_participation:
    "Participação em comitês estratégicos: permitir que colaboradores de diferentes níveis participem de comitês ou grupos para tomada de decisões, como comitê de PLR, comitê para revisão de código de ética, entre outros.",
  engaja_trust_in_top_leadership_actions_results_meetings:
    "Reuniões de resultado: realizar periodicamente encontros abertos para todos os colaboradores com a oportunidade de realizar questionamentos e tirar dúvidas.",
  engaja_trust_in_top_leadership_actions_mentorship_program:
    "Mentoria: implementação de um programa em que os colaboradores mais jovens ou menos experientes são mentorados pelos líderes mais experientes.",

  engaja_compensation_and_benefits_actions_gym_subsidy:
    "Subsídio para academia: oferecer descontos em aplicativos ou redes de academias para incentivar a prática de esportes e atividades físicas entre os colaboradores.",
  engaja_compensation_and_benefits_actions_parental_benefits:
    "Benefícios parentais: oferta de benefícios como licença-paternidade e maternidade estendida, auxílio-creche ou babá, jornadas reduzidas entre outros benefícios para colaboradores com filhos.",
  engaja_compensation_and_benefits_actions_pharmacy_assistance:
    "Benefícios Flexíveis: criar pacotes de benefícios que os colaboradores possam personalizar de acordo com suas necessidades.",
  engaja_compensation_and_benefits_actions_assisted_reproduction_benefits:
    "Benefícios de reprodução assistida (para mulheres e casais homoafetivos)",
  engaja_compensation_and_benefits_actions_private_pension:
    "Previdência privada: acesso a planos para o investimento de previdência privada, com taxas e condições diferenciadas. Ainda é possível que a empresa realize contribuições ou complemente os planos dos colaboradores.",
  engaja_compensation_and_benefits_actions_discounted_products_services:
    "Acesso a produtos e/ou serviços da empresa com desconto e/ou forma de pagamento diferenciada.",
  engaja_compensation_and_benefits_actions_profit_sharing:
    "PLR (Planos de Participação nos Lucros): oferecer um plano em que os colaboradores recebam uma parte dos lucros, com base no desempenho da empresa, com critérios claros para essa remuneração.",
};
