import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  height: 100%;
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  margin-left: 12px;
  max-width: calc(100% - 60px);
`;

const DescriptionContainer = styled.div`
  width: 100%;
`;

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 30px;
  width: 100%;
`;

const ImageContainer = styled.div<{ uri: string }>`
  min-height: 360px;
  width: 100%;
  background-color: blueviolet;
  background-image: url(${({ uri }) => uri});
  background-size: contain;
  background-color: red;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #ffffff;
`;

const ReactionsContainer = styled.div`
  width: 100%;
  padding-bottom: 40px;
`;

const TextWithHtml = styled.div`
  color: var(--color-neutral-dark4);
  font-size: calc(1rem * 0.875);
  width: 100%;

  ul,
  ol {
    padding-left: 0.5em;
  }

  ul li,
  ol li {
    list-style-position: outside;
    margin-left: 1em;
  }
`;

export {
  ButtonsContainer,
  Container,
  DescriptionContainer,
  HeaderContainer,
  ImageContainer,
  ReactionsContainer,
  TextWithHtml,
  TitleContainer,
};
