import styled from "styled-components";
import { Typography } from "@flash-tecnologia/hros-web-ui-v2";

const SectionTitle = styled(Typography)`
  color: var(--color-neutral-dark3);
  margin-bottom: 15px;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  min-width: 200px;

  > button {
    min-width: 174px !important;
  }
`;

export { SectionTitle, ButtonContainer };
