import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 8px;
  overflow: hidden;
  width: 100%;
`;

const ScaleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-neutral-95);
  padding: 24px;
  width: 100%;
  gap: 16px;
`;

const ValueContainer = styled.div`
  min-height: 54px;
  max-height: 54px;
  border-radius: 4px;
  min-width: 30px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-neutral-90);
`;

const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--color-neutral-95);
  width: 100%;
  padding: 6px 24px 24px 24px;
`;

export { Container, ScaleContainer, ValueContainer, LabelContainer };
