import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
`;

const Picture = styled.div<{ source?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  border-radius: 99999px;
  background-color: var(--color-secondary-95);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: ${({ source }) => `url("${source}")`};
`;

export { Container, Picture };
