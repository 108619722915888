import { BoxAdornment, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled, { css } from "styled-components";

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const StyleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  .step-dispatch-field {
    width: 100% !important;
  }

  .step-dispatch-frequency-select {
    width: 200px !important;
  }
`;

const PageTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[20]};
  font-weight: 700;
  margin-bottom: 56px;
`;

const PageInfo = styled.div`
  margin-right: 24px;
`;

const PageSubTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.secondary[50]};
  margin-bottom: 16px;
  font-weight: 700;
`;

const PageDescription = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[50]};
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
`;

const StyledBoxAdornment = styled(BoxAdornment)<{ error?: boolean }>`
  &.box-adornment-container-custom-theme {
    ${({ error }) =>
      error
        ? css`
            background: ${({ theme }) =>
              theme.colors.feedback.error[90]} !important;
            border: 2px solid ${({ theme }) => theme.colors.feedback.error[50]} !important;
          `
        : css``}
  }
`;

export {
  PageDescription,
  PageInfo,
  PageSubTitle,
  PageTitle,
  Row,
  StyleContainer,
  StyledBoxAdornment,
};
