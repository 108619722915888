import { Icons, Tooltip } from "@flash-tecnologia/hros-web-ui-v2";
import { icons, fontColors, backgroundColors } from "./reactions";
import { ReactIconProps } from "./types";
import { MainContainer, IconContainer } from "./styles";

const ReactIcon = ({ type, tooltip, onClick }: ReactIconProps) => {
  const iconName: any = icons[type];

  return (
    <Tooltip
      placement="bottom"
      children={
        <MainContainer onClick={() => onClick && onClick()}>
          <IconContainer type={type}>
            <Icons
              name={iconName}
              color={fontColors[type]}
              fill={backgroundColors[type]}
            />
          </IconContainer>
        </MainContainer>
      }
      title={tooltip}
    />
  );
};

export { ReactIcon };
