import styled from "styled-components";

const PreviewSectionContainer = styled.div`
  padding: 40px;
  border: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  border-radius: 12px;
  width: 100%;
`;

const FlexBetween = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const FlexCenter = styled.div`
  display: flex;
  align-items: center;
`;

const DownloadContainer = styled.div`
  padding: 32px 40px;
  border: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  border-radius: 12px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
`;

const TextWithHtml = styled.div`
  color: var(--color-neutral-dark4);
  font-size: calc(1rem * 0.875);
  width: 100%;

  ul,
  ol {
    padding-left: 0.5em;
  }

  ul li,
  ol li {
    list-style-position: outside;
    margin-left: 1em;
  }
`;

const ImagePreview = styled.div<{ url?: string }>`
  width: 45px;
  height: 45px;
  background-color: #ccc;
  background-image: url(${({ url }) => url});
  background-repeat: no-repeat;
  background-size: cover;
`;

export {
  DownloadContainer,
  FlexBetween,
  FlexCenter,
  ImagePreview,
  PreviewSectionContainer,
  TextWithHtml,
};
