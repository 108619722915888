import { trpc } from "@api/client";
import { MultiSelect } from "@flash-tecnologia/hros-web-ui-v2";
import { alert, dispatchToast } from "@utils";
import { useMemo, useState } from "react";

type TOwner = {
  label: string;
  value: string;
}[];

interface TOwnersMultiSelect {
  owners?: TOwner | null;
  onChange: (
    values: {
      label: string;
      value: string;
    }[]
  ) => void;
  error?: boolean;
}

export const OwnersMultiSelect = ({
  onChange,
  owners,
  error,
}: TOwnersMultiSelect) => {
  const removeOwnerWildCard = owners?.filter((owner) => owner.value !== "*");

  const [selectedOwners, setSelectedOwners] =
    useState<TOwnersMultiSelect["owners"]>(removeOwnerWildCard);

  const [isError, setIsError] = useState(error);

  const { data: allEmployees, isFetching: getEmployeesByCompanyIdLoading } =
    trpc?.getInternalEmployeesByCompanyId?.useQuery(
      {
        status: "ACTIVE",
      },
      {
        retry: false,
        refetchOnWindowFocus: false,
        onError: (e: any) => {
          dispatchToast({
            type: "error",
            content:
              "Sentimos muito, ocorreu um erro ao buscar as opções de responsável",
          });
        },
      }
    );

  const employees = useMemo(() => {
    const result = allEmployees?.employees
      ?.map((item) => ({
        label: item?.name,
        value: item?._id,
      }))
      .filter(
        (employee) =>
          !selectedOwners?.map((item) => item?.value)?.includes(employee?.value)
      );

    return result;
  }, [allEmployees, selectedOwners]);

  return (
    <MultiSelect
      renderInput={() => null}
      value={selectedOwners ?? undefined}
      onSelectChange={(_, values: TOwner) => {
        if (values.length < 1) {
          alert("É necessário ter ao menos um responsável!");
          setIsError(true);
        } else {
          setIsError(false);
        }
        setSelectedOwners(values);
        onChange(values);
      }}
      loading={getEmployeesByCompanyIdLoading}
      loadingText={"Buscando colaboradores..."}
      label={"Responsáveis *"}
      noOptionsText={"Nenhum colaborador encontrado"}
      fullWidth={true}
      options={employees ?? []}
      error={isError}
    />
  );
};
