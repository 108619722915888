import styled from "styled-components";
import { Typography } from "@flash-tecnologia/hros-web-ui-v2";

export const ChildrenContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const BoxContainer = styled.div`
  display: flex;
  margin-top: 10px;
  height: 240px;
  width: 100%;
  ::-webkit-scrollbar-thumb:horizontal {
    display: none;
  }
  overflow-x: auto;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const ShowFullListContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-top: 18px;
`;

export const ShowFullListText = styled(Typography)`
  display: flex;
  align-items: center;
  font-weight: 700;
  color: #fe2b8f;
`;

export const LabelStyle = styled(Typography)`
  font-weight: 400;
  color: var(--color-neutral-dark4);
`;
