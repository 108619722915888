import styled from "styled-components";

const Container = styled.div`
  width: 100%;
`;

const BarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
`;

const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  min-width: 100px;
  max-width: 100px;
  overflow: hidden;
`;

const ValueContainer = styled(LabelContainer)`
  justify-content: flex-start;
  width: auto;
`;

const BarArea = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const Bar = styled.div<{ width: string }>`
  height: 50px;
  width: ${({ width }) => width || 0};
  background-color: var(--color-primary);
  z-index: 1;
`;

const LineContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 0;
`;

const Line = styled.div`
  min-width: 2px;
  height: 100%;
  background-color: var(--color-neutral-95);
`;

export {
  Container,
  LabelContainer,
  ValueContainer,
  BarContainer,
  Bar,
  BarArea,
  LineContainer,
  Line,
};
