import { dayjs, Icons } from "@flash-tecnologia/hros-web-ui-v2";
import { Typography } from "@components/index";
import { Container, DateContainer } from "./styles";
import { BigDatesInteface } from "./types";

export default ({
  createdAt,
  sendDate,
  interactionDeadline,
  updatedAt,
}: BigDatesInteface) => (
  <Container>
    <DateContainer>
      <Icons name="IconCalendar" color="var(--color-secondary-50)" />
      <Typography
        variant="body4"
        fontWeight={600}
        color="var(--color-neutral-50)"
      >
        Criado em
      </Typography>
      <Typography
        variant="headline9"
        fontWeight={700}
        color="var(--color-neutral-30)"
      >
        {dayjs(createdAt)?.format("DD/MM/YYYY")}
      </Typography>
    </DateContainer>
    {sendDate ? (
      <DateContainer>
        <Icons name="IconCalendar" color="var(--color-secondary-50)" />
        <Typography
          variant="body4"
          fontWeight={600}
          color="var(--color-neutral-50)"
        >
          Enviado em
        </Typography>
        <Typography
          variant="headline9"
          fontWeight={700}
          color="var(--color-neutral-30)"
        >
          {dayjs(sendDate)?.format("DD/MM/YYYY")}
        </Typography>
      </DateContainer>
    ) : null}
    {interactionDeadline ? (
      <DateContainer>
        <Icons name="IconFilePlus" color="var(--color-secondary-50)" />
        <Typography
          variant="body4"
          fontWeight={600}
          color="var(--color-neutral-50)"
        >
          Prazo de respostas
        </Typography>
        <Typography
          variant="headline9"
          fontWeight={700}
          color="var(--color-neutral-30)"
        >
          {dayjs(interactionDeadline)?.format("DD/MM/YYYY")}
        </Typography>
      </DateContainer>
    ) : null}
    {/* <DateContainer>
      <Icons name="IconRefresh" color="var(--color-secondary-50)" />
      <Typography
        variant="body4"
        fontWeight={600}
        color="var(--color-neutral-50)"
      >
        Última atualização
      </Typography>
      <Typography
        variant="headline9"
        fontWeight={700}
        color="var(--color-neutral-30)"
      >
        {dayjs(updatedAt)?.format("DD/MM/YYYY")}
      </Typography>
    </DateContainer> */}
  </Container>
);
