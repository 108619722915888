export default {
  translations: {
    page: {
      helloworld: "Hello World with I18N",
      week: "semana",
      weeks: "semanas",
      day: "dia",
      days: "dias",
      month: "mês",
      months: "meses",
      year: "ano",
      years: "anos",
      monday: "segunda-feira",
      tuesday: "terça-feira",
      wednesday: "quarta-feira",
      thursday: "quinta-feira",
      friday: "sexta-feira",
      saturday: "sábado",
      sunday: "domingo",
      morning: "manhã",
      afternoon: "tarde",
      night: "noite",
      status: {
        notStarted: "não iniciado",
        draft: "rascunho",
        scheduled: "agendado",
        send: "enviado",
        progress: "Em andamento",
        closed: "encerrado",
        paused: "pausado",
      },
      scale: {
        star: "estrela",
        number: "número",
        heart: "coração",
        emoji: "emoji",
        enps: "eNPS",
      },
    },
    home: {
      copy: "Cópia",
      error: "Ocorreu um erro, tente novamente mais tarde.",
      title: "Engajamento",
      card: {
        first: {
          title: "Total de campanhas enviadas",
        },
        second: {
          title: "Taxa média de visualização",
          toolTipText:
            "A taxa é calculada a partir do total de destinatários que visualizaram a campanha na área de notificações dividido pelo total de destinatários que receberam a campanha.",
        },
        third: {
          title: "Taxa média de clique em link",
          toolTipText:
            "A taxa é calculada a partir do total de destinatários que clicaram no link da campanha dividido pelo total de destinatários que receberam campanhas do tipo comunicação.",
        },
        fourth: {
          title: "Taxa média de resposta",
          toolTipText:
            "A taxa é calculada a partir do total de destinatários que responderam a uma pesquisa dividido pelo total de destinatários que receberam campanhas do tipo pesquisa.",
        },
        fifth: {
          title: "Taxa média de reações",
          toolTipText:
            "A taxa é calculada a partir do total de reações na comunicação dividido pelo total de destinatários que receberam a comunicação.",
        },
      },
      tab: {
        first: "Campanha",
        second: "Colaboradores",
      },
      placeholder: {
        campaign: "Buscar por campanha",
        employee: "Buscar por nome",
      },
      column: {
        name: "Nome",
        status: "Status",
        createdAt: "Criado em",
        sendingAt: "Enviado em",
        type: "Tipo de campanha",
        groups: "Grupos",
        recipients: "Destinatários",
        views: "Taxa de visializações",
        clicks: "Taxa de clique em link",
        answer: "Taxa de resposta",
      },
      emptyCampaignMessage: {
        title: "Você ainda não criou nenhuma campanha",
        message:
          "Para enviar uma comunicação ou uma pesquisa para seus colaboradores, crie uma nova campanha.",
      },
      emptyEmployeeMessage: {
        title: "Você ainda não criou nenhum colaborador",
        message: "Não encontramos colaboradores ativos.",
      },
      createCampaignButton: "Criar campanha",
      status: {
        send: "Enviado",
        draft: "Rascunho",
      },
      type: {
        research: "Pesquisa",
        communication: "Comunicação",
        enps: "eNPS",
      },
      dropdown: {
        edit: "Editar campanha",
        view: "Ver detalhes",
        copy: "Duplicar campanha",
        remove: "Excluir campanha",
      },
      confirmModal: {
        title: "Tem certeza que deseja excluir este rascunho",
        message:
          "Excluir um rascunho irá removê-lo permanentemente da sua lista.",
        leftButton: "Cancelar",
        rightButton: "Sim, excluir rascunho",
      },
      successModal: {
        title: "Pronto",
        message: "Seu rascunho foi excluído com sucesso.",
        rightButton: "Voltar para engajamento",
      },
    },
    createCampaign: {
      anonymousTag: "Resposta anônima",
      employeeWarning: {
        first:
          "O anonimato está habilitado, portanto, para garantir a segurança da informação a pesquisa deve ser",
        second: "enviada para",
        third: "pelo menos 3 pessoas.",
      },
      error: "Ocorreu um erro, tente novamente mais tarde.",
      maxSizeError: "A imagem deve ser menor que 10 MB",
      resolutionError: "A resolução mínima deve ser 300 x 275px",
      breadcrumbs: {
        first: "Engajamento",
        second: "Criar campanha",
      },
      modal: {
        title: "Pronto",
        campaignMessage: "Sua campanha foi enviada com sucesso",
        draftMessage: "Seu rascunho foi salvo com sucesso",
        rightButton: "Voltar para engajamento",
      },
      cropperModal: {
        title: "Recorte a imagem",
        message: "Selecione a área que você deseja exibir em sua comunicação.",
        leftButton: "Cancelar",
        rightButton: "Salvar",
      },
      footer: {
        draftButton: "Salvar rascunho",
        previousButton: "Anterior",
        nextButton: "Salvar e continuar",
        submitButton: "Adicionar campanha",
      },
      title: "Criar campanha",
      step: {
        first: {
          title: "Conteúdo",
          message:
            "Adicione as informações básicas da sua campanha, como nome, tipo e personalização.",
          sections: {
            first: {
              title: "Nome e tipo",
              message:
                "Dê um nome para a campanha e depois escolha o tipo: comunicação ou pesquisa.",
              firstField: {
                label: "Nome da campanha",
                helperText:
                  "O nome da campanha ficará visível apenas para você.",
              },
              secondField: {
                label: "Tipo de campanha",
                firstOption: "Comunicação",
                secondOption: "Pesquisa",
                thirdOption: "eNPS",
              },
            },
            second: {
              title: "Personalização da comunicação",
              message:
                "Escreva um título chamativo e uma mensagem para enviar aos colaboradores.",
              firstField: {
                label: "Título da mensagem",
                helperText: "Exemplo: Papo com o CEO às 19h!",
              },
              secondField: {
                label: "Mensagem",
              },
            },
            third: {
              firstField: {
                label: "Inserir link",
                helperText:
                  "Selecione esta opção para inserir um link na mensagem.",
              },
              secondField: {
                label: "Selecionar texto do link",
                helperText: "Acesse agora",
              },
              thirdField: {
                label: "Insira a URL",
              },
            },
            fourth: {
              title: "Personalização da pesquisa",
              message:
                "Escreva uma pergunta para enviar para as pessoas. Depois, defina o título da resposta mais baixa e mais alta da escala.",
              firstField: {
                label: "Texto da pergunta",
                helperText:
                  "Exemplo: Numa escala de 1 a 5, como você está se sentindo hoje?",
              },
              secondField: {
                label: "Formato da resposta",
                firstOption: "Escala de números",
                secondOption: "Escala de estrelas",
                thirdOption: "Escala de corações",
                fourthOption: "Escala de emojis",
              },
              thirdField: {
                label: "Título da resposta mais baixa",
                helperText: "exemplo: Péssimo",
              },
              fourthField: {
                label: "Título da resposta mais alta",
                helperText: "exemplo: Ótimo",
              },
              fifthField: {
                label: "Solicitar justificativa da resposta",
                helperText:
                  "Selecione esta opção para inserir um campo de comentário para o colaborador preencher de forma opcional.",
              },
              sixthField: {
                label: "Resposta anônima",
                helperText:
                  "Selecione esta opção para que as respostas sejam registradas de forma anônima.",
              },
            },
            fifth: {
              firstField: {
                label: "Permitir reações",
                helperText:
                  "Selecione esta opção para permitir que as pessoas reajam às comunicações. As reações disponíveis são: gostei, amei, apoio e parabéns.",
              },
            },
          },
        },
        second: {
          title: "Destinatários",
          message: "Selecione grupos ou escolha os colaboradores manualmente.",
          sections: {
            first: {
              title: "Envio da campanha",
              firstField: {
                label: "Enviar para todos",
                placeholder:
                  "Ao selecionar esta opção, a campanha será enviada para todas as pessoas.",
              },
            },
            second: {
              title: "Seleção de pessoas",
              message: "Para quais pessoas essa campanha será enviada?",
              tablePlaceholder: "Buscar por nome",
              tableColumn: "Nome",
            },
          },
          transferList: {
            leftList: {
              emptyStageMessage: "Nenhum registro encontrado.",
              emptyStageMessageFilter:
                "O filtro aplicado não apresenta resultados",
            },
            rightList: {
              emptyStageMessage:
                "Pessoas movidas à comunicação aparecerão aqui.",
              emptyStageMessageFilter:
                "O filtro aplicado não apresenta resultados",
            },
          },
        },
        third: {
          title: "Revisão",
          groups: "Grupos",
          textFieldLabel: "Deixe seu comentário (opcional)",
          employees: "Colaboradores",
          message:
            "Confira a prévia da sua campanha e revise antes de enviá-la.",
          sections: {
            first: {
              title: "Revisar comunicação",
              message: "Confira a prévia da sua campanha e revise a mensagem.",
              link: "Acesse agora",
            },
            second: {
              title: "Revisar destinatários",
              message:
                "Confira os grupos e pessoas para quem a camapanha será enviada.",
            },
            third: {
              title: "Revisar pesquisa",
              message: "Confira a prévia da sua campanha e revise a mensagem.",
            },
            fourth: {
              title: "Revisar destinatários",
              message:
                "Confira os grupos e pessoas para quem a camapanha será enviada.",
            },
          },
        },
      },
    },
    detailEmployee: {
      error: "Ocorreu um erro, tente novamente mais tarde.",
      yes: "Sim",
      not: "Não",
      communication: "Comunicação",
      research: "Pesquisa",
      enps: "eNPS",
      breadcrumbs: {
        first: "Engajamento",
        second: "Detalhe do colaborador",
      },
      card: {
        first: "Campanhas",
        second: "Visualizações",
        third: "Cliques",
        fourth: "Reações",
        fifth: "Like",
        sixth: "Amei",
        seventh: "Apoio",
        eughth: "Parabéns",
      },
      titles: {
        first: "Insights",
        second: "Campanhas associadas ao colaborador",
      },
      column: {
        campaings: "Campanhas",
        type: "Tipo de campanha",
        views: "Visualizações",
        clicks: "Cliques",
        reaction: {
          default: "Reagir",
          heart: "Amei",
          like: "Gostei",
          support: "Apoio",
          congrats: "Parabéns",
        },
      },
      placeholder: "Buscar por campanha",
      emptyCampaignMessage: {
        title: "Você ainda não criou nenhuma campanha",
        message:
          "Para enviar uma comunicação ou uma pesquisa para seus colaboradores, crie uma nova campanha.",
        button: "Criar campanha",
      },
    },
    detailCampaign: {
      error: "Ocorreu um erro, tente novamente mais tarde.",
      yes: "Sim",
      not: "Não",
      copy: "Cópia",
      copyButton: "Duplicar campanha",
      enpsDuplicateButton: "Duplicar eNPS",
      communication: "Comunicação",
      research: "Pesquisa",
      breadcrumbs: {
        first: "Engajamento",
        second: "Detalhe da campanha",
        third: "Detalhe da eNPS",
      },
      status: {
        draft: "Rascunho",
        send: "Enviado",
        anonymous: "Resposta anônima",
      },
      createdAt: "Criado em",
      sendAt: "Enviado em",
      placeholder: "Buscar por nome",
      column: {
        recipients: "Destinatários",
        group: "Grupo",
        type: "Tipo de campanha",
        view: "Visualização",
        click: "Clique",
      },
      card: {
        first: {
          title: "Total de destinatários",
        },
        second: {
          title: "Taxa de visualização",
          toolTipText:
            "Total de colaboradores que acessaram a área de notificações e visualizaram as mensagens no feed.",
        },
        third: {
          title: "Taxa de clique em link",
          toolTipText:
            "Quantidade de colaboradores que clicaram no link da mensagem (somente quando possui link). A taxa é calculada em relação ao total de destinatários.",
        },
        fourth: {
          title: "Reações",
        },
        fifth: {
          title: "Like",
        },
        sixth: {
          title: "Amei",
        },
        seventh: {
          title: "Apoio",
        },
        eughth: {
          title: "Parabéns",
        },
      },
      enpsCard: {
        first: {
          title: "Respostas",
        },
        second: {
          title: "Pontuação",
          toolTipText: "A pontuação é a relação de %promotores - %detratores",
        },
        third: {
          title: "Promotores (9-10)",
        },
        fourth: {
          title: "Neutros (8-7)",
        },
        fifth: {
          title: "Detratores (6-0)",
        },
      },
      emptyEmployeeMessage: {
        title: "Você ainda não adicinou nenhum colaborador",
        message: "Não encontramos colaboradores associados a essa campanha.",
        button: "Editar campanha",
      },
      modal: {
        title: "Pronto",
        message: "Sua campanha foi duplicada com sucesso.",
        rightButton: "Voltar para engajamento",
        enpsModalMessage: "Sua eNPS foi duplicada com sucesso.",
      },
    },
    reaction: {
      default: "React",
      heart: "Loved",
      like: "Liked",
      support: "Support",
      congrats: "Congratulations",
    },
  },
};
