import styled from "styled-components";
import { Typography } from "@flash-tecnologia/hros-web-ui-v2";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Text = styled(Typography)`
  color: var(--color-neutral-dark3);
  font-weight: 600;
  margin-left: 5px;
`;

export { Container, Text };
