import {
  getAccessToken,
  useSelectedCompany,
} from "@flash-tecnologia/hros-web-utility";
import { QueryClient } from "@tanstack/react-query";
import { createTRPCReact, httpBatchLink } from "@trpc/react-query";
import { inferRouterOutputs } from "@trpc/server";
import type { AppRouter } from "server";

type TrpcClientCustomProps = {
  selectedCompany: ReturnType<typeof useSelectedCompany>["selectedCompany"];
};

export type RouterOutput = inferRouterOutputs<AppRouter>;

export const trpc = createTRPCReact<AppRouter>();

export const queryClient = new QueryClient();
export const trpcClient = ({ selectedCompany }: TrpcClientCustomProps) =>
  trpc.createClient({
    links: [
      httpBatchLink({
        // url: "http://localhost:3000/trpc",
        url: `${process.env.BFF_URL}/trpc`,
        headers: async () => {
          const token = await getAccessToken();
          return {
            Authorization: token,
            "X-Selected-Company": selectedCompany?.id,
          };
        },
      }),
    ],
  });
