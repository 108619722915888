import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
`;

const FieldRowContainer = styled.div`
  width: 100%;
`;

const FieldRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;

  .options-text-feild {
    width: 100% !important;
    margin-right: 10px !important;
  }
`;

const TrashButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const LinkButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
`;

export {
  Container,
  FieldRowContainer,
  FieldRow,
  TrashButtonContainer,
  LinkButtonContainer,
};
