import styled from "styled-components";
import { Typography } from "@flash-tecnologia/hros-web-ui-v2";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  div:not(:last-child) {
    margin-right: 15px;
  }
`;

const Button = styled.div<{ selected?: boolean; disabled?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 64px;
  background-color: ${({ selected }) =>
    selected ? "var(--color-secondary-99)" : "var(--color-neutral-90)"};
  margin-left: 10px;
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
  border-radius: 4px;
  border: 2px solid
    ${({ selected }) =>
      selected ? "var(--color-secondary-50)" : "var(--color-neutral-90)"};
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
`;

const Label = styled(Typography)<{ selected?: boolean }>`
  margin-top: 10px;
  color: ${({ selected }) =>
    selected ? "var(--color-secondary-50)" : "var(--color-neutral-30)"};
`;

export { Container, ButtonContainer, Button, Label };
