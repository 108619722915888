import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: var(--color-neutral-95);
  min-height: 400px;
  width: 100%;
  border-radius: 12px;
  border: 1px solid var(--color-neutral-90);
`;

export { Container };
