import { Typography } from "..";
import { Container } from "./styles";

const EmptyStateChart = () => (
  <Container>
    <Typography variant="body1" color="#83727D" fontWeight={600}>
      Nenhum dado encontrato
    </Typography>
    <Typography variant="body1" color="#83727D" fontWeight={600}>
      para exibir esse gráfico
    </Typography>
  </Container>
);

export { EmptyStateChart };
