import styled from "styled-components";

const Container = styled.div<{ isDragging?: boolean; active?: boolean }>`
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  box-shadow: 0px 0px 0px 1.2px
    ${({ isDragging, active }) =>
      isDragging || active
        ? "var(--color-secondary-70)"
        : "var(--color-neutral-90)"};
  border-radius: 12px;
  user-select: none;
  background-color: var(--color-neutral-100);
  margin-bottom: 24px;
  transform: ${({ isDragging }) => (isDragging ? "rotate(10deg)" : "none")};
`;

const ContentContainer = styled.div<{
  isDragging?: boolean;
  active?: boolean;
  isDragDisabled?: boolean;
}>`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  padding: 24px;
  border-left: ${({ isDragDisabled, active, isDragging }) =>
    isDragDisabled
      ? `none`
      : isDragging || active
      ? "1.2px solid var(--color-secondary-70)"
      : "1.2px solid var(--color-neutral-90)"};
  width: 100%;
`;

const LeftContainer = styled.div<{
  isDragging?: boolean;
  active?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 12px 0 12px;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  background-color: ${({ isDragging, active }) =>
    isDragging
      ? "var(--color-secondary-70)"
      : active
      ? "var(--color-secondary-95)"
      : "var(--color-neutral-95)"};
`;

const FieldContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
`;

const FieldRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;

  .answer-format-field {
    width: 300px !important;
  }

  .full-width-field {
    width: 100% !important;
  }
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const IconDivider = styled.div`
  height: 24px;
  width: 1px;
  background-color: var(--color-neutral-90);
`;

export {
  Container,
  ContentContainer,
  LeftContainer,
  FieldContainer,
  FieldRow,
  IconContainer,
  IconDivider,
};
