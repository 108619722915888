import { DataCard } from "../DataCard";
import { TagList } from "../TagsList";

interface StudentsTabProps {
  data: any;
  employees: any;
  onNavigate: React.MouseEventHandler<HTMLButtonElement> | undefined;
}

export const RecipientTab = ({
  data,
  employees,
  onNavigate,
}: StudentsTabProps) => {
  const recipients = (data?.employees || []).map((recipient) => {
    const found = employees?.find((e) => e._id == recipient);

    return {
      id: found._id,
      label: found.name,
      variant: "primary",
    };
  });

  return (
    <DataCard
      title="Destinatários"
      subTitle="Confira as pessoas para quem a comunicação será enviada."
      editable
      onEditClick={onNavigate}
      sections={[
        {
          items: [
            {
              title: "Pessoas",
              description: (
                <TagList
                  style={{ marginTop: "10px" }}
                  minTags={50}
                  tags={recipients}
                />
              ),
            },
          ],
        },
      ]}
    />
  );
};
