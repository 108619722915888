import { Carousel } from "@flash-tecnologia/hros-web-ui-v2";
import { CarouselInterface } from "./types";
import { Title } from "./styles";
import { Card } from "../Card";

const CarouselCards = ({ cards }: CarouselInterface) => (
  <>
    <Title variant="headline8">Insights</Title>
    <Carousel
      slides={cards?.map(({ title, icon, value, subValue, toolTip }, i) => (
        <Card
          key={i}
          label={title}
          value={value}
          subValue={subValue}
          icon={icon || "IconSpeakerphone"}
          info={
            toolTip?.title && toolTip?.description
              ? {
                  title: toolTip?.title,
                  message: toolTip?.description,
                }
              : undefined
          }
        />
      ))}
      gap={20}
      arrows={"bottom"}
    />
  </>
);

export { CarouselCards };
