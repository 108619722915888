import { Modal } from "../Modal";
import { Footer } from "./styles";
import { Button } from "@flash-tecnologia/hros-web-ui-v2";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

export const SendSurveyModal = ({ isOpen, onClose, onSubmit }: ModalProps) => {
  return (
    <Modal
      id={"modal_send_survey"}
      title="Pronto!"
      subTitle={`Suas respostas foram enviadas.`}
      description="Agradecemos por sua participação!"
      onClose={onClose}
      closeButton
      iconStatus="success"
      open={isOpen}
      iconName="IconCheck"
      footer={{
        children: (
          <Footer>
            <div>
              <Button variant={"primary"} size={"large"} onClick={onSubmit}>
                Concluído
              </Button>
            </div>
          </Footer>
        ),
      }}
    />
  );
};
