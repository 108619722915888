import { Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled, { css } from "styled-components";

const TextAreaContainer = styled.div<{ disabled?: boolean }>`
  ${({ disabled }) =>
    disabled
      ? css`
          pointer-events: none;
        `
      : css``}
`;

const ErrorAsterisk = styled.span`
  color: ${({ theme }) => theme.colors.feedback.error[40]};
`;

const SectionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const SectionSubContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const FlexBetween = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const FlexEnd = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
`;

const FlexColumn = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
`;

const MaxLengthText = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[50]};
`;

const DividerBorder = styled.div`
  height: 1px;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors.neutral[90]};
`;

const PageTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[20]};
  font-weight: 700;
  margin-bottom: 56px;
`;

const PageInfo = styled.div`
  margin-right: 24px;
`;

const PageSubTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.secondary[50]};
  margin-bottom: 16px;
  font-weight: 700;
`;

const PageDescription = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[50]};
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
`;

const DisableContainer = styled.div<{ disabled?: boolean }>`
  ${({ disabled }) =>
    disabled
      ? css`
          pointer-events: none;
        `
      : css``}
`;

export {
  PageInfo,
  PageTitle,
  PageSubTitle,
  PageDescription,
  TextAreaContainer,
  SectionContainer,
  SectionSubContainer,
  ErrorAsterisk,
  FlexBetween,
  FlexColumn,
  FlexEnd,
  MaxLengthText,
  DividerBorder,
  DisableContainer,
};
