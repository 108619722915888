import { useState } from "react";
import { SearchField, Tag } from "@flash-tecnologia/hros-web-ui-v2";
import { Typography } from "../../../../Typography";
import { EmployeeRow } from "../";
import {
  Container,
  Header,
  Feed,
  HeaderTitleContainer,
  EmptyStateContainer,
} from "./styles";
import { Data } from "../../types";

const SelectedList = ({
  data,
  onRemove,
}: {
  data: Data;
  onRemove: (id: string) => void;
}) => {
  const [search, setSearch] = useState<string>("");

  return (
    <Container>
      <Header>
        <HeaderTitleContainer>
          <Typography
            variant="headline8"
            color="var(--color-neutral-30)"
            fontWeight={700}
          >
            Selecionados
          </Typography>
          <Tag variant="gray">{data?.length || 0} itens</Tag>
        </HeaderTitleContainer>
        <SearchField
          label="Buscar"
          onChange={(e) => setSearch(e?.target?.value || "")}
        />
      </Header>
      <Feed>
        {data?.length ? (
          data
            ?.filter(({ name }) => name?.match(RegExp(search, "ig")))
            ?.map(({ id, name }, i) => (
              <EmployeeRow
                key={`selected-${id}-${i}`}
                id={id}
                name={name}
                onRemove={(e) => onRemove(e)}
              />
            ))
        ) : (
          <EmptyStateContainer>
            <Typography
              variant="headline9"
              color="var(--color-neutral-30)"
              fontWeight={700}
              style={{ marginBottom: 12 }}
            >
              Nenhuma pessoa selecionada
            </Typography>
            <Typography
              variant="body4"
              color="var(--color-neutral-40)"
              fontWeight={600}
            >
              Selecione as pessoas que deseja
            </Typography>
            <Typography
              variant="body4"
              color="var(--color-neutral-40)"
              fontWeight={600}
            >
              adicionar na lista ao lado
            </Typography>
          </EmptyStateContainer>
        )}
      </Feed>
    </Container>
  );
};
export { SelectedList };
