import styled from "styled-components";

const Container = styled.div`
  width: 100%;
`;

const OptionList = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  overflow: auto;
  max-height: 220px;
`;

const OptionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
`;

const LinkButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-top: 1px solid var(--color-neutral-90);
  margin-top: 16px;
  padding-top: 16px;
`;

export { Container, OptionList, OptionContainer, LinkButtonContainer };
