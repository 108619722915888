import {
  DatePicker,
  Radio,
  SelectField,
  dayjs,
} from "@flash-tecnologia/hros-web-ui-v2";
import { Dayjs } from "@flash-tecnologia/hros-web-ui-v2/dist/utils";
import { segment } from "@utils";
import { useEffect, useState } from "react";
import {
  CounterField,
  DaysPicker,
  LineSeparator,
  Typography,
} from "../../../..";
import { DaysPickerInterface } from "../../../../DaysPicker/types";
import { Divider } from "../../../../v2";
import {
  PageDescription,
  PageInfo,
  PageSubTitle,
  Row,
  StyleContainer,
  StyledBoxAdornment,
} from "./styles";
import { StepDispatchInterface } from "./types";

import { Box } from "@components/EngagementForm/common/components";

import { Grid } from "@mui/material";

const StepDispatch = ({ disabled, hide, formik }: StepDispatchInterface) => {
  const { startDate, recurrence, recurrenceMode, model } = formik?.values;

  const [periodFieldOptions, setPeriodFieldOptions] = useState<
    { label: string; value: "morning" | "afternoon" | "night" }[]
  >([
    {
      label: "Manhã (08h-12h)",
      value: "morning",
    },
    {
      label: "Tarde (12h-18h)",
      value: "afternoon",
    },
    {
      label: "Noite (18h-22h)",
      value: "night",
    },
  ]);

  const [endType, setEndType] = useState<"never" | "date" | "occurrence">();

  const [daysOfTheWeek, setDaysOfTheWeek] = useState<
    DaysPickerInterface["days"]
  >([
    {
      label: "Segunda-feira",
      value: "monday",
      checked: false,
    },
    {
      label: "Terça-feira",
      value: "tuesday",
      checked: false,
    },
    {
      label: "Quarta-feira",
      value: "wednesday",
      checked: false,
    },
    {
      label: "Quinta-feira",
      value: "thursday",
      checked: false,
    },
    {
      label: "Sexta-feira",
      value: "friday",
      checked: false,
    },
    {
      label: "Sábado",
      value: "saturday",
      checked: false,
    },
    {
      label: "Domingo",
      value: "sunday",
      checked: false,
    },
  ]);

  useEffect(() => {
    if (recurrence?.daysOfTheWeek?.length) {
      setDaysOfTheWeek(
        daysOfTheWeek?.map(({ label, value }) => ({
          label,
          value,
          checked: recurrence?.daysOfTheWeek?.includes(value) ? true : false,
        }))
      );
    }

    if (recurrence) {
      if (recurrence?.occurrenceLimit) {
        if (recurrence?.occurrenceLimit === -1) {
          setEndType("never");
          return;
        }
        if (recurrence?.occurrenceLimit > 0) {
          setEndType("occurrence");
          return;
        }
      }

      if (recurrence?.endDate) setEndType("date");
    }
  }, [recurrence]);

  useEffect(() => {
    if (startDate?.date && !disabled) {
      const diff = dayjs(dayjs()?.format("YYYY-MM-DD")).diff(
        dayjs(startDate?.date)?.format("YYYY-MM-DD"),
        "day"
      );

      if (diff >= 0) {
        const hour = parseInt(dayjs()?.format("HH"));

        if (hour < 12) {
          setPeriodFieldOptions([
            {
              label: "Imediatamente",
              value: "morning",
            },
            {
              label: "Tarde (12h-18h)",
              value: "afternoon",
            },
            {
              label: "Noite (18h-22h)",
              value: "night",
            },
          ]);
          return;
        }

        if (hour < 18) {
          setPeriodFieldOptions([
            {
              label: "Imediatamente",
              value: "afternoon",
            },
            {
              label: "Noite (18h-22h)",
              value: "night",
            },
          ]);
          return;
        }

        setPeriodFieldOptions([
          {
            label: "Imediatamente",
            value: "night",
          },
        ]);
        return;
      } else {
        setPeriodFieldOptions([
          {
            label: "Manhã (08h-12h)",
            value: "morning",
          },
          {
            label: "Tarde (12h-18h)",
            value: "afternoon",
          },
          {
            label: "Noite (18h-22h)",
            value: "night",
          },
        ]);
      }
    }
  }, [startDate?.date]);

  useEffect(() => {
    if (startDate?.period && !disabled) {
      const selectedPeriod = periodFieldOptions?.find(
        ({ value }) => value === startDate?.period
      );

      if (!selectedPeriod)
        formik.setValues({
          ...formik.values,
          recurrenceMode,

          recurrence,
          startDate: {
            ...startDate,
            period: undefined,
          },
        });
    }
  }, [periodFieldOptions]);

  if (hide) return <></>;

  return (
    <Grid container>
      <Grid item sm={12} md={5} lg={4}>
        <PageInfo>
          <PageSubTitle variant="headline7">Envio</PageSubTitle>
          <PageDescription variant="body3">
            Configure como deseja enviar.
          </PageDescription>
        </PageInfo>
      </Grid>
      <Grid item sm={12} md={7} lg={8}>
        <>
          <StyleContainer style={{ marginBottom: "40px" }}>
            <Box>
              <Typography
                variant="headline7"
                color="var(--color-neutral-30)"
                fontWeight={700}
              >
                Configurações do envio
              </Typography>
              <Divider spacing="quark" />
              <Typography
                variant="body4"
                color="var(--color-neutral-50)"
                fontWeight={400}
              >
                Escolha se o envio será realizado uma única vez ou configure a
                recorrência.
              </Typography>
              <Divider spacing="s1" />
              <Row>
                <StyledBoxAdornment
                  style={{ width: "100%" }}
                  error={
                    formik.touched.recurrenceMode &&
                    Boolean(formik.errors.recurrenceMode)
                  }
                  description={
                    <>
                      <Typography
                        variant="body3"
                        color="var(--color-neutral-30)"
                        fontWeight={600}
                      >
                        Envio único
                      </Typography>
                    </>
                  }
                  leftAdornment={
                    <Radio
                      disabled={disabled}
                      checked={recurrenceMode === false}
                      onChange={() => {
                        segment({
                          track: `people_strategic_hr_engagement_company_communication_sendinfos_singlesend_clicked`,
                        });

                        formik.setValues({
                          ...formik.values,
                          recurrenceMode: false,

                          startDate,
                          recurrence: { ...recurrence, recurrenceMode: false },
                        });
                      }}
                    />
                  }
                />
                <Divider spacing="xs1" type="horizontal" />
                <StyledBoxAdornment
                  style={{ width: "100%" }}
                  error={
                    formik.touched.recurrenceMode &&
                    Boolean(formik.errors.recurrenceMode)
                  }
                  description={
                    <>
                      <Typography
                        variant="body3"
                        color="var(--color-neutral-30)"
                        fontWeight={600}
                      >
                        Envio recorrente
                      </Typography>
                    </>
                  }
                  leftAdornment={
                    <Radio
                      disabled={disabled}
                      checked={recurrenceMode}
                      onChange={() => {
                        segment({
                          track: `people_strategic_hr_engagement_company_communication_sendinfos_recurringsend_clicked`,
                        });

                        formik.setValues({
                          ...formik.values,
                          recurrenceMode: true,

                          startDate,
                          recurrence: {
                            ...recurrence,
                            recurrenceMode: true,
                            repeat: recurrence?.repeat || 1,
                          },
                        });
                      }}
                    />
                  }
                />
              </Row>
              {recurrenceMode !== undefined ? (
                <>
                  <Divider spacing="xs1" />
                  <LineSeparator />
                </>
              ) : null}
              {recurrenceMode ? (
                <>
                  <Divider spacing="xs1" />
                  <Row>
                    <Typography
                      variant="body3"
                      color="var(--color-neutral-30)"
                      fontWeight={600}
                      minWidth="105px"
                    >
                      Repetir a cada
                    </Typography>
                    <Divider spacing="xxxs" type="horizontal" />
                    <CounterField
                      disabled={disabled}
                      value={recurrence?.repeat || 1}
                      min={1}
                      onChange={(e) => {
                        formik.setValues({
                          ...formik.values,
                          recurrenceMode,

                          startDate,
                          recurrence: {
                            ...recurrence,
                            repeat: e,
                          },
                        });
                      }}
                    />
                    <Divider spacing="xs1" type="horizontal" />
                    <SelectField
                      disabled={disabled}
                      className="step-dispatch-field"
                      label={"Frequência"}
                      fullWidth
                      options={[
                        {
                          label: "Dia",
                          value: "day",
                        },
                        {
                          label: "Semana",
                          value: "week",
                        },
                        {
                          label: "Mês",
                          value: "month",
                        },
                        {
                          label: "Ano",
                          value: "year",
                        },
                      ]}
                      onSelectChange={(_: any, option) => {
                        segment({
                          track: `company_engagement_surveysend_recurringsend_frequency_${
                            option?.value === "day"
                              ? "daily"
                              : `${option?.value}ly`
                          }_clicked`,
                        });

                        formik.setValues({
                          ...formik.values,
                          recurrenceMode,

                          startDate,
                          recurrence: {
                            ...recurrence,
                            frequency: option?.value,
                          },
                        });
                      }}
                      error={
                        formik?.touched?.recurrence?.frequency &&
                        Boolean(formik?.errors?.recurrence?.frequency)
                      }
                      helperText={
                        formik?.touched?.recurrence?.frequency &&
                        (formik?.errors?.recurrence?.frequency as string)
                      }
                      value={recurrence?.frequency}
                    />
                  </Row>
                </>
              ) : null}
              {recurrenceMode && recurrence?.frequency === "week" ? (
                <>
                  <Divider spacing="xs1" />
                  <Row>
                    <Typography
                      variant="headline9"
                      fontWeight={700}
                      color="var(--color-neutral-30)"
                    >
                      Selecione os dias da semana
                    </Typography>
                  </Row>
                  <Divider spacing="xs1" />
                  <Row>
                    <DaysPicker
                      disabled={disabled}
                      days={daysOfTheWeek}
                      onChange={(days, value) => {
                        segment({
                          track: `company_engagement_surveysend_recurringsend_weekly_${value?.value}_selected`,
                        });

                        formik.setValues({
                          ...formik.values,
                          recurrenceMode,

                          startDate,
                          recurrence: {
                            ...recurrence,
                            daysOfTheWeek:
                              days
                                ?.filter(({ checked }) => checked)
                                ?.map(({ value }) => value) || [],
                          },
                        });
                      }}
                    />
                  </Row>
                </>
              ) : null}
              <Divider spacing="xs1" />
              {recurrenceMode !== undefined ? (
                <>
                  {recurrenceMode ? (
                    <Row>
                      <Typography
                        variant="headline9"
                        fontWeight={700}
                        color="var(--color-neutral-30)"
                      >
                        Início da recorrência
                      </Typography>
                    </Row>
                  ) : (
                    <>
                      <Row>
                        <Typography
                          variant="headline9"
                          fontWeight={700}
                          color="var(--color-neutral-30)"
                        >
                          Envio e prazo de respostas
                        </Typography>
                      </Row>
                      <Row>
                        <Typography
                          variant="body4"
                          fontWeight={400}
                          color="var(--color-neutral-50)"
                        >
                          A comunicação estará disponivel pra respostas durante
                          o período abaixo.
                        </Typography>
                      </Row>
                    </>
                  )}
                </>
              ) : null}
              {recurrenceMode !== undefined ? (
                <>
                  <Divider spacing="xs1" />
                  <Row>
                    <DatePicker
                      key={"step-dispatch-start-date-field-1"}
                      disabled={disabled}
                      className="step-dispatch-field"
                      fromDate={dayjs(new Date())}
                      label="Data de envio"
                      onDateChange={(value: Dayjs | undefined) => {
                        const date = dayjs(value)?.format("YYYY-MM-DD");

                        if (date && date !== "Invalid Date")
                          formik.setValues({
                            ...formik.values,
                            recurrenceMode,

                            recurrence,
                            startDate: {
                              ...startDate,
                              date: dayjs(value)?.format("YYYY-MM-DD"),
                            },
                          });
                      }}
                      error={
                        formik?.touched?.startDate?.date &&
                        Boolean(formik?.errors?.startDate?.date)
                      }
                      helperText={
                        formik?.touched?.startDate?.date &&
                        (formik?.errors?.startDate?.date as string)
                      }
                      value={startDate?.date}
                    />
                    <Divider spacing="xs1" type="horizontal" />
                    <SelectField
                      disabled={disabled}
                      className="step-dispatch-field"
                      label={"Período"}
                      fullWidth
                      options={periodFieldOptions}
                      error={
                        formik?.touched?.startDate?.period &&
                        Boolean(formik?.errors?.startDate?.period)
                      }
                      helperText={
                        formik?.touched?.startDate?.period &&
                        (formik?.errors?.startDate?.period as string)
                      }
                      onSelectChange={(_: any, option) => {
                        const p = option?.value;

                        let sPeriod = "immediately";
                        if (option?.label !== "Imediatamente") {
                          sPeriod = p;
                          if (p === "night") sPeriod = "evening";
                        }

                        segment({
                          track: `company_engagement_surveysend_${
                            recurrenceMode ? "recurring" : "single"
                          }send_sendtime_${sPeriod}_clicked`,
                        });

                        formik.setValues({
                          ...formik.values,
                          recurrenceMode,

                          recurrence,
                          startDate: {
                            ...startDate,
                            period: p,
                          },
                        });
                      }}
                      value={startDate?.period}
                    />
                  </Row>
                </>
              ) : null}

              {recurrenceMode && recurrence?.frequency && startDate?.date ? (
                <>
                  <Divider spacing="xs1" />
                  <Row>
                    <Typography
                      variant="headline9"
                      fontWeight={700}
                      color="var(--color-neutral-30)"
                    >
                      Fim da recorrência
                    </Typography>
                  </Row>
                  <Divider spacing="xs1" />
                  <Row>
                    <StyledBoxAdornment
                      style={{ width: "100%" }}
                      error={
                        formik?.touched?.recurrence?.endType &&
                        Boolean(formik?.errors?.recurrence?.endType)
                      }
                      description={
                        <>
                          <Typography
                            variant="body3"
                            color="var(--color-neutral-30)"
                            fontWeight={600}
                          >
                            Nunca termina
                          </Typography>
                        </>
                      }
                      leftAdornment={
                        <Radio
                          checked={endType === "never"}
                          onChange={() => {
                            segment({
                              track: `company_engagement_surveysend_recurringsend_${
                                recurrence?.frequency === "day"
                                  ? "daily"
                                  : `${recurrence?.frequency}ly`
                              }recurringends_never_selected`,
                            });

                            formik.setValues({
                              ...formik.values,
                              recurrenceMode,
                              startDate,
                              recurrence: {
                                ...recurrence,
                                endType: "never",
                                endDate: undefined,
                                occurrenceLimit: -1,
                              },
                            });

                            setEndType("never");
                          }}
                        />
                      }
                    />
                  </Row>
                  <Divider spacing="xs1" />
                  <Row>
                    <StyledBoxAdornment
                      style={{ width: "100%" }}
                      error={
                        formik?.touched?.recurrence?.endType &&
                        Boolean(formik?.errors?.recurrence?.endType)
                      }
                      description={
                        <Row>
                          <Typography
                            variant="body3"
                            color="var(--color-neutral-30)"
                            fontWeight={600}
                            minWidth="86px"
                          >
                            Termina em
                          </Typography>
                          <Divider spacing="xxxs" type="horizontal" />
                          <DatePicker
                            disabled={endType !== "date"}
                            fromDate={dayjs(startDate?.date).add(1, "day")}
                            label="Data"
                            onDateChange={(value: Dayjs | undefined) => {
                              const date = dayjs(value)?.format("YYYY-MM-DD");

                              if (date && date !== "Invalid Date") {
                                if (
                                  recurrence?.endDate &&
                                  recurrence?.endDate === date
                                )
                                  return;

                                formik.setValues({
                                  ...formik.values,
                                  recurrenceMode,

                                  startDate,
                                  recurrence: {
                                    ...recurrence,
                                    endType: "date",
                                    occurrenceLimit: undefined,
                                    endDate: date,
                                  },
                                });
                              }
                            }}
                            error={
                              formik?.touched?.recurrence?.endDate &&
                              Boolean(formik?.errors?.recurrence?.endDate)
                            }
                            helperText={
                              formik?.touched?.recurrence?.endDate &&
                              (formik?.errors?.recurrence?.endDate as string)
                            }
                            value={recurrence?.endDate}
                          />
                        </Row>
                      }
                      leftAdornment={
                        <Radio
                          checked={endType === "date"}
                          onChange={() => {
                            segment({
                              track: `company_engagement_surveysend_recurringsend_${
                                recurrence?.frequency === "day"
                                  ? "daily"
                                  : `${recurrence?.frequency}ly`
                              }recurringends_specificdate_selected`,
                            });

                            formik.setValues({
                              ...formik.values,
                              recurrenceMode,

                              startDate,
                              recurrence: {
                                ...recurrence,
                                endType: "date",
                                occurrenceLimit: undefined,
                                endDate: undefined,
                              },
                            });

                            setEndType("date");
                          }}
                        />
                      }
                    />
                  </Row>
                  <Divider spacing="xs1" />
                  <Row>
                    <StyledBoxAdornment
                      style={{ width: "100%" }}
                      error={
                        formik?.touched?.recurrence?.endType &&
                        Boolean(formik?.errors?.recurrence?.endType)
                      }
                      description={
                        <Row>
                          <Typography
                            variant="body3"
                            color="var(--color-neutral-30)"
                            fontWeight={600}
                          >
                            Termina após
                          </Typography>
                          <Divider spacing="xxxs" type="horizontal" />
                          <CounterField
                            disabled={endType !== "occurrence"}
                            value={
                              recurrence?.occurrenceLimit &&
                              recurrence?.occurrenceLimit > 0
                                ? recurrence?.occurrenceLimit
                                : 1
                            }
                            min={1}
                            onChange={(e) => {
                              formik.setValues({
                                ...formik.values,
                                recurrenceMode,

                                startDate,
                                recurrence: {
                                  ...recurrence,
                                  endDate: undefined,
                                  occurrenceLimit: e,
                                },
                              });
                            }}
                          />
                          <Divider spacing="xxxs" type="horizontal" />
                          <Typography
                            variant="body3"
                            color="var(--color-neutral-30)"
                            fontWeight={600}
                          >
                            ocorrências
                          </Typography>
                        </Row>
                      }
                      leftAdornment={
                        <Radio
                          checked={endType === "occurrence"}
                          onChange={() => {
                            segment({
                              track: `company_engagement_surveysend_recurringsend_${
                                recurrence?.frequency === "day"
                                  ? "daily"
                                  : `${recurrence?.frequency}ly`
                              }recurringends_endAfter_selected`,
                            });

                            formik.setValues({
                              ...formik.values,
                              recurrenceMode,

                              startDate,
                              recurrence: {
                                ...recurrence,
                                endType: "occurrence",
                                endDate: undefined,
                                occurrenceLimit: 1,
                              },
                            });

                            setEndType("occurrence");
                          }}
                        />
                      }
                    />
                  </Row>
                </>
              ) : null}
            </Box>
          </StyleContainer>
        </>
      </Grid>
    </Grid>
  );
};

export { StepDispatch };
