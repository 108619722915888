import styled from "styled-components";

const Container = styled.div`
  cursor: pointer;
  width: 92px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  border-radius: 40px;
  padding: 12px;
  gap: 2px;
  border: 1px solid var(--color-neutral-80);
`;

export { Container };
