import { trpc } from "@api/client";
import {
  GenericProfilePicture,
  LinkButton,
  ShapeIcon,
  Skeleton,
  Typography,
  dayjs,
} from "@flash-tecnologia/hros-web-ui-v2";
import { useSelectedCompany } from "@flash-tecnologia/hros-web-utility";
import { dispatchToast, sanitizeHtml } from "@utils";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTheme } from "styled-components";
import FlashLogo from "../../assets/flash-logo.webp";
import { Modal } from "./Modal";
import { ReactForm } from "./ReactForm";
import {
  ButtonsWrapper,
  Card,
  Container,
  Header,
  HeaderContainer,
  ImageContainer,
  Logo,
  Signature,
  TextWithHtml,
  TitleContainer,
} from "./styled";

export default () => {
  const theme = useTheme() as any;
  const { id = "" } = useParams();
  const { selectedCompany } = useSelectedCompany();
  const company = selectedCompany;

  const [openPdfModal, setOpenPdfModal] = useState<boolean>(false);

  const { data, isFetching: loading } = trpc.getCommunicationById.useQuery(
    {
      id,
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
      onError: (e: any) => {
        dispatchToast({
          type: "error",
          content:
            "Sentimos muito, ocorreu um erro ao carregar os dados da comunicação. Por favor, tente novamente mais tarde.",
        });
      },
    }
  );

  const communication = data?.value?.communication;
  const count = data?.value?.count;
  const reaction = data?.value?.reaction;
  const pdfFile = data?.value?.communication?.pdfFile;

  const reactionsCount = [
    count?.liked || 0,
    count?.loved || 0,
    count?.support || 0,
    count?.congratulations || 0,
  ];

  const total = reactionsCount.reduce((acc, curr) => acc + curr, 0);

  const { isLoading: isSettingMonitoring, mutate: setMonitoringMutate } =
    trpc?.setMonitoring?.useMutation({
      onError: (e: any) => {
        const msgError = "Ocorreu um erro, tente novamente mais tarde.";

        alert(msgError);
        console.log("err: ", e);
      },
    });

  useEffect(() => {
    setMonitoringMutate({
      engagementId: id,
      type: "visualized",
    });
  }, []);

  return (
    <>
      <Container>
        <Header>
          <GenericProfilePicture name={company?.name} size={40} />
          <Typography variant="body4" weight={700}>
            {company?.name}
          </Typography>
        </Header>
        {loading ? (
          <Skeleton width={648} height={556} />
        ) : (
          <Card>
            <HeaderContainer>
              <ShapeIcon
                name={"IconSpeakerphone"}
                variant="default"
                size={48}
                color="var(--color-secondary-50)"
              />
              <TitleContainer>
                <Typography
                  variant="headline8"
                  color="var(--color-neutral-20)"
                  weight={700}
                >
                  {communication?.title || ""}
                </Typography>
                {communication?.sendDate ? (
                  <Typography
                    variant="caption"
                    color="var(--color-neutral-40)"
                    weight={600}
                  >
                    {`Enviado em ${dayjs(communication?.sendDate)?.format(
                      "DD/MM/YYYY"
                    )} `}
                  </Typography>
                ) : null}
              </TitleContainer>
            </HeaderContainer>

            <TextWithHtml
              dangerouslySetInnerHTML={{
                __html: sanitizeHtml(communication?.message ?? ""),
              }}
            />

            <ButtonsWrapper>
              {communication?.link?.url ? (
                <LinkButton
                  variant="default"
                  onClick={() => {
                    setMonitoringMutate({
                      engagementId: id,
                      type: "open",
                    });
                    window.open(`${communication?.link?.url}`, "_blank");
                  }}
                >
                  <Typography
                    variant="caption"
                    variantColor={theme.colors.primary}
                    style={{ fontWeight: 700 }}
                  >
                    {"Acesse agora"}
                  </Typography>
                </LinkButton>
              ) : null}
              {pdfFile ? (
                <LinkButton
                  variant="default"
                  onClick={() => {
                    setOpenPdfModal(true);
                  }}
                >
                  <Typography
                    variant="caption"
                    variantColor={theme.colors.primary}
                    style={{ fontWeight: 700 }}
                  >
                    Visualizar PDF
                  </Typography>
                </LinkButton>
              ) : null}
            </ButtonsWrapper>

            {communication?.imageUrl ? (
              <>
                {communication?.imageUrl ? (
                  <ImageContainer uri={communication?.imageUrl} />
                ) : null}
              </>
            ) : null}
            <ReactForm
              totalLikes={total}
              reactionsCount={reactionsCount}
              engagementId={id}
              reaction={reaction}
            />
          </Card>
        )}
        <Signature>
          <Typography variant="body4">Comunicação criada com</Typography>
          <Logo src={FlashLogo} />
        </Signature>
      </Container>
      <Modal
        open={openPdfModal}
        onClose={() => setOpenPdfModal(false)}
        pdfUrl={communication?.pdfFile ?? ""}
      />
    </>
  );
};
