import { useState } from "react";
import {
  TagDateFilter,
  TagFilter,
  dayjs,
  LinkButton,
} from "@flash-tecnologia/hros-web-ui-v2";
import { DateRange } from "react-day-picker";
import { TableFilterInterface } from "./types";
import { Container, LinkButtonContainer } from "./styles";
import { Space } from "../Space";

const TableFilter = ({ filters, onClick, onClear }: TableFilterInterface) => {
  const [firstOnClick, setFirstOnClick] = useState(false);

  const onFilter = (id: string, values: Array<string> | DateRange) => {
    const currentFilters = filters?.map((v) => ({
      ...v,
      options: v?.options?.map((o) => ({
        ...o,
        checked: o?.checked ? o?.checked : false,
      })),
    }));

    const updatedFilters = currentFilters?.map((filter) => {
      if (id !== filter?.id) return filter;

      if (!Array.isArray(values)) {
        filter["dateRange"] = values;
        return filter;
      }

      filter["options"] = filter?.options?.map((option) => {
        if (values?.includes(option?.value) !== option?.checked)
          return { ...option, checked: !!!option?.checked };
        return option;
      });

      return filter;
    });

    if (!firstOnClick) setFirstOnClick(true);
    onClick(updatedFilters);
  };

  return (
    <Container>
      {filters?.map(
        (
          { id, label, valueInLabel, type, options, disabled, dateRange },
          i
        ) => {
          if (type === "date")
            return (
              <TagDateFilter
                key={i}
                hasLeftIcon={false}
                variant="secondary"
                filterLabel={
                  valueInLabel && dateRange?.from && dateRange?.to
                    ? `${dayjs(dateRange?.from)?.format(
                        "DD/MM/YYYY"
                      )} - ${dayjs(dateRange?.to)?.format("DD/MM/YYYY")}`
                    : label
                }
                onSubmit={(e) => onFilter(id, e)}
                disabled={disabled}
                initialDateRange={dateRange}
              />
            );

          return (
            <TagFilter
              key={i}
              variant="secondary"
              optionIconType={type === "boolean" ? "none" : "checkbox"}
              hasLeftIcon={false}
              disableOptionsSearch
              filterLabel={
                type === "boolean"
                  ? valueInLabel
                    ? filters
                        ?.find(({ id: filterId }) => filterId === id)
                        ?.options?.filter(({ checked }) => checked)
                        ?.map(({ label }) => label)[0] || label
                    : label
                  : valueInLabel &&
                    filters
                      ?.find(({ id: filterId }) => filterId === id)
                      ?.options?.filter(({ checked }) => checked)?.length === 1
                  ? filters
                      ?.find(({ id: filterId }) => filterId === id)
                      ?.options?.filter(({ checked }) => checked)
                      ?.map(({ label }) => label)[0] || label
                  : label
              }
              selectedOptions={
                filters
                  ?.find(({ id: filterId }) => filterId === id)
                  ?.options?.filter(({ checked }) => checked)
                  ?.map(({ value }) => value) || []
              }
              options={options}
              onClick={(e) => {
                if (type === "multi-select") {
                  const currentValues = filters
                    ?.find(({ id: filterId }) => filterId === id)
                    ?.options?.filter(({ checked }) => checked)
                    ?.map(({ value }) => value);

                  const updatedFilters = currentValues?.includes(e)
                    ? currentValues?.filter((v) => v !== e)
                    : [...(currentValues || []), e];

                  onFilter(id, updatedFilters);
                  return;
                }

                const updatedFilters =
                  filters
                    ?.find(({ id: filterId }) => filterId === id)
                    ?.options?.find(({ checked }) => checked)?.value === e
                    ? []
                    : [e];

                onFilter(id, updatedFilters);
              }}
              onClear={() => onFilter(id, [])}
              disabled={disabled}
            />
          );
        }
      )}
      {onClear ? (
        <LinkButtonContainer>
          <Space size="xs" />
          <LinkButton
            variant="neutral"
            onClick={() =>
              onClear(
                filters?.map((filter) => ({
                  ...filter,
                  dateRange: undefined,
                  options: filter?.options?.map((option) => ({
                    ...option,
                    checked: false,
                  })),
                }))
              )
            }
          >
            Limpar filtros
          </LinkButton>
        </LinkButtonContainer>
      ) : null}
    </Container>
  );
};

export { TableFilter };
