import {
  Tooltip as TooltipM,
  tooltipClasses,
  TooltipProps,
} from "@mui/material";
import styled from "styled-components";
import { Typography } from "@flash-tecnologia/hros-web-ui-v2";

const CardMainContainer = styled.div`
  position: relative;
  border: 1px solid var(--color-neutral-light2);
  border-radius: 12px;
  background-color: var(--color-neutral-100);
  padding: 20px;
  display: inline-flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  min-width: 300px;

  h3 {
    color: var(--color-neutral-dark3);
  }

  h5 {
    color: var(--color-neutral-dark5);
  }

  p {
    color: var(--color-neutral-dark5);
  }
`;

const IconContainer = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  width: 60px;
  border-radius: 100%;
  background-color: var(--color-secondary-light2);
`;

const NumberContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
`;

const InfoContainer = styled.div`
  display: inline-flex;
  align-items: center;
  position: absolute;
  padding-top: 15px;
  padding-right: 15px;
  top: 0;
  right: 0;
`;

const NumberDivisor = styled.div`
  display: inline;
  background-color: var(--color-neutral-dark5);
  height: 5px;
  width: 5px;
  border-radius: 100%;
  margin: 0 10px;
`;

const CardsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow-y: auto;

  > div {
    width: 100%;
    margin: 0 10px;
  }

  > div:first-child {
    margin-left: 0;
  }

  > div:last-child {
    margin-right: 0;
  }

  @media screen and (max-width: 700px) {
    flex-direction: column;
    > div {
      margin: 0 0 20px 0;
    }
  }
`;

const Tooltip = styled(({ className, ...props }: TooltipProps) => (
  <TooltipM {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "var(--color-neutral-dark1)",
    padding: "15px",
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "var(--color-neutral-dark1)",
  },
}));

const TooltipText = styled(Typography)<{ strong?: boolean }>`
  color: var(--color-neutral-100);
  font-weight: ${({ strong }) => (strong ? 700 : 400)} !important;
  margin: 5px 0;
`;

const SkeletonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  * {
    width: 24%;
    height: 200px;
  }

  @media screen and (max-width: 700px) {
    flex-direction: column;

    * {
      width: 100%;
      margin-bottom: 15px;
    }
  }
`;

export {
  CardMainContainer,
  IconContainer,
  NumberContainer,
  InfoContainer,
  NumberDivisor,
  CardsContainer,
  TooltipText,
  Tooltip,
  SkeletonContainer,
};
