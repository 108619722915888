import { Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

const MainContainer = styled.div<{ avatarSize?: string }>`
  display: inline-flex;
  align-items: center;
  justify-content: center;

  > :first-child {
    height: ${({ avatarSize }) => (avatarSize ? avatarSize : "40px")};
    width: ${({ avatarSize }) => (avatarSize ? avatarSize : "40px")};
    background-color: var(--color-primary);
  }
`;

const Container = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  margin-left: 10px;
`;

const Name = styled(Typography)`
  color: var(--color-neutral-dark3);
`;

const Email = styled(Typography)`
  color: var(--color-neutral-dark4);
`;

const EmptyStateMessageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const EmptyStateMessage = styled(Typography)`
  color: #c96c01 !important;
  font-weight: 500 !important;
`;

export {
  MainContainer,
  Container,
  Name,
  Email,
  EmptyStateMessageContainer,
  EmptyStateMessage,
};
