import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
`;

const FieldRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;

  .scale-label {
    width: 100% !important;
  }
`;

const SelectContainer = styled.div`
  > div {
    min-width: 150px !important;
  }
`;

export { Container, FieldRow, SelectContainer };
