import styled from "styled-components";

const IconContainer = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 100%;
  border: 1px solid var(--color-neutral-light2);
  color: var(--color-neutral-dark4);
  cursor: pointer;

  &:hover {
    border-color: var(--color-neutral-light3);
  }
`;

export { IconContainer };
