import styled from "styled-components";

export const Button = styled.button<{
  status: "active" | "neutral" | "disabled";
}>`
  border-radius: 100px;
  border-style: solid;
  border-width: 1px;

  align-items: center;
  display: flex;
  gap: 8px;
  flex-direction: row;
  padding: 8px 16px;
  text-align: center;
  white-space: nowrap;

  ${(p) => {
    switch (p.status) {
      case "active":
        return {
          "border-color": p.theme.colors.secondary["95"],
          "background-color": p.theme.colors.secondary["95"],
          color: p.theme.colors.secondary["50"],
        };
      case "neutral":
        return {
          "border-color": p.theme.colors.neutral["80"],
          "background-color": "transparent",
          color: p.theme.colors.neutral["40"],
        };
      case "disabled":
        return {
          "border-color": p.theme.colors.neutral["90"],
          "background-color": p.theme.colors.neutral["90"],
          color: p.theme.colors.neutral["80"],
          cursor: "default",
        };
    }
  }}
  &:hover {
    background-color: ${(p) => p.theme.colors.neutral["90"]};
    color: ${(p) =>
      p.status === "disabled"
        ? p.theme.colors.neutral["80"]
        : p.theme.colors.neutral["40"]};
    border-color: ${(p) => p.theme.colors.neutral["90"]};
  }
`;
