import { useEffect, useState } from "react";
import { Button, Modal, ShapeIcon } from "@flash-tecnologia/hros-web-ui-v2";
import { trpc } from "@api/client";
import { Typography } from "..";
import { Content, Footer, Img, TextContainer } from "./styles";
import { FeatureNotificationInterface } from "./types";
import data from "./data";

const { Root: ModalRoot } = Modal;

const FeatureNotification = ({
  id: featureId,
}: FeatureNotificationInterface) => {
  const feature = data?.find(({ id }) => id === featureId);

  const { mutate: registerFeatureViewLog } =
    trpc?.registerFeatureViewLog?.useMutation({
      onSuccess: () => setIsLive(true),
      onError: () => setIsLive(false),
    });

  const { refetch: getFeatureViewLog } = trpc?.getFeatureViewLog?.useQuery(
    { featureName: featureId },
    {
      retry: false,
      enabled: false,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        if (data?.value?.length) return;
        registerFeatureViewLog({
          featureName: featureId,
        });
      },
      onError: (e) => console.log("err: ", e),
    }
  );

  useEffect(() => {
    getFeatureViewLog();
  }, []);

  const onClose = () => setIsLive(false);

  const [isLive, setIsLive] = useState(false);

  return (
    <ModalRoot
      open={isLive}
      onClose={onClose}
      children={
        <Content>
          <ShapeIcon
            variant={"default"}
            name={"IconSparkles"}
            size={64}
            color="var(--color-secondary-50)"
          />
          <TextContainer>
            <Typography
              variant="body3"
              fontWeight={700}
              color="var(--color-secondary-50)"
            >
              Novidade
            </Typography>
            <Typography
              variant="headline6"
              fontWeight={700}
              color="var(--color-neutral-30)"
            >
              {feature?.title}
            </Typography>
            <Typography
              variant="body3"
              fontWeight={400}
              color="var(--color-neutral-50)"
            >
              {feature?.description}
            </Typography>
          </TextContainer>
          <Img src={feature?.gifPath} alt="nova funcionalidade" />
        </Content>
      }
      footer={
        <Footer>
          <div>
            <Button variant="primary" size={"large"} onClick={onClose}>
              Fechar
            </Button>
          </div>
        </Footer>
      }
    />
  );
};

export { FeatureNotification };
