import { T } from "./styles";
import { TypographyInterface } from "./types";

const Typography = ({
  fontWeight,
  color,
  minWidth,
  children,
  ...rest
}: TypographyInterface) => (
  <T {...rest} color={color} fontWeight={fontWeight} minWidth={minWidth}>
    {children}
  </T>
);

export { Typography };
