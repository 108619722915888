import { ShapeIcon } from "@flash-tecnologia/hros-web-ui-v2";
import { EditStepButtonInterface } from "./types";

const EditStepButton = ({ onClick }: EditStepButtonInterface) => (
  <ShapeIcon
    onClick={() => onClick()}
    name={"IconPencil"}
    variant="transparent"
    style={{
      position: "absolute",
      top: 20,
      right: 20,
      border: "1px solid var(--color-neutral-80)",
      fontWeight: 700,
      cursor: "pointer",
    }}
    size={48}
    color="var(--color-neutral-40)"
  />
);

export { EditStepButton };
