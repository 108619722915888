import styled from "styled-components";

const getSize = (size?: string) => {
  if (size === "medium") return "2px";
  if (size === "large") return "5px";
  if (size === "tiny") return "1px";
  return "1px";
};

const LineSeparator = styled.div<{
  size?: "tiny" | "small" | "medium" | "large";
}>`
  height: ${({ size }) => (size ? getSize(size) : getSize("tiny"))};
  width: 100%;
  background-color: var(--color-neutral-light3);
`;

export { LineSeparator };
