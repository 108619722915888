import { useNavigate } from "react-router-dom";
import { dayjs, Button } from "@flash-tecnologia/hros-web-ui-v2";
import { trpc } from "@api/client";
import { alert, segment } from "../../../../utils";
import { DataGrid } from "../../../../components";
import { OccurrenceDataGridInterface } from "./types";
import { ButtonContainer } from "./styles";

const OccurrenceDataGrid = ({
  data,
  engagementId,
}: OccurrenceDataGridInterface) => {
  const navigate = useNavigate();

  const { refetch: getEngagementReportByEngagementId } =
    trpc?.getEngagementReportByEngagementId?.useQuery(
      {
        screen: "survey-recurrence-monitoring",
        type: "survey",
        id: engagementId,
      },
      {
        enabled: false,
        retry: false,
        refetchOnWindowFocus: false,
        onError: (error: any) => {
          alert("Ocorreu um erro, tente novamente mais tarde.");
          console.log(error);
        },
      }
    );

  return (
    <DataGrid
      report={{
        type: "survey",
        onExport: () => {
          segment({
            track:
              "company_engagement_recurringsurveydetail_exportsendsreport_clicked",
          });
          getEngagementReportByEngagementId();
        },
        texts: {
          title: <>Deseja exportar o relatório?</>,
          message: (
            <>
              O relatório apresentará somente os{" "}
              <strong>dados dessa tabela</strong>
              <br />
              convertidos em um arquivo CSV.
            </>
          ),
          toolTip: "Exportar relatório",
          endButton: "Voltar para detalhe da pesquisa",
        },
      }}
      columns={[
        {
          Header: "Data de envio",
          accessor: "sendDate",
          disableSortBy: true,
          Cell: ({ row }: any) => {
            const sendDate = row?.original?.sendDate
              ? dayjs(row?.original?.sendDate)?.format("DD/MM/YYYY - HH:mm")
              : "-";

            return <>{sendDate}</>;
          },
        },
        {
          Header: "Pessoas",
          accessor: "employees",
          disableSortBy: true,
          Cell: ({ row }: any) => {
            const totalEmployees = row?.original?.employees?.length || 0;
            return <>{totalEmployees || ""}</>;
          },
        },
        {
          sticky: "right",
          Header: "",
          accessor: "_id",
          disableSortBy: true,
          Cell: ({ value }: any) => {
            return (
              <ButtonContainer>
                <Button
                  variant="secondary"
                  size="medium"
                  onClick={() => navigate(`/engagement/survey/${value}`)}
                >
                  Ver envio
                </Button>
              </ButtonContainer>
            );
          },
        },
      ]}
      data={data}
    />
  );
};

export { OccurrenceDataGrid };
