import { Avatar as AvatarM } from "@mui/material";
import { Icons } from "@flash-tecnologia/hros-web-ui-v2";
import {
  MainContainer,
  Container,
  Name,
  Email,
  EmptyStateMessage,
  EmptyStateMessageContainer,
} from "./styles";
import { Space } from "../";
import { AvatarInterface } from "./types";

const Avatar = ({
  name,
  email,
  image,
  variants,
  avatarSize,
  showEmptyStateMessage,
}: AvatarInterface) => {
  const getImage = () => {
    if (image) return { src: image };
    return {};
  };

  return (
    <MainContainer avatarSize={avatarSize}>
      <AvatarM alt={name} {...getImage()} />
      <Container>
        <Name variant={variants?.name || "body4"}>{name}</Name>
        {email ? (
          <Email variant={variants?.email || "body4"}>{email}</Email>
        ) : showEmptyStateMessage ? (
          <EmptyStateMessageContainer>
            <Icons
              name={"IconAlertTriangle"}
              color="#C96C01"
              fill="transparent"
              size={14}
            />
            <Space size="xs" />
            <EmptyStateMessage variant="body4">
              {"E-mail corp. não cadastrado"}
            </EmptyStateMessage>
          </EmptyStateMessageContainer>
        ) : null}
      </Container>
    </MainContainer>
  );
};

export { Avatar };
