type EType = "error" | "success" | "warning";

interface dispatchToastParams {
  content?: string | any;
  title?: string;
  description?: string;
  variant?: string;
  type?: EType;
}

export default function dispatchToast(detail: dispatchToastParams) {
  dispatchEvent(new CustomEvent("showToast", { detail }));
}
