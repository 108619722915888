import { trpc } from "@api/client";
import { createContext } from "react";
import { Result } from "server/src/services/contract/checkContractModule/types";

export type Contract = Result;

export const ContractContext = createContext<Contract | null>(null);

export function ContractProvider({ children }) {
  const [{ data }] = trpc.useQueries((t) => [
    t?.checkContractModule(undefined, {
      retry: false,
      refetchOnWindowFocus: false,
    }),
  ]);

  return (
    <ContractContext.Provider value={data ?? null}>
      {children}
    </ContractContext.Provider>
  );
}
