import { getAccessToken } from "@flash-tecnologia/hros-web-utility";
import axios from "axios";
import dispatchToast from "./dispatchToast";

export const uploadFile = async ({
  file,
  fileName,
  folder,
}): Promise<{
  path: string;
  origin: string;
  signedUrl: string;
} | null> => {
  try {
    if (!file) return null;
    const token = await getAccessToken();

    const form = new FormData();
    form.append("content", file);
    form.append("fileName", fileName);
    form.append("folder", folder);

    const res = (await axios.put(
      `${process.env.GATEWAY_BASE_URL}/storage/upload`,
      form,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "x-flash-auth": `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      }
    )) as any;

    return res?.data?.value || {};
  } catch (error) {
    dispatchToast({
      content: "Sentimos muito, ocorreu um erro no upload.",
      type: "error",
    });
    throw Error("Upload error");
  }
};
