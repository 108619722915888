const pageSizeOptions = [
  {
    label: "5 itens",
    value: 5,
  },
  {
    label: "10 itens",
    value: 10,
  },
  {
    label: "25 itens",
    value: 25,
  },
];

export { pageSizeOptions };
