import {
  TextField,
  TextArea,
  Icons,
  LinkButton,
} from "@flash-tecnologia/hros-web-ui-v2";
import { Typography } from "../../../..";
import { Divider } from "../../../../v2";
import {
  StepDescription,
  StepContainer,
  Box as BoxComponent,
} from "../../../common/components";
import { StepInfoInterface } from "./types";
import {
  ErrorAsterisk,
  InfoWrapper,
  SectionContainer,
  SectionSubContainer,
  StyledCheckbox,
  TermsConditionsContainer,
  TextAreaContainer,
} from "./styles";
import { Box } from "@mui/material";
import { useTheme } from "styled-components";
import { trpc } from "@api/client";
import { useUser } from "src/hooks/useUser";

const StepInfo = ({
  disabled,
  hide,
  model,
  name,
  title,
  description,
  termsAndConditions,
  onChange,
}: StepInfoInterface) => {
  const user = useUser();
  const fields = { name, title, description, termsAndConditions };
  const theme = useTheme() as any;
  if (hide) return <></>;

  const { data: pdfURL, isFetching } = trpc.getS3File.useQuery(
    {
      path: "people/engagement/termo_anuencia_engaja_2024.pdf",
    },
    {
      retry: 1,
      refetchOnWindowFocus: false,
    }
  );

  const textAreaProp: any = { disabled: disabled || false };

  return (
    <StepContainer>
      <StepDescription
        model={model}
        title="Informações básicas"
        description="Adicione as informações básicas da sua<br/> pesquisa, como nome de identificação, nome de exibição e descrição."
      />
      <Box display="flex" flexDirection="column" gap="24px" width={"100%"}>
        <BoxComponent>
          <SectionContainer>
            <SectionSubContainer>
              <Typography
                variant="headline7"
                color="var(--color-neutral-30)"
                fontWeight={700}
              >
                Nome e descrição
              </Typography>
              <Divider spacing="quark" />
              <Typography
                variant="body4"
                color="var(--color-neutral-50)"
                fontWeight={400}
              >
                Dê um nome para identificar a pesquisa, um nome público e
                descreva o objetivo.
              </Typography>
            </SectionSubContainer>
            <div style={{ display: "flex" }}>
              <Typography
                variant="body4"
                color="var(--color-neutral-50)"
                fontWeight={400}
              >
                <ErrorAsterisk>*</ErrorAsterisk> campo obrigatório
              </Typography>
            </div>
          </SectionContainer>
          <Divider spacing="s1" />
          <TextField
            disabled={disabled}
            label="Nome de identificação da pesquisa *"
            helperText="O nome ficará visível apenas para você na listagem da tabela."
            value={name || ""}
            fullWidth
            inputProps={{
              maxLength: 50,
            }}
            onChange={(e) => onChange({ ...fields, name: e?.target?.value })}
          />
          <InfoWrapper>
            <Typography variant="caption" color={theme?.colors?.neutral40}>
              {name?.length ?? 0}/{50}
            </Typography>
          </InfoWrapper>
          <Divider spacing="xs2" />
          <TextField
            disabled={disabled}
            label="Nome de exibição pública *"
            helperText="O nome de exibição pública será exibido para os usuários."
            value={title || ""}
            fullWidth
            inputProps={{
              maxLength: 30,
            }}
            onChange={(e) => onChange({ ...fields, title: e?.target?.value })}
          />
          <InfoWrapper>
            <Typography variant="caption" color={theme?.colors?.neutral40}>
              {title?.length ?? 0}/{30}
            </Typography>
          </InfoWrapper>
          <Divider spacing="xs2" />
          <TextAreaContainer>
            <TextArea
              {...textAreaProp}
              value={description || ""}
              maxLength={300}
              placeholder="Descrição da pesquisa *"
              onChange={(e: any) =>
                onChange({ ...fields, description: e?.target?.value || "" })
              }
            />
          </TextAreaContainer>
        </BoxComponent>
        {model === "engaja" && (
          <TermsConditionsContainer>
            <Typography
              variant="body4"
              variantColor={theme?.colors?.neutral40}
              weight={700}
            >
              A pesquisa apresentará dados de análise personalizados entre as
              empresas participantes. O anonimato dos dados é garantido.
            </Typography>
            <Box
              display="flex"
              flexDirection="row"
              gap="12px"
              alignItems="center"
            >
              <StyledCheckbox
                checked={!!fields?.termsAndConditions}
                onChange={(_, checked) =>
                  onChange({
                    ...fields,
                    termsAndConditions: checked
                      ? {
                          ...termsAndConditions,
                          date: new Date().toISOString(),
                          acceptedBy: (user?._id || user?.id) ?? "",
                          type: "engaja",
                        }
                      : null,
                  })
                }
              />
              <Typography
                variant="headline9"
                variantColor={theme?.colors?.neutral50}
                weight={600}
                style={{ display: "flex", gap: "6px" }}
              >
                Li e concordo com os
                <LinkButton
                  variant="default"
                  onClick={() => window.open(pdfURL, "_blank")}
                  disabled={isFetching}
                >
                  {" "}
                  termos e condições{" "}
                </LinkButton>{" "}
                da pesquisa.
              </Typography>
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              gap="4px"
              alignItems="center"
            >
              <Icons
                name="IconInfoCircle"
                size={16}
                color={theme?.colors?.error50}
              />
              <Typography
                variant="caption"
                variantColor={theme?.colors?.neutral50}
                weight={600}
              >
                Você deve concordar com os termos e condições para continuar.
              </Typography>
            </Box>
          </TermsConditionsContainer>
        )}
      </Box>
    </StepContainer>
  );
};

export { StepInfo };
