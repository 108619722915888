import { TagList } from "@components/TagList";
import { DataCard } from "../DataCard";
import { useUser } from "src/hooks/useUser";
import { useTheme } from "styled-components";

interface SettingsTabProps {
  data: any;
  onNavigate: React.MouseEventHandler<HTMLButtonElement> | undefined;
}

export const SettingsTab = ({ data, onNavigate }: SettingsTabProps) => {
  const theme = useTheme() as any;
  const { like, notifications, owners } = data;

  const user = useUser();

  return (
    <DataCard
      title="Configurações"
      subTitle="Confira as configurações habilitadas."
      editable
      onEditClick={onNavigate}
      sections={[
        {
          items: [
            {
              title: "Reações",
              description: like ? "Habilitado" : "Desabilitado",
            },
            {
              title: "Notificações",
              description:
                notifications?.push || notifications?.email
                  ? `Habilitado - ${
                      notifications?.push ? "Push no aplicativo" : ""
                    } ${
                      notifications?.push && notifications?.email ? " e " : ""
                    } ${notifications?.email ? "E-mail corporativo" : ""}`
                  : undefined,
            },
            {
              title: "Envio da Notificação",
              description:
                notifications?.reminder?.enabled &&
                notifications?.reminder?.minRate
                  ? `Notificar a cada 3 dias até atingir a taxa de visualização de ${notifications?.reminder?.minRate}%`
                  : "Desabilitado",
            },
            {
              title: "Criador",
              tooltip:
                "O criador é um responsável que não pode ser removido da lista de responsáveis e suas permissões não podem ser modificadas.",
              description: user?.name,
            },
            {
              title: "Responsáveis",
              description: !owners?.map((o) => o.id ?? o._id).includes("*") ? (
                <TagList
                  tags={
                    owners?.map((owner) => ({
                      id: owner?.id,
                      label: owner?.name,
                    })) ?? []
                  }
                />
              ) : (
                "Todos com acesso ao módulo"
              ),
            },
          ],
        },
      ]}
    />
  );
};
