import { Icons } from "@flash-tecnologia/hros-web-ui-v2";
import { backgroundColors, fontColors, icons } from "../ReactIcon/reactions";
import { Container, IconContainer, TotalNumberContainer } from "./styles";
import { TotalReactionsProps } from "./types";

const TotalReactions = ({ total, reactionsCount }: TotalReactionsProps) => {
  const iconTypes: any = icons;

  const count = [
    { type: "like", value: reactionsCount[0] || 0 },
    { type: "heart", value: reactionsCount[1] || 0 },
    { type: "support", value: reactionsCount[2] || 0 },
    { type: "congrats", value: reactionsCount[3] || 0 },
  ];

  const sortReactionsByValue = (
    count: Array<{ type: string; value: number }>
  ) => {
    return (
      count?.sort(function (a, b) {
        return b?.value - a?.value;
      }) || []
    );
  };

  return (
    <Container>
      {sortReactionsByValue(count)
        ?.filter(({ value }) => value)
        ?.map(({ type }, i) => (
          <IconContainer key={i} backgroundColor={backgroundColors[type]}>
            <Icons
              size={14}
              name={iconTypes[type]}
              color={fontColors[type]}
              fill={backgroundColors[type]}
            />
          </IconContainer>
        ))}

      <TotalNumberContainer variant="body3">{total}</TotalNumberContainer>
    </Container>
  );
};

export { TotalReactions };
