import styled from "styled-components";

const Container = styled.div`
  width: 100%;
`;

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: 100%;
  border-radius: 12px;
  border: 1px solid var(--color-neutral-90);
  padding: 8px;
`;

const Btn = styled.div<{ selected?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 16px;
  height: 40px;
  width: 100%;
  min-width: max-content;
  border-radius: 12px;
  background-color: ${({ selected }) =>
    selected ? "var(--color-secondary-95)" : "var(--color-neutral-100)"};
  border: 1px solid
    ${({ selected }) =>
      selected ? "var(--color-secondary-70)" : "var(--color-neutral-100)"};
  font-weight: 700;
  color: ${({ selected }) =>
    selected ? "var(--color-secondary-50)" : " var(--color-neutral-40)"};
  cursor: pointer;
`;

export { Container, ButtonsContainer, Btn };
