import styled from "styled-components";
import { Typography } from "@flash-tecnologia/hros-web-ui-v2";

const Container = styled.div`
  display: inline-flex;
  flex-direction: column;
  width: 350px;
  padding-right: 25px;
`;

const Title = styled(Typography)`
  color: var(--color-secondary-dark5);
  margin-bottom: 10px;
`;

const Message = styled(Typography)`
  color: var(--color-neutral-dark5);
`;

export { Container, Title, Message };
