import { useContext } from "react";
import { UserContext } from "../context/UserProvider";

export function useUser() {
  const context = useContext(UserContext);

  if (context === undefined) {
    throw new Error("useUser must be used inside an UserProvider.");
  }

  return context;
}
