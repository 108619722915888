import React, { CSSProperties } from "react";
import {
  AdornmentContainer,
  AdornmentDescription,
  AdornmentFlex,
  AdornmentFlexColumn,
  AdornmentTitle,
  Container,
} from "./styles";

export type BoxToggleProps = {
  title?: React.ReactNode;
  description: React.ReactNode;
  leftAdornment: React.ReactNode;
  isActive: boolean;
  activeComponent: React.ReactNode;
  style?: CSSProperties;
};

export const BoxAdornment = ({
  title,
  description,
  leftAdornment,
  isActive,
  activeComponent,
  style,
}: BoxToggleProps) => {
  return (
    <Container style={style}>
      <AdornmentContainer>
        <AdornmentFlex>{leftAdornment}</AdornmentFlex>
        <AdornmentFlexColumn>
          <AdornmentTitle variant="body3" className="box-adornment-title">
            {title}
          </AdornmentTitle>
          <AdornmentDescription
            variant="body4"
            className="box-adornment-description"
          >
            {description}
          </AdornmentDescription>
        </AdornmentFlexColumn>
      </AdornmentContainer>
      {isActive ? activeComponent : <></>}
    </Container>
  );
};
