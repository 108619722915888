import { Icons } from "@flash-tecnologia/hros-web-ui-v2";
import { backgroundColors, fontColors, icons } from "./reactions";
import {
  ReactContainer,
  IconContainer,
  TotalNumberContainer,
  Label,
  ButtonContainer,
  MainContainer,
} from "./styles";
import { TotalReactionInterface } from "./types";

const TotalReactions = ({
  total,
  reactionsCount,
  hiddeLikes,
}: TotalReactionInterface) => {
  const iconTypes: any = icons;

  const count = [
    { type: "like", value: reactionsCount[0] || 0 },
    { type: "heart", value: reactionsCount[1] || 0 },
    { type: "support", value: reactionsCount[2] || 0 },
    { type: "congrats", value: reactionsCount[3] || 0 },
  ];

  const sortReactionsByValue = (
    count: Array<{ type: string; value: number }>
  ) => {
    return (
      count?.sort(function (a, b) {
        return b?.value - a?.value;
      }) || []
    );
  };

  return (
    <MainContainer>
      {hiddeLikes ? null : (
        <ReactContainer>
          {sortReactionsByValue(count)
            ?.filter(({ value }) => value)
            ?.map(({ type }, i) => (
              <IconContainer key={i} backgroundColor={backgroundColors[type]}>
                <Icons
                  size={14}
                  name={iconTypes[type]}
                  color={fontColors[type]}
                  fill={backgroundColors[type]}
                />
              </IconContainer>
            ))}

          <TotalNumberContainer variant="body3">{total}</TotalNumberContainer>
        </ReactContainer>
      )}
      <ButtonContainer>
        <Icons
          name="IconMoodHappy"
          color="var(--color-neutral-dark5)"
          fill="white"
        />
        <Label variant="body3" color={undefined}>
          Reagir
        </Label>
      </ButtonContainer>
    </MainContainer>
  );
};

export { TotalReactions };
