import { TotalReactions } from "@components/TotalReactions";
import { Typography } from "@components/Typography";
import {
  Icons,
  LinkButton,
  ShapeIcon,
  dayjs,
} from "@flash-tecnologia/hros-web-ui-v2";
import { sanitizeHtml } from "@utils";
import {
  ButtonsContainer,
  Container,
  DescriptionContainer,
  HeaderContainer,
  ImageContainer,
  ReactionsContainer,
  TextWithHtml,
  TitleContainer,
} from "./styles";
import { PreviewInterface } from "./types";

const Preview = ({
  title,
  message,
  sendDate,
  image,
  like,
  link,
  pdf,
}: PreviewInterface) => {
  return (
    <Container>
      <HeaderContainer>
        <ShapeIcon
          name={"IconSpeakerphone"}
          variant="default"
          size={48}
          color="var(--color-secondary-50)"
        />
        <TitleContainer>
          <Typography
            variant="headline8"
            color="var(--color-neutral-20)"
            fontWeight={700}
          >
            {title || ""}
          </Typography>
          {sendDate ? (
            <Typography
              variant="caption"
              color="var(--color-neutral-40)"
              fontWeight={600}
            >
              {`Enviado em ${dayjs(sendDate)?.format("DD/MM/YYYY")} `}
            </Typography>
          ) : null}
        </TitleContainer>
      </HeaderContainer>
      <DescriptionContainer>
        <TextWithHtml
          dangerouslySetInnerHTML={{ __html: sanitizeHtml(message) }}
        />
      </DescriptionContainer>
      {link?.url || pdf ? (
        <ButtonsContainer>
          {link?.url ? (
            <LinkButton variant="default">
              {link?.label || "Acesse agora"} <Icons name="IconArrowUpRight" />
            </LinkButton>
          ) : null}
          {pdf ? (
            <LinkButton variant="default">
              Visualizar PDF <Icons name="IconDownload" />
            </LinkButton>
          ) : null}
        </ButtonsContainer>
      ) : null}
      {image ? <ImageContainer uri={image} /> : null}
      {like ? (
        <ReactionsContainer>
          <TotalReactions total={100} reactionsCount={[4, 3, 2, 1]} />
        </ReactionsContainer>
      ) : null}
    </Container>
  );
};

export { Preview };
