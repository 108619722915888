import { Button, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { Box } from "@mui/material";
import { isValidElement, MouseEventHandler, ReactNode } from "react";

export interface InnerCardInterface {
  title: string;
  description: string;
  svg: ReactNode;
  actionButtonLabel: string | ReactNode;
  actionButtonCallback: MouseEventHandler<HTMLButtonElement>;
}

export const InnerCard = ({
  title,
  description,
  actionButtonLabel,
  actionButtonCallback,
  svg,
}: InnerCardInterface) => {
  return (
    <Box
      gap={"8px"}
      display={"flex"}
      flexDirection={"row"}
      border={"1px solid var(--Neutral-Neutral-90, #EBE6E9)"}
      borderRadius={"12px"}
      padding={"24px"}
      width={"100%"}
      justifyContent={"space-between"}
    >
      {svg}
      <Box
        gap={"8px"}
        display={"flex"}
        flexDirection={"column"}
        flex="1 0 0"
        maxWidth={"386px"}
      >
        <Typography variant="headline7">{title}</Typography>
        <Typography variant="body4">{description}</Typography>
        <Box marginTop={"8px"}>
          {isValidElement(actionButtonLabel) ? (
            actionButtonLabel
          ) : (
            <Button
              variant="secondary"
              onClick={actionButtonCallback}
              style={{ width: "100%" }}
            >
              {actionButtonLabel}
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
};
