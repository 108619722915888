import styled from "styled-components";

const Container = styled.div<{ direction?: "row" | "column" }>`
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 24px;
  flex-direction: ${({ direction }) => direction};
  width: 100%;
  margin-bottom: 32px;
  max-width: 1440px;
`;

export { Container };
