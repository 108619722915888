import { DataCard } from "../DataCard";

import { UploadPreview } from "@components/Upload/UploadPreview";
import { Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { sanitizeHtml } from "@utils";
import { useTheme } from "styled-components";
import { TextWithHtml } from "./styled";

interface BasicInfoTabProps {
  data: any;
  onNavigate: React.MouseEventHandler<HTMLButtonElement> | undefined;
}

export const BasicInfoTab = ({ data, onNavigate }: BasicInfoTabProps) => {
  const theme = useTheme() as any;
  const { name, title, message, link, imageUrl, pdfFile } = data;

  return (
    <DataCard
      title="Informações básicas"
      subTitle="Confira as informações básicas."
      editable
      onEditClick={onNavigate}
      sections={[
        {
          items: [
            {
              title: "Nome de identificação da comunicação",
              description: name,
            },
            {
              title: "Título da mensagem",
              description: title,
            },
            {
              title: "Mensagem",
              description: (
                <TextWithHtml
                  dangerouslySetInnerHTML={{ __html: sanitizeHtml(message) }}
                />
              ),
            },
            {
              title: "Inserir link",
              description: link?.url ? "Habilitado" : "Desabilitado",
            },
            {
              title: "Texto do link",
              description: "Acesse agora",
            },
            {
              title: "Link",
              description: link?.url || "-",
            },
            {
              title: "Imagem",
              description: imageUrl ? "Possui imagem" : "Sem imagem",
            },
            {
              title: "Inserir PDF",
              description: pdfFile ? "Habilitado" : "Desabilitado",
            },
          ],
        },
      ]}
    >
      {pdfFile ? (
        <div>
          <Typography
            variant="caption"
            variantColor={theme.colors.neutral[50]}
            style={{ fontWeight: 700 }}
          >
            Arquivo anexado
          </Typography>

          <UploadPreview file={pdfFile} />
        </div>
      ) : (
        <></>
      )}
    </DataCard>
  );
};
