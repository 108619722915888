import { BigNumber } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

const ResultadoWrapper = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 24px;
  position: relative;
`;

const Section = styled.div`
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  position: relative;
  width: 100%;
`;

const Survey = styled.div`
  align-items: flex-start;
  border: 1px solid;
  border-color: rgb(235, 230, 233);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  padding: 24px;
  position: relative;
  width: 100%;
  max-width: 558px;
`;

const Frame = styled.div`
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 12px;
  position: relative;
  width: 100%;
`;

const ResultLineWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const StyledBigNumber = styled(BigNumber)`
  flex: 1;
  max-width: none;
`;

const Thermometer = styled.div<{ color?: string; width?: number }>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 10px;
  background-color: var(--color-neutral-80);
  border-radius: 12px;
  width: 100%;

  :before {
    content: "";
    height: 10px;
    background-color: ${({ color }) => color || "pink"};
    border-radius: 12px;
    width: ${({ width }) => (width ? `${width}%` : 0)};
  }
`;

const YellowContainer = styled.div`
  width: 100%;
  border-radius: 0;

  > div {
    :before {
      border-radius: 0;
    }
  }
`;

const RedContainer = styled(YellowContainer)`
  width: 100%;
`;

const GreenContainer = styled(YellowContainer)``;

const Content = styled.div`
  display: flex;
  gap: 24px;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
`;

export {
  ResultadoWrapper,
  Survey,
  Frame,
  Section,
  ResultLineWrapper,
  Thermometer,
  YellowContainer,
  RedContainer,
  GreenContainer,
  StyledBigNumber,
  Content,
};
