import { Menu, Icons, IconsProps } from "@flash-tecnologia/hros-web-ui-v2";
import { MouseEvent } from "react";
import { BorderIcon } from "../BorderIcon";
import { Container, Text } from "./styles";

const MenuIcon = ({
  options,
  disabled,
}: {
  options: Array<{
    label: string;
    icon: IconsProps["name"];
    onClick: (e: MouseEvent<any>) => void;
    disabled?: boolean;
  }>;
  disabled?: boolean;
}) => {
  return (
    <Menu
      type="default"
      disabled={disabled}
      options={options
        ?.filter(({ disabled }) => !disabled)
        ?.map(({ label, icon, onClick }) => ({
          children: (
            <Container onClick={onClick}>
              <Icons
                name={icon}
                fill="transparent"
                color="var(--color-neutral-dark3)"
              />
              <Text variant="body3">{label}</Text>
            </Container>
          ),
        }))}
    >
      <BorderIcon name="IconDotsVertical" />
    </Menu>
  );
};

export { MenuIcon };
