import styled from "styled-components";
import { TagInterface, GetColorInterface } from "./types";

const getColor = ({ variant, mode }: GetColorInterface) => {
  if (variant === "draft") {
    if (mode === "color") return "var(--color-feedback-error-dark1)";
    return "var(--color-feedback-error-light1)";
  }

  if (variant === "send") {
    if (mode === "color") return "var(--color-feedback-success-dark2)";
    return "var(--color-feedback-success-light1)";
  }

  if (variant === "scheduled") {
    if (mode === "color") return "var(--color-feedback-info-dark1)";
    return "var(--color-feedback-info-light1)";
  }

  if (variant === "progress") {
    if (mode === "color") return "var(--color-feedback-success-dark1)";
    return "var(--color-feedback-success-light1)";
  }

  if (variant === "paused") {
    if (mode === "color") return "var(--color-feedback-error-dark2)";
    return "var(--color-feedback-error-light1)";
  }

  if (variant === "closed") {
    if (mode === "color") return "var(--color-neutral-dark1)";
    return "var(--color-neutral-light2)";
  }

  if (variant === "neutral") {
    if (mode === "color") return "var(--color-neutral-dark3)";
    return "var(--color-neutral-light2)";
  }

  if (variant === "item") {
    if (mode === "color") return "var(--color-secondary-50)";
    return "var(--color-secondary-95)";
  }

  if (mode === "color") return "var(--color-secondary-dark5)";
  return "var(--color-secondary-light2)";
};

const Tag = styled.div<{ variant: TagInterface["variant"] }>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 5px 15px;
  border-radius: 30px;
  font-weight: 600;
  font-size: 12px;
  min-width: max-content;
  margin: 0 10px 10px 0;
  background-color: ${({ variant }) =>
    getColor({ variant, mode: "background" })};
  color: ${({ variant }) => getColor({ variant, mode: "color" })};
`;

export { Tag };
