import styled from "styled-components";
import { Typography } from "@flash-tecnologia/hros-web-ui-v2";

const Title = styled(Typography)`
  color: var(--color-neutral-dark2);
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;

export { Title, TitleContainer };
