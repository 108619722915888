import { SelectField, TextField } from "@flash-tecnologia/hros-web-ui-v2";
import { LineSeparator, Typography } from "@components/index";
import { Divider } from "@components/v2";
import { Container, FieldRow } from "./styles";
import { ReactInterface } from "./types";

const React = ({
  disabled,
  answerFormat,
  lowerLabel,
  upperLabel,
  onChange,
}: ReactInterface) => {
  return (
    <Container>
      <FieldRow>
        <Typography
          variant="headline9"
          color="var(--color-neutral-30)"
          fontWeight={700}
        >
          Configurar legendas
        </Typography>
      </FieldRow>
      <Divider spacing="xs1" />
      <FieldRow>
        <div style={{ width: "100%" }}>
          <TextField
            disabled={disabled}
            value={lowerLabel || ""}
            className="scale-label"
            label="Legenda de menor valor"
            helperText="Escreva o título com a nota mais negativa. Exemplo: Péssimo"
            type="text"
            onChange={(e) =>
              e?.target?.value?.length < 21
                ? onChange({
                    answerFormat,
                    upperLabel,
                    lowerLabel: e?.target?.value,
                  })
                : null
            }
          />
        </div>
        <Divider spacing="xs1" type="horizontal" />
        <div style={{ width: "100%" }}>
          <TextField
            disabled={disabled}
            value={upperLabel || ""}
            className="scale-label"
            label="Legenda de maior valor"
            helperText="Escreva o título com a nota mais positiva. Exemplo: Ótimo"
            type="text"
            onChange={(e) =>
              e?.target?.value?.length < 21
                ? onChange({
                    answerFormat,
                    lowerLabel,
                    upperLabel: e?.target?.value,
                  })
                : null
            }
          />
        </div>
      </FieldRow>
    </Container>
  );
};

export { React };
