import { useEffect, useState } from "react";
import { MainContainer, BorderContainer } from "./styles";
import { ReactFormProps } from "./types";
import { ReactButton, TotalReactions } from "./components";
import { trpc } from "@api/client";
import { segment } from "@utils";

const ReactForm = ({
  reaction,
  engagementId,
  totalLikes,
  reactionsCount: reactionsCountValues,
}: ReactFormProps) => {
  const [reactValue, setReactValue] = useState(reaction ?? 0);
  const [totalReactions, setTotalReactions] = useState(0);
  const [reactionsCount, setReactionsCount] = useState<Array<number>>([]);

  const { mutate: setMonitoringMutate } = trpc?.setMonitoring?.useMutation({
    onError: (e: any) => {
      const msgError = "Ocorreu um erro, tente novamente mais tarde.";

      alert(msgError);
      console.log("err: ", e);
    },
  });

  useEffect(() => {
    if (totalLikes) setTotalReactions(totalLikes);
  }, [totalLikes]);

  useEffect(() => {
    if (reactionsCountValues?.length) setReactionsCount(reactionsCountValues);
  }, [reactionsCountValues]);

  return (
    <MainContainer>
      {totalLikes ? (
        <TotalReactions total={totalLikes} reactionsCount={reactionsCount} />
      ) : null}
      <BorderContainer>
        <ReactButton
          onClick={(value) => {
            segment({
              track:
                "people_strategic_hr_engagement_employee_communication_react_clicked",
            });
            setReactValue(value);

            setReactionsCount(
              reactionsCount?.map((count, i) => {
                if (i + 1 === value) return count + 1;
                if (reactValue === i + 1) return count - 1;
                return count;
              })
            );

            if (reactValue === 0) setTotalReactions(totalReactions + 1);

            setMonitoringMutate({
              engagementId: engagementId,
              type: "like",
              like: value,
            });
          }}
          value={reactValue}
        />
      </BorderContainer>
    </MainContainer>
  );
};

export { ReactForm };
