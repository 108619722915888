import styled from "styled-components";

const Header = styled.header`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 15px 15px 0 15px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const Footer = styled.footer`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 30px 0;
  border-top: 1px solid var(--color-neutral-90);
`;

const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  width: 500px;
  max-width: 100%;
`;

const ContentIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  padding: 12px;
  background-color: var(--color-secondary-95);
`;

export {
  Header,
  ContentContainer,
  ContentIconContainer,
  IconContainer,
  Footer,
};
