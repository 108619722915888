import {
  Button,
  LinkButton,
  Modal as ModalUiv2,
  TypographyProps,
} from "@flash-tecnologia/hros-web-ui-v2";
import { Divider } from "../v2";
import { Typography } from "../Typography";
import {
  Body,
  Footer,
  StatusIcon,
  StatusContainer,
  TextContainer,
} from "./styles";
import { ModalInterface } from "./types";
import { isValidElement } from "react";
const { Root: ModalRoot } = ModalUiv2;

const NewModal = ({
  id = "",
  footer,
  onClose,
  open,
  title,
  description,
  status,
}: ModalInterface) => {
  const handleText = ({
    text,
    color,
    variant,
  }: {
    text: string;
    color: string;
    variant: TypographyProps["variant"];
  }) => {
    const splitedText = text?.split("<br/>");
    return (
      <TextContainer>
        {splitedText?.map((word) => (
          <Typography variant={variant} color={color || "blue"}>
            {word}
          </Typography>
        ))}
      </TextContainer>
    );
  };

  return (
    <ModalRoot
      open={open}
      onClose={onClose}
      showClose={false}
      children={
        <Body>
          <StatusContainer>
            {status?.icon ? (
              <StatusIcon
                name={status?.icon}
                size={24}
                color={status?.color || "var(--color-neutral-10)"}
              />
            ) : null}
            {handleText({
              text: status?.message,
              color: status?.color || "var(--color-neutral-10)",
              variant: "body4",
            })}
          </StatusContainer>
          <Divider spacing={"xxxs"} />
          {handleText({
            text: title,
            color: "var(--color-neutral-10)",
            variant: "headline8",
          })}
          <Divider spacing={"xxxs"} />
          {isValidElement(description)
            ? description
            : handleText({
                text: description as string,
                color: "var(--color-neutral-40)",
                variant: "body4",
              })}

          <Divider spacing={"xxxs"} />
          <div id={id} />
        </Body>
      }
      footer={
        <Footer
          style={{
            justifyContent:
              footer?.submitButton && footer?.closeButton
                ? "space-between"
                : "center",
          }}
        >
          {footer?.closeButton ? (
            <div>
              <LinkButton
                onClick={footer?.closeButton?.onClick}
                variant={footer?.closeButton?.variant || "default"}
                disabled={
                  footer?.closeButton?.disabled || footer?.closeButton?.loading
                }
              >
                {footer?.closeButton?.label}
              </LinkButton>
            </div>
          ) : null}
          {footer?.submitButton ? (
            <div style={{ marginLeft: footer?.closeButton ? "10px" : "0px" }}>
              <Button
                variant={footer?.submitButton?.variant || "primary"}
                variantType={footer?.submitButton?.variantType || "default"}
                size={footer?.submitButton?.size || "large"}
                onClick={footer?.submitButton?.onClick}
                disabled={footer?.submitButton?.disabled}
                loading={footer?.submitButton?.loading}
              >
                {footer?.submitButton?.label}
              </Button>
            </div>
          ) : null}
        </Footer>
      }
    />
  );
};

export { NewModal };
