import styled from "styled-components";
import { Container } from "../Card/styles";

const BlankContainer = styled(Container)`
  cursor: pointer;
  justify-content: center;
  border-color: var(--color-secondary-99);
  background-color: var(--color-secondary-99);
`;

const TitleContainer = styled.div`
  margin-top: 16px;
  text-align: center;
  width: 150px;
`;

export { BlankContainer, TitleContainer };
