import { Checkbox } from "@flash-tecnologia/hros-web-ui-v2";
import { MainContainer, Container, Label } from "./styles";
import { DaysPickerInterface } from "./types";
import { Space } from "../Space";

const DaysPicker = ({ days, onChange, disabled }: DaysPickerInterface) => {
  const getDayByValue = (value: string) => {
    const selected = days?.find(({ value: dValue }) => dValue === value);
    if (selected?.value) return { ...selected, checked: !selected?.checked };
    return;
  };

  const handleChange = (value: string) =>
    onChange(
      days?.map((day) =>
        day?.value === value ? { ...day, checked: !day?.checked } : day
      ),
      getDayByValue(value)
    );

  return (
    <MainContainer>
      {days?.map(({ label, value, checked }) => (
        <Container
          key={value}
          onClick={() => (disabled ? null : handleChange(value))}
        >
          <Checkbox checked={checked} disabled={disabled} />
          <Label variant="body3">{label}</Label>
          <Space size="lg" />
        </Container>
      ))}
    </MainContainer>
  );
};

export { DaysPicker };
