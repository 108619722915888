import { useEffect, useState } from "react";
import { TransferListInterface } from "../../../../v2/TransferList/types";
import { trpc } from "@api/client";
import { useSelectedCompany } from "@flash-tecnologia/hros-web-utility";
import { TransferList } from "../../../../v2";
import { StepDescription, StepContainer } from "../../components";
import { alert } from "../../../../../utils";
import { Container } from "./styles";
import { StepEmployeeInterface } from "./types";

const StepEmployee = ({
  model,
  selectedEmployeeIds,
  hide,
  companyEmployees,
  anonymous,
  disabled,
  type,
  notifications,
  onChange,
  onClick,
}: StepEmployeeInterface) => {
  const { selectedCompany } = useSelectedCompany();

  const [leftSections, setLeftSections] = useState<
    TransferListInterface["filter"]["sections"]
  >([
    {
      id: "section-group",
      title: "Grupos",
      options: [],
      search: "",
    },
    {
      id: "section-department",
      title: "Departamento",
      options: [],
      search: "",
    },
    {
      id: "section-role",
      title: "Cargo",
      options: [],
      search: "",
    },
  ]);

  const { data: roles, refetch: getRolesByCompanyId } =
    trpc?.getRolesByCompanyId?.useQuery(
      {
        companyId: selectedCompany?.id,
      },
      {
        enabled: false,
        retry: false,
        onSuccess: (data) => {
          const roles = data;
          const options = roles?.map(({ _id, name }) => ({
            value: _id,
            title: name,
            asideText: "",
            checked: false,
            hidden: false,
          }));

          const updatedLeftSections = leftSections?.map(({ id, ...rest }) =>
            id === "section-role" ? { id, ...rest, options } : { id, ...rest }
          );

          if (roles?.length) setLeftSections([...updatedLeftSections]);
        },
        refetchOnWindowFocus: false,
        onError: (e: any) => {
          alert("Erro ao buscar cargos, tente novamente mais tarde.");
          console.log("err: ", e);
        },
      }
    );

  const { data: allGroups, refetch: getGroupsByCompanyId } =
    trpc?.getGroupsByCompanyId?.useQuery(
      {
        active: true,
      },
      {
        enabled: false,
        retry: false,
        onSuccess: (data) => {
          const options = data?.map(({ _id, name }) => ({
            value: _id,
            title: name,
            asideText: "",
            checked: false,
            hidden: false,
          }));

          const updatedLeftSections = leftSections?.map(({ id, ...rest }) =>
            id === "section-group" ? { id, ...rest, options } : { id, ...rest }
          );

          if (data?.length) setLeftSections([...updatedLeftSections]);
        },
        refetchOnWindowFocus: false,
        onError: (e: any) => {
          alert("Erro ao buscar grupos, tente novamente mais tarde.");
          console.log("err: ", e);
        },
      }
    );

  const { data: allDepartments, refetch: getDepartmentsByCompanyId } =
    trpc?.getDepartmentsByCompanyId?.useQuery(
      {
        companyId: selectedCompany?.id,
      },
      {
        enabled: false,
        retry: false,
        onSuccess: (data) => {
          const departments = data;
          const options = departments?.map(({ _id, name }) => ({
            value: _id,
            title: name,
            asideText: "",
            checked: false,
            hidden: false,
          }));

          const updatedLeftSections = leftSections?.map(({ id, ...rest }) =>
            id === "section-department"
              ? { id, ...rest, options }
              : { id, ...rest }
          );

          if (departments?.length) setLeftSections([...updatedLeftSections]);
        },
        refetchOnWindowFocus: false,
        onError: (e: any) => {
          alert("Erro ao buscar departamentos, tente novamente mais tarde.");
          console.log("err: ", e);
        },
      }
    );

  const getFilters = async () => {
    await getGroupsByCompanyId();
    await getRolesByCompanyId();
    await getDepartmentsByCompanyId();
  };

  useEffect(() => {
    getFilters();
  }, []);

  if (hide) return <></>;

  return (
    <StepContainer>
      <StepDescription
        anonymous={anonymous}
        currentStep={4}
        model={model}
        title="Destinatários"
        description={`Selecione os participantes que responderão a ${
          type === "communication" ? "comunicação" : "pesquisa"
        }.`}
        notifications={notifications}
        recipients={
          companyEmployees?.filter(({ _id }) =>
            selectedEmployeeIds?.includes(_id)
          ) || []
        }
        onClick={() => (onClick ? onClick() : null)}
      />
      <Container>
        <TransferList
          disabled={disabled}
          data={
            companyEmployees
              ?.filter(({ _id: employeeId }) => {
                let filtered = false;

                const groupOptions = leftSections[0]?.options;
                const groupIds = groupOptions
                  ?.filter(({ checked }) => checked)
                  ?.map(({ value }) => value);

                const departmentOptions = leftSections[1]?.options;
                const departmentIds = departmentOptions
                  ?.filter(({ checked }) => checked)
                  ?.map(({ value }) => value);

                const roleOptions = leftSections[2]?.options;
                const roleIds = roleOptions
                  ?.filter(({ checked }) => checked)
                  ?.map(({ value }) => value);

                const hasFilter =
                  (departmentIds?.length || 0) +
                  (groupIds?.length || 0) +
                  (roleIds?.length || 0);

                if (hasFilter === 0) return true;

                groupIds?.forEach((groupId) => {
                  const group = allGroups?.find(({ _id }) => _id === groupId);
                  if (group?.employees?.some((e) => e?._id === employeeId))
                    filtered = true;
                });

                departmentIds?.forEach((departmentId) => {
                  const department = allDepartments?.find(
                    ({ _id }) => _id === departmentId
                  );
                  if (department?.employees?.includes(employeeId))
                    filtered = true;
                });

                roleIds?.forEach((roleId) => {
                  const role = roles?.find(({ _id }) => _id === roleId);
                  if (role?.employees?.includes(employeeId)) filtered = true;
                });

                return filtered;
              })
              ?.map(({ _id, name, corporateEmail }) => ({
                id: _id,
                name,
                corporateEmail: corporateEmail || "",
              })) || []
          }
          selected={
            selectedEmployeeIds?.map((id) => ({
              id,
              name: companyEmployees?.find(({ _id }) => _id === id)?.name || "",
              corporateEmail:
                companyEmployees?.find(({ _id }) => _id === id)
                  ?.corporateEmail || "",
            })) || []
          }
          onChange={(selected) => onChange(selected?.map(({ id }) => id))}
          filter={{
            sections: leftSections,
            onChange: (e) =>
              setLeftSections([
                ...leftSections?.map((s) => (s?.id === e?.id ? e : s)),
              ]),
            onReset: (e) => setLeftSections([...e]),
          }}
        />
      </Container>
    </StepContainer>
  );
};

export { StepEmployee };
