import { Container } from "./styles";
import { Icons } from "@flash-tecnologia/hros-web-ui-v2";
import { Typography } from "../../../../Typography";
import { FilterButtonInterface } from "./types";

const FilterButton = ({ onClick }: FilterButtonInterface) => (
  <Container onClick={onClick}>
    <Icons name="IconFilter" color="var(--color-neutral-40)" />
    <Typography
      variant="body4"
      color="var(--color-neutral-40)"
      fontWeight={700}
    >
      Filtros
    </Typography>
  </Container>
);

export { FilterButton };
