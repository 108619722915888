import styled from "styled-components";

const Container = styled.footer`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-top: 1px solid var(--color-neutral-light2);
  height: 80px;
  padding: 0 24px;

  @media (max-width: 1000px) {
    height: 150px;
    flex-direction: column;
    justify-content: center;

    > :first-child {
      margin-bottom: 20px;
      flex-direction: column;
    }
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export { Container, ButtonsContainer };
