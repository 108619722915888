import styled from "styled-components";
import { Typography } from "@flash-tecnologia/hros-web-ui-v2";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: #f8f6f8;
  width: 100%;
  position: fixed;
  bottom: 0;
  z-index: -1;
`;

const ContentContainer = styled.div`
  max-width: 1366px;
  margin: 0 auto;
  display: flex;
  padding: 30px 136px;
  width: 100%;
  justify-content: space-between;
  @media screen and (max-width: 992px) {
    padding: 30px 48px;
  }
  @media screen and (max-width: 759px) {
    flex-direction: column-reverse;
    text-align: center;
  }
`;

const DataContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const CompanyTexts = styled(Typography)`
  && {
    font-weight: 600;
    color: ${({ theme }) => theme.colors.neutral.light.light5};
  }
`;

const DoubtsText = styled(Typography)`
  && {
    font-weight: 600;
    color: ${({ theme }) => theme.colors.neutral.dark.dark5};
  }
`;

const FlexAligned = styled.div`
  display: flex;
  align-items: center;
  gap: 19px;
  margin-top: 4px;

  @media screen and (max-width: 759px) {
    flex-direction: column;
    gap: 5px;
    margin-top: 8px;
    margin-bottom: 20px;
  }
`;

const IconTexts = styled.div`
  display: flex;
  align-items: center;
  gap: 7px;

  svg {
    stroke: ${({ theme }) => theme.colors.secondary.light.light5};
    fill: transparent;
  }
`;

const ContactText = styled(Typography)`
  && {
    font-weight: 700;
    color: ${({ theme }) => theme.colors.secondary.light.light5};
  }
`;

export {
  Container,
  ContentContainer,
  DataContainer,
  CompanyTexts,
  DoubtsText,
  FlexAligned,
  IconTexts,
  ContactText,
};
