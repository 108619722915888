import { Container, ChildrenContainer, Title, Message } from "./styles";
import { NotFoundInterface } from "./types";

const NotFoundMessage = ({ title, message, children }: NotFoundInterface) => {
  return (
    <Container>
      <Title variant="headline6">{title}</Title>
      <Message variant="body2">{message}</Message>
      <ChildrenContainer>{children}</ChildrenContainer>
    </Container>
  );
};

export { NotFoundMessage };
