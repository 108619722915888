import styled from "styled-components";
import { Typography } from "@flash-tecnologia/hros-web-ui-v2";

const MainContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  flex-wrap: wrap;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Label = styled(Typography)`
  color: var(--color-neutral-dark3);
  font-weight: 600;
  min-width: max-content;
`;

export { MainContainer, Container, Label };
