import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Error } from "@components/Error";
import {
  Breadcrumbs,
  NotificationCard,
  Skeleton,
  Tab,
  dayjs,
} from "@flash-tecnologia/hros-web-ui-v2";
import { useSelectedCompany } from "@flash-tecnologia/hros-web-utility";
import { CarouselCards, PageContainer, StatisticRow } from "../../components";
import { Divider } from "../../components/v2";
import { alert } from "../../utils";
import { trpc } from "@api/client";
import {
  Header,
  MonitoringDataGrid,
  PeopleDataGrid,
  Detail,
  Analytic,
} from "./components";
import { routes } from "@routes";
import { EngajaNotificationCard } from "@components/EngajaNotificationCard";

export default () => {
  const { selectedCompany } = useSelectedCompany();
  const navigate = useNavigate();
  const { id = "" } = useParams();

  useEffect(() => {
    if (id) {
      if (["ShY1SYEMjLQ-qyCMYklnu", "3h8BQMy-fRc2nfCrLhyXE"]?.includes(id))
        navigate(-1);
    }
  }, [id]);

  const { data: departments, isFetching: getDepartmentsByCompanyIdLoading } =
    trpc?.getDepartmentsByCompanyId?.useQuery(
      { companyId: selectedCompany?.id || "" },
      {
        retry: false,
        refetchOnWindowFocus: false,

        onError: (e) => {
          alert("Erro ao buscar destinatários, tente novamente mais tarde.");
          console.log("err: ", e);
        },
      }
    );

  const {
    data: engagement,
    isFetching: getEngagementByIdLoading,
    refetch,
  } = trpc.getEngagementById.useQuery(
    {
      id: id,
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
      onSuccess: (e: any) => {
        if (e?.code === "EMPLOYEE_NOT_ENGAGEMENT_OWNER_ERROR") {
          alert(
            "Você não tem permissão para visualizar os detalhes desta pesquisa."
          );
        }
      },
      onError: (e) => {
        alert("Erro ao buscar pesquisa, tente novamente mais tarde.");
        console.log("err: ", e);
      },
    }
  );

  const {
    data: employees,
    isFetching: getEmployeeMonitoringsByEngagementIdLoading,
  } = trpc?.getEmployeeMonitoringsByEngagementId.useQuery(
    { id },
    {
      retry: false,
      refetchOnWindowFocus: false,
      onSuccess: (e) => setData(e),
      onError: (e) => {
        alert("Erro ao buscar destinatários, tente novamente mais tarde.");
        console.log("err: ", e);
      },
    }
  );

  const { data: insights, isFetching: getSurveyInsightsLoading } =
    trpc?.getSurveyInsights.useQuery(
      { id },
      {
        retry: false,
        refetchOnWindowFocus: false,
        onError: (e) => {
          alert("Erro ao buscar insights, tente novamente mais tarde.");
          console.log("err: ", e);
        },
      }
    );

  const getAllMonitorings = () => {
    const result: any[] = [];

    employees?.forEach(({ monitorings }) =>
      monitorings?.forEach((m) => {
        if (m?.type === "react" || m?.type === "enps") result?.push(m);
      })
    );

    return result;
  };

  const totalAnswers = employees?.filter(
    (e) =>
      e?.monitorings?.filter(
        (m) => m?.type && ["enps", "react"]?.includes(m?.type) && !m?.draft
      )?.length
  )?.length;

  const [data, setData] = useState<typeof employees>([]);

  if (
    getEngagementByIdLoading ||
    getEmployeeMonitoringsByEngagementIdLoading ||
    getSurveyInsightsLoading
  )
    return <Skeleton variant="rectangular" width="100%" height="100%" />;

  if ((engagement as any)?.code === "EMPLOYEE_NOT_ENGAGEMENT_OWNER_ERROR")
    return (
      <Error
        title="Você não tem permissão para acessar os detalhes"
        description="Se achar que isso é um erro, entre em contato com seu gestor."
        buttonLabel="Voltar ao Início"
        buttonCallback={() => navigate(-1)}
      />
    );

  return (
    <PageContainer>
      {engagement?.engagementOrigin || engagement?.recurrence?.status ? (
        <Breadcrumbs
          separator=">"
          breadcrumbs={[
            <Link to="/engagement/survey">Gerenciar pesquisas</Link>,
            <Link to={`/engagement/survey/${id}`} onClick={() => navigate(-1)}>
              Detalhe da pesquisa
            </Link>,
            <Link to={`/engagement/survey/${id}`}>Detalhe do envio</Link>,
          ]}
        />
      ) : (
        <Breadcrumbs
          separator=">"
          breadcrumbs={[
            <Link to="/engagement/survey">Gerenciar pesquisas</Link>,
            <Link to={`/engagement/survey/${id}`}>Detalhe da pesquisa</Link>,
          ]}
        />
      )}
      <Header
        refetch={refetch}
        pendingCount={
          (engagement?.employees?.length || 0) - (totalAnswers || 0)
        }
        name={engagement?.name || ""}
        status={engagement?.status || ""}
        data={
          engagement
            ? {
                ...engagement,
                notifications: {
                  email: engagement?.notifications?.email || false,
                  push: engagement?.notifications?.push || false,
                  reminder: {
                    enabled:
                      engagement?.notifications?.reminder?.enabled || false,
                    minRate: engagement?.notifications?.reminder?.minRate || 0,
                    lastSentDate: engagement?.notifications?.reminder
                      ?.lastSentDate
                      ? new Date(
                          engagement?.notifications?.reminder?.lastSentDate
                        )
                      : undefined,
                  },
                },
                createdAt: engagement?.createdAt
                  ? new Date(engagement?.createdAt)
                  : undefined,
                updatedAt: engagement?.updatedAt
                  ? new Date(engagement?.updatedAt)
                  : undefined,
                sendDate: engagement?.sendDate
                  ? new Date(engagement?.sendDate)
                  : undefined,
              }
            : undefined
        }
      />

      {/* {new Date() < new Date(2024, 7, 13) ? (
        <EngajaNotificationCard
          title="Análise comparativa em andamento"
          description={
            <>
              O <strong>resultado estará disponível em 13/08/2024. </strong>
              Enquanto isso, você pode acompanhar os dados de sua empresa
              normalmente. Assim que a análise estiver concluída, notificaremos
              aqui na plataforma!
            </>
          }
        />
      ) : (
        <EngajaNotificationCard
          title="Análise comparativa disponível"
          description="Confira os dados da análise acessando o botão acima. Para aprender
          melhores práticas e ver dicas de atitudes que podem ser tomadas a
          partir do resultado participem do nosso Webinar dia 19/08/2024."
          linkButtons={[
            {
              label: "Acessar webinar",
              callback: () => null,
            },
          ]}
        />
      )} */}

      <Divider spacing="s3" />
      <CarouselCards
        cards={
          engagement?.type === "enps"
            ? [
                {
                  title: "Pontuação eNPS",
                  icon: "IconDashboard",
                  value: insights?.enps ? insights?.enps?.score || 0 : 0,
                },
                {
                  title: "Detratores (0-6)",
                  icon: "IconUserX",
                  value: insights?.enps ? insights?.enps?.detractors || 0 : 0,
                },
                {
                  title: "Neutros (7-8)",
                  icon: "IconUserExclamation",
                  value: insights?.enps ? insights?.enps?.neutrals || 0 : 0,
                },
                {
                  title: "Promotores (9-10)",
                  icon: "IconUserPlus",
                  value: insights?.enps ? insights?.enps?.promoters || 0 : 0,
                },
                {
                  title: "Total de pessoas",
                  icon: "IconUser",
                  value: insights?.recipients || 0,
                },
                {
                  title: "Respostas",
                  icon: "IconMessage",
                  value: insights?.answerRate || 0,
                  subValue: insights?.answers || 0,
                },
                {
                  title: "Visualizações",
                  icon: "IconEye",
                  value: insights?.viewRate || 0,
                  subValue: insights?.views || 0,
                },
              ]
            : [
                {
                  title: "Total de destinatários",
                  icon: "IconUser",
                  value: insights?.recipients || 0,
                },
                {
                  title: "Perguntas",
                  icon: "IconQuestionMark",
                  value: insights?.questions || 0,
                },
                {
                  title: "Taxa de visualização",
                  icon: "IconEye",
                  value: insights?.viewRate || 0,
                  subValue: insights?.views,
                },
                {
                  title: "Taxa de resposta",
                  icon: "IconGraph",
                  value: insights?.answerRate || 0,
                  subValue: insights?.answers,
                },
              ]
        }
      />
      <Divider spacing="xs2" />
      <StatisticRow
        data={[
          {
            label: "Criado em",
            value: engagement?.createdAt
              ? dayjs(engagement?.createdAt)?.format("DD/MM/YYYY")
              : "-",
          },
          {
            label: "Enviado em",
            value: engagement?.sendDate
              ? dayjs(engagement?.sendDate)?.format("DD/MM/YYYY")
              : "-",
          },
          {
            label: "Prazo de respostas",
            value: engagement?.interactionDeadline
              ? dayjs(engagement?.interactionDeadline)?.format("DD/MM/YYYY")
              : "-",
          },
        ]}
      />
      <Divider spacing="xs2" />
      <Tab
        tabItens={[
          {
            label: "Análise",
            component: (
              <Analytic
                totalAnswers={totalAnswers || 0}
                empty={!totalAnswers}
                model={engagement?.model || ""}
                departments={
                  departments?.filter(({ employees }) =>
                    engagement?.employees?.some((employeeId) =>
                      employees?.includes(employeeId)
                    )
                  ) || []
                }
                anonymous={engagement?.anonymous}
                employees={
                  data?.map((e) => ({
                    _id: e?._id,
                    name: e?.name,
                    monitorings: e?.monitorings,
                  })) || []
                }
                engagementId={id || ""}
                sections={engagement?.sections}
                monitorings={getAllMonitorings()}
                enps={
                  engagement?.model === "enps" ||
                  engagement?.model === "climate" ||
                  engagement?.model === "engaja"
                    ? {
                        title: engagement?.name || "",
                        detractors: insights?.enps?.detractors || 0,
                        neutrals: insights?.enps?.neutrals || 0,
                        promoters: insights?.enps?.promoters || 0,
                        score: insights?.enps?.score || 0,
                      }
                    : undefined
                }
                engagementEmployees={engagement?.employees || []}
              />
            ),
          },
          {
            label: `Respostas (${totalAnswers})`,
            component: (
              <MonitoringDataGrid
                empty={
                  !employees?.filter(
                    (e) =>
                      e?.monitorings?.filter(
                        (m) =>
                          m?.type &&
                          ["enps", "react"]?.includes(m?.type) &&
                          !m?.draft
                      )?.length
                  )?.length
                }
                engagementId={id || ""}
                data={
                  data
                    ? data?.filter(
                        (e) =>
                          e?.monitorings?.filter(
                            (m) =>
                              m?.type &&
                              ["enps", "react"]?.includes(m?.type) &&
                              !m?.draft
                          )?.length
                      )
                    : []
                }
                employees={employees || []}
                engagement={{
                  sections: engagement?.sections,
                  anonymous: engagement?.anonymous,
                }}
                onSearch={(e) =>
                  setData(
                    employees?.filter(({ name }) =>
                      name?.match(RegExp(e, "ig"))
                    ) || []
                  )
                }
              />
            ),
          },
          {
            label: `Pessoas (${insights?.selectedEmployees?.length || 0})`,
            component: (
              <PeopleDataGrid
                selectedEmployees={insights?.selectedEmployees || []}
                engagementId={id}
                data={data || []}
                anonymous={engagement?.anonymous}
                onSearch={(e) =>
                  setData(
                    employees?.filter(({ name }) =>
                      name?.match(RegExp(e, "ig"))
                    ) || []
                  )
                }
              />
            ),
          },
          {
            label: "Detalhe",
            component: engagement ? (
              <Detail
                data={{
                  ...engagement,
                  notifications: {
                    email: engagement?.notifications?.email || false,
                    push: engagement?.notifications?.push || false,
                    reminder: {
                      enabled:
                        engagement?.notifications?.reminder?.enabled || false,
                      minRate:
                        engagement?.notifications?.reminder?.minRate || 0,
                    },
                  },
                  recurrence: undefined,
                  createdBy: engagement?.createdBy,
                  createdAt: engagement?.createdAt
                    ? new Date(engagement?.createdAt)
                    : undefined,
                  updatedAt: engagement?.updatedAt
                    ? new Date(engagement?.updatedAt)
                    : undefined,
                  sendDate: engagement?.sendDate
                    ? new Date(engagement?.sendDate)
                    : undefined,
                }}
              />
            ) : (
              <></>
            ),
          },
        ]}
      />
    </PageContainer>
  );
};
