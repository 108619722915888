import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;

  > button:not(:last-child) {
    margin-right: 10px;
  }
`;

const LinkButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export { Container, LinkButtonContainer };
