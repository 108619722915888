import styled from "styled-components";

const StyleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const ErrorAsterisk = styled.div`
  color: ${({ theme }) => theme.colors.feedback.error[40]};
`;

const ReviewStepsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
`;

const Step = styled.div<{ selected?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: calc(100% - 15px);
  font-size: 14px;
  font-weight: 600;
  border-radius: 8px;
  padding: 8px 16px;
  cursor: pointer;
  color: ${({ selected }) =>
    selected ? "var(--color-secondary-50)" : "var(--color-neutral-50)"};
  background-color: ${({ selected }) =>
    selected ? "var(--color-secondary-95)" : "var(--color-neutral-100)"};
  border: 1px solid
    ${({ selected }) =>
      selected ? "var(--color-secondary-70)" : "var(--color-neutral-90)"};
`;

export { StyleContainer, ErrorAsterisk, ReviewStepsContainer, Step };
