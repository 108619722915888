import { Tag } from "@components/Tag";
import { Typography } from "@components/Typography";
import { Divider } from "@components/v2";
import {
  Accordion,
  Checkbox,
  Icons,
  Toggle,
} from "@flash-tecnologia/hros-web-ui-v2";
import {
  AccordionContainer,
  FlexBetween,
  FlexColumn,
  StyledBoxAdornment,
  BorderDivider,
} from "../styles";
import { segment, StyledText, StyledTitle } from "@utils";
import { CounterField } from "@components/CounterField";

const SectionNotification = ({ disabled, formik }) => {
  const { notifications } = formik?.values;

  return (
    <AccordionContainer style={{ marginBottom: "40px" }}>
      <Accordion
        customHeader={
          <>
            <Tag variant="paused" style={{ marginBottom: "16px" }}>
              Obrigatório
            </Tag>
            <Typography
              variant="headline7"
              color="var(--color-neutral-30)"
              fontWeight={700}
            >
              Notificações
            </Typography>
            <Divider spacing="quark" />
            <Typography
              variant="body4"
              color="var(--color-neutral-50)"
              fontWeight={400}
            >
              Selecione pelo menos uma opção de como deseja notificar os
              destinatários.
            </Typography>
          </>
        }
        children={
          <FlexColumn style={{ gap: "16px" }}>
            <StyledTitle
              variant="headline9"
              setColor={"neutral30"}
              style={{ marginBottom: "16px", fontWeight: 700 }}
            >
              Seleção dos canais de notificação
            </StyledTitle>
            <StyledBoxAdornment
              title={"Push do aplicativo"}
              description={
                <StyledText variant="body4" setColor="neutral50">
                  O destinatário receberá uma notificação de push do aplicativo
                  em suas notificações do celular.
                </StyledText>
              }
              error={
                formik.touched.notifications &&
                Boolean(formik.errors.notifications)
              }
              leftAdornment={
                <Checkbox
                  disabled={disabled}
                  checked={!!notifications?.push}
                  onChange={(e) => {
                    const checked = e.target.checked;

                    if (checked) {
                      segment({
                        track:
                          "people_strategic_hr_engagement_company_communication_configuration_pushnotification_selected",
                      });
                    }

                    formik.handleChange({
                      target: {
                        id: "notifications",
                        value: {
                          ...notifications,
                          push: checked,
                        },
                      },
                    });
                  }}
                />
              }
              style={{ width: "100%" }}
            />
            <StyledBoxAdornment
              title={"E-mail corporativo"}
              description={
                <StyledText variant="body4" setColor="neutral50">
                  O destinatário receberá um e-mail em seu e-mail corporativo
                  cadastrado informando que possui uma nova comunicação.
                </StyledText>
              }
              error={
                formik.touched.notifications &&
                Boolean(formik.errors.notifications)
              }
              leftAdornment={
                <Checkbox
                  disabled={disabled}
                  checked={!!notifications?.email}
                  onChange={(e) => {
                    const checked = e.target.checked;

                    if (checked) {
                      segment({
                        track:
                          "people_strategic_hr_engagement_company_communication_configuration_emailnotification_selected",
                      });
                    }

                    formik.handleChange({
                      target: {
                        id: "notifications",
                        value: {
                          ...notifications,
                          email: checked,
                        },
                      },
                    });
                  }}
                />
              }
              style={{ width: "100%" }}
            />

            <BorderDivider />

            <StyledTitle
              variant="headline9"
              setColor={"neutral30"}
              style={{
                marginBottom: "16px",
                fontWeight: 700,
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              Envio da notificação
            </StyledTitle>

            <StyledBoxAdornment
              title={"Habilitar envio de notificações automáticas"}
              description={
                <FlexColumn>
                  <div>
                    <StyledText variant="body4" setColor="neutral50">
                      Selecione esta opção para habilitar o envio de
                      notificações automáticas. Quando ativado, a cada 3 dias o
                      sistema enviará notificações aos usuários que não
                      visualizaram o comunicação até que a taxa de visualização
                      esperada seja alcançada.
                    </StyledText>
                  </div>
                  {!!notifications?.reminder?.enabled ? (
                    <>
                      <BorderDivider />
                      <FlexBetween>
                        <StyledText variant="body4" setColor="neutral50">
                          Notificar <b>a cada 3 dias</b> até atingir a taxa de
                          visualização de
                        </StyledText>
                        <CounterField
                          percentageMode
                          setIncrementAndDecrement={5}
                          disabled={
                            disabled || !notifications?.reminder?.enabled
                          }
                          value={notifications?.reminder?.minRate || 75}
                          max={100}
                          onChange={(value) => {
                            formik.handleChange({
                              target: {
                                id: "notifications",
                                value: {
                                  ...notifications,
                                  reminder: {
                                    ...(notifications?.reminder || {}),
                                    minRate: value,
                                  },
                                },
                              },
                            });
                          }}
                          min={50}
                        />
                      </FlexBetween>
                    </>
                  ) : null}
                </FlexColumn>
              }
              leftAdornment={
                <Toggle
                  disabled={disabled}
                  checked={!!notifications?.reminder?.enabled}
                  onChange={(e) => {
                    const checked = e.target.checked;

                    if (checked) {
                      segment({
                        track:
                          "people_strategic_hr_engagement_company_communcation_configuration_automaticnotifications_selected",
                      });
                    }

                    formik.handleChange({
                      target: {
                        id: "notifications",
                        value: {
                          ...notifications,
                          reminder: {
                            enabled: checked,
                            minRate: checked ? 75 : 0,
                          },
                        },
                      },
                    });
                  }}
                />
              }
              style={{ width: "100%" }}
            />
          </FlexColumn>
        }
        variant={"default"}
        defaultExpanded
      />
    </AccordionContainer>
  );
};

export { SectionNotification };
