import { MouseEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Tag,
  IconsProps,
  dayjs,
  Button,
  Icons,
} from "@flash-tecnologia/hros-web-ui-v2";
import { trpc } from "@api/client";
import {
  InteractionDeadlineModalForm,
  MenuIcon,
  Typography,
} from "../../../../components";
import { Divider, Modal } from "../../../../components/v2";
import { alert, segment } from "../../../../utils";
import {
  MainContainer,
  LeftContainer,
  RightContainer,
  StatusContainer,
} from "./styles";
import { HeaderInterface } from "./types";
import { EditOwnersModal } from "src/modals";
import { EngagementModel } from "@models";

const Header = ({
  name,
  status,
  recurrence,
  data,
  pendingCount,
  refetch,
}: HeaderInterface) => {
  const [ownersEditModal, setOwnersEditModal] = useState<{
    open: boolean;
    engagement?: EngagementModel;
  }>({
    open: false,
    engagement: undefined,
  });
  const [reminderModalVisible, setReminderModalVisible] = useState(false);
  const [reminderModalErrorVisible, setReminderModalErrorVisible] =
    useState(false);
  const [reminderJobModalVisible, setReminderJobModalVisible] = useState(false);
  const [reminderJobEnabled, setReminderJobEnabled] = useState(
    data?.notifications?.reminder?.enabled || false
  );
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState<boolean>(false);
  const [interactionDeadline, setInteractionDeadline] = useState<
    string | undefined
  >(data?.interactionDeadline);
  const navigate = useNavigate();

  const updateInteractionDeadline = trpc?.updateInteractionDeadline.useMutation(
    {
      onMutate: () => setLoading(!loading),
      onSuccess: () => {
        alert("Data atualizada com sucesso!", "success");
        setVisible(!visible);
        setLoading(!loading);
      },
      onError: (e) => {
        console.log(e);
        alert("Ocorreu um erro, tente novamente mais tarde.");
        setVisible(!visible);
        setLoading(!loading);
      },
    }
  );

  const copyEngagement = trpc.copyEngagement.useMutation({
    onSuccess: () => {
      alert("Pesquisa criada com sucesso", "success");
      navigate(`/engagement/survey`);
    },
    onError: (err) => {
      alert("Erro ao criar pesquisa, tente novamente mais tarde.");
      console.log(err);
    },
  });

  const { isLoading: reminderloading, mutate: reminder } =
    trpc.reminder.useMutation({
      onSuccess: () => {
        alert("Enviado com sucesso", "success");
        if (reminderModalVisible) setReminderModalVisible(false);
        if (reminderModalErrorVisible) setReminderModalErrorVisible(false);
      },
      onError: (err) => {
        if (err?.message?.includes("notifications.reminder.lastSentDate")) {
          // alert("O envio da notificação foi realizado há menos de 24 horas.");
          setReminderModalVisible(false);
          setReminderModalErrorVisible(true);
          return;
        }

        alert("Erro ao enviar notificações, tente novamente mais tarde.");
        console.log(err);
        setReminderModalVisible(false);
      },
    });

  const {
    isLoading: updateReminderStatusLoading,
    mutate: updateReminderStatus,
  } = trpc.updateReminderStatus.useMutation({
    onSuccess: (e) => {
      setReminderJobModalVisible(false);
      setReminderJobEnabled(false);
      alert("Atualizado com sucesso", "success");
    },
    onError: (err) => {
      alert("Erro ao atualizar notificações, tente novamente mais tarde.");
      console.log(err?.message);
      setReminderJobModalVisible(false);
    },
  });

  const deleteEngagement = trpc?.deleteEngagement.useMutation({
    onSuccess: () => {
      alert("Sua pesquisa foi excluída com sucesso.", "success");
      navigate(`/engagement/survey`);
    },
    onError: (err) => {
      alert("Erro ao excluir pesquisa, tente novamente mais tarde.");
      console.log(err);
    },
  });

  const updateRecurrenceStatus = trpc?.updateRecurrenceStatus.useMutation({
    onSuccess: () => {
      alert("Sua pesquisa foi atualizada com sucesso.", "success");
      navigate(`/engagement/survey`);
    },
    onError: (e) => {
      console.log(e);
      alert("Erro ao atualizar pesquisa, tente novamente mais tarde.");
    },
  });

  const options: Array<{
    label: string;
    icon: IconsProps["name"];
    onClick: (e: MouseEvent<any>) => void;
    disabled?: boolean;
  }> = [
    {
      label: "Duplicar pesquisa",
      icon: "IconCopy",
      onClick: () => {
        if (data?._id) {
          segment({
            track: `people_strategic_hr_engagement_company_surveys_duplicatesurvey`,
          });
          copyEngagement.mutate({ id: data?._id });
        }
      },
    },
    {
      label: "Editar prazo de resposta",
      icon: "IconCalendarStats",
      disabled:
        data?.interactionDeadline &&
        dayjs(dayjs()?.format("YYYY-MM-DD")).diff(data?.interactionDeadline) > 0
          ? true
          : false,
      onClick: () => setVisible(!visible),
    },
    {
      label: "Editar responsáveis",
      icon: "IconCheckupList",
      onClick: () => {
        setOwnersEditModal({
          open: true,
          engagement: data,
        });
      },
    },
    {
      label: "Enviar notificação",
      icon: "IconBell",
      disabled: recurrence ? true : false,
      onClick: () => {
        if (data?._id) {
          const today = dayjs()?.locale("pt-br");
          const twentyFourHoursAgo = today?.subtract(24, "hour");
          const notificationDate =
            data?.notifications?.reminder?.lastSentDate || data?.sendDate;

          if (notificationDate) {
            if (twentyFourHoursAgo?.diff(notificationDate) < 0) {
              setReminderModalErrorVisible(!reminderModalErrorVisible);
            } else {
              setReminderModalVisible(!reminderModalVisible);
            }
          }
        }
      },
    },
    {
      label: "Parar notificações automáticas",
      icon: "IconBellX",
      disabled: recurrence || !reminderJobEnabled ? true : false,
      onClick: () => {
        if (data?._id) setReminderJobModalVisible(!reminderJobModalVisible);
      },
    },
    {
      label: "Editar pesquisa",
      icon: "IconPencil",
      disabled:
        (data?.status && ["draft", "scheduled"]?.includes(data?.status)) ||
        (data?.recurrence?.status && data?.recurrence?.status !== "closed")
          ? false
          : true,
      onClick: () => {
        navigate(`/engagement/survey/edit/${data?._id}`);
      },
    },
    {
      label: "Excluir pesquisa",
      icon: "IconTrash",
      disabled:
        data?.status && ["draft", "scheduled"]?.includes(data?.status)
          ? false
          : true,
      onClick: () => {
        if (data?._id) deleteEngagement.mutate({ id: data?._id });
      },
    },
  ];

  if (recurrence && data?.status !== "draft") {
    options?.push({
      label: "Pausar pesquisa",
      icon: "IconCopy",
      disabled: data?.recurrence?.status === "progress" ? false : true,
      onClick: () => {
        if (data?._id) {
          segment({
            track: `people_strategic_hr_engagement_company_surveys_pausesurvey`,
          });
          updateRecurrenceStatus.mutate({ id: data?._id, status: "paused" });
        }
      },
    });
    options?.push({
      label: "Retomar pesquisa",
      icon: "IconCopy",
      disabled: data?.recurrence?.status === "paused" ? false : true,
      onClick: () => {
        if (data?._id)
          updateRecurrenceStatus.mutate({ id: data?._id, status: "progress" });
      },
    });
    options?.push({
      label: "Encerrar pesquisa",
      icon: "IconCopy",
      disabled: ["progress", "paused", "scheduled"]?.includes(
        data?.recurrence?.status || ""
      )
        ? false
        : true,
      onClick: () => {
        if (data?._id) {
          segment({
            track: "people_strategic_hr_engagement_company_surveys_closesurvey",
          });
          updateRecurrenceStatus.mutate({ id: data?._id, status: "closed" });
        }
      },
    });
  }

  const allStatus = {
    draft: "Rascunho",
    scheduled: "Agendado",
    send: "Enviado",
    progress: "Em andamento",
    paused: "Pausado",
    closed: "Encerrado",
  };

  return (
    <MainContainer>
      <InteractionDeadlineModalForm
        loading={loading}
        hideMode
        visible={visible}
        value={interactionDeadline}
        onChange={(e) => {
          setInteractionDeadline(e);
        }}
        onClick={() => setVisible(!visible)}
        onClose={() => setVisible(!visible)}
        onFinish={() => {
          if (data?._id && interactionDeadline) {
            updateInteractionDeadline.mutate({
              id: data?._id,
              interactionDeadline,
            });
          }
        }}
      />
      <LeftContainer>
        <Typography
          variant="headline6"
          fontWeight={700}
          color="var(--color-neutral-20)"
        >
          {name}
        </Typography>
        {status && allStatus[status] ? (
          <>
            <Divider type="horizontal" spacing="xxs3" />
            <StatusContainer>
              {recurrence ? (
                <>
                  <Tag variant="gray">Envio recorrente</Tag>
                  <Divider type="horizontal" spacing="xxxs" />
                </>
              ) : null}
              <Tag
                variant={
                  status === "draft" || status === "paused"
                    ? "yellow"
                    : status === "send" || status === "progress"
                    ? "success"
                    : "gray"
                }
              >
                {allStatus[status]}
              </Tag>
            </StatusContainer>
          </>
        ) : null}
      </LeftContainer>
      <RightContainer>
        {/* <Button
          variant="primary"
          size="medium"
          disabled={new Date() < new Date(2024, 7, 13)}
        >
          Análise comparativa
          <Icons name="IconWorldDownload" />
        </Button>
        <Button variant="primary" size="medium">
          Visualizar pesquisa
          <Icons name="IconExternalLink" />
        </Button> */}

        <MenuIcon options={options} />
      </RightContainer>
      <Modal
        open={reminderModalVisible}
        onClose={() => setReminderModalVisible(!reminderModalVisible)}
        title="Atenção!"
        subTitle={`Tem certeza que deseja <br/> enviar a notificação <br/> novamente?`}
        description={`${
          pendingCount || 0
        } pessoas que ainda não responderam esta pesquisa serão notificadas.`}
        iconStatus="alert"
        closeButton
        iconName="IconAlertCircle"
        footer={{
          closeButton: {
            label: "Não enviar",
            onClick: () => setReminderModalVisible(!reminderModalVisible),
            variant: "neutral",
          },
          submitButton: {
            label: "Enviar notificação",
            loading: reminderloading || false,
            onClick: () => {
              reminder({ engagementId: data?._id || "" });
            },
            variant: "secondary",
            variantType: "error",
          },
        }}
      />
      <Modal
        open={reminderModalErrorVisible}
        onClose={() => setReminderModalErrorVisible(!reminderModalErrorVisible)}
        title="Atenção!"
        subTitle={`O envio da notificação foi <br/> realizado há menos de 24 <br/> horas.`}
        description="Notificar os colaboradores em um curto espaço de tempo <br/> pode gerar incômodo e prejudicar o engajamento da sua <br/> pesquisa."
        iconStatus="alert"
        closeButton
        iconName="IconAlertCircle"
        footer={{
          closeButton: {
            label: "Não enviar",
            onClick: () =>
              setReminderModalErrorVisible(!reminderModalErrorVisible),
            variant: "neutral",
          },
          submitButton: {
            label: "Notificar mesmo assim",
            loading: reminderloading || false,
            onClick: () => {
              reminder({ engagementId: data?._id || "", force: true });
            },
            variant: "secondary",
            variantType: "error",
          },
        }}
      />
      <Modal
        open={reminderJobModalVisible}
        onClose={() => setReminderJobModalVisible(!reminderJobModalVisible)}
        title="Atenção!"
        subTitle={`Tem certeza que deseja parar <br/> o envio automático de <br/> notificações?`}
        description={`${
          pendingCount || 0
        } pessoas ainda não responderam <br/> esta pesquisa e não serão mais notificadas.`}
        iconStatus="alert"
        closeButton
        iconName="IconAlertCircle"
        footer={{
          closeButton: {
            label: "Não enviar",
            onClick: () => setReminderJobModalVisible(!reminderJobModalVisible),
            variant: "neutral",
          },
          submitButton: {
            label: "Notificar mesmo assim",
            loading: updateReminderStatusLoading || false,
            onClick: () => {
              updateReminderStatus({ engagementId: data?._id || "" });
            },
            variant: "secondary",
            variantType: "error",
          },
        }}
      />
      <EditOwnersModal
        open={ownersEditModal?.open}
        engagement={ownersEditModal?.engagement}
        onClose={() =>
          setOwnersEditModal({
            open: false,
            engagement: undefined,
          })
        }
        onSuccess={() => refetch()}
      />
    </MainContainer>
  );
};

export { Header };
