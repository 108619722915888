import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  width: 321px;
  height: 300px;
  padding: 40px 24px;
  border-radius: 12px;
  border: 1px solid var(--color-neutral-90);
`;

const ContentContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  width: 100%;
`;

const SubtitleContainer = styled.div`
  width: 184px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  width: 100%;
  padding-top: 24px;

  button {
    width: 100% !important;
  }
`;

const IconsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: flex-start;
`;

export {
  Container,
  ContentContainer,
  SubtitleContainer,
  ButtonsContainer,
  IconsWrapper,
};
