import { Icons } from "@flash-tecnologia/hros-web-ui-v2";
import { Typography } from "../../../../../..";
import {
  Container,
  LeftContainer,
  NumberContainer,
  RightContainer,
} from "./styles";
import { MiniSectionInterface } from "./types";

const MiniSection = ({
  title,
  totalQuestions,
  isDragging,
  selected,
  index,
  disabled,
  onClick,
}: MiniSectionInterface) => {
  return (
    <Container
      isDragging={disabled ? false : isDragging}
      selected={selected}
      onClick={onClick}
    >
      <LeftContainer>
        <Icons
          name="IconGripVertical"
          color={
            isDragging || selected
              ? "var(--color-primary)"
              : disabled
              ? "var(--color-neutral-95)"
              : "var(--color-neutral-80)"
          }
          style={{ marginRight: "8px" }}
        />
        <Typography
          variant="body4"
          color={
            isDragging || selected
              ? "var(--color-primary)"
              : "var(--color-neutral-50)"
          }
        >
          {`${
            title
              ? title?.length > 22
                ? `${title?.slice(0, 22)}...`
                : title
              : "Seção sem título"
          }`}
        </Typography>
      </LeftContainer>
      <RightContainer>
        <NumberContainer isSelected={isDragging || selected}>
          <Typography
            variant="caption"
            color={
              isDragging || selected
                ? "var(--color-primary)"
                : "var(--color-neutral-10)"
            }
          >
            {totalQuestions || "0"}
          </Typography>
        </NumberContainer>
      </RightContainer>
    </Container>
  );
};

export { MiniSection };
