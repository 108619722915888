import styled from "styled-components";

const TextContainer = styled.div`
  position: absolute;
  top: 125px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
`;

const LeftLabel = styled.div`
  position: absolute;
  bottom: -25px;
  left: 0;
  min-width: 35px !important;
  max-width: 35px !important;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const RightLabel = styled.div`
  position: absolute;
  bottom: -25px;
  right: 0;
  min-width: 35px !important;
  max-width: 35px !important;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Pointer = styled.div<{ value: number }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  height: 60px;
  width: 4px;
  background-color: var(--color-neutral-80);
  border-radius: 100px;
  transform-origin: bottom;
  transform: rotate(${({ value }) => `${value}deg`});
`;

const PointerLeftBorder = styled.div`
  height: 60px;
  width: 5px;
  background-color: var(--color-neutral-80);
  transform: rotate(4deg);
  margin-left: -2px;
  border-radius: 100px;
`;

const PointerRightBorder = styled.div`
  height: 60px;
  width: 5px;
  background-color: var(--color-neutral-80);
  transform: rotate(-4deg);
  margin-right: -2px;
  border-radius: 100px;
`;

const Axle = styled.div`
  position: absolute;
  bottom: -10px;
  left: calc(50% - 10px);
  padding: 5px;
  background-color: var(--color-neutral-80);
  border-radius: 100%;
  border: 5px solid var(--color-neutral-30);
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-width: 270px;
  min-height: 270px;
  max-height: 270px;
  max-width: 270px;
  border-radius: 100%;
  overflow: hidden;
`;

const Bar = styled.div`
  position: absolute;
  width: 270px;
  height: 270px;
  border-radius: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
`;

const ThirdColumn = styled.div`
  position: absolute;
  top: 25px;
  left: 54px;
  background-color: rgb(106, 160, 29);
  height: 45px;
  width: 38%;
  transform: rotate(30deg);
  border-left: 3px solid white;
  z-index: 1 !important;

  :before {
    content: "";
    position: absolute;
    bottom: -23px;
    right: -20px;
    transform: rotate(29deg);
    background-color: rgb(106, 160, 29);
    height: 100px;
    width: 35px;
    border-right: 3px solid white;
  }
`;

const Column = styled.div<{
  width: string;
  bgColor: string;
  height: string;
}>`
  position: relative;
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  background-color: ${({ bgColor }) => bgColor};
`;

const Origin = styled.div`
  position: relative;
  height: 200px;
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
`;

const Content = styled.div`
  position: relative;
  height: 200px;
  width: 200px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: white;
  z-index: 2 !important;
  padding-bottom: 60px;
`;

export {
  Container,
  Origin,
  Content,
  Bar,
  Column,
  ThirdColumn,
  Pointer,
  PointerLeftBorder,
  PointerRightBorder,
  Axle,
  LeftLabel,
  RightLabel,
  TextContainer,
};
