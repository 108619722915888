import { useMemo } from "react";
import { useNavigate, NavigateOptions } from "react-router-dom";

import {
  IconButton,
  Icons,
  LinkButton,
} from "@flash-tecnologia/hros-web-ui-v2";

import {
  Container,
  LeftContainer,
  StyledBreadcrumbs,
  StyledStepper,
} from "./styled";

interface RoutesProps {
  route?: string;
  label: string;
  options?: NavigateOptions;
}

interface StepperProps {
  steps: string[];
  activeStep: number;
}

interface HeaderProps {
  routes: RoutesProps[];
  stepper?: StepperProps;
  containerHeaderStyle?: any;
  customContainer?: any;
  isNotMobile?: boolean;
}

export const Header = ({
  routes,
  stepper,
  containerHeaderStyle,
  customContainer,
  isNotMobile,
}: HeaderProps) => {
  const navigate = useNavigate();

  const breadCrumbs = useMemo(
    () =>
      routes.map(({ label, route, options }) => {
        return (
          <LinkButton
            key={label}
            variant="neutral"
            onClick={() => route && navigate(route, options)}
          >
            {label}
          </LinkButton>
        );
      }),
    [routes]
  );

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Container style={containerHeaderStyle}>
        <LeftContainer>
          <IconButton
            variant={"line"}
            size={"small"}
            icon="IconArrowLeft"
            onClick={() => navigate(-1)}
          />

          <StyledBreadcrumbs
            separator={<Icons name="IconChevronRight" fill="transparent" />}
            breadcrumbs={breadCrumbs}
          />
        </LeftContainer>
        {isNotMobile ? (
          <StyledStepper
            steps={stepper?.steps || []}
            activeStep={stepper?.activeStep}
          />
        ) : (
          <></>
        )}
      </Container>
      {customContainer}
    </div>
  );
};
