import { useEffect, useState } from "react";

import { dispatchToast, uploadFile } from "@utils";
import { PreviewLoading } from "./PreviewLoading";
import { UploadPreview } from "./UploadPreview";

import { trpc } from "@api/client";
import { StyledDropZone } from "./styles";

interface UploadProps {
  title: string;
  value?: any;
  infoContent: any;
  folder: string;
  onUpload?: (file: any) => Promise<void>;
  onRemove?: (file: any) => Promise<void>;
  canDelete?: boolean;
  canDownload?: boolean;
  accept: string[];
  maxSize?: number;
  onFileChange?: (data: any) => void;
}

export const Upload = ({
  title,
  infoContent,
  value,
  accept,
  folder,
  canDelete = true,
  canDownload = true,
  onUpload,
  onRemove,
  onFileChange,
}: UploadProps) => {
  const [localFile, setLocalFile] = useState<any>();

  useEffect(() => {
    if (value) setLocalFile(value);
  }, [value]);

  const { mutateAsync } = trpc.deleteS3File.useMutation({
    onError: (e: any) => {
      alert("Erro ao deletar o arquivo, tente novamente mais tarde.");
    },
  });

  const handleRemove = async (file: any) => {
    setLocalFile(undefined);
    await mutateAsync({ path: file?.path });

    onRemove && (await onRemove(file));
    return;
  };

  const handleChange = async (files) => {
    try {
      const { file, id } = files[0];
      const fileName = id
        ?.normalize("NFD")
        ?.replace(/[\u0300-\u036f]/g, "")
        ?.toLowerCase();

      const uploadedFile = await uploadFile({ file, fileName, folder });

      setLocalFile(uploadedFile);
      onUpload && (await onUpload(uploadedFile));
    } catch (err) {
      dispatchToast({
        content: "Algo aconteceu ao fazer o upload do arquivo.",
        type: "error",
      });
    }
  };

  if (localFile)
    return (
      <UploadPreview
        file={localFile}
        canDownload={canDownload}
        handleRemove={canDelete ? handleRemove : undefined}
        onFileChange={(e) => (onFileChange ? onFileChange(e) : null)}
      />
    );

  return (
    <StyledDropZone
      accept={accept}
      title={title}
      infoContent={infoContent}
      multiple={false}
      onChange={handleChange}
      style={{ width: "100%" }}
      customPreview={() => <PreviewLoading />}
      maxSize={10 * 1024 * 1024}
      onFileSizeError={() => {
        dispatchToast({
          content:
            "Este arquivo ultrapassa o limite máximo. Por favor, faça o upload de um arquivo menor.",
          type: "error",
        });
      }}
    />
  );
};
