import { TransferList as TransferListUi } from "@flash-tecnologia/hros-web-ui-v2";
import { Avatar } from "../index";
import { TransferListInterface, HandleCheckInterface } from "./types";

const TransferList = ({
  leftList,
  rightList,
  disabled,
  showUserWithoutCorporateEmail,
}: TransferListInterface) => {
  const handleCheck = ({ list, data, allChecked }: HandleCheckInterface) => {
    if (data?.length) {
      const newData = [
        ...data?.map((row) => ({
          ...row,
          checked:
            allChecked !== undefined && !row?.hidden
              ? allChecked
              : !!row?.checked,
        })),
      ];

      list === "left"
        ? leftList?.setData
          ? leftList?.setData([...newData])
          : null
        : rightList?.setData
        ? rightList?.setData([...newData])
        : null;
    }
  };

  return (
    <TransferListUi
      disabled={disabled}
      columns={[
        {
          Header: "Nome",
          accessor: "name",
          disableSortBy: true,
          Cell: ({ row }: any) => {
            const employee = row?.original;
            const { name, profilePicture, corporateEmail } = employee;
            const corpEmail = corporateEmail
              ? corporateEmail
              : showUserWithoutCorporateEmail
              ? " "
              : undefined;

            const image = profilePicture?.path || "";

            return (
              <Avatar
                name={name}
                email={corpEmail}
                image={image}
                showEmptyStateMessage
              />
            );
          },
        },
      ]}
      leftList={{
        ...leftList,
        onCheck: (result) => handleCheck({ list: "left", ...result }),
        onSearch: (result) =>
          leftList?.onSearch ? leftList?.onSearch(result) : null,
        onScroll: (allChecked) =>
          leftList?.onScroll ? leftList?.onScroll(allChecked, false) : null,
      }}
      rightList={{
        ...rightList,
        onCheck: (result) => handleCheck({ list: "right", ...result }),
        onSearch: (result) =>
          rightList?.onSearch ? rightList?.onSearch(result) : null,
        onScroll: (allChecked) =>
          rightList?.onScroll ? rightList?.onScroll(allChecked, false) : null,
      }}
      onTransfer={(dataTables) => {
        const { leftList: leftListResult, rightList: rightListResult } =
          dataTables;

        leftList?.setData ? leftList?.setData([...leftListResult?.data]) : null;
        rightList?.setData
          ? rightList?.setData([...rightListResult?.data])
          : null;

        if (
          leftList?.data?.length > leftListResult?.data?.length &&
          !leftListResult?.allChecked &&
          leftList?.onScroll &&
          leftListResult?.data?.length < 10
        )
          leftList?.onScroll(false, false);

        if (
          rightList?.data?.length > rightListResult?.data?.length &&
          !rightListResult?.allChecked &&
          rightListResult?.data?.length < 10 &&
          rightList?.onScroll
        )
          rightList?.onScroll(false, false);
      }}
    />
  );
};

export { TransferList };
