import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 20px 30px;
  font-weight: 700;
  line-height: 22px;
  border-radius: 10px;
  color: var(--color-neutral-dark2);
  background-color: var(--color-secondary-light1);
  border: 1px solid var(--color-secondary-light4);

  &:hover {
    border-color: var(--color-primary);
  }

  .dropzone-file-item-content {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: calc(100% - 50px);
  }

  .dropzone-file-item-name-container {
    max-width: calc(100% - 100px);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-weight: 600;
  }

  .dropzone-file-item-image {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    width: 71px;
    height: 48px;
    border: 1px solid transparent;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-right: 10px;
  }

  .dropzone-file-item-icon-container {
    border: 1px solid var(--color-secondary-light4);
    padding: 10px 10px 7px 10px;
    border-radius: 30px;
    cursor: pointer;

    &:hover {
      border-color: var(--color-primary);
    }
  }
`;

export { Container };
