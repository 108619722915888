import { CommunicationForm } from "./communication";
import { SurveyForm } from "./survey";
import { FormInterface } from "./types";

const Form = ({ id, type, forceEngaja }: FormInterface) =>
  type === "communication" ? (
    <CommunicationForm id={id} />
  ) : (
    <SurveyForm id={id} forceEngaja={forceEngaja || false} />
  );

export { Form };
