import styled from "styled-components";

const MainContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  border-bottom: none;
  width: 100%;
  z-index: 3 !important;
  padding: 20px 25px;
  box-shadow: 0px 4px 26px rgba(0, 0, 0, 0.1);
`;

const Container = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
`;

const RightContainer = styled(Container)`
  justify-content: flex-end;
`;

export { MainContainer, Container, RightContainer };
