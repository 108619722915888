import {
  Accordion,
  Checkbox,
  SearchField,
  LinkButton,
} from "@flash-tecnologia/hros-web-ui-v2";
import { Typography } from "../../../../../Typography";
import {
  Container,
  LinkButtonContainer,
  OptionContainer,
  OptionList,
} from "./styles";
import { SectionInterface } from "./types";

const Section = ({
  id,
  title = "",
  search = "",
  options,
  onChange,
}: SectionInterface) => {
  const handleChange = (optionValue: string, checked: boolean) =>
    onChange({
      id,
      title,
      search,
      options: options?.map((o) =>
        optionValue === o?.value ? { ...o, checked: checked ? false : true } : o
      ),
    });

  const handleSearch = (searchValue: string) => {
    onChange({
      id,
      title,
      options,
      search: searchValue || "",
    });
  };

  return (
    <Container>
      <Accordion variant="default" defaultExpanded title={title}>
        <SearchField
          label="Buscar"
          value={search}
          onChange={(e) => handleSearch(e?.target?.value || "")}
        />
        <OptionContainer
          style={{
            margin: "16px 0",
            padding: "5px 0",
            borderTop: "1px solid var(--color-neutral-90)",
            borderBottom: "1px solid var(--color-neutral-90)",
          }}
        >
          <Checkbox
            onChange={() =>
              onChange({
                id,
                title,
                search,
                options: search?.length
                  ? options?.map((o) => ({ ...o, checked: true }))
                  : options?.map((o) => ({ ...o, checked: true })),
              })
            }
            checked={
              options?.length ===
                options?.filter(({ checked }) => checked)?.length &&
              options?.length
                ? true
                : false
            }
          />
          <Typography
            variant="body3"
            fontWeight={600}
            color="var(--color-neutral-30)"
          >
            Selecionar todos
          </Typography>
        </OptionContainer>
        <OptionList>
          {options
            ?.filter((o) => o?.title?.match(RegExp(search, "ig")))
            ?.map(({ title, value, checked }, i) => (
              <OptionContainer key={`transfer-list-option-${value}-${i}`}>
                <Checkbox
                  checked={checked || false}
                  onChange={() => handleChange(value, checked)}
                />
                <Typography
                  variant="body3"
                  fontWeight={600}
                  color="var(--color-neutral-30)"
                >
                  {title}
                </Typography>
              </OptionContainer>
            ))}
        </OptionList>
        <LinkButtonContainer>
          <LinkButton
            variant="default"
            onClick={() =>
              onChange({
                id,
                title,
                search,
                options: options?.map((o) => ({ ...o, checked: false })),
              })
            }
          >
            Limpar seleção
          </LinkButton>
        </LinkButtonContainer>
      </Accordion>
    </Container>
  );
};

export { Section };
