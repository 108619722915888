import { removeHtmlTags } from "@utils";
import * as yup from "yup";

const validationSchema = {
  0: yup.object({
    name: yup.string().max(50).required("Favor digitar o nome"),
    title: yup.string().required("Favor digitar o título"),
    message: yup
      .string()
      .test("empty text editor", "Favor digitar a mensagem", (val) =>
        Boolean(removeHtmlTags(val).trim().length)
      )
      .required("Favor digitar a mensagem"),
    imageUrl: yup.mixed().notRequired(),
    showSectionLink: yup.boolean().notRequired(),
    link: yup.object().when("showSectionLink", {
      is: (val) => val,
      then: () =>
        yup.object().shape({
          label: yup.string().required("Favor digitar o texto do link"),
          url: yup.string().required("Favor digitar a url do link"),
        }),
      otherwise: (schema) => schema.notRequired(),
    }),
    showPdfLink: yup.boolean().notRequired(),
    pdfFile: yup.mixed().when("showPdfLink", {
      is: (val) => val,
      then: (schema) => schema.required("Favor fazer o upload do pdf"),
      otherwise: (schema) => schema.notRequired(),
    }),
  }),
  2: yup.object({
    like: yup.boolean().notRequired(),
    notifications: yup
      .object({
        push: yup.boolean().notRequired(),
        email: yup.boolean().notRequired(),
        reminder: yup.object({
          enabled: yup.boolean(),
          minRate: yup.number(),
        }),
      })
      .test(
        "notification test",
        "Favor selecionar ao menos um modo de envio",
        (val) => Boolean(val.push || val.email)
      )
      .required("Favor selecionar ao menos um modo de envio"),
    owners: yup
      .array()
      .of(yup.mixed())
      .when("responsibilityMode", {
        is: "specific",
        then: yup
          .array()
          .of(yup.mixed())
          .required(
            "É necessário adicionar pelo menos um responsável além do criador da campanha antes de continuar."
          ),
      }),
  }),
  3: yup.object({
    employees: yup.array().of(yup.mixed()).min(1),
  }),
  1: yup.object({
    recurrenceMode: yup
      .boolean()
      .test("recurrence mode test", "Favor selecionar modo de envio", (val) =>
        Boolean(val != undefined)
      )
      .required("Favor selecionar modo de envio"),
    recurrence: yup.object().when("recurrenceMode", {
      is: (val) => val,
      then: () =>
        yup
          .object({
            recurrenceMode: yup.boolean(),
            frequency: yup.string().required("Favor selecionar a frequência"),
            daysOfTheWeek: yup
              .array()
              .of(yup.string())
              .when("frequency", {
                is: (val) => val == "week",
                then: (schema) => schema.min(1),
                otherwise: (schema) => schema.min(0),
              }),
            repeat: yup
              .mixed()
              .required("Favor selecionar o número de repetição"),
            endType: yup
              .string()
              .required("Favor selecionar o fim da recorrência"),
            occurrenceLimit: yup.number(),
            endDate: yup.string().when("endType", {
              is: (val) => val == "date",
              then: (schema) =>
                schema.required("Favor selecionar uma data de término válida"),
              otherwise: (schema) => schema.notRequired(),
            }),
          })
          .required(),
    }),
    startDate: yup
      .object({
        date: yup.string().required("Favor selecionar a data de envio"),
        period: yup.string().required("Favor selecionar o período"),
      })
      .required(),
  }),
};

export { validationSchema };
