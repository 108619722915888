import { Link } from "react-router-dom";
import {
  Breadcrumbs,
  Icons,
  Tag,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";
import { MenuIcon } from "@components/MenuIcon";
import { Container, TitleContainer, ContentContainer } from "./styles";
import { HeaderInteface } from "./types";

export default ({ id, title, status, options }: HeaderInteface) => (
  <Container>
    <Breadcrumbs
      separator={<Icons name="IconChevronRight" />}
      breadcrumbs={[
        <Link to={"/engagement/survey"}>Gerenciar pesquisas</Link>,
        <Link to={`/engagement/survey/engaja/${id}`}>Detalhe da pesquisa</Link>,
      ]}
    />
    <ContentContainer>
      <TitleContainer>
        <Typography variant="headline6" color="var(--color-neutral-20)">
          {title}
        </Typography>
        <Tag
          variant={
            status === "send"
              ? "green"
              : status === "scheduled"
              ? "blue"
              : "gray"
          }
        >
          {
            {
              draft: "Rascunho",
              send: "Enviado",
              scheduled: "Agendado",
            }[status]
          }
        </Tag>
      </TitleContainer>
      {options && options?.length ? <MenuIcon options={options} /> : null}
    </ContentContainer>
  </Container>
);
