import { useEffect, useState } from "react";

import { Skeleton } from "@mui/material";

import { IconButton, Typography } from "@flash-tecnologia/hros-web-ui-v2";

import { trpc } from "@api/client";
import FileIcon from "../../../assets/file-icon.png";
import { PreviewContainer, PreviewImage } from "./styles";
interface UploadPreviewProps {
  variant?: string;
  file: any;
  canDownload?: boolean;
  handleRemove?: (file: any) => void;
  onFileChange?: (data: any) => void;
}

export const UploadPreview = ({
  variant = "default",
  file,
  canDownload = true,
  handleRemove,
  onFileChange,
}: UploadPreviewProps) => {
  const [isRemoving, setIsRemoving] = useState<boolean>(false);

  const lastIndexName = file.path?.lastIndexOf("/") || "";
  const nameOfArchive =
    file.path && lastIndexName
      ? file.path.substring(lastIndexName + 1)
      : "Nome não identificado";

  const { data, isFetching: isGettingFile } = trpc.getS3File.useQuery(
    { path: file.path },
    {
      enabled: !!!file?.signedUrl && !!file?.path,
      retry: 1,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (onFileChange) {
      if (data) return onFileChange(data);
    }
  }, [data]);

  if (isRemoving || isGettingFile)
    return <Skeleton variant="rectangular" width="100%" height="114px" />;

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <PreviewContainer variant={variant}>
        <PreviewImage
          src={data || file?.signedUrl}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src = FileIcon;
          }}
        />

        <Typography
          variant="body4"
          variantColor="#53464F"
          style={{
            width: "100%",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {nameOfArchive}
        </Typography>

        <div style={{ display: "flex" }}>
          {handleRemove && (
            <IconButton
              size="medium"
              variant="line"
              icon="IconTrash"
              onClick={async (e) => {
                e.stopPropagation();
                e.preventDefault();

                setIsRemoving(true);

                await handleRemove(file);

                onFileChange ? onFileChange(false) : null;
                setIsRemoving(false);
              }}
              style={{ marginRight: "10px" }}
            />
          )}

          {canDownload ? (
            <IconButton
              size="medium"
              variant="line"
              icon="IconDownload"
              onClick={async () => {
                if (!data && !!!file?.signedUrl) return;
                const url = data || file?.signedUrl;
                const readFile = await fetch(url, {
                  mode: "cors",
                  cache: "no-cache",
                  credentials: "same-origin",
                });
                const fileToArrayBuffer = await readFile.arrayBuffer();
                const res = URL.createObjectURL(new Blob([fileToArrayBuffer]));
                const a = document.createElement("a");
                a.href = res;
                a.download = nameOfArchive;
                a.click();
              }}
            />
          ) : (
            <></>
          )}
        </div>
      </PreviewContainer>
    </div>
  );
};
