import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 236px;
  border: 1px solid var(--color-neutral-90);
  border-radius: 12px;
  flex-direction: column;
`;

export { Container };
