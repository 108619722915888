import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;

  .step-config-accordion,
  .step-config-box-adornment {
    width: 100% !important;
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  width: 100%;
`;

const Column = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 24px;
`;

export { Container, Row, Column };
