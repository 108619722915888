import { Icons } from "@flash-tecnologia/hros-web-ui-v2";
import { Typography, LineSeparator, GaugeChart } from "..";
import { Divider } from "../v2";
import {
  ContentContainer,
  Thermometer,
  RedContainer,
  GreenContainer,
  YellowContainer,
  Row,
  Container,
  ChartRow,
} from "./styles";
import { ENPSChartsInterface } from "./types";

const ENPSCharts = ({
  detractors = 0,
  neutrals = 0,
  promoters = 0,
  score = 0,
  title,
}: ENPSChartsInterface) => {
  const total = detractors + neutrals + promoters;

  return (
    <Container>
      {title ? (
        <>
          <Typography
            variant="headline8"
            color="var(--color-neutral-30)"
            fontWeight={700}
          >
            {title}
          </Typography>
          <Divider spacing="xxs3" />
        </>
      ) : null}
      <ChartRow>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "center",
            width: "50%",
          }}
        >
          <ContentContainer>
            <Row>
              <Typography
                variant="body4"
                color="var(--color-neutral-30)"
                fontWeight={700}
                style={{ marginRight: 5 }}
              >
                Total:
              </Typography>
              <Typography
                variant="body4"
                color="var(--color-neutral-30)"
                fontWeight={400}
              >
                {`${total || 0} ${total > 1 ? "respostas" : "resposta"}`}
              </Typography>
            </Row>
            <div
              style={{
                overflow: "hidden",
                borderRadius: "12px",
                width: "100%",
              }}
            >
              {detractors + neutrals + promoters === 0 ? (
                <Thermometer width={0} color="var(--color-neutral-80)" />
              ) : (
                <Thermometer>
                  <RedContainer
                    style={{ width: `${(detractors * 100) / total}%` }}
                  >
                    <Thermometer width={100} color="#F45A55" />
                  </RedContainer>
                  <YellowContainer
                    style={{ width: `${(neutrals * 100) / total}%` }}
                  >
                    <Thermometer width={100} color="#F8B81C" />
                  </YellowContainer>
                  <GreenContainer
                    style={{ width: `${(promoters * 100) / total}%` }}
                  >
                    <Thermometer width={100} color="#6AA01D" />
                  </GreenContainer>
                </Thermometer>
              )}
            </div>
            <Divider spacing="xxs3" />
            <LineSeparator />
            <Divider spacing="xxs3" />
            <Row>
              <Typography
                variant="body4"
                fontWeight={700}
                color="var(--color-neutral-30)"
                minWidth="180px"
              >
                Detratores (0-6)
              </Typography>
              <Row style={{ width: "180px" }}>
                <Typography
                  variant="body4"
                  fontWeight={600}
                  color="var(--color-neutral-30)"
                >
                  {detractors || 0}
                </Typography>
                <Typography
                  variant="body4"
                  fontWeight={400}
                  color="var(--color-neutral-30)"
                >
                  {`(${
                    detractors ? Math.round((detractors * 100) / total) : 0
                  }%)`}
                </Typography>
              </Row>
              <Thermometer width={(detractors * 100) / total} color="#F45A55" />
            </Row>
            <Divider spacing="xxs3" />
            <LineSeparator />
            <Divider spacing="xxs3" />
            <Row>
              <Typography
                variant="body4"
                fontWeight={700}
                color="var(--color-neutral-30)"
                minWidth="180px"
              >
                Neutros (7-8)
              </Typography>
              <Row style={{ width: "180px" }}>
                <Typography
                  variant="body4"
                  fontWeight={600}
                  color="var(--color-neutral-30)"
                >
                  {neutrals || 0}
                </Typography>
                <Typography
                  variant="body4"
                  fontWeight={400}
                  color="var(--color-neutral-30)"
                >
                  {`(${neutrals ? Math.round((neutrals * 100) / total) : 0}%)`}
                </Typography>
              </Row>
              <Thermometer width={(neutrals * 100) / total} color="#F8B81C" />
            </Row>
            <Divider spacing="xxs3" />
            <LineSeparator />
            <Divider spacing="xxs3" />
            <Row>
              <Typography
                variant="body4"
                fontWeight={700}
                color="var(--color-neutral-30)"
                minWidth="180px"
              >
                Promotores (9-10)
              </Typography>
              <Row style={{ width: "180px" }}>
                <Typography
                  variant="body4"
                  fontWeight={600}
                  color="var(--color-neutral-30)"
                >
                  {promoters || 0}
                </Typography>
                <Typography
                  variant="body4"
                  fontWeight={400}
                  color="var(--color-neutral-30)"
                >
                  {`(${
                    promoters ? Math.round((promoters * 100) / total) : 0
                  }%)`}
                </Typography>
              </Row>
              <Thermometer width={(promoters * 100) / total} color="#6AA01D" />
            </Row>
          </ContentContainer>
          <Divider spacing="xxxs" />
          <Row>
            <Icons name="IconInfoCircle" color="var(--color-neutral-30)" />
            <Divider spacing="xxxs" type="horizontal" />
            <Typography
              variant="body3"
              fontWeight={600}
              color="var(--color-neutral-30)"
            >
              {"Pontuação eNPS = % Promotores – % Detratores"}
            </Typography>
          </Row>
        </div>
        <div
          style={{
            width: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <GaugeChart value={score} />
        </div>
      </ChartRow>
    </Container>
  );
};

export { ENPSCharts };
