import styled from "styled-components";

const Container = styled.div`
  min-height: 10px;
  max-height: 400px;
  width: 100%;
  overflow: auto;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
`;

const QuestionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid var(--color-neutral-90);
  padding: 20px 0;
`;

const QuestionCol = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
`;

export { Container, QuestionContainer, QuestionCol };
