import styled from "styled-components";
import { Typography } from "@flash-tecnologia/hros-web-ui-v2";

const MainContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  border: 1px solid var(--color-neutral-light4);
  border-radius: 8px;
  width: 100%;
  padding: 10px 14px 10px 14px;
`;

const Label = styled(Typography)`
  color: var(--color-neutral-dark3);
  font-weight: 400;
  margin: 0 10px;
  min-width: max-content;
`;

export { MainContainer, Label };
