import styled from "styled-components";

const MainContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  width: 100%;
`;

const BorderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  border-top: 1px solid var(--color-neutral-light2);
  border-bottom: 1px solid var(--color-neutral-light2);
  padding: 10px 0;
  margin-top: 10px;
`;

export { MainContainer, BorderContainer };
