import { BoxAdornment } from "../BoxAdornment";

import { Upload } from "@components/Upload";
import { Toggle } from "@flash-tecnologia/hros-web-ui-v2";
import { DisableContainer } from "../../../styles";

const PdfBoxAdornment = ({ formik, disabled }) => {
  const checked = !!formik?.values?.showPdfLink;

  return (
    <BoxAdornment
      title={"Inserir PDF"}
      description={
        "Selecione esta opção para inserir um arquivo de PDF na mensagem."
      }
      leftAdornment={
        <Toggle
          checked={checked}
          disabled={disabled}
          onChange={(e) =>
            formik.handleChange({
              target: {
                id: "showPdfLink",
                value: e?.target?.checked,
              },
            })
          }
        />
      }
      isActive={checked}
      activeComponent={
        <div style={{ display: "flex", width: "100%", marginTop: "20px" }}>
          <div
            style={{
              marginRight: "20px",
              visibility: "hidden",
              opacity: 0,
            }}
          >
            <Toggle disabled={true} />
          </div>
          <DisableContainer
            disabled={disabled}
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Upload
              value={formik?.values?.pdfFile}
              title="PDF da comunicação"
              infoContent={
                <div>
                  <p>Peso máximo: 10 MB</p>
                </div>
              }
              maxSize={10485760}
              accept={["pdf"]}
              folder="campaign"
              canDownload={false}
              onRemove={async (file) => {
                formik.handleChange({
                  target: {
                    id: "pdfFile",
                    value: "",
                  },
                });
              }}
              onUpload={async (file) => {
                const value = file;
                delete value.value;

                formik.handleChange({
                  target: {
                    id: "pdfFile",
                    value: value,
                  },
                });
              }}
            />
          </DisableContainer>
        </div>
      }
    />
  );
};

export { PdfBoxAdornment };
