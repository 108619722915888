import { EmptyMessage } from "./EmptyMessage";
import { EmptyStateContainer } from "./styles";
import ErrorSVG from "../../../../assets/out_of_deadline.svg";

export const OutOfDeadline = () => {
  return (
    <EmptyStateContainer>
      <EmptyMessage
        headerElement={<ErrorSVG />}
        title={"Essa pesquisa está encerrada"}
        message={
          "Não foi possível exibir essa pesquisa, pois ela está encerrada e não receberá mais respostas."
        }
      />
    </EmptyStateContainer>
  );
};
