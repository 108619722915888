import { trpc } from "@api/client";
import { EmployeeModel } from "@models";
import { createContext } from "react";

import { getAccessTokenPayloadSync } from "@flash-tecnologia/hros-web-utility";

export type User = EmployeeModel &
  Partial<{
    id: string;
    phoneNumber: string;
    email: string;
    documentNumber: string;
    economicGroupId: string;
  }>;

export const UserContext = createContext<User | null>(null);

export function UserProvider({ children }) {
  const { employeeId } = getAccessTokenPayloadSync("hrosAccessToken");

  const [{ data }] = trpc.useQueries((t) => [
    t?.getEmployeeById(
      {
        id: employeeId,
      },
      {
        retry: false,
        refetchOnWindowFocus: false,
      }
    ),
  ]);

  return (
    <UserContext.Provider value={data ?? null}>{children}</UserContext.Provider>
  );
}
