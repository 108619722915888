import styled from "styled-components";
import { Typography as TypographyE } from "@flash-tecnologia/hros-web-ui-v2";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  width: 100%;
`;

const Title = styled(TypographyE)`
  color: var(--color-neutral-dark4);
  font-weight: 700;
  max-width: 500px;
  text-align: center;
`;

const Message = styled(TypographyE)`
  color: var(--color-neutral-dark5);
  font-weight: 600;
  max-width: 550px;
  text-align: center;
  margin: 20px 0;
`;

const ChildrenContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export { Container, ChildrenContainer, Title, Message };
