import { useCallback, useRef, useState } from "react";
import { Icons } from "@flash-tecnologia/hros-web-ui-v2";
import { useTranslation } from "react-i18next";
import { ReactIcon } from "../ReactIcon";
import { backgroundColors, fontColors, icons } from "../ReactIcon/reactions";
import { ButtonContainer, IconsContainer, Label } from "./styles";
import { ReactButtonProps } from "./types";

const ReactButton = ({ value, onClick }: ReactButtonProps) => {
  const [t] = useTranslation("translations", {
    keyPrefix: "",
  });

  const ref = useRef<HTMLDivElement>(null);
  const [visible, setVisible] = useState(false);

  const convertValueToLabel = useCallback(
    (reactValue: number) => {
      const labels = ["like", "heart", "support", "congrats"];
      return labels[reactValue];
    },
    [value]
  );

  const reactionType = convertValueToLabel((value || 1) - 1);

  return (
    <ButtonContainer ref={ref} onClick={() => setVisible(!visible)}>
      {visible ? (
        <IconsContainer>
          <ReactIcon
            type="like"
            onClick={() => onClick(1)}
            tooltip={t(`reaction.like`)}
          />
          <ReactIcon
            type="heart"
            onClick={() => onClick(2)}
            tooltip={t(`reaction.heart`)}
          />
          <ReactIcon
            type="support"
            onClick={() => onClick(3)}
            tooltip={t(`reaction.support`)}
          />
          <ReactIcon
            type="congrats"
            onClick={() => onClick(4)}
            tooltip={t(`reaction.congrats`)}
          />
        </IconsContainer>
      ) : null}
      {value ? (
        <Icons
          name={icons[reactionType]}
          color={fontColors[reactionType]}
          fill={backgroundColors[reactionType]}
          onClick={() => onClick(1)}
        />
      ) : (
        <Icons
          name="IconMoodHappy"
          color="var(--color-neutral-dark5)"
          fill="white"
        />
      )}
      <Label
        variant="body3"
        color={value ? fontColors[reactionType] : undefined}
      >
        {value ? t(`reaction.${reactionType}`) : t("reaction.default")}
      </Label>
    </ButtonContainer>
  );
};

export { ReactButton };
