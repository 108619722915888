import styled from "styled-components";

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const StyleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  .step-dispatch-field {
    width: 100% !important;
  }

  .step-dispatch-frequency-select {
    width: 200px !important;
  }
`;

export { StyleContainer, Row };
