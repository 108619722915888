import { TextField, SelectField } from "@flash-tecnologia/hros-web-ui-v2";
import { LineSeparator, Typography } from "@components/index";
import { Divider } from "@components/v2";
import { Container, FieldRow, SelectContainer } from "./styles";
import { NumericScaleInterface } from "./types";

const NumericScale = ({
  disabled,
  lowerLabel,
  upperLabel,
  scaleRange,
  model,
  onChange,
}: NumericScaleInterface) => {
  const disabledOption = (
    type: "min" | "max",
    optionValue: number | undefined
  ) => {
    if (typeof optionValue !== "number") return false;

    if (type === "min" && typeof scaleRange?.max === "number") {
      if (optionValue >= scaleRange?.max) return true;
      return false;
    }

    if (type === "max" && typeof scaleRange?.min === "number") {
      if (optionValue <= scaleRange?.min) return true;
      return false;
    }

    return false;
  };

  return (
    <Container>
      {["enps", "climate", "engaja"]?.includes(model || "") ? null : (
        <>
          <FieldRow>
            <Typography
              variant="body3"
              color="var(--color-neutral-30)"
              fontWeight={600}
            >
              Escala entre
            </Typography>
            <Divider spacing="xs1" type="horizontal" />
            <SelectContainer>
              <SelectField
                label="Mínimo"
                fullWidth
                options={[
                  {
                    label: "0",
                    value: 0,
                    disabled: disabledOption("min", 0),
                  },
                  {
                    label: "1",
                    value: 1,
                    disabled: disabledOption("min", 1),
                  },
                  {
                    label: "2",
                    value: 2,
                    disabled: disabledOption("min", 2),
                  },
                  {
                    label: "3",
                    value: 3,
                    disabled: disabledOption("min", 3),
                  },
                  {
                    label: "4",
                    value: 4,
                    disabled: disabledOption("min", 4),
                  },
                  {
                    label: "5",
                    value: 5,
                    disabled: disabledOption("min", 5),
                  },
                  {
                    label: "6",
                    value: 6,
                    disabled: disabledOption("min", 6),
                  },
                  {
                    label: "7",
                    value: 7,
                    disabled: disabledOption("min", 7),
                  },
                  {
                    label: "8",
                    value: 8,
                    disabled: disabledOption("min", 8),
                  },
                  {
                    label: "9",
                    value: 9,
                    disabled: disabledOption("min", 9),
                  },
                  {
                    label: "10",
                    value: 10,
                    disabled: disabledOption("min", 10),
                  },
                ]}
                onSelectChange={(_: any, option) => {
                  onChange({
                    lowerLabel,
                    upperLabel,
                    scaleRange: {
                      ...scaleRange,
                      min: option?.value,
                    },
                  });
                }}
                value={scaleRange?.min}
              />
            </SelectContainer>
            <>
              <Divider spacing="xs1" type="horizontal" />
              <Typography
                variant="body3"
                color="var(--color-neutral-30)"
                fontWeight={600}
              >
                e
              </Typography>
              <Divider spacing="xs1" type="horizontal" />
            </>
            <SelectContainer>
              <SelectField
                label="Máximo"
                fullWidth
                options={[
                  {
                    label: "0",
                    value: 0,
                    disabled: disabledOption("max", 0),
                  },
                  {
                    label: "1",
                    value: 1,
                    disabled: disabledOption("max", 1),
                  },
                  {
                    label: "2",
                    value: 2,
                    disabled: disabledOption("max", 2),
                  },
                  {
                    label: "3",
                    value: 3,
                    disabled: disabledOption("max", 3),
                  },
                  {
                    label: "4",
                    value: 4,
                    disabled: disabledOption("max", 4),
                  },
                  {
                    label: "5",
                    value: 5,
                    disabled: disabledOption("max", 5),
                  },
                  {
                    label: "6",
                    value: 6,
                    disabled: disabledOption("max", 6),
                  },
                  {
                    label: "7",
                    value: 7,
                    disabled: disabledOption("max", 7),
                  },
                  {
                    label: "8",
                    value: 8,
                    disabled: disabledOption("max", 8),
                  },
                  {
                    label: "9",
                    value: 9,
                    disabled: disabledOption("max", 9),
                  },
                  {
                    label: "10",
                    value: 10,
                    disabled: disabledOption("max", 10),
                  },
                ]}
                onSelectChange={(_: any, option) => {
                  onChange({
                    lowerLabel,
                    upperLabel,
                    scaleRange: {
                      ...scaleRange,
                      max: option?.value,
                    },
                  });
                }}
                value={scaleRange?.max}
              />
            </SelectContainer>
          </FieldRow>
          <Divider spacing="xs1" />
          <LineSeparator />
          <Divider spacing="xs1" />
        </>
      )}
      <FieldRow>
        <Typography
          variant="headline9"
          color="var(--color-neutral-30)"
          fontWeight={700}
        >
          Configurar legendas
        </Typography>
      </FieldRow>
      <Divider spacing="xs1" />
      <FieldRow>
        <div style={{ width: "100%" }}>
          <TextField
            disabled={model === "enps" || disabled}
            value={lowerLabel || ""}
            className="scale-label"
            label="Legenda de menor valor"
            helperText="Escreva o título com a nota mais negativa. Exemplo: Péssimo"
            type="text"
            onChange={(e) =>
              e?.target?.value?.length < 21
                ? onChange({
                    scaleRange,
                    upperLabel,
                    lowerLabel: e?.target?.value,
                  })
                : null
            }
          />
        </div>
        <Divider spacing="xs1" type="horizontal" />
        <div style={{ width: "100%" }}>
          <TextField
            disabled={model === "enps" || disabled}
            value={upperLabel || ""}
            className="scale-label"
            label="Legenda de maior valor"
            helperText="Escreva o título com a nota mais positiva. Exemplo: Ótimo"
            type="text"
            onChange={(e) =>
              e?.target?.value?.length < 21
                ? onChange({
                    scaleRange,
                    lowerLabel,
                    upperLabel: e?.target?.value,
                  })
                : null
            }
          />
        </div>
      </FieldRow>
    </Container>
  );
};

export { NumericScale };
