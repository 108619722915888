import { useContext } from "react";
import { ContractContext } from "../context/ContractProvider";

export function useContractValidation() {
  const context = useContext(ContractContext);

  if (context === undefined) {
    throw new Error(
      "useContractValidation must be used inside an ContractProvider."
    );
  }

  return context?.value?.modules?.engagement;
}
