import { SectionAdditionalInfo } from "./SectionAdditionalInfo";
import { SectionName } from "./SectionName";
import { StepInfoInterface } from "./types";

import { FlexColumn, PageInfo, PageDescription, PageSubTitle } from "./styles";
import { Grid } from "@mui/material";

const StepInfo = ({
  disabled,
  hide,
  formik,
  onFileChange,
}: StepInfoInterface) => {
  if (hide) return <></>;

  return (
    <Grid container>
      <Grid item sm={12} md={5} lg={4}>
        <PageInfo>
          <PageSubTitle variant="headline7">Informações básicas</PageSubTitle>
          <PageDescription variant="body3">
            Adicione as informações básicas de conteúdo da sua comunicação.
          </PageDescription>
        </PageInfo>
      </Grid>
      <Grid item sm={12} md={7} lg={8}>
        <FlexColumn style={{ gap: "40px" }}>
          <SectionName disabled={disabled} formik={formik} />
          <SectionAdditionalInfo
            disabled={disabled}
            formik={formik}
            onFileChange={(e) => (onFileChange ? onFileChange(e) : null)}
          />
        </FlexColumn>
      </Grid>
    </Grid>
  );
};

export { StepInfo };
