import styled from "styled-components";
import { Typography } from "@flash-tecnologia/hros-web-ui-v2";

const Container = styled.div<{ width?: string }>`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  min-width: ${({ width }) => width || "322px"};
  max-width: ${({ width }) => width || "322px"};
  margin-bottom: 16px;

  @media (max-width: 1000px) {
    display: none !important;
  }
`;

const Title = styled(Typography)`
  font-weight: 700 !important;
  color: var(--color-secondary-50);
`;

const Description = styled(Typography)`
  font-weight: 400 !important;
  color: var(--color-neutral-50);
`;

const ChildrenNotificationCard = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  gap: 8px;
`;

export { Container, Title, Description, ChildrenNotificationCard };
