import { Icons } from "@flash-tecnologia/hros-web-ui-v2";
import { sanitizeHtml } from "@utils";
import { Divider, Space } from "..";
import {
  BorderIcon,
  Container,
  Content,
  Date,
  Image,
  Label,
  Row,
  TextWithHtml,
  Title,
  TitleContainer,
  Value,
} from "./styles";
import { CardInfoInterface } from "./types";
import { isValidElement } from "react";

const CardInfo = ({
  title,
  text,
  data,
  direction: mainDirection,
  children,
  imageSrc,
  style,
  date,
}: CardInfoInterface) => (
  <Container style={style}>
    {title ? (
      <>
        <TitleContainer>
          <Title variant="headline8">{title}</Title>
          {date ? <Date variant="body4">{date}</Date> : null}
        </TitleContainer>
        <Divider size="tiny" />
      </>
    ) : null}
    {text ? (
      <>
        <TextWithHtml
          dangerouslySetInnerHTML={{ __html: sanitizeHtml(text) }}
        />
        <Divider size="tiny" />
      </>
    ) : null}
    {imageSrc ? (
      <>
        <Image crossOrigin="anonymous" src={imageSrc} />
        <Divider size="tiny" />
      </>
    ) : null}
    {data?.map(({ label, value, direction, icon }, i) => (
      <div key={i}>
        {i === 0 ? null : <Divider size="tiny" />}
        <Row>
          {icon ? (
            <BorderIcon>
              <Icons
                name={icon}
                size={14}
                color="var(--color-neutral-dark4)"
                fill="transparent"
              />
            </BorderIcon>
          ) : null}
          <Content direction={direction || mainDirection}>
            <Label variant="body4">
              <strong>{label}</strong>
            </Label>
            {(direction || mainDirection) !== "column" ? (
              <>
                <Label variant="body4">
                  {" "}
                  <strong>:</strong>
                </Label>
                <Space size="sm" />
              </>
            ) : (
              ""
            )}
            {label === "Mensagem" ? (
              <TextWithHtml
                dangerouslySetInnerHTML={{ __html: sanitizeHtml(value) }}
              />
            ) : isValidElement(value) ? (
              value
            ) : (
              <Value variant="body3">{value}</Value>
            )}
          </Content>
        </Row>
      </div>
    ))}
    {children}
  </Container>
);

export { CardInfo };
