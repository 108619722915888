import styled from "styled-components";

export const RadioContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
`;

export const RadioBox = styled.div`
  border: 1px solid var(--color-neutral-light2);
  border-radius: 8px;
  padding: 7px 16px;
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
`;
