import styled from "styled-components";
import { Typography } from "@flash-tecnologia/hros-web-ui-v2";

const Container = styled.div`
  background-color: var(--color-neutral-95);
  padding: 20px;
  width: 100%;
  border-radius: 8px;
`;

const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  div:not(:last-child) {
    margin-right: 15px !important;
  }
`;

const Item = styled.div`
  height: 64px;
  width: 100%;
  border-radius: 4px;
  background-color: var(--color-neutral-90);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Num = styled(Typography)<{ size?: number }>`
  color: var(--color-neutral-20);
  font-weight: 700 !important;
  font-size: ${({ size }) => (size ? `${size}px` : undefined)};
`;

const LabelsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
`;

const Label = styled(Typography)`
  color: var(--color-neutral-30);
`;

export { Container, ItemContainer, Item, Num, LabelsContainer, Label };
