import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const BlockContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Block = styled.div<{ color: string }>`
  width: 60px;
  height: 32px;
  background-color: ${({ color }) => color};
  border-right: 1px solid var(--color-neutral-95);
  border-bottom: 1px solid var(--color-neutral-95);
  border-top: 1px solid var(--color-neutral-95);
`;

export { Container, BlockContainer, Block };
