import styled from "styled-components";

const StepContainer = styled.div<{ flexColumn?: boolean }>`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: ${({ flexColumn }) => (flexColumn ? "column" : "row")};
  margin-top: 25px;
`;

export { StepContainer };
