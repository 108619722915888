import styled from "styled-components";
import { Typography } from "@flash-tecnologia/hros-web-ui-v2";

const Container = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
`;

const Title = styled(Typography)`
  color: var(--color-neutral-dark3);
  font-weight: 700;
`;

const Description = styled(Typography)`
  color: var(--color-neutral-dark5);
  font-weight: 400;
`;

export { Container, Title, Description };
