import {
  MainContainer,
  RelativeContainer,
  Shadow,
  FloatContainer,
  Header,
  ShapeIconContainer,
  ContentContainer,
  TitleContainer,
} from "./styles";
import { DrawerInterface } from "./types";
import { Typography } from "@components/index";
import { ShapeIcon } from "@flash-tecnologia/hros-web-ui-v2";

const Drawer = ({ title, content, open, onClose }: DrawerInterface) => (
  <MainContainer open={open}>
    <RelativeContainer>
      {open ? <Shadow onClick={() => (open ? onClose(false) : null)} /> : null}
      <FloatContainer>
        <Header>
          <ShapeIconContainer onClick={() => (open ? onClose(false) : null)}>
            <ShapeIcon
              name="IconX"
              variant="transparent"
              size={40}
              color="var(--color-neutral-40)"
            />
          </ShapeIconContainer>
          <TitleContainer>
            <Typography
              variant="headline7"
              fontWeight={700}
              color="var(--color-neutral-10)"
              style={{ marginLeft: 5 }}
            >
              {title}
            </Typography>
          </TitleContainer>
        </Header>
        <ContentContainer>{content}</ContentContainer>
      </FloatContainer>
    </RelativeContainer>
  </MainContainer>
);

export { Drawer };
