import { TextField } from "@flash-tecnologia/hros-web-ui-v2";
import { Container } from "./styles";

const TextPreview = () => (
  <Container>
    <TextField label="Texto da resposta" value="" disabled={true} />
  </Container>
);

export { TextPreview };
