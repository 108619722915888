import { Typography } from "@components/Typography";
import { Divider } from "@components/v2";
import {
  Accordion,
  BoxAdornment,
  Radio,
} from "@flash-tecnologia/hros-web-ui-v2";
import { Column, Row } from "./styles";
import { useState } from "react";
import { OwnersMultiSelect } from "@components/OwnersMultiSelect";
import { useUser } from "src/hooks/useUser";
import { OwnersNotificationCard } from "@components/OwnersNotificationCard";
import { segment } from "@utils";

const SectionOwners = ({ disabled, formik }) => {
  const owners = formik?.values?.owners;
  const user = useUser();

  const [responsibilityMode, setResponsibilityMode] = useState<
    "everyone" | "specific"
  >(
    !!owners && owners?.length > 1
      ? "specific"
      : formik?.values?.responsibilityMode ?? "everyone"
  );

  return (
    <Accordion
      className="step-config-accordion"
      customHeader={
        <>
          <Typography
            variant="headline8"
            color="var(--color-neutral-30)"
            fontWeight={700}
          >
            Responsáveis
          </Typography>
          <Typography
            variant="body4"
            color="var(--color-neutral-50)"
            fontWeight={400}
          >
            Escolha abaixo quem terá permissão para acessar, visualizar dados e
            realizar ações. Administradores com permissão geral terão acesso
            independente da seleção feita.
          </Typography>
        </>
      }
      children={
        <Column>
          <Row>
            <BoxAdornment
              style={{ width: "100%" }}
              description={
                <>
                  <Typography
                    variant="body3"
                    color="var(--color-neutral-30)"
                    fontWeight={600}
                  >
                    Todos com acesso ao módulo
                  </Typography>
                </>
              }
              leftAdornment={
                <Radio
                  disabled={disabled}
                  checked={responsibilityMode === "everyone"}
                  onChange={() => {
                    segment({
                      track:
                        "people_strategic_hr_engagement_company_communication_configuration_responsible_everyone_clicked",
                    });

                    setResponsibilityMode("everyone");
                    formik.handleChange({
                      target: {
                        id: "responsibilityMode",
                        value: "everyone",
                      },
                    });
                    formik.handleChange({
                      target: {
                        id: "owners",
                        value: [{ name: "*", id: "*" }],
                      },
                    });
                  }}
                />
              }
            />
            <Divider spacing="xs1" type="horizontal" />
            <BoxAdornment
              style={{ width: "100%" }}
              description={
                <>
                  <Typography
                    variant="body3"
                    color="var(--color-neutral-30)"
                    fontWeight={600}
                  >
                    Selecionar pessoas
                  </Typography>
                </>
              }
              leftAdornment={
                <Radio
                  disabled={disabled}
                  checked={responsibilityMode === "specific"}
                  onChange={() => {
                    segment({
                      track:
                        "people_strategic_hr_engagement_company_communication_configuration_responsible_specificpeople_clicked",
                    });

                    setResponsibilityMode("specific");

                    formik.handleChange({
                      target: {
                        id: "responsibilityMode",
                        value: "specific",
                      },
                    });

                    formik.handleChange({
                      target: {
                        id: "owners",
                        value: undefined,
                      },
                    });
                  }}
                />
              }
            />
          </Row>
          {responsibilityMode === "specific" && (
            <>
              <OwnersNotificationCard />
              <OwnersMultiSelect
                error={formik.errors.owners}
                owners={formik.values.owners?.map((item) => ({
                  label: item.name,
                  value: item.id,
                }))}
                onChange={(values) => {
                  formik.handleChange({
                    target: {
                      id: "owners",
                      value: values.map((value) => ({
                        name: value?.label,
                        id: value?.value,
                      })),
                    },
                  });
                }}
              />
            </>
          )}
        </Column>
      }
      variant={"default"}
      defaultExpanded
    />
  );
};

export { SectionOwners };
