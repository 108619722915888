import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  ThemeProvider as NewProvider,
  Breadcrumbs,
  Tab,
  dayjs,
  Button,
} from "@flash-tecnologia/hros-web-ui-v2";
import {
  TitleContainer,
  PageContainer,
  DataGrid,
  Divider,
  Tag,
  CardSkeleton,
  DataGridSkeleton,
  Avatar,
  ColumnReactIcon,
  CampaignReport,
  CarouselCards,
} from "../../components";
import { ButtonContainer, SectionTitle } from "./styles";
import { alert, segment } from "../../utils";
import { FiltersType } from "../TableFilter/types";
import { trpc } from "@api/client";

const EmployeeDetail = ({
  type,
  id,
}: {
  type: "communication" | "survey";
  id: string;
}) => {
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const [selectedTab, setSelectedTab] = useState(type || "communication");
  const [communicationFilter, setCommunicationFilter] = useState<FiltersType>([
    {
      id: "viewed",
      type: "boolean",
      label: "Visualização",
      valueInLabel: true,
      options: [
        {
          label: "Com visualização",
          value: "true",
        },
        {
          label: "Sem visualização",
          value: "false",
        },
      ],
    },
    {
      id: "clicked",
      type: "boolean",
      label: "Clique em link",
      valueInLabel: true,
      options: [
        {
          label: "Com clique",
          value: "true",
        },
        {
          label: "Sem clique",
          value: "false",
        },
      ],
    },
    {
      id: "like",
      type: "boolean",
      label: "Reação",
      valueInLabel: true,
      options: [
        {
          label: "Gostei",
          value: "1",
        },
        {
          label: "Amei",
          value: "2",
        },
        {
          label: "Apoio",
          value: "3",
        },
        {
          label: "Parabéns",
          value: "4",
        },
      ],
    },
  ]);
  const [surveyFilter, setSurveyFilter] = useState<FiltersType>([
    {
      id: "viewed",
      type: "boolean",
      label: "Visualização",
      valueInLabel: true,
      options: [
        {
          label: "Com visualização",
          value: "true",
        },
        {
          label: "Sem visualização",
          value: "false",
        },
      ],
    },
    {
      id: "anonymous",
      type: "boolean",
      label: "Anonimato",
      valueInLabel: true,
      options: [
        {
          label: "Anônimo",
          value: "true",
        },
        {
          label: "Não anônimo",
          value: "false",
        },
      ],
    },
  ]);
  const [search, setSearch] = useState<{
    communication: string;
    survey: string;
  }>({
    communication: "",
    survey: "",
  });

  const {
    data: communications,
    refetch: getCommunicationsByEmployeeIdRefetch,
    isLoading: getCommunicationsByEmployeeIdRefetchLoading,
  } = trpc.getEngagementsByEmployeeId.useQuery(
    {
      id,
      type: "communication",
      search: search[type],
      viewed:
        communicationFilter
          .find((e) => e.id === "viewed")
          ?.options?.filter(({ checked }) => checked)
          ?.map(({ value }) => value) || [],
      clicked:
        communicationFilter
          .find((e) => e.id === "clicked")
          ?.options?.filter(({ checked }) => checked)
          ?.map(({ value }) => value) || [],
      like:
        communicationFilter
          .find((e) => e.id === "like")
          ?.options?.filter(({ checked }) => checked)
          ?.map(({ value }) => value) || [],
      answer:
        surveyFilter
          .find((e) => e.id === "answer")
          ?.options?.filter(({ checked }) => checked)
          ?.map(({ value }) => value) || [],
      anonymous:
        surveyFilter
          .find((e) => e.id === "anonymous")
          ?.options?.filter(({ checked }) => checked)
          ?.map(({ value }) => value) || [],
      answerFormat:
        surveyFilter
          .find((e) => e.id === "answerFormat")
          ?.options?.filter(({ checked }) => checked)
          ?.map(({ value }) => value) || [],
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
      onError: (e: any) => {
        alert("Ocorreu um erro, tente novamente mais tarde.");
        console.log("err: ", e);
      },
    }
  );

  const {
    data: surveys,
    refetch: getSurveysByEmployeeIdRefetch,
    isLoading: getSurveysByEmployeeIdRefetchLoading,
  } = trpc.getEngagementsByEmployeeId.useQuery(
    {
      id,
      type: "survey",
      search: search[type],
      viewed:
        surveyFilter
          .find((e) => e.id === "viewed")
          ?.options?.filter(({ checked }) => checked)
          ?.map(({ value }) => value) || [],
      clicked:
        communicationFilter
          .find((e) => e.id === "clicked")
          ?.options?.filter(({ checked }) => checked)
          ?.map(({ value }) => value) || [],
      like:
        communicationFilter
          .find((e) => e.id === "like")
          ?.options?.filter(({ checked }) => checked)
          ?.map(({ value }) => value) || [],
      answer:
        surveyFilter
          .find((e) => e.id === "answer")
          ?.options?.filter(({ checked }) => checked)
          ?.map(({ value }) => value) || [],
      anonymous:
        surveyFilter
          .find((e) => e.id === "anonymous")
          ?.options?.filter(({ checked }) => checked)
          ?.map(({ value }) => value) || [],
      answerFormat:
        surveyFilter
          .find((e) => e.id === "answerFormat")
          ?.options?.filter(({ checked }) => checked)
          ?.map(({ value }) => value) || [],
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
      onError: (e: any) => {
        alert("Ocorreu um erro, tente novamente mais tarde.");
        console.log("err: ", e);
      },
    }
  );

  const { data: employee, isLoading: getEmployeeByIdLoading } =
    trpc.getEmployeeById.useQuery(
      { id },
      {
        retry: false,
        refetchOnWindowFocus: false,
        onError: (e: any) => {
          alert("Ocorreu um erro, tente novamente mais tarde.");
          console.log("err: ", e);
        },
      }
    );

  const { refetch: getCommunicationAndSurveyReportByEmployeeIdRefetch } =
    trpc.getEngagementReportByEmployeeId.useQuery(
      { id, type: "all" },
      {
        enabled: false,
        retry: false,
        refetchOnWindowFocus: false,
        onError: (e: any) => {
          alert("Ocorreu um erro, tente novamente mais tarde.");
          console.log("err: ", e);
        },
      }
    );

  const { refetch: getCommunicationReportByEmployeeIdRefetch } =
    trpc.getEngagementReportByEmployeeId.useQuery(
      { id, type: "communication" },
      {
        enabled: false,
        retry: false,
        refetchOnWindowFocus: false,
        onError: (e: any) => {
          alert("Ocorreu um erro, tente novamente mais tarde.");
          console.log("err: ", e);
        },
      }
    );

  const { refetch: getSurveyReportByEmployeeIdRefetch } =
    trpc.getEngagementReportByEmployeeId.useQuery(
      { id, type: "survey" },
      {
        enabled: false,
        retry: false,
        refetchOnWindowFocus: false,
        onError: (e: any) => {
          alert("Ocorreu um erro, tente novamente mais tarde.");
          console.log("err: ", e);
        },
      }
    );

  const { data: insights, isLoading: getSurveyInsightsLoading } =
    trpc?.getEmployeeInsights.useQuery(
      { id },
      {
        retry: false,
        refetchOnWindowFocus: false,
        onError: (e) => {
          alert("Erro ao buscar insights, tente novamente mais tarde.");
          console.log("err: ", e);
        },
      }
    );

  const findCampaigns = (type: "communication" | "survey") => {
    type === "communication"
      ? getCommunicationsByEmployeeIdRefetch()
      : getSurveysByEmployeeIdRefetch();
  };

  useEffect(() => {
    if (selectedTab === "communication" && communications !== null)
      findCampaigns("communication");
  }, [communicationFilter, search]);

  useEffect(() => {
    if (selectedTab === "survey" && surveys !== null) findCampaigns("survey");
  }, [surveyFilter, search]);

  const handleSurveyFilter = (values: FiltersType) => {
    if (values?.length) {
      const answerFilter = values?.find(({ id }) => id === "answer");
      const viewedFilter = values?.find(({ id }) => id === "viewed");

      if (
        answerFilter?.options?.find(({ checked }) => checked) ||
        viewedFilter?.options?.find(({ checked }) => checked)
      ) {
        const updatedFilters = values?.map((filter) => {
          if (filter?.id === "anonymous")
            return {
              ...filter,
              disabled: true,
              options: filter?.options?.map(({ label, value }) =>
                label === "Anônimo"
                  ? { checked: false, value, label }
                  : { label, value, checked: true }
              ),
            };

          return filter;
        });

        return updatedFilters;
      }
    }

    return values?.map((f) => ({ ...f, disabled: false }));
  };

  const getTotalAnswers = () =>
    surveys?.reduce((a, c) => {
      return a + (!c?.anonymous && c?.monitoring?.draft === false ? 1 : 0);
    }, 0);

  const getTotalReacts = (type?: 1 | 2 | 3 | 4) =>
    communications?.reduce(
      (a, c) =>
        a +
        (c?.monitoring?.like
          ? !type
            ? 1
            : type === c?.monitoring?.like
            ? 1
            : 0
          : 0),
      0
    );

  const generateEmployeeEngagementsReport = (
    type: "communicationAndSurvey" | "communication" | "survey"
  ) => {
    if (type === "communicationAndSurvey") {
      getCommunicationAndSurveyReportByEmployeeIdRefetch();
      return;
    }
    if (type === "communication") {
      getCommunicationReportByEmployeeIdRefetch();
      return;
    }

    if (type === "survey") {
      getSurveyReportByEmployeeIdRefetch();
      return;
    }
  };

  if (getEmployeeByIdLoading)
    return (
      <PageContainer>
        <CardSkeleton />
        <Divider />
        <DataGridSkeleton />
      </PageContainer>
    );

  return (
    <PageContainer>
      <Breadcrumbs
        separator=">"
        breadcrumbs={[
          <Link to={`/engagement/${type}`}>
            {type === "communication" ? "Comunicação" : "Pesquisa"}
          </Link>,
          <Link to={`/engagement/employee/${id}`}>{"Detalhe da pessoa"}</Link>,
        ]}
      />
      <Divider />
      <>
        <TitleContainer>
          <Avatar
            name={employee?.name || ""}
            email={
              employee?.contacts?.find(({ type }) => type === "email")?.value ||
              ""
            }
            image=""
            variants={{
              name: "headline6",
            }}
            avatarSize="64px"
          />
          <CampaignReport
            type={type}
            visible={visible}
            onClick={() => {
              segment({
                track: `company_engagement_persondetail_exportreport_clicked`,
              });
              setVisible(!visible);
            }}
            onClose={() => {
              segment({
                track: `company_engagement_exportpersondetail_cancel_clicked`,
              });
              setVisible(!visible);
            }}
            onFinish={() => {
              setVisible(!visible);
            }}
            onExport={() => {
              segment({
                track: `company_engagement_exportpersondetail_exportreport_clicked`,
              });
              generateEmployeeEngagementsReport("communicationAndSurvey");
            }}
            texts={{
              title: (
                <>
                  Deseja exportar o relatório <br /> completo?
                </>
              ),
              message: (
                <>
                  O relatório apresentará somente os{" "}
                  <strong>dados das tabelas</strong>
                  <br /> contidas nessa página convertidos em um arquivo CSV.
                </>
              ),
              toolTip: "Exportar relatório completo",
              endButton: "Voltar para detalhe da pessoa",
            }}
          />
        </TitleContainer>
        <Divider />
        <NewProvider>
          <Tab
            selected={selectedTab === "communication" ? 0 : 1}
            onTabChanged={() =>
              setSelectedTab(
                selectedTab === "communication" ? "survey" : "communication"
              )
            }
            tabItens={[
              {
                label: `Comunicações (${communications?.length || 0})`,
                component: null,
              },
              {
                label: `Pesquisas (${surveys?.length || 0})`,
                component: null,
              },
            ]}
          />
        </NewProvider>
        <Divider />
        <>
          {selectedTab === "communication" ? (
            <CarouselCards
              key={"CarouselCardscommunication"}
              cards={[
                {
                  title: "Comunicações",
                  value: communications?.length || 0,
                  icon: "IconSpeakerphone",
                },
                {
                  title: "Visualizações",
                  value:
                    communications?.reduce(
                      (a, c) => a + (c?.monitoring?.visualized ? 1 : 0),
                      0
                    ) || 0,
                  icon: "IconEyeCheck",
                },
                {
                  title: "Cliques",
                  value:
                    communications?.reduce(
                      (a, c) => a + (c?.monitoring?.open ? 1 : 0),
                      0
                    ) || 0,
                  icon: "IconHandClick",
                },
                {
                  title: "Reações",
                  value: getTotalReacts() || 0,
                  icon: "IconMoodHappy",
                },
                {
                  title: "Like",
                  value: getTotalReacts(1) || 0,
                  icon: "IconThumbUp",
                },
                {
                  title: "Amei",
                  value: getTotalReacts(2) || 0,
                  icon: "IconHeart",
                },
                {
                  title: "Apoio",
                  value: getTotalReacts(3) || 0,
                  icon: "IconHeartHandshake",
                },
                {
                  title: "Parabéns",
                  value: getTotalReacts(4) || 0,
                  icon: "IconConfetti",
                },
              ]}
            />
          ) : (
            <CarouselCards
              key={"CarouselCardsSurvey"}
              cards={[
                {
                  title: "Pesquisas",
                  value: insights?.surveys || 0,
                  icon: "IconCheckbox",
                },
                {
                  title: "Taxa de visualização",
                  value: insights?.viewRate || 0,
                  subValue: insights?.views || 0,
                  icon: "IconEye",
                  toolTip: {
                    title: "Visualizações",
                    description:
                      "A quantidade de visualizações é referente às pesquisas não anônimas.",
                  },
                },
                {
                  title: "Taxa de resposta",
                  value: insights?.answerRate || 0,
                  subValue: insights?.answers || 0,
                  icon: "IconGraph",
                  toolTip: {
                    title: "Respostas",
                    description:
                      "A quantidade de respostas é referente às pesquisas não anônimas.",
                  },
                },
              ]}
            />
          )}
        </>
        <NewProvider>
          <SectionTitle variant="headline8">
            {`${
              selectedTab === "communication" ? "Comunicações" : "Pesquisas"
            } associadas à pessoa`}
          </SectionTitle>

          {selectedTab === "communication" ? (
            <DataGrid
              report={{
                onClick: () => {
                  segment({
                    track: `company_engagement_persondetail_exportcommunicationreport_clicked`,
                  });
                },
                type: "communication",
                onExport: () => {
                  segment({
                    track: `company_engagement_personcommunication_exportreport_clicked`,
                  });
                  generateEmployeeEngagementsReport("communication");
                },
                onClose: () => {
                  segment({
                    track: `company_engagement_personcommunication_cancel_clicked`,
                  });
                },
                texts: {
                  title: <>Deseja exportar o relatório?</>,
                  message: (
                    <>
                      O relatório apresentará somente os{" "}
                      <strong>dados dessa tabela</strong>
                      <br />
                      convertidos em um arquivo CSV.
                    </>
                  ),
                  toolTip: "Exportar relatório",
                  endButton: "Voltar para detalhe da pessoa",
                },
              }}
              emptyState={{
                isFiltered: true,
                message: "Nenhum resultado encontrado",
              }}
              loading={getCommunicationsByEmployeeIdRefetchLoading}
              filterTags={{
                filters: communicationFilter,
                onFilter: (e) => setCommunicationFilter(e),
                onClear: (e) => setCommunicationFilter(e),
              }}
              searchField={{
                onSearch: (e) => setSearch({ ...search, communication: e }),
                placeholder: "Buscar por comunicação",
                value: search?.communication,
              }}
              columns={[
                {
                  Header: "Comunicação",
                  accessor: "name",
                },
                {
                  id: "view",
                  Header: "Visualização",
                  accessor: "monitoring",
                  Cell: ({ row }: any) => (
                    <Tag
                      variant={
                        row?.original?.monitoring?.visualized ? "send" : "draft"
                      }
                    >
                      {row?.original?.monitoring?.visualized ? "Sim" : "Não"}
                    </Tag>
                  ),
                },
                {
                  id: "click",
                  Header: "Clique em link",
                  accessor: "monitoring",
                  Cell: ({ row }: any) => {
                    return (
                      <Tag
                        variant={
                          row?.original?.monitoring?.open ? "send" : "draft"
                        }
                      >
                        {row?.original?.monitoring?.open ? "Sim" : "Não"}
                      </Tag>
                    );
                  },
                },
                {
                  id: "like",
                  Header: "Reação",
                  Cell: ({ row }: any) => {
                    const reaction = row?.original?.monitoring?.like;

                    return typeof reaction === "number" && reaction ? (
                      <ColumnReactIcon value={reaction} />
                    ) : (
                      <>-</>
                    );
                  },
                },
              ]}
              data={communications || []}
            />
          ) : (
            <DataGrid
              report={{
                type: "survey",
                onClick: () => {
                  segment({
                    track: `company_engagement_persondetail_exportsurveyreport_clicked`,
                  });
                },
                onClose: () => {
                  segment({
                    track: `company_engagement_personsurveys_cancel_clicked`,
                  });
                },
                onExport: () => {
                  segment({
                    track: `company_engagement_personsurveys_exportreport_clicked`,
                  });
                  generateEmployeeEngagementsReport("survey");
                },
                texts: {
                  title: <>Deseja exportar o relatório?</>,
                  message: (
                    <>
                      O relatório apresentará somente os{" "}
                      <strong>dados dessa tabela</strong>
                      <br />
                      convertidos em um arquivo CSV.
                    </>
                  ),
                  toolTip: "Exportar relatório",
                  endButton: "Voltar para detalhe da pessoa",
                },
              }}
              emptyState={{
                isFiltered: true,
                message: "Nenhum resultado encontrado",
              }}
              loading={getSurveysByEmployeeIdRefetchLoading}
              searchField={{
                onSearch: (e) => setSearch({ ...search, survey: e }),
                placeholder: "Buscar por pesquisa",
                value: search?.survey,
              }}
              filterTags={{
                filters: surveyFilter,
                onFilter: (e) => {
                  const result = handleSurveyFilter(e);
                  setSurveyFilter(result);
                },
                onClear: (e) =>
                  setSurveyFilter(
                    e?.map((filter) => ({ ...filter, disabled: false }))
                  ),
              }}
              columns={[
                {
                  Header: "Pesquisa",
                  accessor: "name",
                },
                {
                  id: "status",
                  Header: "Status",
                  // disableSortBy: true,
                  accessor: "monitoring",
                  Cell: ({ value, row }) => {
                    if (row?.original?.anonymous) return <>N/A</>;

                    const status: "not-started" | "done" | "started" = value
                      ?.sections?.length
                      ? value?.draft
                        ? "started"
                        : "done"
                      : "not-started";

                    if (status === "not-started")
                      return <Tag variant="neutral">Não iniciado</Tag>;
                    if (status === "done")
                      return <Tag variant="progress">Concluído</Tag>;
                    if (status === "started")
                      return <Tag variant="paused">Não concluído</Tag>;

                    return <></>;
                  },
                },
                {
                  id: "sendDate",
                  Header: "Data de envio",
                  // disableSortBy: true,
                  accessor: "sendDate",
                  Cell: ({ value }) => {
                    if (value) return <>{dayjs(value)?.format("DD/MM/YYYY")}</>;
                    return <></>;
                  },
                },
                {
                  id: "interactionDeadline",
                  Header: "Prazo de resposta",
                  // disableSortBy: true,
                  accessor: "interactionDeadline",
                  Cell: ({ value }) => {
                    if (value) return <>{dayjs(value)?.format("DD/MM/YYYY")}</>;
                    return <></>;
                  },
                },
                {
                  id: "updatedAt",
                  Header: "Data da resposta",
                  // disableSortBy: true,
                  accessor: "updatedAt",
                  Cell: ({ row }) => {
                    if (row?.original?.anonymous) return <>N/A</>;
                    const date = row?.original?.monitoring?.updatedAt;
                    if (date) return <>{dayjs(date)?.format("DD/MM/YYYY")}</>;
                    return <></>;
                  },
                },
                {
                  id: "anonymous",
                  Header: "Resposta Anônima",
                  // disableSortBy: true,
                  accessor: "anonymous",
                  Cell: ({ value }) => {
                    return (
                      <Tag variant={value ? "send" : "draft"}>
                        {value ? "Sim" : "Não"}
                      </Tag>
                    );
                  },
                },
                {
                  id: "view",
                  Header: "Visualização",
                  // disableSortBy: true,
                  accessor: "monitoring",
                  Cell: ({ row }: any) => {
                    const anonymous = row?.original?.anonymous;
                    return anonymous ? (
                      <div>N/A</div>
                    ) : (
                      <Tag
                        variant={
                          row?.original?.monitoring?.visualized
                            ? "send"
                            : "draft"
                        }
                      >
                        {row?.original?.monitoring?.visualized ? "Sim" : "Não"}
                      </Tag>
                    );
                  },
                },
                {
                  sticky: "right",
                  Header: "",
                  accessor: "_id",
                  disableSortBy: true,
                  Cell: ({ value, row }: any) => {
                    const status: "not-started" | "done" | "started" = row
                      ?.original?.monitoring?.sections?.length
                      ? row?.original?.monitoring?.draft
                        ? "started"
                        : "done"
                      : "not-started";

                    return (
                      <ButtonContainer>
                        <Button
                          disabled={
                            row?.original?.anonymous || status !== "done"
                          }
                          variant="secondary"
                          size="medium"
                          onClick={() => {
                            segment({
                              track: `company_engagement_persondetail_viewanwers_clicked`,
                            });
                            navigate(
                              `/engagement/employee/${id}/survey/${value}`
                            );
                          }}
                        >
                          Ver respostas
                        </Button>
                      </ButtonContainer>
                    );
                  },
                },
              ]}
              data={surveys || []}
            />
          )}
        </NewProvider>
      </>
    </PageContainer>
  );
};

export { EmployeeDetail };
