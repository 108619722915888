import { Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

const MainContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
`;

const ReactContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding-bottom: 5px;

  > div:not(:first-child) {
    margin-left: -5px;
  }
`;

const IconContainer = styled.div<{ backgroundColor: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 24px;
  border-radius: 100%;
  background-color: ${({ backgroundColor }) => backgroundColor};
`;

const TotalNumberContainer = styled(Typography)`
  margin-left: 5px;
  color: var(--color-neutral-dark4);
  font-weight: 400;
`;

const ButtonContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-top: 1px solid var(--color-neutral-light2);
  border-bottom: 1px solid var(--color-neutral-light2);
  width: 100%;
  padding: 5px 0 5px 0;
`;

const Label = styled(Typography)<{ color?: string }>`
  color: ${({ color = "var(--color-neutral-dark5)" }) => color};
  font-weight: 600;
  margin-left: 5px;
`;

export {
  MainContainer,
  ReactContainer,
  IconContainer,
  TotalNumberContainer,
  Label,
  ButtonContainer,
};
