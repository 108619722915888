import {
  BarChart as BarChartRecharts,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  LabelList,
  Cell,
} from "recharts";
import { Typography } from "../Typography";
import { BarChartInterface } from "./types";
import { Container, LabelsContainer, TitleContainer } from "./styles";
import { Divider } from "../v2";
import { EmptyStateChart } from "..";

const VerticalBarChart = ({
  enps,
  title,
  data,
  lowerLabel,
  upperLabel,
}: BarChartInterface) => {
  const totalDomain = data?.reduce((a, c) => a + c?.value, 0);

  const getBarColor = (name: string) => {
    if (!enps) return "var(--color-primary)";

    const value = parseInt(name);

    if (value < 7) return "#F45A55";
    if (value < 9) return "#F8B81C";
    if (value === 9) return "#6AA01D";
    return "#22894B";
  };

  const renderCustomizedLabel = (props) => {
    const { x, y, width, value } = props;
    const v = data?.find(({ name }) => name === value)?.value;
    const radius = 10;

    const percentage = ((v || 0) * 100) / totalDomain;

    return (
      <g>
        <text
          x={x + width / 2}
          y={y - 20 - radius + (percentage > 90 ? 45 : 0)}
          fill={
            percentage > 90
              ? "var(--color-neutral-100)"
              : "var(--color-neutral-30)"
          }
          textAnchor="middle"
          dominantBaseline="middle"
          style={{ fontWeight: 700 }}
        >
          {`${~~percentage}%`}
        </text>
        <text
          x={x + width / 2}
          y={y - radius + (percentage > 90 ? 45 : 0)}
          fill={
            percentage > 90
              ? "var(--color-neutral-100)"
              : "var(--color-neutral-30)"
          }
          textAnchor="middle"
          dominantBaseline="middle"
          style={{ fontWeight: 400 }}
        >
          {`${v} resp.`}
        </text>
      </g>
    );
  };

  if (!totalDomain) return <EmptyStateChart />;

  return (
    <div style={{ width: "100%" }}>
      <Container>
        <TitleContainer>
          <Typography
            variant="headline8"
            color="var(--color-neutral-30)"
            fontWeight={700}
          >
            {title}
          </Typography>
        </TitleContainer>
        <Divider spacing="xxs3" />
        <ResponsiveContainer width="100%" height="100%">
          <BarChartRecharts
            width={150}
            height={150}
            data={data}
            maxBarSize={150}
          >
            <CartesianGrid
              strokeDasharray="0 0"
              stroke="var(--color-neutral-95)"
            />
            <XAxis dataKey="name" stroke="var(--color-neutral-50)" />
            <YAxis domain={[0, totalDomain]} stroke="var(--color-neutral-50)" />
            <Bar dataKey="value" fill={"red"}>
              <LabelList dataKey="name" content={renderCustomizedLabel} />
              {data.map(({ name }, index) => (
                <Cell key={`cell-${index}`} fill={getBarColor(name)} />
              ))}
            </Bar>
          </BarChartRecharts>
        </ResponsiveContainer>
      </Container>
      {lowerLabel && upperLabel ? (
        <LabelsContainer>
          <Typography
            variant="body3"
            color="var(--color-neutral-30)"
            fontWeight={600}
          >
            {lowerLabel}
          </Typography>
          <Typography
            variant="body3"
            color="var(--color-neutral-30)"
            fontWeight={600}
          >
            {upperLabel}
          </Typography>
        </LabelsContainer>
      ) : null}
    </div>
  );
};

export { VerticalBarChart };
