import { trpc } from "@api/client";
import { UploadPreview } from "@components/Upload/UploadPreview";
import { Error } from "@components/Error";

import {
  Breadcrumbs,
  Button,
  ThemeProvider as NewProvider,
  Tab,
  Typography,
  dayjs,
} from "@flash-tecnologia/hros-web-ui-v2";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useTheme } from "styled-components";
import {
  Avatar,
  BorderIcon,
  ButtonWithIcon,
  CampaignReport,
  CardInfo,
  CardSkeleton,
  CarouselCards,
  ColumnReactIcon,
  DataGrid,
  DataGridSkeleton,
  Divider,
  Dot,
  MenuIcon,
  Modal,
  NotFoundMessage,
  PageContainer,
  StatisticRow,
  Tag,
  Title,
  TitleContainer,
} from "../../components";
import { FiltersType } from "../../components/TableFilter/types";
import { Modal as Modal2 } from "../../components/v2";
import { alert, capitalize, segment } from "../../utils";
import {
  DataText,
  DateContainer,
  PreviewAside,
  PreviewContainer,
  StatusContainer,
} from "./styles";
import { TagList } from "@components/TagList";
import { EditOwnersModal } from "src/modals";
import { EngagementModel } from "@models";
import { routes } from "@routes";

export default () => {
  const theme = useTheme() as any;

  const [t] = useTranslation("translations", {
    keyPrefix: "page",
  });
  const [ownersEditModal, setOwnersEditModal] = useState<{
    open: boolean;
    engagement?: EngagementModel;
  }>({
    open: false,
    engagement: undefined,
  });
  const { id = "" } = useParams();
  const navigate = useNavigate();
  const [reminderModalVisible, setReminderModalVisible] = useState(false);
  const [reminderModalErrorVisible, setReminderModalErrorVisible] =
    useState(false);
  const [reminderJobModalVisible, setReminderJobModalVisible] = useState(false);
  const [reminderJobEnabled, setReminderJobEnabled] = useState(false);
  const [reportEngagementId, setReportEngagementId] = useState<string | null>();
  const [showCampaignReport, setShowCampaignReport] = useState(false);
  const [recurrenceMode, setRecurrenceMode] = useState(false);

  const [recurrenceFilters, setRecurrenceFilters] = useState<FiltersType>([
    {
      id: "sendDate",
      type: "date",
      label: "Data de envio",
      valueInLabel: true,
    },
  ]);

  const { isLoading: reminderloading, mutate: reminder } =
    trpc.reminder.useMutation({
      onSuccess: () => {
        alert("Enviado com sucesso", "success");
        if (reminderModalVisible) setReminderModalVisible(false);
        if (reminderModalErrorVisible) setReminderModalErrorVisible(false);
      },
      onError: (err) => {
        if (err?.message?.includes("notifications.reminder.lastSentDate")) {
          // alert("O envio da notificação foi realizado há menos de 24 horas.");
          setReminderModalVisible(false);
          setReminderModalErrorVisible(true);
          return;
        }

        alert("Erro ao enviar notificações, tente novamente mais tarde.");
        console.log(err);
        setReminderModalVisible(false);
      },
    });

  const {
    isLoading: updateReminderStatusLoading,
    mutate: updateReminderStatus,
  } = trpc.updateReminderStatus.useMutation({
    onSuccess: (e) => {
      setReminderJobModalVisible(false);
      setReminderJobEnabled(false);
      alert("Atualizado com sucesso", "success");
    },
    onError: (err) => {
      alert("Erro ao atualizar notificações, tente novamente mais tarde.");
      console.log(err?.message);
      setReminderJobModalVisible(false);
    },
  });

  const { data: occurrences, isFetching: getRecurrenceByIdLoading } =
    trpc?.getRecurrenceById.useQuery(
      {
        id,
        sendDate: recurrenceFilters?.find(({ id }) => id === "sendDate")
          ?.dateRange?.from
          ? {
              from: dayjs(
                recurrenceFilters?.find(({ id }) => id === "sendDate")
                  ?.dateRange?.from
              )?.format("YYYY-MM-DD"),
              to: dayjs(
                recurrenceFilters?.find(({ id }) => id === "sendDate")
                  ?.dateRange?.to
              )?.format("YYYY-MM-DD"),
            }
          : undefined,
      },
      {
        retry: false,
        refetchOnWindowFocus: false,
        onError: (e: any) => {
          alert("Ocorreu um erro, tente novamente mais tarde.");
          console.log("err: ", e);
        },
      }
    );

  const {
    data: campaign,
    isFetching: loading,
    refetch,
  } = trpc.getEngagementById.useQuery(
    {
      id: id,
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        const e: any = data;

        if (e?.code === "EMPLOYEE_NOT_ENGAGEMENT_OWNER_ERROR") {
          alert(
            "Você não tem permissão para visualizar os detalhes desta comunicação."
          );
        }
        if (e?.notifications?.reminder?.enabled)
          setReminderJobEnabled(e?.notifications?.reminder?.enabled);
      },
      onError: (e: any) => {
        console.log(e);
        alert("Ocorreu um erro, tente novamente mais tarde.");
        console.log("err: ", e);
      },
    }
  );

  const { data: creator } = trpc.getEmployeeById.useQuery(
    { id: campaign?.createdBy ?? "" },
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: !!campaign?.createdBy,
    }
  );

  useEffect(() => {
    if (campaign?.recurrence && !recurrenceMode) setRecurrenceMode(true);
  }, [campaign]);

  const { refetch: getEngagementReportByEngagementIdRefetch } =
    trpc?.getEngagementReportByEngagementId?.useQuery(
      {
        screen:
          recurrenceMode && campaign?.recurrence && reportEngagementId === null
            ? "recurrence-communication-home"
            : "survey-recurrence-employee",
        type: "communication",
        id: reportEngagementId || id,
      },
      {
        retry: false,
        refetchOnWindowFocus: false,
        onError: (error: any) => {
          alert("Ocorreu um erro, tente novamente mais tarde.");
          console.log(error);
        },
        enabled: false,
      }
    );

  useEffect(() => {
    if (reportEngagementId || reportEngagementId === null)
      getEngagementReportByEngagementIdRefetch();
  }, [reportEngagementId]);

  const [filters, setFilters] = useState<FiltersType>([
    {
      id: "viewed",
      type: "boolean",
      label: "Visualização",
      valueInLabel: true,
      options: [
        {
          label: "Com visualização",
          value: "true",
        },
        {
          label: "Sem visualização",
          value: "false",
        },
      ],
    },
    {
      id: "clicked",
      type: "boolean",
      label: "Clique em link",
      valueInLabel: true,
      options: [
        {
          label: "Com clique",
          value: "true",
        },
        {
          label: "Sem clique",
          value: "false",
        },
      ],
    },
    {
      id: "like",
      type: "boolean",
      label: "Reação",
      valueInLabel: true,
      options: [
        {
          label: "Gostei",
          value: "1",
        },
        {
          label: "Amei",
          value: "2",
        },
        {
          label: "Apoio",
          value: "3",
        },
        {
          label: "Parabéns",
          value: "4",
        },
      ],
    },
  ]);

  const [showModal, setShowModal] = useState<
    "deleted" | "duplicated" | "closed" | "paused" | "progress"
  >();

  const handleModalMessage = (
    status: "deleted" | "duplicated" | "closed" | "paused" | "progress"
  ) => {
    if (status === "deleted")
      return "Sua comunicação foi deletada com sucesso.";
    if (status === "duplicated")
      return "Sua comunicação foi duplicada com sucesso.";
    if (status === "closed")
      return "Sua comunicação foi encerrada com sucesso.";
    if (status === "paused") return "Sua comunicação foi pausada com sucesso.";
    if (status === "progress")
      return "Sua comunicação foi retomada com sucesso.";
    return "";
  };

  const [currentPage, setCurrentPage] = useState<number>(0);
  const [search, setSearch] = useState<string>("");
  const [desc, setDesc] = useState<boolean>(false);
  const [pageSize, setPageSize] = useState(10);

  const {
    data: recurrenceEmployees,
    isFetching: getRecurrenceEmployeesLoading,
  } = trpc?.getRecurrenceEmployees.useQuery(
    { id },
    {
      retry: false,
      refetchOnWindowFocus: false,
      onError: (e) => {
        alert("Erro ao buscar destinatários, tente novamente mais tarde.");
        console.log("err: ", e);
      },
    }
  );

  const {
    data: employees,
    isFetching: getEmployeesByEngagementIdRefetchLoading,
    refetch: getEmployeesByEngagementIdRefetch,
  } = trpc.getEmployeesByEngagementId.useQuery(
    {
      id: id || "",
      index: currentPage * pageSize,
      pageSize,
      search: search,
      sort: {
        field: "name",
        type: desc ? "desc" : "asc",
      },
      viewed:
        filters
          .find((e) => e.id === "viewed")
          ?.options?.filter(({ checked }) => checked)
          ?.map(({ value }) => value) || [],
      clicked:
        filters
          .find((e) => e.id === "clicked")
          ?.options?.filter(({ checked }) => checked)
          ?.map(({ value }) => value) || [],
      like:
        filters
          .find((e) => e.id === "like")
          ?.options?.filter(({ checked }) => checked)
          ?.map(({ value }) => value) || [],
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
      onError: (e: any) => {
        alert("Ocorreu um erro, tente novamente mais tarde.");
        console.log("err: ", e);
      },
    }
  );

  useEffect(() => {
    getEmployeesByEngagementIdRefetch();
  }, [currentPage, filters]);

  useEffect(() => {
    if (currentPage !== 0) {
      setCurrentPage(0);
      return;
    }

    if (employees?.employees?.length) getEmployeesByEngagementIdRefetch();
  }, [search, desc, pageSize]);

  const copyEngagement = trpc.copyEngagement.useMutation({
    onSuccess: () => {
      setShowModal("duplicated");
    },
    onError: () => {
      alert("Ocorreu um erro, tente novamente mais tarde.");
    },
  });

  const copyCampaign = (campaignId: string) =>
    copyEngagement.mutate({ id: campaignId });

  const peopleTabColumns =
    recurrenceMode && campaign?.recurrence
      ? [
          {
            disableSortBy: true,
            Header: "Nome",
            accessor: "name",
            Cell: ({ row }: any) => {
              const { name, contacts, profilePicture } = row?.original;

              const email =
                contacts?.find(({ type }) => type === "email")?.value || "";
              const image = profilePicture?.path || "";

              return (
                <Avatar
                  name={campaign?.anonymous ? "Anônimo" : name}
                  email={campaign?.anonymous ? "" : email}
                  image={campaign?.anonymous ? "" : image}
                />
              );
            },
          },
        ]
      : [
          {
            disableSortBy: true,
            Header: "Nome",
            accessor: "name",
            Cell: ({ row }: any) => {
              const { name, contacts, profilePicture } = row?.original;

              const email =
                contacts?.find(({ type }) => type === "email")?.value || "";
              const image = profilePicture?.path || "";

              return (
                <Avatar
                  name={campaign?.anonymous ? "Anônimo" : name}
                  email={campaign?.anonymous ? "" : email}
                  image={campaign?.anonymous ? "" : image}
                />
              );
            },
          },
          {
            disableSortBy: true,
            id: "view",
            Header: "Visualização",
            accessor: "monitoring",
            Cell: ({ row }: any) => {
              return (
                <Tag variant={row?.original?.visualized ? "send" : "draft"}>
                  {row?.original?.visualized ? "Sim" : "Não"}
                </Tag>
              );
            },
          },
          {
            disableSortBy: true,
            id: "click",
            Header: "Clique em link",
            accessor: "monitoring",
            Cell: ({ row }: any) => {
              return (
                <Tag variant={row?.original?.open ? "send" : "draft"}>
                  {row?.original?.open ? "Sim" : "Não"}
                </Tag>
              );
            },
          },
          {
            disableSortBy: true,
            id: "like",
            Header: "Reação",
            Cell: ({ row }: any) => {
              const reaction = row?.original?.like;

              return typeof reaction === "number" && reaction ? (
                <ColumnReactIcon value={reaction} />
              ) : (
                <>-</>
              );
            },
          },
          {
            disableSortBy: true,
            accessor: "employeeId",
            sticky: "right",
            Cell: ({ value }) => (
              <BorderIcon
                name="IconUser"
                fill="transparent"
                onClick={() =>
                  navigate(`/engagement/employee/communication/${value}`)
                }
              />
            ),
          },
        ];

  const peopleTab = {
    label: "Pessoas",
    component: (
      <>
        {campaign?.employees?.length ||
        campaign?.recurrence?.employees?.length ? (
          campaign?.recurrence && recurrenceMode ? (
            <DataGrid
              loading={getRecurrenceEmployeesLoading}
              report={
                campaign?.status === "draft"
                  ? undefined
                  : {
                      type: "communication",
                      onExport: () => {
                        segment({
                          track:
                            "company_engagement_singlecommunicationdetail_exportreport_clicked",
                        });
                        setReportEngagementId(id);
                      },
                    }
              }
              emptyState={{
                isFiltered: true,
                message: "Nenhum resultado encontrado",
              }}
              data={recurrenceEmployees || []}
              columns={peopleTabColumns}
            />
          ) : (
            <DataGrid
              report={
                campaign?.status === "draft"
                  ? undefined
                  : {
                      type: "communication",
                      onExport: () => {
                        segment({
                          track:
                            "company_engagement_singlecommunicationdetail_exportreport_clicked",
                        });
                        setReportEngagementId(id);
                      },
                    }
              }
              emptyState={{
                isFiltered: true,
                message: "Nenhum resultado encontrado",
              }}
              loading={getEmployeesByEngagementIdRefetchLoading}
              initialState={{
                pageIndex: getEmployeesByEngagementIdRefetchLoading
                  ? 0
                  : currentPage,
                pageSize: getEmployeesByEngagementIdRefetchLoading
                  ? 0
                  : pageSize,
                // sortBy: [{ id: "name", desc: desc }],
              }}
              pageCount={
                getEmployeesByEngagementIdRefetchLoading
                  ? 0
                  : (employees?.total || 0) / pageSize || 10
              }
              fetchData={({ pageIndex, sortBy: sBy, pageSize: pgSize }) => {
                if (pgSize && pgSize !== pageSize) {
                  setPageSize(pgSize);
                  return;
                }

                if (sBy?.length && sBy[0]?.desc !== desc) {
                  setDesc(sBy[0]?.desc || false);
                  return;
                }
                setCurrentPage(pageIndex || 0);
              }}
              searchField={{
                onSearch: (e) => setSearch(e || ""),
                placeholder: "Buscar por nome",
              }}
              filterTags={
                recurrenceMode && campaign?.recurrence
                  ? undefined
                  : {
                      filters: filters,
                      onFilter: (e) => {
                        setCurrentPage(0);
                        setFilters(e);
                      },
                      onClear: (e) => {
                        setCurrentPage(0);
                        setFilters(e);
                      },
                    }
              }
              columns={peopleTabColumns}
              data={employees?.employees || []}
            />
          )
        ) : (
          <>
            <Divider size="large" />
            <NotFoundMessage
              title={"Você ainda não adicinou nenhuma pessoa"}
              message={"Não encontramos pessoas associados a essa comunicação."}
            >
              <Button
                size="large"
                variant="primary"
                onClick={() => navigate(`/engagement/communication/edit/${id}`)}
              >
                {"Editar comunicação"}
              </Button>
            </NotFoundMessage>
          </>
        )}
      </>
    ),
  };

  const DetailTab = {
    label: "Detalhes",
    component: (
      <PreviewContainer>
        <PreviewAside>
          <CardInfo
            style={{ flex: 1 }}
            direction="column"
            title="Detalhes"
            data={[
              {
                label: "Data da criação",
                value: dayjs(campaign?.createdAt)?.format("DD/MM/YYYY - HH:mm"),
              },
              {
                label: "Título da mensagem",
                value: campaign?.title || "-",
              },
              {
                label: "Mensagem",
                value: campaign?.message || "-",
              },
              {
                label: "Inserir link",
                value:
                  campaign?.link?.label && campaign?.link?.url
                    ? "Habilitado"
                    : "Desabilitado",
              },
              {
                label: "Texto do link",
                value: campaign?.link?.label || "-",
              },
              {
                label: "Link",
                value: campaign?.link?.url || "-",
              },
              {
                label: "Reações",
                value: campaign?.like ? "Habilitado" : "Desabilitado",
              },
              {
                label: "Imagem",
                value: campaign?.imageUrl?.path ? "Com imagem" : "Sem imagem",
              },
              {
                label: "Inserir PDF",
                value: campaign?.pdfFile?.path ? "Habilitado" : "Desabilitado",
              },
            ]}
          >
            {campaign?.pdfFile ? (
              <div>
                <Divider size="tiny" />

                <Typography
                  variant="caption"
                  variantColor={theme.colors.neutral[50]}
                  style={{ fontWeight: 700 }}
                >
                  Arquivo anexado
                </Typography>

                <UploadPreview file={campaign?.pdfFile} variant={"secondary"} />
              </div>
            ) : (
              <></>
            )}
          </CardInfo>
          <CardInfo
            style={{ flex: 1 }}
            direction="column"
            title="Configurações"
            data={[
              {
                label: "Anonimato",
                value: campaign?.anonymous
                  ? "Habilitado (as pessoas não serão identificadas)"
                  : "Não habilitado (as pessoas serão identificadas)",
              },
              {
                label: "Notificações",
                value:
                  campaign?.notifications?.push ||
                  campaign?.notifications?.email
                    ? `${
                        campaign?.notifications?.push
                          ? "Push do aplicativo"
                          : ""
                      } ${
                        campaign?.notifications?.email
                          ? campaign?.notifications?.push
                            ? "e e-mail-corporativo"
                            : "E-mail corporativo"
                          : ""
                      }`
                    : "-",
              },
              {
                label: "Envio da Notificação",
                value:
                  campaign?.notifications?.reminder?.enabled &&
                  campaign?.notifications?.reminder?.minRate
                    ? `Notificar a cada 3 dias até atingir a taxa de visualização de ${campaign?.notifications?.reminder?.minRate}%`
                    : "Desabilitado",
              },
              {
                label: "Criador",
                value: creator?.name ?? "",
              },
              {
                label: "Responsáveis",
                value: !campaign?.owners?.map((o) => o.id)?.includes("*") ? (
                  <TagList
                    tags={
                      campaign?.owners?.map((owner) => ({
                        id: owner?.id,
                        label: owner?.name,
                      })) ?? []
                    }
                  />
                ) : (
                  "Todos com acesso ao módulo"
                ),
              },
            ]}
          />
        </PreviewAside>
        <PreviewAside style={{ flex: 1 }}>
          <CardInfo title="Informações de envio">
            <CardInfo
              style={{
                backgroundColor: "var(--color-neutral-light1)",
              }}
              direction="row"
              data={
                recurrenceMode && campaign?.recurrence
                  ? [
                      {
                        icon: "IconHistory",
                        label: "Recorrência",
                        value:
                          campaign?.recurrence?.frequency &&
                          campaign?.recurrence?.repeat
                            ? `A cada ${campaign?.recurrence?.repeat} ${t(
                                campaign?.recurrence?.repeat > 1
                                  ? `${campaign?.recurrence?.frequency}s`
                                  : campaign?.recurrence?.frequency
                              )}`
                            : "",
                      },
                      {
                        icon: "IconCalendar",
                        label: "Dias de envio",
                        value:
                          campaign?.recurrence?.daysOfTheWeek &&
                          campaign?.recurrence?.daysOfTheWeek?.length
                            ? `${campaign?.recurrence?.daysOfTheWeek?.reduce(
                                (a, c, i) => {
                                  return `${a} ${t(c)}${
                                    i ===
                                    (campaign?.recurrence?.daysOfTheWeek
                                      ?.length || 0) -
                                      1
                                      ? ""
                                      : ","
                                  }`;
                                },
                                ""
                              )}`
                            : "-",
                      },
                      {
                        icon: "IconClockHour3",
                        label: "Período",
                        value: campaign?.startDate?.period
                          ? capitalize(t(campaign?.startDate?.period))
                          : "",
                      },
                      {
                        icon: "IconCalendar",
                        label:
                          recurrenceMode && campaign?.recurrence
                            ? "Início da recorrência"
                            : "Data de envio",
                        value: campaign?.sendDate
                          ? dayjs(campaign?.startDate?.date)?.format(
                              "DD/MM/YYYY"
                            )
                          : "-",
                      },
                      {
                        icon: "IconCalendarTime",
                        label: "Término da recorrência",
                        value: campaign?.recurrence?.endDate
                          ? dayjs(campaign?.recurrence?.endDate)?.format(
                              "DD/MM/YYYY"
                            )
                          : campaign?.recurrence?.occurrenceLimit === -1
                          ? "Nunca"
                          : `após ${campaign?.recurrence?.occurrenceLimit} ocorrências`,
                      },
                    ]
                  : [
                      {
                        icon: "IconCalendar",
                        label: "Data de envio",
                        value: campaign?.sendDate
                          ? dayjs(campaign?.sendDate)?.format(
                              "DD/MM/YYYY - HH:mm"
                            )
                          : "-",
                      },
                      {
                        icon: "IconClockHour3",
                        label: "Período",
                        value: campaign?.startDate?.period
                          ? capitalize(t(campaign?.startDate?.period))
                          : "-",
                      },
                    ]
              }
            />
          </CardInfo>

          <CardInfo title="Visualização do envio">
            <CardInfo
              title={campaign?.title || "-"}
              date={
                campaign?.sendDate
                  ? dayjs(campaign?.sendDate)?.format("DD/MM/YYYY - HH:mm")
                  : undefined
              }
              text={campaign?.message || "-"}
              imageSrc={campaign?.imageUrl?.signedUrl}
              style={{
                backgroundColor: "var(--color-neutral-light1)",
              }}
            ></CardInfo>
          </CardInfo>
        </PreviewAside>
      </PreviewContainer>
    ),
  };

  const deleteEngagement = trpc?.deleteEngagement.useMutation({
    onSuccess: () => {
      (() => {
        setShowModal("deleted");
      })();
    },
    onError: () => {
      alert("Ocorreu um erro, tente novamente mais tarde.");
    },
  });

  const updateRecurrenceStatus = trpc?.updateRecurrenceStatus.useMutation({
    onSuccess: (e) => {
      if (e?.recurrence?.status !== "scheduled")
        setShowModal(e?.recurrence?.status);
    },
    onError: (e) => {
      console.log(e);
      alert("Ocorreu um erro, tente novamente mais tarde.");
    },
  });

  const updateRecStatus = (
    id: string,
    status: "scheduled" | "progress" | "paused" | "closed"
  ) => {
    updateRecurrenceStatus.mutate({ id, status });
  };

  const SendTab = {
    label: "Envios",
    component: (
      <DataGrid
        loading={getRecurrenceByIdLoading}
        emptyState={{
          isFiltered: true,
          message: "Nenhum resultado encontrado",
        }}
        filterTags={{
          filters: recurrenceFilters,
          onFilter: (e) => setRecurrenceFilters(e),
          onClear: (e) => setRecurrenceFilters(e),
        }}
        report={
          campaign?.status === "draft"
            ? undefined
            : {
                type: "communication",
                onExport: () => {
                  segment({
                    track:
                      "company_engagement_recurringcommunicationdetail_exportreport_clicked",
                  });
                  setReportEngagementId(null);
                },
              }
        }
        columns={[
          {
            disableSortBy: true,
            id: "s-title",
            Header: "Data de envio",
            accessor: "sendDate",
            Cell: ({ value }: any) => {
              return (
                <>{value ? dayjs(value)?.format("DD/MM/YYYY - HH:mm") : ""}</>
              );
            },
          },
          {
            disableSortBy: true,
            id: "s-employee",
            Header: "Pessoas",
            accessor: "employees",
            Cell: ({ value }: any) => {
              return <>{value?.length || 0}</>;
            },
          },
          {
            disableSortBy: true,
            id: "s-view",
            Header: "Visualizações",
            accessor: "analytics",
            Cell: ({ value }: any) => {
              return <>{value?.visualization || 0}</>;
            },
          },
          {
            disableSortBy: true,
            id: "s-link",
            Header: "Cliques em link",
            accessor: "analytics",
            Cell: ({ value }: any) => {
              return <>{value?.open || 0}</>;
            },
          },
          {
            disableSortBy: true,
            id: "s-react",
            Header: "Reações",
            accessor: "analytics",
            Cell: ({ value }: any) => {
              return <>{value?.likeCount || 0}</>;
            },
          },
          {
            disableSortBy: true,
            id: "s-action",
            Header: "",
            accessor: "_id",
            sticky: "right",
            Cell: ({ value, row }: any) => {
              return (
                <>
                  <MenuIcon
                    options={[
                      {
                        label: "Ver detalhes do envio",
                        icon: "IconFileDescription",
                        onClick: () => {
                          segment({
                            track:
                              "company_engagement_recurringcommunicationdetail_viewsend_clicked",
                          });
                          value === id && recurrenceMode && campaign?.recurrence
                            ? setRecurrenceMode(false)
                            : navigate(`/engagement/communication/${value}`);
                        },
                      },
                      {
                        label: "Exportar relatório da comunicação",
                        icon: "IconDownload",
                        onClick: () => {
                          segment({
                            track:
                              "company_engagement_recurringcommunicationdetail_exportsendreport_clicked",
                          });
                          setReportEngagementId(value);
                          setShowCampaignReport(!showCampaignReport);
                        },
                        disabled: row?.original?.status === "draft",
                      },
                    ]}
                  />
                </>
              );
            },
          },
        ]}
        data={occurrences || []}
      />
    ),
  };

  if ((campaign as any)?.code === "EMPLOYEE_NOT_ENGAGEMENT_OWNER_ERROR")
    return (
      <Error
        title="Você não tem permissão para acessar os detalhes"
        description="Se achar que isso é um erro, entre em contato com seu gestor."
        buttonLabel="Voltar ao Início"
        buttonCallback={() => navigate(-1)}
      />
    );

  return (
    <PageContainer>
      {campaign?.engagementOrigin ||
      (!recurrenceMode && campaign?.recurrence) ? (
        <Breadcrumbs
          separator=">"
          breadcrumbs={[
            <Link to="/engagement/communication">{"Comunicação"}</Link>,
            <button
              onClick={() =>
                campaign?.recurrence && recurrenceMode === false
                  ? setRecurrenceMode(true)
                  : navigate(-1)
              }
            >
              {"Detalhe da comunicação"}
            </button>,
            <Link to={`/engagement/communication/${id}`}>
              {"Detalhe do envio"}
            </Link>,
          ]}
        />
      ) : (
        <Breadcrumbs
          separator=">"
          breadcrumbs={[
            <Link to="/engagement/communication">{"Comunicação"}</Link>,
            <Link to={`/engagement/communication/${id}`}>
              {"Detalhe da comunicação"}
            </Link>,
          ]}
        />
      )}
      <Divider />
      {loading ? (
        <>
          <CardSkeleton />
          <Divider />
          <DataGridSkeleton />
        </>
      ) : (
        <>
          <TitleContainer>
            <div>
              <StatusContainer>
                <Title>{campaign?.name}</Title>
                {!campaign?.recurrence?.status && !campaign?.status ? (
                  ""
                ) : campaign?.status === "draft" ? (
                  <Tag variant={"draft"}>{capitalize(t(`status.draft`))}</Tag>
                ) : (
                  <Tag
                    variant={
                      recurrenceMode && campaign?.recurrence?.status
                        ? campaign?.recurrence?.status
                        : campaign?.status
                    }
                  >
                    {capitalize(
                      t(
                        `status.${
                          campaign?.recurrence?.status === "scheduled"
                            ? "notStarted"
                            : recurrenceMode && campaign?.recurrence?.status
                            ? campaign?.recurrence?.status
                            : campaign?.status
                        }`
                      )
                    )}
                  </Tag>
                )}
                {campaign?.anonymous ? (
                  <Tag variant="neutral">{"Resposta anônima"}</Tag>
                ) : null}
              </StatusContainer>
              <DateContainer>
                <DataText variant="body4">
                  {"Criado em"} {"   "}
                  {dayjs(campaign?.createdAt).format("DD/MM/YYYY - HH:mm")}
                </DataText>
                {campaign?.sendDate ? (
                  <>
                    <Dot />
                    <DataText variant="body4">
                      {"Enviado em"} {"   "}
                      {dayjs(campaign?.sendDate).format("DD/MM/YYYY - HH:mm")}
                    </DataText>
                  </>
                ) : null}
              </DateContainer>
            </div>
            {!recurrenceMode || campaign?.engagementOrigin ? (
              <>
                {campaign?.recurrence || campaign?.engagementOrigin ? (
                  <></>
                ) : (
                  <MenuIcon
                    options={[
                      {
                        label: "Duplicar comunicação",
                        icon: "IconCopy",
                        onClick: () => {
                          segment({
                            track:
                              "company_engagement_singlecommunicationdetail_duplicatecommunication_clicked",
                          });

                          copyCampaign(id);
                        },
                      },
                      {
                        label: "Enviar notificação",
                        icon: "IconBell",
                        disabled:
                          typeof campaign?.notifications?.reminder?.enabled !==
                          "boolean"
                            ? true
                            : false,
                        onClick: () => {
                          if (campaign?._id) {
                            const today = dayjs()?.locale("pt-br");
                            const twentyFourHoursAgo = today?.subtract(
                              24,
                              "hour"
                            );
                            const notificationDate =
                              campaign?.notifications?.reminder?.lastSentDate ||
                              campaign?.sendDate;

                            if (notificationDate) {
                              if (
                                twentyFourHoursAgo?.diff(notificationDate) < 0
                              ) {
                                setReminderModalErrorVisible(
                                  !reminderModalErrorVisible
                                );
                              } else {
                                setReminderModalVisible(!reminderModalVisible);
                              }
                            }
                          }
                        },
                      },
                      {
                        label: "Parar notificações automáticas",
                        icon: "IconBellX",
                        disabled: !campaign?.notifications?.reminder?.enabled
                          ? true
                          : false,
                        onClick: () => {
                          if (campaign?._id)
                            setReminderJobModalVisible(
                              !reminderJobModalVisible
                            );
                        },
                      },
                      {
                        label: "Editar comunicação",
                        icon: "IconPencil",
                        disabled:
                          campaign?.status &&
                          ["draft", "scheduled"]?.includes(campaign?.status)
                            ? false
                            : true,
                        onClick: () => {
                          segment({
                            track:
                              "company_engagement_communicationdetail_editcommunication_clicked",
                          });
                          navigate(`/engagement/communication/edit/${id}`);
                        },
                      },
                      {
                        label: "Editar responsáveis",
                        icon: "IconCheckupList",
                        onClick: () => {
                          setOwnersEditModal({
                            open: true,
                            engagement: campaign as any,
                          });
                        },
                      },
                      {
                        label: "Excluir comunicação",
                        icon: "IconTrash",
                        disabled:
                          campaign?.status &&
                          ["draft", "scheduled"]?.includes(campaign?.status)
                            ? false
                            : true,
                        onClick: () => {
                          segment({
                            track:
                              "company_engagement_communicationdetail_deletecommunication_clicked",
                          });
                          deleteEngagement.mutate({ id });
                        },
                      },
                    ]}
                  />
                )}
              </>
            ) : (
              <></>
            )}
            {recurrenceMode && campaign?.recurrence ? (
              <MenuIcon
                options={[
                  {
                    label: "Retomar comunicação",
                    icon: "IconPlayerPlay",
                    onClick: () => {
                      segment({
                        track: `company_engagement_recurringcommunicationdetail_resumecommunication_clicked`,
                      });
                      updateRecStatus(id, "progress");
                    },
                    disabled:
                      campaign?.recurrence?.status === "paused" ? false : true,
                  },
                  {
                    label: "Encerrar comunicação",
                    icon: "IconX",
                    onClick: () => {
                      segment({
                        track:
                          "company_engagement_recurringcommunicationdetail_closeucommunication_clicked",
                      });
                      updateRecStatus(id, "closed");
                    },
                    disabled:
                      campaign?.recurrence?.status &&
                      ["paused", "progress", "scheduled"]?.includes(
                        campaign?.recurrence?.status
                      ) &&
                      campaign?.status !== "draft"
                        ? false
                        : true,
                  },
                  {
                    label: "Pausar comunicação",
                    icon: "IconPlayerPause",
                    onClick: () => {
                      segment({
                        track:
                          "company_engagement_recurringcommunicationdetail_pausecommunication_clicked",
                      });
                      updateRecStatus(id, "paused");
                    },
                    disabled:
                      campaign?.recurrence?.status === "progress"
                        ? false
                        : true,
                  },
                  {
                    label: "Duplicar comunicação",
                    icon: "IconCopy",
                    onClick: () => {
                      segment({
                        track:
                          "company_engagement_recurringcommunicationdetail_duplicatecommunication_clicked",
                      });
                      copyCampaign(id);
                    },
                  },
                  {
                    label: "Editar comunicação",
                    icon: "IconPencil",
                    disabled:
                      (campaign?.recurrence?.status &&
                        ["draft", "scheduled", "progress"]?.includes(
                          campaign?.recurrence?.status
                        )) ||
                      campaign?.status === "draft"
                        ? false
                        : true,
                    onClick: () => {
                      segment({
                        track:
                          "company_engagement_recurringcommunicationdetail_editcommunication_clicked",
                      });
                      navigate(`/engagement/communication/edit/${id}`);
                    },
                  },
                  {
                    label: "Editar responsáveis",
                    icon: "IconCheckupList",
                    onClick: () => {
                      setOwnersEditModal({
                        open: true,
                        engagement: campaign as any,
                      });
                    },
                  },
                  {
                    label: "Excluir comunicação",
                    icon: "IconTrash",
                    disabled:
                      campaign?.status &&
                      ["draft", "scheduled"]?.includes(campaign?.status)
                        ? false
                        : true,
                    onClick: () => deleteEngagement.mutate({ id }),
                  },
                ]}
              />
            ) : (
              <></>
            )}
          </TitleContainer>
          {/* <Divider size="medium" /> */}
          {/* {campaign?.message || campaign?.question ? (
            <MessageContainer>
              <QuotationMarks>{`“`}</QuotationMarks>
              <Message variant="headline7">{campaign?.message}</Message>
            </MessageContainer>
          ) : null} */}
          <Divider size="medium" />
          {loading ? (
            <CardSkeleton />
          ) : (
            <CarouselCards
              cards={
                recurrenceMode && campaign?.recurrence
                  ? [
                      {
                        title: "Envios",
                        icon: "IconSpeakerphone",
                        value: occurrences?.length || 0,
                      },
                      {
                        title: "Total de destinatários",
                        icon: "IconUser",
                        value:
                          campaign?.recurrence?.employees?.length ||
                          campaign?.analytics?.employees ||
                          0,
                      },
                      {
                        title: "Taxa média de visualização",
                        icon: "IconEyeCheck",
                        value:
                          (occurrences?.reduce(
                            (a, c) =>
                              a + (c?.analytics?.visualizationRate || 0),
                            0
                          ) || 0) / (occurrences?.length || 0) || 0,
                        subValue:
                          occurrences?.reduce(
                            (a, c) => a + (c?.analytics?.visualization || 0),
                            0
                          ) || 0,
                      },
                      {
                        title: "Taxa média de clique em link",
                        icon: "IconHandClick",
                        value:
                          (occurrences?.reduce(
                            (a, c) => a + (c?.analytics?.openRate || 0),
                            0
                          ) || 0) / (occurrences?.length || 0) || 0,
                        subValue:
                          occurrences?.reduce(
                            (a, c) => a + (c?.analytics?.open || 0),
                            0
                          ) || 0,
                      },
                    ]
                  : [
                      {
                        title: "Total de destinatários",
                        icon: "IconSpeakerphone",
                        value: campaign?.analytics?.employees || 0,
                      },
                      {
                        title: "Taxa de visualização",
                        icon: "IconEyeCheck",
                        value: campaign?.analytics?.visualizationRate || 0,
                        subValue: campaign?.analytics?.visualization || 0,
                        toolTip: {
                          title: "Taxa de visualização",
                          description:
                            "A taxa é calculada a partir do total de destinatários que acessaram a área de notificações e visualizaram as mensagens no feed dividido pelo total de destinatários da campanha.",
                        },
                      },
                      {
                        title: "Taxa de clique em link",
                        icon: "IconHandClick",
                        value: campaign?.analytics?.openRate || 0,
                        subValue: campaign?.analytics?.open || 0,
                        toolTip: {
                          title: "Taxa de clique em link",
                          description:
                            "Quantidade de pessoas que clicaram no link da mensagem (somente quando possui link). A taxa é calculada em relação ao total de destinatários.",
                        },
                      },
                      {
                        title: "Reações",
                        icon: "IconMoodHappy",
                        value: campaign?.analytics?.likeCount || 0,
                        toolTip: {
                          title: "Reações",
                          description:
                            "A taxa é calculada a partir do total de reações na comunicação dividido pelo total de destinatários que receberam a comunicação.",
                        },
                      },
                      {
                        title: "Like",
                        icon: "IconThumbUp",
                        value:
                          campaign?.analytics?.like &&
                          campaign?.analytics?.like["1"]
                            ? campaign?.analytics?.like["1"]
                            : 0,
                      },
                      {
                        title: "Amei",
                        icon: "IconHeart",
                        value:
                          campaign?.analytics?.like &&
                          campaign?.analytics?.like["2"]
                            ? campaign?.analytics?.like["2"]
                            : 0,
                      },
                      {
                        title: "Apoio",
                        icon: "IconHeartHandshake",
                        value:
                          campaign?.analytics?.like &&
                          campaign?.analytics?.like["3"]
                            ? campaign?.analytics?.like["3"]
                            : 0,
                      },
                      {
                        title: "Parabéns",
                        icon: "IconConfetti",
                        value:
                          campaign?.analytics?.like &&
                          campaign?.analytics?.like["4"]
                            ? campaign?.analytics?.like["4"]
                            : 0,
                      },
                    ]
              }
            />
          )}
          <NewProvider>
            <>
              {campaign?.recurrence?.active && recurrenceMode ? (
                <>
                  <Divider />
                  <StatisticRow
                    data={[
                      {
                        label: "Criado em",
                        value: dayjs(campaign?.createdAt)?.format("DD/MM/YYYY"),
                      },
                      {
                        label: "Início em",
                        value: dayjs(campaign?.startDate?.date)?.format(
                          "DD/MM/YYYY"
                        ),
                      },
                      {
                        label: "Término em",
                        value: campaign?.recurrence?.endDate
                          ? dayjs(campaign?.recurrence?.endDate)?.format(
                              "DD/MM/YYYY"
                            )
                          : "-",
                      },
                    ]}
                    rightContent={
                      campaign?.status !== "draft" ? (
                        campaign?.recurrence?.status !== "paused" ? (
                          <ButtonWithIcon
                            icon={{
                              name: "IconPlayerPause",
                              position: "right",
                              style: { fill: "transparent" },
                            }}
                            variant="secondary"
                            variantType="error"
                            size={"small"}
                            onClick={() => updateRecStatus(id, "paused")}
                          >
                            Pausar recorrência
                          </ButtonWithIcon>
                        ) : (
                          <ButtonWithIcon
                            icon={{
                              name: "IconPlayerPlay",
                              position: "right",
                              style: { fill: "transparent" },
                            }}
                            variant="secondary"
                            variantType="error"
                            size={"small"}
                            onClick={() => updateRecStatus(id, "progress")}
                          >
                            Retomar recorrência
                          </ButtonWithIcon>
                        )
                      ) : null
                    }
                  />
                </>
              ) : null}
              <Divider />
              <Divider />
              <Tab
                tabItens={
                  recurrenceMode && campaign?.recurrence
                    ? [SendTab, DetailTab, peopleTab]
                    : [peopleTab, DetailTab]
                }
              />
            </>
            <Modal
              title={"Pronto"}
              message={handleModalMessage(showModal || "duplicated")}
              open={showModal ? true : false}
              rightButton={{
                label: "Voltar para comunicação",
                onClick: () => navigate(`/engagement/communication`),
              }}
              status="success"
              onClose={() => navigate(`/engagement/communication`)}
            />
          </NewProvider>
        </>
      )}
      <CampaignReport
        hideMode
        type={"communication"}
        visible={showCampaignReport}
        onClick={() => {
          setShowCampaignReport(!showCampaignReport);
        }}
        onExport={() => null}
        onClose={() => {
          setShowCampaignReport(!showCampaignReport);
        }}
        onFinish={() => {
          setShowCampaignReport(!showCampaignReport);
        }}
        texts={{
          message: (
            <>
              O relatório apresentará somente os <strong>dados dessa</strong>
              <br />
              <strong>comunicação</strong> convertidos em um arquivo CSV.
            </>
          ),
        }}
      />
      <Modal2
        open={reminderModalVisible}
        onClose={() => setReminderModalVisible(!reminderModalVisible)}
        title="Atenção!"
        subTitle={`Tem certeza que deseja <br/> enviar a notificação <br/> novamente?`}
        description={`${
          (campaign?.employees?.length || 0) -
          (campaign?.analytics?.visualization || 0)
        } pessoas que ainda não visualizaram esta comunicação serão notificadas.`}
        iconStatus="alert"
        closeButton
        iconName="IconAlertCircle"
        footer={{
          closeButton: {
            label: "Não enviar",
            onClick: () => setReminderModalVisible(!reminderModalVisible),
            variant: "neutral",
          },
          submitButton: {
            label: "Enviar notificação",
            loading: reminderloading || false,
            onClick: () => {
              reminder({ engagementId: campaign?._id || "" });
            },
            variant: "secondary",
            variantType: "error",
          },
        }}
      />
      <Modal2
        open={reminderModalErrorVisible}
        onClose={() => setReminderModalErrorVisible(!reminderModalErrorVisible)}
        title="Atenção!"
        subTitle={`O envio da notificação foi <br/> realizado há menos de 24 <br/> horas.`}
        description="Notificar os colaboradores em um curto espaço de tempo <br/> pode gerar incômodo e prejudicar o engajamento da sua <br/> comunicação."
        iconStatus="alert"
        closeButton
        iconName="IconAlertCircle"
        footer={{
          closeButton: {
            label: "Não enviar",
            onClick: () =>
              setReminderModalErrorVisible(!reminderModalErrorVisible),
            variant: "neutral",
          },
          submitButton: {
            label: "Notificar mesmo assim",
            loading: reminderloading || false,
            onClick: () => {
              reminder({ engagementId: campaign?._id || "", force: true });
            },
            variant: "secondary",
            variantType: "error",
          },
        }}
      />
      <Modal2
        open={reminderJobModalVisible}
        onClose={() => setReminderJobModalVisible(!reminderJobModalVisible)}
        title="Atenção!"
        subTitle={`Tem certeza que deseja parar <br/> o envio automático de <br/> notificações?`}
        description={`${
          (campaign?.employees?.length || 0) -
          (campaign?.analytics?.visualization || 0)
        } pessoas ainda não visualizaram <br/> esta comunicação e não serão mais notificadas.`}
        iconStatus="alert"
        closeButton
        iconName="IconAlertCircle"
        footer={{
          closeButton: {
            label: "Não enviar",
            onClick: () => setReminderJobModalVisible(!reminderJobModalVisible),
            variant: "neutral",
          },
          submitButton: {
            label: "Notificar mesmo assim",
            loading: updateReminderStatusLoading || false,
            onClick: () => {
              updateReminderStatus({ engagementId: campaign?._id || "" });
            },
            variant: "secondary",
            variantType: "error",
          },
        }}
      />
      <EditOwnersModal
        open={ownersEditModal?.open}
        engagement={ownersEditModal?.engagement}
        onClose={() =>
          setOwnersEditModal({
            open: false,
            engagement: undefined,
          })
        }
        onSuccess={() => refetch()}
      />
    </PageContainer>
  );
};
