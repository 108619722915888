import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  flex-direction: column;
`;

const ContentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  padding: 24px;
  background-color: var(--color-neutral-95);
  border: 1px solid var(--color-neutral-90);
  border-radius: 8px;

  @media screen and (max-width: 1100px) {
    width: 100%;
    max-width: 100%;
    margin-right: 0;
    margin-bottom: 50px;
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
  width: 100%;
`;

const Thermometer = styled.div<{ color?: string; width?: number }>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 10px;
  background-color: var(--color-neutral-80);
  border-radius: 12px;
  width: 100%;

  :before {
    content: "";
    height: 10px;
    background-color: ${({ color }) => color || "pink"};
    border-radius: 12px;
    width: ${({ width }) => (width ? `${width}%` : 0)};
  }
`;

const YellowContainer = styled.div`
  width: 100%;
  border-radius: 0;

  > div {
    :before {
      border-radius: 0;
    }
  }
`;

const RedContainer = styled(YellowContainer)`
  width: 100%;
`;

const GreenContainer = styled(YellowContainer)``;

const ChartRow = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;

  @media screen and (max-width: 1100px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

export {
  ContentContainer,
  Thermometer,
  RedContainer,
  YellowContainer,
  GreenContainer,
  Row,
  Container,
  ChartRow,
};
