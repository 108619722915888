import styled from "styled-components";
import { SpacingType, DividerInterface } from "./types";

const handleHeight = (size: SpacingType) => {
  const spacing = {
    quark: "4px",
    xxxs: "8px",
    xxs2: "12px",
    xxs3: "16px",
    xs1: "20px",
    xs2: "24px",
    s1: "32px",
    s3: "40px",
    m1: "48px",
    m2: "56px",
    m3: "64px",
    l1: "72px",
    l2: "80px",
    l3: "88px",
    xl1: "96px",
    xl2: "104px",
    xl3: "112px",
    xxl1: "120px",
    xxl2: "128px",
    xxl3: "136px",
    xxxl: "144px",
    huge: "152px",
    giang: "160px",
  };

  return spacing[size];
};

const Divider = styled.div<{
  spacing: SpacingType;
  type?: DividerInterface["type"];
}>`
  min-height: ${({ spacing, type }) =>
    type === "horizontal" ? "1px" : handleHeight(spacing)};
  min-width: ${({ spacing, type }) =>
    type === "horizontal" ? handleHeight(spacing) : "100%"};
  margin: 0;
  padding: 0;
`;

export { Divider };
