import { BoxAdornment, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled, { css } from "styled-components";

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const FlexBetween = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  flex-direction: row;
  width: 100%;
`;

const PageTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[20]};
  font-weight: 700;
  margin-bottom: 56px;
`;

const PageInfo = styled.div`
  margin-right: 24px;
`;

const PageSubTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.secondary[50]};
  margin-bottom: 16px;
  font-weight: 700;
`;

const PageDescription = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[50]};
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
`;

const AccordionContainer = styled.div`
  width: 100%;
`;

const StyledBoxAdornment = styled(BoxAdornment)<{ error?: boolean }>`
  &.box-adornment-container-custom-theme {
    ${({ error }) =>
      error
        ? css`
            background: ${({ theme }) =>
              theme.colors.feedback.error[90]} !important;
            border: 2px solid ${({ theme }) => theme.colors.feedback.error[50]} !important;
          `
        : css``}
  }
`;

const BorderDivider = styled.div`
  width: 100%;
  height: 1px;
  border: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  margin: 20px 0px;
`;

export {
  PageInfo,
  PageTitle,
  PageSubTitle,
  PageDescription,
  FlexColumn,
  FlexBetween,
  AccordionContainer,
  StyledBoxAdornment,
  BorderDivider,
};
