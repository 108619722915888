import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { DataGrid, Avatar, BorderIcon } from "..";
import { alert } from "../../utils";
import { trpc } from "@api/client";

const PeopleTable = ({
  originPage,
  getTotalEmployees,
}: {
  originPage: string;
  getTotalEmployees?: (total: number) => void;
}) => {
  const navigate = useNavigate();
  const [t] = useTranslation("translations", {
    keyPrefix: "home",
  });

  const [search, setSearch] = useState<string>("");
  const [employees, setEmployees] = useState<{ _id: string; name: string }[]>(
    []
  );

  const { refetch: getInternalEmployeesByCompanyId, isFetching: loading } =
    trpc?.getInternalEmployeesByCompanyId?.useQuery(
      {
        status: "ACTIVE",
      },
      {
        enabled: false,
        retry: false,
        refetchOnWindowFocus: false,
        onSuccess: (data) => {
          if (data?.employees?.length) {
            setEmployees([...data?.employees]);
            if (getTotalEmployees)
              getTotalEmployees(data?.employees?.length || 0);
          }
        },
        onError: (e: any) => {
          alert(t("error"));
          console.log("err: ", e);
        },
      }
    );

  useEffect(() => {
    getInternalEmployeesByCompanyId();
  }, []);

  return (
    <DataGrid
      loading={loading}
      searchField={{
        onSearch: (e) => setSearch(e || ""),
        placeholder: t("placeholder.employee"),
      }}
      columns={[
        {
          Header: t("column.recipients"),
          accessor: "name",
          disableSortBy: true,
          Cell: ({ row }: any) => {
            const { name, contacts, profilePicture } = row?.original;

            const email =
              contacts?.find(({ type }) => type === "email")?.value || "";
            const image = profilePicture?.path || "";

            return <Avatar name={name} email={email} image={image} />;
          },
        },
        {
          disableSortBy: true,
          accessor: "_id",
          sticky: "right",
          Cell: ({ value }) => (
            <BorderIcon
              name="IconUser"
              fill="transparent"
              onClick={() =>
                navigate(`/engagement/employee/${originPage}/${value}`)
              }
            />
          ),
        },
      ]}
      data={
        employees?.filter(({ name }) => {
          if (name?.match(RegExp(search, "ig"))) return true;
          return false;
        }) || []
      }
    />
  );
};

export { PeopleTable };
