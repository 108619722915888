import styled from "styled-components";

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const AnswerFormatTagContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const QuestionContainer = styled(AnswerFormatTagContainer)`
  justify-content: flex-start;
`;

export { Header, AnswerFormatTagContainer, QuestionContainer };
