import styled from "styled-components";

const Container = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

const IconContainer = styled.div<{ backgroundColor: string }>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  border-radius: 100%;
  background-color: ${({ backgroundColor }) => backgroundColor || "red"};
`;

const Label = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
`;

export { Container, IconContainer, Label };
