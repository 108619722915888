import { Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const WarnArea = styled.div<{ type?: "info" | "error" }>`
  border: 1px solid
    ${({ type }) =>
      type === "info" ? "var(--color-feedback-info-70)" : "#FEC380"};
  border-radius: 8px;
  padding: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const WarnIcon = styled.div<{ type?: "info" | "error" }>`
  background: ${({ type }) =>
    type === "info" ? "var(--color-feedback-info-90)" : "#FFECD6"};
  padding: 8px;
  border-radius: 200px;
  margin-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    color: ${({ type }) =>
      type === "info" ? "var(--color-feedback-info-50)" : "#C96C01"};
  }
`;

export const WarnTitle = styled(Typography)<{ type?: "info" | "error" }>`
  color: ${({ type }) =>
    type === "info" ? "var(--color-feedback-info-10)" : "#4C2900"};
  line-height: 22px;
`;

export const WarnDescription = styled(Typography)<{ type?: "info" | "error" }>`
  color: ${({ type }) =>
    type === "info" ? "var(--color-feedback-info-10)" : "#4C2900"};
  line-height: 20px;
  margin-bottom: 6px;
`;

export const CloseButton = styled.div<{ type?: "info" | "error" }>`
  align-self: self-start;
  cursor: pointer;
  svg {
    color: ${({ type }) =>
      type === "info" ? "var(--color-feedback-info-40)" : "#4C2900"};
  }
`;
