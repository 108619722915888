import styled from "styled-components";

const Container = styled.div<{ isDragging?: boolean; selected?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  width: 100%;
  padding: 8px 16px 8px 8px;
  user-select: none;
  border: 1px solid
    ${({ isDragging, selected }) =>
      isDragging || selected
        ? "var(--color-primary)"
        : "var(--color-neutral-90)"};
  background-color: ${({ isDragging, selected }) =>
    isDragging || selected
      ? "var(--color-secondary-95)"
      : "var(--color-neutral-100)"};
  transform: ${({ isDragging }) => (isDragging ? "rotate(10deg)" : "none")};
`;

const LeftContainer = styled.div`
  display: flex;
  align-items: center;
  justify-items: flex-start;
`;

const RightContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const NumberContainer = styled.div<{ isSelected?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: ${({ theme, isSelected }) =>
    isSelected ? "transparent" : theme.colors.neutral[90]};
  width: 22px;
  height: 20px;
`;

export { Container, LeftContainer, RightContainer, NumberContainer };
