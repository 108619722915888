import { PageContainer as PageConatinerWbUi } from "@flash-tecnologia/hros-web-ui-v2";
import { ReactNode } from "react";
import { Container } from "./styles";

const PageContainer = ({ children }: { children: ReactNode }) => (
  <Container>
    <PageConatinerWbUi>{children}</PageConatinerWbUi>
  </Container>
);
export { PageContainer };
