import { useParams } from "react-router-dom";
import { EmployeeDetail } from "../../components";

export default () => {
  const { id, type } = useParams();

  const campaignType = type === "communication" ? "communication" : "survey";

  return <EmployeeDetail id={id || ""} type={campaignType} />;
};
