import React from "react";
import { Icons } from "@flash-tecnologia/hros-web-ui-v2";
import { backgroundColors, fontColors, icons } from "./types";
import { Container, IconContainer, Label } from "./styles";
import { useTranslation } from "react-i18next";

const ColumnReactIcon = ({ value }: { value: number }) => {
  const [t] = useTranslation("translations", {
    keyPrefix: "detailEmployee",
  });

  const convertValueToLabel = (reactValue: number) => {
    const labels = ["like", "heart", "support", "congrats"];
    return labels[reactValue];
  };

  const reactionType = convertValueToLabel((value || 1) - 1);

  return (
    <Container>
      <IconContainer backgroundColor={backgroundColors[reactionType]}>
        <Icons
          name={icons[reactionType]}
          color={fontColors[reactionType]}
          fill={backgroundColors[reactionType]}
          size={18}
        />
      </IconContainer>
      <Label>{t(`column.reaction.${reactionType}`)}</Label>
    </Container>
  );
};

export { ColumnReactIcon };
