import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  border: 1px solid var(--color-neutral-90);
  border-radius: 12px;
  padding: 16px 24px 16px 24px;
  min-height: 56px;
  width: 100%;
  margin-bottom: 24px;
`;

const DateContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

export { Container, DateContainer };
