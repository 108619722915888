import styled from "styled-components";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-content: stretch;
  width: 100%;
  min-height: 900px;
  height: 100%;
`;

const DataGridContainer = styled.div`
  width: calc(100% - 196px);
  border-top: 1px solid var(--color-neutral-90);
  border-left: 1px solid var(--color-neutral-90);
  border-bottom: 1px solid var(--color-neutral-90);
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  padding: 32px 24px 32px 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;

  .data-grid-table-header-container > tr > th:first-child {
    width: 100px !important;
  }
`;

const DataGridTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
`;

const DataGridSearchFieldContainer = styled(DataGridTitleContainer)`
  justify-content: space-between;
`;

const NameColumn = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
`;

const NameColumnActionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
`;

export {
  Container,
  DataGridContainer,
  DataGridTitleContainer,
  DataGridSearchFieldContainer,
  NameColumn,
  NameColumnActionContainer,
};
