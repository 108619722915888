import { useState } from "react";
import { getAccessTokenPayloadSync } from "@flash-tecnologia/hros-web-utility";
import {
  Icons,
  Modal as ModalWebUi,
  Button,
  Tooltip,
} from "@flash-tecnologia/hros-web-ui-v2";
import { trpc } from "@api/client";
import { BorderIcon, Space, Divider, Text } from "../";
import { CampaignReportInterface } from "./types";
const { Root: Modal } = ModalWebUi;
import {
  ContentContainer,
  ContentIconContainer,
  Footer,
  Header,
  IconContainer,
} from "./styles";

const CampaignReport = ({
  type,
  email: emailValeu,
  visible,
  hideMode,
  texts,
  onClick,
  onClose,
  onExport,
  onFinish,
}: CampaignReportInterface) => {
  const { employeeId } = getAccessTokenPayloadSync("hrosAccessToken");
  const [email, setEmail] = useState(emailValeu || "");
  const [step, setStep] = useState(0);
  const campaignType = type === "survey" ? "pesquisa" : "comunicação";

  const { isFetching } = trpc?.getEmployeeById?.useQuery(
    {
      id: employeeId,
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
      onSuccess: (e) => {
        if (!email) setEmail(e?.corporateEmail || e?.email || "");
      },
      onError: (error: any) => console.log(error),
      enabled: true,
    }
  );

  return (
    <>
      {hideMode ? null : (
        <Tooltip
          arrow
          placement="top"
          children={
            <div>
              <BorderIcon onClick={onClick} name="IconDownload" fill="white" />
            </div>
          }
          title={<div>{texts?.toolTip || "Exportar relatório"}</div>}
        />
      )}
      <Modal
        open={visible && step === 0}
        onClose={onClose}
        footer={
          <Footer>
            <Space size="xl2" />
            <Button variant="secondary" size="large" onClick={onClose}>
              Cancelar
            </Button>
            <Space size="xl2" />
            <Space size="xl2" />
            <Button
              variant="primary"
              size="large"
              onClick={() => {
                if (onExport) onExport();
                setStep(1);
              }}
            >
              Exportar relatório
            </Button>
            <Space size="xl2" />
          </Footer>
        }
        header={
          <Header>
            <BorderIcon
              onClick={onClose}
              name={"IconX"}
              fill="var(--color-neutral-dark4)"
              color="var(--color-neutral-dark4)"
              size={15}
            />
          </Header>
        }
      >
        <ContentContainer>
          <ContentIconContainer>
            <IconContainer>
              <Icons
                name={"IconTable"}
                color="var(--color-secondary-50)"
                fill="var(--color-secondary-95)"
                size={33}
              />
            </IconContainer>
          </ContentIconContainer>
          <Divider />
          <Text variant={"headline6"} color="var(--color-neutral-20)">
            {texts?.title ? texts?.title : <> Deseja exportar o relatório?</>}
          </Text>
          <Divider />
          <Text variant="body3" color="var(--color-neutral-50)">
            {texts?.message ? (
              texts?.message
            ) : (
              <>
                O relatório apresentará somente os{" "}
                <strong>dados dessa tabela</strong>
                <br /> convertidos em um arquivo CSV.
              </>
            )}
            <br />O arquivo será enviado para seu email
            {/* <br /> */}
          </Text>
          {/* <Divider /> */}
          {/* <Text
            variant="body3"
            color="var(--color-neutral-50)"
            fontWeight={700}
          >
            {email}
          </Text> */}
        </ContentContainer>
      </Modal>
      <Modal
        open={visible && step === 1}
        onClose={onFinish}
        footer={
          <Footer>
            <Button
              variant="primary"
              size="large"
              onClick={() => {
                if (onFinish) onFinish();
                setStep(0);
              }}
            >
              {texts?.endButton || `Voltar para ${campaignType}`}
            </Button>
          </Footer>
        }
        header={
          <Header>
            <BorderIcon
              onClick={() => {
                if (onFinish) onFinish();
                setStep(0);
              }}
              name={"IconX"}
              fill="var(--color-neutral-dark4)"
              color="var(--color-neutral-dark4)"
              size={15}
            />
          </Header>
        }
      >
        <ContentContainer>
          <ContentIconContainer>
            <IconContainer>
              <Icons
                name={"IconCheck"}
                color="var(--color-secondary-50)"
                fill="var(--color-secondary-95)"
                size={33}
              />
              <Divider size="tiny" />
            </IconContainer>
            <Text
              variant="body3"
              color="var(--color-secondary-50)"
              fontWeight={700}
            >
              Pronto!
            </Text>
          </ContentIconContainer>
          <Divider />
          <Text variant={"headline6"} color="var(--color-neutral-20)">
            Seu relatório está sendo
            <br /> exportado!
          </Text>
          <Divider />
          <Text variant="body3" color="var(--color-neutral-50)">
            Para acessá-lo é só conferir o e-mail que será enviado em
            <br /> alguns minutos para o endereço:
            <br />
            <strong>{isFetching ? "Carregando..." : email}</strong>
          </Text>
          <Divider />
          <Text variant="body3" color="var(--color-neutral-50)">
            Para acessar os dados utilize os{" "}
            <strong>
              4 primeiros dígitos do
              <br />
              seu CPF{" "}
            </strong>
            como senha.
          </Text>
        </ContentContainer>
      </Modal>
    </>
  );
};

export { CampaignReport };
