import { StepInfoInterface } from "../types";

import { Typography } from "@components/Typography";
import { Divider } from "@components/v2";
import { Box } from "../../../../common/components";

import {
  DisableContainer,
  DividerBorder,
  SectionContainer,
  SectionSubContainer,
} from "../styles";

import { Upload } from "@components/Upload";
import { LinkBoxAdornment, PdfBoxAdornment } from "./components";

const SectionAdditionalInfo = ({
  disabled,
  formik,
  onFileChange,
}: StepInfoInterface) => {
  return (
    <div style={{ marginBottom: "40px", width: "100%" }}>
      <Box>
        <SectionContainer>
          <SectionSubContainer>
            <Typography
              variant="headline7"
              color="var(--color-neutral-30)"
              fontWeight={700}
            >
              Informações adicionais
            </Typography>
            <Divider spacing="quark" />
            <Typography
              variant="body4"
              color="var(--color-neutral-50)"
              fontWeight={400}
            >
              Adicione imagem e link na comunicação.
            </Typography>
          </SectionSubContainer>
        </SectionContainer>
        <Divider spacing="s1" />

        <Typography
          children={<>Imagem da comunicação</>}
          variant="headline9"
          color="var(--color-neutral-30)"
          style={{ marginBottom: "16px" }}
        />

        <DisableContainer disabled={disabled}>
          <Upload
            value={formik?.values?.imageUrl}
            title="Imagem da comunicação"
            infoContent={
              <div>
                <p>Resolução mínima: 300 x 275 pixels</p>
                <p>Peso máximo: 10 MB</p>
              </div>
            }
            maxSize={10485760}
            accept={["png", "jpeg"]}
            folder="campaign"
            canDownload={false}
            onRemove={async (file) => {
              formik.handleChange({
                target: {
                  id: "imageUrl",
                  value: "",
                },
              });
            }}
            onUpload={async (file) => {
              const value = file;
              delete value.value;

              formik.handleChange({
                target: {
                  id: "imageUrl",
                  value: value,
                },
              });
            }}
            onFileChange={(e) => (onFileChange ? onFileChange(e) : null)}
          />
        </DisableContainer>

        <Divider spacing="s1" />
        <DividerBorder />
        <Divider spacing="s1" />

        <Typography
          children={<>Link da comunicação</>}
          variant="headline9"
          color="var(--color-neutral-30)"
          style={{ marginBottom: "16px" }}
        />

        <LinkBoxAdornment formik={formik} disabled={disabled} />

        <Divider spacing="s1" />
        <DividerBorder />
        <Divider spacing="s1" />

        <Typography
          children={<>PDF da comunicação</>}
          variant="headline9"
          color="var(--color-neutral-30)"
          style={{ marginBottom: "16px" }}
        />

        <PdfBoxAdornment formik={formik} disabled={disabled} />
      </Box>
    </div>
  );
};

export { SectionAdditionalInfo };
