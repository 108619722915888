import { Typography, Icons } from "@flash-tecnologia/hros-web-ui-v2";
import { Skeleton as SkeletonE } from "@mui/material";
import {
  CardsContainer,
  CardMainContainer,
  IconContainer,
  NumberContainer,
  InfoContainer,
  NumberDivisor,
  TooltipText,
  Tooltip,
  SkeletonContainer,
} from "./styles";
import { CardInterface } from "./types";

const Card = ({ label, value, subValue, icon, info }: CardInterface) => (
  <CardMainContainer>
    {info ? (
      <InfoContainer>
        <Tooltip
          arrow
          placement="top-end"
          title={
            <>
              <TooltipText variant="body4" strong={true}>
                {info?.title}
              </TooltipText>
              <TooltipText variant="body4">{info?.message}</TooltipText>
            </>
          }
        >
          <div>
            <Icons
              name={"IconInfoCircle"}
              fill={"transparent"}
              color={"var(--color-neutral-dark3)"}
            />
          </div>
        </Tooltip>
      </InfoContainer>
    ) : null}
    <IconContainer>
      <Icons
        name={icon}
        fill={"transparent"}
        color={"var(--color-secondary-dark5)"}
        size={30}
      />
    </IconContainer>
    <NumberContainer>
      <Typography variant="headline5">
        {typeof value === "string"
          ? value
          : `${Math.round(value)}${subValue ? "%" : ""}`}
      </Typography>
      {subValue ? (
        <>
          <NumberDivisor />{" "}
          <Typography variant="headline7">
            {typeof subValue === "string" ? value : Math.round(subValue)}
          </Typography>
        </>
      ) : null}
    </NumberContainer>
    <Typography variant="body3">{label}</Typography>
  </CardMainContainer>
);

const CardSkeleton = () => (
  <>
    <SkeletonContainer>
      <SkeletonE variant="rounded" />
      <SkeletonE variant="rounded" />
      <SkeletonE variant="rounded" />
      <SkeletonE variant="rounded" />
    </SkeletonContainer>
  </>
);

export { Card, CardSkeleton, CardsContainer };
