import { Radio, Checkbox } from "@flash-tecnologia/hros-web-ui-v2";
import { Container, OptionContainer } from "./styles";
import { Typography } from "../../../../../../../..";
import { Divider } from "../../../../../../../../v2";
import { OptionsPreviewInterface } from "./types";

const OptionsPreview = ({
  answerFormat,
  options,
  justification,
}: OptionsPreviewInterface) => (
  <Container>
    {options?.map(({ value }, i) => (
      <OptionContainer key={`option-preview-${i}`}>
        {answerFormat === "multiple-choice" ? (
          <Radio disabled />
        ) : answerFormat === "select-box" ? (
          <Checkbox disabled />
        ) : (
          <>
            <Typography
              variant="body3"
              color="var(--color-neutral-30)"
              fontWeight={600}
            >
              {i + 1}.
            </Typography>
            <Divider spacing={"quark"} type="horizontal" />
          </>
        )}
        <Typography
          variant="body3"
          color="var(--color-neutral-30)"
          fontWeight={600}
        >
          {value}
        </Typography>
      </OptionContainer>
    ))}
    {justification ? (
      <OptionContainer>
        {answerFormat === "multiple-choice" ? (
          <Radio disabled />
        ) : answerFormat === "select-box" ? (
          <Checkbox disabled />
        ) : (
          <>
            <Typography
              variant="body3"
              color="var(--color-neutral-30)"
              fontWeight={600}
            >
              {options?.length || 0 + 1}.
            </Typography>
            <Divider spacing={"quark"} type="horizontal" />
          </>
        )}
        <Typography
          variant="body3"
          color="var(--color-neutral-30)"
          fontWeight={600}
        >
          outro
        </Typography>
      </OptionContainer>
    ) : null}
  </Container>
);

export { OptionsPreview };
