import { generateId } from "@components/EngagementForm/common/components/generateId";
import { SectionInterface } from "@components/EngagementForm/survey/types";
import { NotificationCardV2 } from "@components/NotificationCardV2";
import { Divider, Modal as ModalV2 } from "@components/v2";
import {
  Accordion,
  Button,
  Icons,
  LinkButton,
  Modal,
  Tag,
  TextArea as TextAreaDS,
  TextField,
} from "@flash-tecnologia/hros-web-ui-v2";
import { segment } from "@utils";
import { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Typography } from "../../../..";
import {
  StepContainer,
  StepDescription,
  generateSurveyDataByModel,
} from "../../../common/components";
import { EmptyState, MiniSection } from "./components";
import { Question } from "./components/Question";
import {
  AccordionContainer,
  AccordionContent,
  ButtonContainer,
  Container,
  LinkButtonContainer,
  ModalBody,
  ModalFooter,
  ModalHeader,
  OverflowText,
} from "./styles";
import { StepContentInterface } from "./types";
import { TextAreaContainer } from "../StepInfo/styles";

const TextArea: any = TextAreaDS;

const { Root } = Modal;

const reorder = (
  list: StepContentInterface["sections"],
  startIndex: number,
  endIndex: number,
  enpsClimateSection?: SectionInterface
) => {
  if (list?.length) {
    if (enpsClimateSection?.id)
      list = list?.filter((s) => s?.id !== enpsClimateSection?.id);

    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return enpsClimateSection?.id ? [...result, enpsClimateSection] : result;
  }

  return [];
};

const StepContent = ({
  disabled,
  hide,
  model,
  sections,
  onChange,
}: StepContentInterface) => {
  const [sectionToBeRemoved, setSectionToBeRemoved] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [id, setId] = useState<string>("");
  const [title, setTitle] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [active, setActive] = useState<string>();
  const [activeSection, setActiveSection] = useState<string>();

  useEffect(() => {
    if (id) setShowModal(!showModal);
  }, [id]);

  useEffect(() => {
    if (active) {
      const section = sections?.find(({ questions }) =>
        questions?.some(({ id }) => id === active)
      );
      if (section?.id) setActiveSection(section?.id);
    }
  }, [active]);

  if (hide) return <></>;

  const enpsClimateSection =
    model === "climate"
      ? sections?.find((s) =>
          s?.questions?.find(
            (q) => q?.scaleRange?.max === 10 || q?.answerFormat === "text"
          )
        )
      : undefined;

  // disabled={
  //   ((model === "enps" ||
  //     enpsClimateSection?.id ===
  //       section?.id) &&
  //   section?.questions &&
  //   section?.questions?.length > 1
  //     ? true
  //     : false) || disabled
  // }

  const getAddQuestionButtonDisabledStatus = (
    sectionId: string,
    sectionQuestionLength = 0
  ) => {
    if (disabled) return true;

    if (
      model === "enps" ||
      (model === "climate" &&
        enpsClimateSection?.id &&
        enpsClimateSection?.id === sectionId)
    ) {
      if (sectionQuestionLength > 1) return true;
    } else if (model === "climate") {
      return true;
    }

    return false;
  };

  return (
    <StepContainer>
      <StepDescription
        model={model}
        title="Conteúdo"
        description="Crie as seções e as perguntas da pesquisa. Navegue entre as seções e reordene utilizando o menu abaixo."
        children={
          <div style={{ width: "100%" }}>
            <DragDropContext
              onDragEnd={(e) =>
                e?.destination
                  ? onChange(
                      reorder(
                        sections,
                        e?.source?.index,
                        e?.destination?.index,
                        enpsClimateSection
                      )
                    )
                  : null
              }
            >
              <Droppable droppableId="section-droppable">
                {(provided) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={{ width: "100%" }}
                  >
                    {sections
                      ?.filter((s) => {
                        if (
                          enpsClimateSection &&
                          enpsClimateSection?.id === s?.id
                        )
                          return false;
                        return true;
                      })
                      ?.map((item, index) => (
                        <Draggable
                          key={item?.id}
                          draggableId={item?.id}
                          index={index}
                          isDragDisabled={model === "enps" || disabled}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={provided?.draggableProps?.style}
                            >
                              <MiniSection
                                disabled={model === "enps" || disabled}
                                index={index + 1}
                                isDragging={snapshot.isDragging}
                                selected={
                                  (active &&
                                  item?.questions?.find(
                                    ({ id }) => id === active
                                  )
                                    ? true
                                    : false) || activeSection === item?.id
                                }
                                title={item?.title || ""}
                                totalQuestions={item?.questions?.length || 0}
                                onClick={() => {
                                  const $section = document?.getElementById(
                                    item?.id || ""
                                  );
                                  const $container = document.getElementById(
                                    "survey-steps-container"
                                  );

                                  const position =
                                    $section?.getBoundingClientRect();

                                  if ($section && $container && position) {
                                    $container.scrollTop = position?.top
                                      ? position?.top - 100
                                      : 0;

                                    const firstQuestionId = item?.questions
                                      ?.length
                                      ? item?.questions[0]?.id
                                      : undefined;

                                    if (firstQuestionId) {
                                      setActive(firstQuestionId);
                                    } else {
                                      setActiveSection(item?.id);
                                      setActive(undefined);
                                    }
                                  }
                                }}
                              />
                              <Divider spacing="xxxs" />
                              {provided?.placeholder}
                            </div>
                          )}
                        </Draggable>
                      ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            {model === "climate" && enpsClimateSection?.id ? (
              <div>
                <MiniSection
                  disabled
                  index={sections?.length}
                  isDragging={false}
                  selected={false}
                  title={enpsClimateSection?.title}
                  totalQuestions={enpsClimateSection?.questions?.length || 0}
                />
                <Divider spacing="xxxs" />
              </div>
            ) : null}
            <Divider spacing="xxxs" />
            {!["enps", "climate"]?.includes(model || "") ? (
              <LinkButtonContainer>
                <LinkButton
                  disabled={disabled}
                  variant="neutral"
                  onClick={() => {
                    segment({
                      track:
                        "people_strategic_hr_engagement_company_survey_content_addsection_clicked",
                    });
                    setTitle("");
                    setDescription("");
                    setShowModal(!showModal);
                  }}
                >
                  <Icons name="IconPlus" />
                  Adicionar nova seção
                </LinkButton>
              </LinkButtonContainer>
            ) : (
              <></>
            )}
          </div>
        }
      />
      {sections?.length ? (
        <Container>
          {model === "enps" ? (
            <>
              <NotificationCardV2
                variant="contained"
                type="error"
                title={"Modelo bloqueado"}
                subtitle="No modelo de eNPS, a fim de respeitar a metodologia, a pesquisa é bloqueada e não é possível adicionar mais perguntas, somente excluir a justificativa."
                iconName={"IconAlertTriangle"}
                autoClose
                closeButton
              />
              <Divider spacing={"xs2"} />
            </>
          ) : null}
          <DragDropContext
            onDragEnd={(e) => {
              if (e?.destination === null) return;

              const source: { index: number; droppableId: string } = e?.source;
              const destination: { index: number; droppableId: string } =
                e?.destination;
              const draggableId: string = e?.draggableId;

              if (source?.droppableId === destination?.droppableId) {
                const result = sections?.map((section) => {
                  if (section?.id === source?.droppableId) {
                    const totalQuestions = section?.questions?.length || 0;
                    const restante =
                      section?.questions?.filter(
                        ({ id }) => id !== draggableId
                      ) || [];
                    const question = section?.questions?.find(
                      ({ id }) => id === draggableId
                    );

                    const questionsResult = Array(totalQuestions);
                    questionsResult[destination?.index] = question;

                    for (let i = 0; i < totalQuestions; i++) {
                      if (!questionsResult[i]?.id) {
                        const q = restante?.find(
                          ({ id: questionId }) =>
                            !questionsResult?.find((t) => t?.id === questionId)
                        );

                        if (q) questionsResult[i] = q;
                      }
                    }

                    return {
                      ...section,
                      questions: questionsResult,
                    };
                  }
                  return section;
                });

                if (result?.length) onChange([...result]);
              } else {
                const destinationSection = sections?.find(
                  ({ id }) => id === destination?.droppableId
                );
                const sourceSection = sections?.find(
                  ({ id }) => id === source?.droppableId
                );
                const sourceQuestion = sourceSection?.questions?.find(
                  ({ id }) => id === draggableId
                );

                const result = sections?.map((section) => {
                  if (section?.id === sourceSection?.id) {
                    return {
                      ...section,
                      questions: section?.questions?.filter(
                        ({ id }) => id !== sourceQuestion?.id
                      ),
                    };
                  } else if (section?.id === destinationSection?.id) {
                    const questions = section?.questions || [];
                    questions?.splice(
                      destination?.index,
                      0,
                      sourceQuestion || {}
                    );

                    return {
                      ...section,
                      questions,
                    };
                  }

                  return section;
                });

                if (result?.length) onChange([...result]);
              }
            }}
          >
            {sections
              ?.filter((s) =>
                model !== "climate"
                  ? true
                  : !!!s?.questions?.find((q) => q?.scaleRange?.max === 10)
              )
              ?.map((section, sectionIndex) => (
                <div
                  id={section?.id}
                  key={`${section?.id}-${sectionIndex}`}
                  style={{ width: "100%" }}
                >
                  <Droppable droppableId={section?.id}>
                    {(provided, snapshot) => (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={{
                          width: "100%",
                        }}
                      >
                        <AccordionContainer>
                          <Accordion
                            className={`step-config-accordion ${
                              snapshot?.isDraggingOver
                                ? "accordion-selected"
                                : ""
                            }`}
                            actions={
                              model === "engaja"
                                ? []
                                : [
                                    {
                                      icon: "IconPencil",
                                      onClick: () => {
                                        if (section?.id && !disabled) {
                                          setId(section?.id);
                                          setTitle(section?.title || "");
                                          setDescription(
                                            section?.description || ""
                                          );
                                        }
                                      },
                                    },
                                    {
                                      icon: "IconTrash",
                                      onClick: () => {
                                        if (
                                          ["enps", "climate"]?.includes(
                                            model || ""
                                          ) ||
                                          disabled
                                        )
                                          return;

                                        if (section?.id)
                                          setSectionToBeRemoved(section?.id);
                                      },
                                    },
                                    {
                                      icon: "IconCopy",
                                      onClick: () => {
                                        if (
                                          ["enps", "climate"]?.includes(
                                            model || ""
                                          ) ||
                                          disabled
                                        )
                                          return;

                                        const selectedSection = sections?.find(
                                          ({ id }) => id === section?.id
                                        );

                                        if (model === "climate") {
                                          if (
                                            selectedSection?.questions?.find(
                                              (q) =>
                                                q?.scaleRange?.max === 10 ||
                                                q?.answerFormat === "text"
                                            )
                                          )
                                            return;
                                        }

                                        if (selectedSection?.id) {
                                          const newSection = {
                                            ...selectedSection,
                                            questions:
                                              selectedSection?.questions?.map(
                                                (q) => ({
                                                  ...q,
                                                  options: q?.options?.map(
                                                    (o) => ({
                                                      ...o,
                                                      id: generateId("option"),
                                                    })
                                                  ),
                                                  id: generateId("question"),
                                                })
                                              ),
                                            id: generateId("section"),
                                          };

                                          if (
                                            model === "climate" &&
                                            enpsClimateSection?.id
                                          ) {
                                            onChange([
                                              ...sections?.filter(
                                                (s) =>
                                                  s?.id !==
                                                  enpsClimateSection?.id
                                              ),
                                              ...[
                                                newSection,
                                                enpsClimateSection,
                                              ],
                                            ]);
                                          } else {
                                            onChange([...sections, newSection]);
                                          }
                                        }
                                      },
                                    },
                                  ]
                            }
                            customHeader={
                              <>
                                {section?.questions?.length ? (
                                  <Tag variant="gray">
                                    {`${section?.questions?.length} pergunta${
                                      section?.questions?.length > 1 ? "s" : ""
                                    }`}
                                  </Tag>
                                ) : null}

                                <Typography
                                  variant="headline8"
                                  color="var(--color-neutral-30)"
                                  fontWeight={700}
                                >
                                  <OverflowText>
                                    {section?.title || "Seção sem título"}
                                  </OverflowText>
                                </Typography>
                                <Typography
                                  variant="body4"
                                  color="var(--color-neutral-50)"
                                  fontWeight={400}
                                >
                                  <OverflowText>
                                    {section?.description || ""}
                                  </OverflowText>
                                </Typography>
                              </>
                            }
                            children={
                              <AccordionContent>
                                {section?.questions?.map(
                                  (question, questionIndex) => (
                                    <Draggable
                                      key={question?.id}
                                      draggableId={question?.id}
                                      index={questionIndex}
                                      isDragDisabled={
                                        model === "enps" ||
                                        enpsClimateSection?.id ===
                                          section?.id ||
                                        disabled
                                      }
                                    >
                                      {(provided, snapshot) => (
                                        <div
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          style={provided.draggableProps.style}
                                        >
                                          <Question
                                            {...question}
                                            model={model}
                                            index={
                                              sections
                                                ?.filter(
                                                  (_, i) => i < sectionIndex
                                                )
                                                ?.reduce(
                                                  (a, { questions }) =>
                                                    a +
                                                    (questions?.length || 0),
                                                  0
                                                ) +
                                              (questionIndex + 1)
                                            }
                                            isDragging={snapshot.isDragging}
                                            active={active === question?.id}
                                            isDragDisabled={
                                              disabled ||
                                              enpsClimateSection?.id ===
                                                section?.id ||
                                              model === "enps"
                                            }
                                            disabledFields={disabled}
                                            onClick={() =>
                                              setActive(question?.id)
                                            }
                                            onCloneQuestionClick={(
                                              newQuestion
                                            ) => {
                                              const updatedSections =
                                                sections?.map((s) => {
                                                  if (s?.id === section?.id)
                                                    return {
                                                      ...s,
                                                      questions: [
                                                        ...(section?.questions ||
                                                          []),
                                                        newQuestion,
                                                      ],
                                                    };
                                                  return s;
                                                });

                                              onChange([...updatedSections]);
                                            }}
                                            onChange={(updatedQuestion) => {
                                              const updatedQuestions =
                                                section?.questions?.map((q) => {
                                                  if (q?.id === question?.id)
                                                    return {
                                                      ...updatedQuestion,
                                                    };
                                                  return q;
                                                });

                                              const updatedSections =
                                                sections?.map((s) => {
                                                  if (s?.id === section?.id)
                                                    return {
                                                      ...s,
                                                      questions:
                                                        updatedQuestions?.filter(
                                                          (q) => q?.id
                                                        ),
                                                    };
                                                  return s;
                                                });

                                              onChange([...updatedSections]);
                                            }}
                                          />
                                          {provided?.placeholder}
                                        </div>
                                      )}
                                    </Draggable>
                                  )
                                )}
                                <ButtonContainer>
                                  <Button
                                    disabled={getAddQuestionButtonDisabledStatus(
                                      section?.id || "",
                                      section?.questions?.length || 0
                                    )}
                                    variant="secondary"
                                    size="medium"
                                    onClick={() => {
                                      segment({
                                        track:
                                          "people_strategic_hr_engagement_company_survey_content_addquestion_clicked",
                                      });

                                      const enpsQuestions =
                                        generateSurveyDataByModel("enps")[0]
                                          ?.questions;

                                      const enpsQuestion =
                                        enpsQuestions?.length &&
                                        enpsQuestions?.length > 1
                                          ? enpsQuestions[1]
                                          : undefined;

                                      const newQuestionId =
                                        generateId("question");

                                      const format: "scale" | "text" = "scale";

                                      const updatedSections = sections?.map(
                                        (s) => {
                                          if (s?.id === section?.id)
                                            return {
                                              ...s,
                                              questions: [
                                                ...(section?.questions || []),
                                                (model === "enps" ||
                                                  enpsClimateSection?.id ===
                                                    section?.id) &&
                                                enpsQuestion
                                                  ? enpsQuestion
                                                  : model === "climate"
                                                  ? {
                                                      id: newQuestionId,
                                                      answerFormat: format,
                                                      scaleRange: {
                                                        min: 1,
                                                        max: 5,
                                                      },
                                                    }
                                                  : { id: newQuestionId },
                                              ],
                                            };

                                          return s;
                                        }
                                      );

                                      setActive(newQuestionId);
                                      onChange([...updatedSections]);
                                    }}
                                  >
                                    <Icons
                                      name="IconPlus"
                                      color={
                                        getAddQuestionButtonDisabledStatus(
                                          section?.id || "",
                                          section?.questions?.length || 0
                                        )
                                          ? "#E9E2E7"
                                          : "var(--color-primary)"
                                      }
                                    />
                                    {`Adicionar ${
                                      model === "enps" ||
                                      enpsClimateSection?.id === section?.id
                                        ? "justificativa"
                                        : "nova pergunta"
                                    }`}
                                  </Button>
                                </ButtonContainer>
                              </AccordionContent>
                            }
                            variant={"default"}
                            defaultExpanded
                          />
                          <Divider spacing="xs2" />
                        </AccordionContainer>
                      </div>
                    )}
                  </Droppable>
                </div>
              ))}
          </DragDropContext>
          <Divider spacing="xxxs" />
          {model === "climate" && enpsClimateSection?.id ? (
            <AccordionContainer>
              <Accordion
                className={`step-config-accordion`}
                actions={[
                  {
                    icon: "IconPencil",
                    onClick: () => {
                      if (enpsClimateSection?.id && !disabled) {
                        setId(enpsClimateSection?.id);
                        setTitle(enpsClimateSection?.title || "");
                        setDescription(enpsClimateSection?.description || "");
                      }
                    },
                  },
                  {
                    icon: "IconTrash",
                    onClick: () =>
                      disabled
                        ? null
                        : onChange([
                            ...sections?.filter(
                              (s) => s?.id !== enpsClimateSection?.id
                            ),
                          ]),
                  },
                  {
                    icon: "IconCopy",
                    onClick: () => undefined,
                  },
                ]}
                customHeader={
                  <>
                    {enpsClimateSection?.questions?.length ? (
                      <Tag variant="gray">
                        {`${enpsClimateSection?.questions?.length} pergunta${
                          enpsClimateSection?.questions?.length > 1 ? "s" : ""
                        }`}
                      </Tag>
                    ) : null}
                    <Typography
                      variant="headline8"
                      color="var(--color-neutral-30)"
                      fontWeight={700}
                    >
                      <OverflowText>
                        {enpsClimateSection?.title || "Seção sem título"}
                      </OverflowText>
                    </Typography>
                    <Typography
                      variant="body4"
                      color="var(--color-neutral-50)"
                      fontWeight={400}
                    >
                      <OverflowText>
                        {enpsClimateSection?.description || ""}
                      </OverflowText>
                    </Typography>
                  </>
                }
                children={
                  <AccordionContent>
                    {enpsClimateSection?.questions?.map(
                      (question, questionIndex) => (
                        <Question
                          key={question?.id}
                          {...question}
                          model={model}
                          index={
                            sections
                              ?.filter(
                                ({ id }) => id !== enpsClimateSection?.id
                              )
                              ?.reduce(
                                (a, { questions }) =>
                                  a + (questions?.length || 0),
                                0
                              ) +
                            (questionIndex + 1)
                          }
                          isDragging={false}
                          active={active === question?.id}
                          isDragDisabled={true}
                          disabledFields={disabled}
                          onClick={() => setActive(question?.id)}
                          onCloneQuestionClick={(_) => undefined}
                          onChange={(updatedQuestion) => {
                            const updatedQuestions =
                              enpsClimateSection?.questions?.map((q) => {
                                if (q?.id === question?.id)
                                  return {
                                    ...updatedQuestion,
                                  };
                                return q;
                              });

                            const updatedSections = sections?.map((s) => {
                              if (s?.id === enpsClimateSection?.id)
                                return {
                                  ...s,
                                  questions: updatedQuestions?.filter(
                                    (q) => q?.id
                                  ),
                                };
                              return s;
                            });

                            onChange([...updatedSections]);
                          }}
                        />
                      )
                    )}
                    <ButtonContainer>
                      <Button
                        disabled={
                          (enpsClimateSection?.questions &&
                          enpsClimateSection?.questions?.length > 1
                            ? true
                            : false) || disabled
                        }
                        variant="secondary"
                        size="medium"
                        onClick={() => {
                          segment({
                            track:
                              "people_strategic_hr_engagement_company_survey_content_addquestion_clicked",
                          });

                          const enpsQuestions =
                            generateSurveyDataByModel("enps")[0]?.questions;

                          const enpsQuestion =
                            enpsQuestions?.length && enpsQuestions?.length > 1
                              ? enpsQuestions[1]
                              : undefined;

                          if (enpsQuestion) {
                            const newQuestionId = generateId("question");

                            const updatedSections = sections?.map((s) => {
                              if (s?.id === enpsClimateSection?.id)
                                return {
                                  ...s,
                                  questions: [
                                    ...(enpsClimateSection?.questions || []),
                                    enpsQuestion,
                                  ],
                                };

                              return s;
                            });

                            setActive(newQuestionId);
                            onChange([...updatedSections]);
                          }
                        }}
                      >
                        <Icons
                          name="IconPlus"
                          color={
                            enpsClimateSection?.questions &&
                            enpsClimateSection?.questions?.length > 1
                              ? "#E9E2E7"
                              : "var(--color-primary)"
                          }
                        />
                        {`Adicionar justificativa`}
                      </Button>
                    </ButtonContainer>
                  </AccordionContent>
                }
                variant={"default"}
                defaultExpanded
              />
              <Divider spacing="xs2" />
            </AccordionContainer>
          ) : null}
          {!["enps", "climate"]?.includes(model || "") ? (
            <LinkButtonContainer>
              <LinkButton
                disabled={disabled}
                variant="neutral"
                onClick={() => {
                  segment({
                    track:
                      "people_strategic_hr_engagement_company_survey_content_addsection_clicked",
                  });
                  setTitle("");
                  setDescription("");
                  setShowModal(!showModal);
                }}
              >
                <Icons name="IconPlus" />
                Adicionar nova seção
              </LinkButton>
            </LinkButtonContainer>
          ) : (
            <></>
          )}
        </Container>
      ) : (
        <EmptyState
          onClick={() => {
            segment({
              track:
                "people_strategic_hr_engagement_company_survey_content_addsection_clicked",
            });
            setTitle("");
            setDescription("");
            setShowModal(!showModal);
          }}
        />
      )}
      <Root
        header={
          <ModalHeader>
            <Typography
              variant="headline6"
              color="var(--color-neutral-20)"
              fontWeight={700}
            >
              {`${id ? "Editar" : "Adicionar"} seção`}
            </Typography>
            <Divider spacing="xxxs" />
            <Typography
              variant="body4"
              color="var(--color-neutral-50)"
              fontWeight={400}
            >
              Crie seções para organizar as perguntas da pesquisa. A pesquisa
              será exibida
            </Typography>
            <Typography
              variant="body4"
              color="var(--color-neutral-50)"
              fontWeight={400}
            >
              às pessoas dividida em seções.
            </Typography>
          </ModalHeader>
        }
        open={showModal}
        onClose={() => setShowModal(!showModal)}
        footer={
          <ModalFooter>
            <div>
              <LinkButton
                variant="default"
                onClick={() => {
                  setId("");
                  setShowModal(!showModal);
                }}
              >
                Cancelar
              </LinkButton>
            </div>
            <div>
              <Button
                disabled={title?.length ? false : true}
                variant="primary"
                size="large"
                onClick={() => {
                  if (id && sections?.length) {
                    onChange([
                      ...sections?.map((section) =>
                        section?.id === id
                          ? { ...section, title, description }
                          : section
                      ),
                    ]);
                  } else {
                    const newSectionId = generateId("section");

                    if (model === "climate" && enpsClimateSection?.id) {
                      onChange([
                        ...(sections?.filter(
                          (s) => s?.id !== enpsClimateSection?.id
                        ) || []),
                        ...[
                          {
                            id: newSectionId,
                            title,
                            description,
                          },
                          enpsClimateSection,
                        ],
                      ]);
                    } else {
                      onChange([
                        ...(sections || []),
                        {
                          id: newSectionId,
                          title,
                          description,
                        },
                      ]);
                    }
                  }

                  setId("");
                  setTitle("");
                  setDescription("");
                  setShowModal(!showModal);
                }}
              >
                {`${id ? "Salvar edição" : "Adicionar seção"}`}
              </Button>
            </div>
          </ModalFooter>
        }
      >
        <ModalBody>
          <Typography
            variant="body3"
            color="var(--color-neutral-30)"
            fontWeight={700}
          >
            Nome e descrição
          </Typography>
          <Divider spacing="xs1" />
          <TextField
            value={title || ""}
            label="Nome da seção"
            onChange={(e) =>
              e?.target?.value?.length < 51
                ? setTitle(e?.target?.value || "")
                : null
            }
          />
          <Divider spacing="xs1" />
          <TextAreaContainer>
            <TextArea
              value={description || ""}
              maxLength={150}
              placeholder={"Descrição da seção"}
              onChange={(e: any) =>
                e?.target?.value?.length < 151
                  ? setDescription(e?.target?.value || "")
                  : null
              }
            />
          </TextAreaContainer>
        </ModalBody>
      </Root>
      <ModalV2
        open={sectionToBeRemoved ? true : false}
        onClose={() => setSectionToBeRemoved("")}
        title="Atenção!"
        subTitle={`Tem certeza que deseja <br/> excluir esta seção?`}
        description="Esta ação também excluirá todas as perguntas <br/> associadas a ela e não poderá ser desfeita."
        iconStatus="alert"
        closeButton
        iconName="IconAlertCircle"
        footer={{
          closeButton: {
            label: "Não excluir",
            onClick: () => setSectionToBeRemoved(""),
            variant: "neutral",
          },
          submitButton: {
            label: "Excluir mesmo assim",
            onClick: () => {
              const currentSections = sections || [];
              onChange([
                ...currentSections?.filter((s) => s?.id !== sectionToBeRemoved),
              ]);
              setSectionToBeRemoved("");
            },
            variant: "secondary",
            variantType: "error",
          },
        }}
      />
    </StepContainer>
  );
};

export { StepContent };
