import { Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  border-radius: 12px;
  border: 1px solid var(--color-neutral-90);
  padding: 24px;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const Label = styled(Typography)`
  color: var(--color-neutral-50) !important;
  font-weight: 600 !important;
`;

const InfoText = styled(Typography)`
  color: var(--color-neutral-30) !important;
  font-weight: 700 !important;
`;

export { Container, Row, Label, InfoText };
