import { Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

const Header = styled.header`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 15px 15px 0 15px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
`;

const Footer = styled.footer`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 30px 72px;
  border-top: 1px solid var(--color-neutral-90);
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 100%;
  background-color: var(--color-feedback-error-light1);
`;

const BoxAdornmentTitle = styled(Typography)`
  color: var(--color-feedback-error-dark2) !important;
  font-weight: 600 !important;
`;

const BoxAdornmentDescription = styled(Typography)`
  color: var(--color-feedback-error-dark2) !important;
  font-weight: 400 !important;
`;

const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  width: 500px;
  max-width: 100%;
  text-align: left;
`;

export {
  Header,
  ContentContainer,
  Footer,
  IconContainer,
  BoxAdornmentTitle,
  BoxAdornmentDescription,
};
