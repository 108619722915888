import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 1px solid var(--color-neutral-90);
  border-radius: 12px;
  width: 100%;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--color-neutral-90);
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  width: 100%;
  padding: 16px 32px 16px 32px;
`;

const ChildrenContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 32px;
`;

export { Container, Header, ChildrenContainer };
