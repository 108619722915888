import { SectionInteraction } from "./SectionInteraction";
import { SectionNotification } from "./SectionNotification";
import { IStepConfiguration } from "./types";

import { FlexColumn, PageInfo, PageDescription, PageSubTitle } from "./styles";

import { Grid } from "@mui/material";
import { SectionOwners } from "./SectionOwners";

const StepConfiguration = ({ hide, disabled, formik }: IStepConfiguration) => {
  if (hide) return <></>;

  return (
    <Grid container>
      <Grid item sm={12} md={5} lg={4}>
        <PageInfo>
          <PageSubTitle variant="headline7">Configurações</PageSubTitle>
          <PageDescription variant="body3">
            Adicione as configurações da sua comunicação, como interações e
            notificações.
          </PageDescription>
        </PageInfo>
      </Grid>
      <Grid item sm={12} md={7} lg={8}>
        <FlexColumn style={{ gap: "40px" }}>
          <SectionInteraction disabled={disabled} formik={formik} />
          <SectionNotification disabled={disabled} formik={formik} />
          <SectionOwners disabled={disabled} formik={formik} />
        </FlexColumn>
      </Grid>
    </Grid>
  );
};

export { StepConfiguration };
