import { Tag, TagProps, Tooltip } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export type TagListProps = Array<
  {
    id: string | number;
    label: string;
    variant?: TagProps["variant"];
  } & Omit<TagProps, "variant">
>;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
`;

export const TagList = ({ tags }: { tags: TagListProps }) => {
  const visibleTags = tags?.slice(0, 12);
  const remainingTags = tags?.slice(12);

  return (
    <Container>
      {visibleTags?.map((tag) => (
        <Tag
          key={tag?.id}
          {...tag}
          variant={tag?.variant ? tag?.variant : "primary"}
          disabled
        >
          {tag?.label}
        </Tag>
      ))}
      {remainingTags?.length > 0 && (
        <Tooltip
          title={remainingTags?.map((tag) => tag?.label).join(", ")}
          placement="top-start"
        >
          <Tag key="remainingCountTag" variant="primary">
            {`+${remainingTags?.length} more`}
          </Tag>
        </Tooltip>
      )}
    </Container>
  );
};
