import { Skeleton as SkeletonWebUi } from "@flash-tecnologia/hros-web-ui-v2";
import { Container } from "./styles";

const Skeleton = () => (
  <Container>
    <SkeletonWebUi />
  </Container>
);

export { Skeleton };
