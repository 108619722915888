import React from "react";
import { Button, Icons, LinkButton } from "@flash-tecnologia/hros-web-ui-v2";
import { Typography } from "../../../../Typography";
import { Section } from "./Section";
import {
  Container,
  Header,
  CloseButton,
  ContentContainer,
  MainContainer,
  Shadow,
  Footer,
} from "./styles";
import { FilterInterface } from "./types";

const Filter = ({
  open,
  onClose,
  onReset,
  sections,
  onChange,
}: FilterInterface) => {
  return (
    <MainContainer
      id="transfer-list-filter-main-container"
      open={open || false}
    >
      {open ? <Shadow onClick={onClose} /> : null}
      <Container>
        <Header>
          <Typography
            variant="headline6"
            fontWeight={700}
            color="var(--color-neutral-10)"
          >
            Filtros
          </Typography>
          <CloseButton onClick={onClose}>
            <Icons name="IconX" color="var(--color-neutral-40)" size={16} />
          </CloseButton>
        </Header>
        <ContentContainer>
          {sections?.map((s, i) => (
            <Section
              {...s}
              key={`transfer-list-section-${s?.id}-${i}`}
              onChange={(e) => onChange(e)}
            />
          ))}
        </ContentContainer>
        <Footer>
          <div>
            <LinkButton
              onClick={() =>
                onReset(
                  sections?.map((s) => ({
                    ...s,
                    options: s?.options?.map((o) => ({ ...o, checked: false })),
                  }))
                )
              }
              variant="default"
            >
              Redefinir
            </LinkButton>
          </div>
          <div>
            <Button
              variant={"primary"}
              size={"medium"}
              style={{ width: "266px" }}
              onClick={() => onClose()}
            >
              Filtrar
            </Button>
          </div>
        </Footer>
      </Container>
    </MainContainer>
  );
};

export { Filter };
