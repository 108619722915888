import { useEffect, useState, useRef } from "react";
import debounce from "lodash.debounce";
import {
  SearchField,
  DataGrid as Table,
} from "@flash-tecnologia/hros-web-ui-v2";
import { Skeleton } from "@mui/material";
import { Divider, TableFilter, CampaignReport } from "../../components";
import { DataGridInterface } from "./types";
import { DataGridContainer, FilterContainer, TableContainer } from "./styles";

const DataGrid = ({
  searchField,
  filterTags,
  report,
  ...rest
}: DataGridInterface) => {
  const [showCampaignReport, setShowCampaignReport] = useState(false);
  const [dbValue, saveToDb] = useState("");

  const debouncedSave = useRef(
    debounce((nextValue) => saveToDb(nextValue), 500)
  ).current;

  const handleChange = (e) => debouncedSave(e.target?.value);

  useEffect(() => {
    searchField?.onSearch(dbValue);
  }, [dbValue]);

  return (
    <DataGridContainer>
      {searchField?.onSearch ? (
        <>
          <SearchField
            label={searchField?.placeholder || ""}
            onChange={handleChange}
            className="data-grid-search-field"
            value={searchField?.value || ""}
          />
          <Divider />
        </>
      ) : null}
      {(filterTags && filterTags?.filters) || report ? (
        <>
          <FilterContainer>
            {filterTags && filterTags?.filters ? (
              <TableFilter
                filters={filterTags?.filters}
                onClick={filterTags?.onFilter}
                onClear={filterTags?.onClear}
              />
            ) : (
              <div></div>
            )}
            {report ? (
              <CampaignReport
                type={report?.type}
                email={report?.email}
                texts={report?.texts}
                visible={showCampaignReport}
                onClick={() => {
                  if (report?.onClick) report?.onClick();
                  setShowCampaignReport(!showCampaignReport);
                }}
                onClose={() => {
                  if (report?.onClose) report?.onClose();
                  setShowCampaignReport(!showCampaignReport);
                }}
                onFinish={() => {
                  if (report?.onFinish) report?.onFinish();
                  setShowCampaignReport(!showCampaignReport);
                }}
                onExport={() => (report?.onExport ? report?.onExport() : null)}
              />
            ) : null}
          </FilterContainer>
          <Divider />
        </>
      ) : null}
      <TableContainer>
        <Table
          {...rest}
          hasPagination
          pageSizeOptions={[
            { label: "5 itens", value: 5 },
            { label: "10 itens", value: 10 },
            { label: "20 itens", value: 20 },
          ]}
        />
      </TableContainer>
    </DataGridContainer>
  );
};

const DataGridSkeleton = () => (
  <Skeleton variant="rounded" width={"100%"} height={500} />
);

export { DataGrid, DataGridSkeleton };
