import {
  Accordion,
  Button,
  Checkbox,
  Cropper,
  DatePicker,
  Dropzone,
  Icons,
  Modal as ModalWebUiV1,
  ThemeProvider as NewThemeProvider,
  SelectField,
  Stepper,
  TextField,
  Toggle,
  TransferListDataType,
  TransferListSectionsType,
  Typography,
  dayjs,
} from "@flash-tecnologia/hros-web-ui-v2";
import { Dayjs } from "@flash-tecnologia/hros-web-ui-v2/dist/utils";
import { useSelectedCompany } from "@flash-tecnologia/hros-web-utility";
import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { trpc } from "../../api/client";
import {
  AsideText,
  BorderIcon,
  CounterField,
  DataGridSkeleton,
  DaysPicker,
  Divider,
  FileItem,
  FixedFooter,
  LineSeparator,
  List,
  Modal,
  NotificationCard,
  PageContainer,
  RadioField,
  ScaleForm,
  ScalePreview,
  Section,
  SectionField,
  SectionSkeleton,
  SectionsContainer,
  Space,
  StepContainer,
  Tag,
  Title,
  TotalReactions,
  TransferList,
} from "../../components";
import { DaysPickerInterface } from "../../components/DaysPicker/types";
import { Modal as ModalV2 } from "../../components/v2";
import {
  alert,
  getCroppedImage,
  getPeriod,
  handleTrackNameByCampaignType,
  segment,
  uploadFile,
} from "../../utils";
import { ReviewSection } from "./components/ReviewSecion";
import {
  AccordionDescription,
  AccordionList,
  AccordionTitle,
  ButtonStepsContainer,
  CropperModalFooter,
  CropperModalHeader,
  CropperModalTitle,
  DropzoneContainer,
  EmptyStageMessageContainer,
  FormContainer,
  FormRow,
  Header,
  ImagePreview,
  InputContainer,
  LinkButton,
  LinkButtonsContainer,
  PeriodLabel,
  PreviewTextFieldContainer,
  ReviewContainer,
  ReviewContainerBetween,
  ReviewDataContainer,
  ReviewText,
  ReviewTitle,
  TagContainer,
  TitleContainer,
  ToggleTextContainer,
  ToogleContainer,
  TransferListContainer,
} from "./styles";
import { SendInfoInterface } from "./types";

const { Root: ModalWebUi } = ModalWebUiV1;

const CampaignForm = ({
  id,
  type,
}: {
  id?: string;
  type: "communication" | "research";
}) => {
  const navigate = useNavigate();
  const [t] = useTranslation("translations", {
    keyPrefix: "home",
  });

  const [progress, setProgress] = useState(false);

  const { selectedCompany } = useSelectedCompany();

  const [daysOfTheWeek, setDaysOfTheWeek] = useState<
    DaysPickerInterface["days"]
  >([
    {
      label: "Segunda-feira",
      value: "monday",
      checked: false,
    },
    {
      label: "Terça-feira",
      value: "tuesday",
      checked: false,
    },
    {
      label: "Quarta-feira",
      value: "wednesday",
      checked: false,
    },
    {
      label: "Quinta-feira",
      value: "thursday",
      checked: false,
    },
    {
      label: "Sexta-feira",
      value: "friday",
      checked: false,
    },
    {
      label: "Sábado",
      value: "saturday",
      checked: false,
    },
    {
      label: "Domingo",
      value: "sunday",
      checked: false,
    },
  ]);
  const [sendInfo, setSendInfo] = useState<SendInfoInterface>({
    startDate: {
      date: dayjs()?.format("YYYY-MM-DD"),
    },
    recurrence: {
      repeat: 1,
      occurrenceLimit: 2,
      endDate: dayjs()?.format("YYYY-MM-DD"),
      interactionDeadlineConfig: {
        calculatedDate: {
          multiplier: 1,
        },
      },
    },
  });

  useEffect(() => {
    if (sendInfo?.recurrenceMode) {
      setSendInfo({
        ...sendInfo,
        recurrence: {
          ...sendInfo?.recurrence,
          daysOfTheWeek: daysOfTheWeek
            ?.filter(({ checked }) => checked)
            ?.map(({ value }) => value),
        },
      });
    }
  }, [daysOfTheWeek]);

  const [selectedCampaign, setSelectedCampaign] = useState<any>(null);
  const [answerFormat, setAnswerFormat] = useState<"enps" | "rating">();
  const [DropzoneElement, setDropzoneElement] = useState<ReactNode>(null);
  const [cropperModalVisible, setCroppperModalVisible] = useState(false);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<any>();
  const [campaignStatus, setCampaignStatus] = useState<
    "draft" | "send" | "scheduled"
  >();
  const [showCorporateEmailModal, setShowCorporateEmailModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showSectionLink, setShowSectionLink] = useState(false);
  const [campaign, setCampaign] = useState<any>({
    name: "",
    type: type,
    notifications: {
      push: false,
      email: false,
    },
  });
  const [employees, setEmployees] = useState<any>(null);
  const [currentStep, setCurrentStep] = useState(0);
  const [selectedEmployees, setSelectedEmployees] =
    useState<TransferListDataType>([]);
  const [leftSections, setLeftSections] = useState<TransferListSectionsType>([
    {
      id: "section-group",
      title: "Grupos",
      options: [],
    },
    {
      id: "section-department",
      title: "Departamento",
      options: [],
    },
    {
      id: "section-role",
      title: "Cargo",
      options: [],
    },
  ]);

  const leftListCountSelected = useMemo(
    () => employees?.filter(({ checked }) => checked)?.length || 0,
    [employees]
  );
  const rightListCountSelected = useMemo(
    () => selectedEmployees?.filter(({ checked }) => checked)?.length || 0,
    [selectedEmployees]
  );

  useEffect(() => {
    if (
      campaign?.type === "enps" &&
      campaign?.research?.scale !== "enps" &&
      hasLoading()
    ) {
      setCampaign({
        ...campaign,
        research: {
          ...(campaign?.research || {}),
          scale: "enps",
        },
      });
    }
  }, [campaign?.type]);

  useEffect(() => {
    if (currentStep === 2) {
      if (selectedEmployees?.find(({ hidden }) => hidden))
        setSelectedEmployees([
          ...selectedEmployees?.map((employee) => ({
            ...employee,
            hidden: false,
          })),
        ]);

      if (employees?.find(({ hidden }) => hidden))
        setEmployees([
          ...employees?.map((employee) => ({
            ...employee,
            hidden: false,
          })),
        ]);
    }
  }, [currentStep]);

  const {
    isLoading: updateRecurrenceEmployeesLoading,
    mutate: updateRecurrenceEmployees,
  } = trpc?.updateRecurrenceEmployees?.useMutation({
    onSuccess: () => {
      alert("Recorrência atualizada com sucesso", "success");
      navigate(`/engagement/survey`);
    },
    onError: (e: any) => {
      alert("Erro ao atualizar recorrência, tente novamente mais tarde.");
      console.log("error: ", e);
    },
  });

  const {
    data: allGroups,
    refetch: getGroupsByCompanyId,
    isFetching: getGroupsByCompanyIdLoading,
  } = trpc?.getGroupsByCompanyId?.useQuery(
    {
      active: true,
    },
    {
      retry: false,
      onSuccess: (data) => {
        const options = data?.map(({ _id, name }) => ({
          key: _id,
          label: name,
          asideText: "",
          checked: false,
          hidden: false,
        }));

        const updatedLeftSections = leftSections?.map(({ id, ...rest }) =>
          id === "section-group" ? { id, ...rest, options } : { id, ...rest }
        );

        if (data?.length) setLeftSections([...updatedLeftSections]);
      },
      refetchOnWindowFocus: false,
      onError: (e: any) => {
        alert("Ocorreu um erro, tente novamente mais tarde.");
        console.log("err: ", e);
      },
    }
  );

  const { data: roles, refetch: getRolesByCompanyId } =
    trpc?.getRolesByCompanyId?.useQuery(
      {
        companyId: selectedCompany?.id,
      },
      {
        enabled: false,
        retry: false,
        onSuccess: (data) => {
          const roles = data;
          const options = roles?.map(({ _id, name }) => ({
            key: _id,
            label: name,
            asideText: "",
            checked: false,
            hidden: false,
          }));

          const updatedLeftSections = leftSections?.map(({ id, ...rest }) =>
            id === "section-role" ? { id, ...rest, options } : { id, ...rest }
          );

          if (roles?.length) setLeftSections([...updatedLeftSections]);
        },
        refetchOnWindowFocus: false,
        onError: (e: any) => {
          alert("Ocorreu um erro, tente novamente mais tarde.");
          console.log("err: ", e);
        },
      }
    );

  const { data: allDepartments, refetch: getDepartmentsByCompanyId } =
    trpc?.getDepartmentsByCompanyId?.useQuery(
      {
        companyId: selectedCompany?.id,
      },
      {
        enabled: false,
        retry: false,
        onSuccess: (data) => {
          const departments = data;
          const options = departments?.map(({ _id, name }) => ({
            key: _id,
            label: name,
            asideText: "",
            checked: false,
            hidden: false,
          }));

          const updatedLeftSections = leftSections?.map(({ id, ...rest }) =>
            id === "section-department"
              ? { id, ...rest, options }
              : { id, ...rest }
          );

          if (departments?.length) setLeftSections([...updatedLeftSections]);
        },
        refetchOnWindowFocus: false,
        onError: (e: any) => {
          alert("Ocorreu um erro, tente novamente mais tarde.");
          console.log("err: ", e);
        },
      }
    );

  useEffect(() => {
    (async () => {
      await getGroupsByCompanyId();
      await getRolesByCompanyId();
      await getDepartmentsByCompanyId();
    })();
  }, []);

  const {
    isFetching: getEngagementByIdLoading,
    refetch: getEngagementByIdRefetch,
  } = trpc?.getEngagementById?.useQuery(
    {
      id: id || "",
    },
    {
      retry: false,
      enabled: false,
      onSuccess: (data) => {
        setSelectedCampaign(data);
        getEmployeesByCompanyIdRefetch();

        if (
          data?._id &&
          data?.recurrence?.status === "progress" &&
          progress === false
        )
          setProgress(true);
      },
      refetchOnWindowFocus: false,
      onError: (e: any) => {
        alert("Ocorreu um erro, tente novamente mais tarde.");
        console.log("err: ", e);
      },
    }
  );

  useEffect(() => {
    if (id) getEngagementByIdRefetch();
  }, [id]);

  const {
    data: allEmployees,
    refetch: getEmployeesByCompanyIdRefetch,
    isFetching: getEmployeesByCompanyIdLoading,
  } = trpc?.getInternalEmployeesByCompanyId?.useQuery(
    {
      status: "ACTIVE",
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: false,
      onError: (e: any) => {
        alert(t("error"));
        console.log("err: ", e);
      },
    }
  );

  useEffect(() => {
    if (!id) getEmployeesByCompanyIdRefetch();
  }, []);

  useEffect(() => {
    if (id) {
      const employeesResult = allEmployees?.employees;

      if (employeesResult?.length) {
        setEmployees([
          ...employeesResult?.filter(
            ({ _id }) =>
              !selectedCampaign?.employees?.find(
                (selectedEmployeeId) => selectedEmployeeId === _id
              )
          ),
        ]);
      }

      if (selectedCampaign?.employees?.length)
        setSelectedEmployees([
          ...selectedCampaign?.employees
            ?.map((employee) => {
              const currentEmployee = employeesResult?.find(
                ({ _id }) => _id === employee
              );

              return {
                ...currentEmployee,
                checked: false,
                hidden: false,
              };
            })
            ?.filter((e) => e?._id),
        ]);
    } else {
      const employeesResult = allEmployees?.employees;
      if (employeesResult?.length) setEmployees([...employeesResult]);
    }
  }, [allEmployees, selectedCampaign]);

  useEffect(() => {
    if (id && selectedCampaign?._id) {
      if (selectedCampaign?.link?.url) setShowSectionLink(true);

      if (selectedCampaign?.recurrence || selectedCampaign?.startDate) {
        let endRecurrenceType: "never" | "date" | "occurrence" | undefined =
          undefined;

        if (selectedCampaign?.recurrence?.endDate?.length)
          endRecurrenceType = "date";
        if (selectedCampaign?.recurrence?.occurrenceLimit === -1)
          endRecurrenceType = "never";
        if (selectedCampaign?.recurrence?.occurrenceLimit > 1)
          endRecurrenceType = "occurrence";

        setSendInfo({
          recurrenceMode: selectedCampaign?.recurrence ? true : false,
          endRecurrenceType,
          interactionDeadline: selectedCampaign?.interactionDeadline,
          startDate: selectedCampaign?.startDate?.date
            ? {
                date:
                  selectedCampaign?.startDate?.date ||
                  dayjs()?.format("YYYY-MM-DD"),
                period: selectedCampaign?.startDate?.period,
              }
            : {
                date: dayjs()?.format("YYYY-MM-DD"),
              },
          recurrence: selectedCampaign?.recurrence
            ? {
                ...selectedCampaign?.recurrence,
                interactionDeadlineConfig: {
                  ...(selectedCampaign?.recurrence
                    ?.interactionDeadlineConfig || {
                    calculatedDate: {
                      multiplier: 1,
                    },
                  }),
                  nextMode: selectedCampaign?.recurrence
                    ?.interactionDeadlineConfig?.nextMode
                    ? true
                    : false,
                },
                repeat: selectedCampaign?.recurrence?.repeat
                  ? selectedCampaign?.recurrence?.repeat
                  : 1,
                occurrenceLimit: selectedCampaign?.recurrence?.occurrenceLimit
                  ? selectedCampaign?.recurrence?.occurrenceLimit
                  : 2,
                endDate:
                  selectedCampaign?.recurrence?.endDate ||
                  dayjs()?.format("YYYY-MM-DD"),
              }
            : {
                repeat: 1,
                occurrenceLimit: 2,
                endDate: dayjs()?.format("YYYY-MM-DD"),
                interactionDeadlineConfig: {
                  calculatedDate: {
                    multiplier: 1,
                  },
                },
              },
        });

        if (
          selectedCampaign?.recurrence?.daysOfTheWeek &&
          selectedCampaign?.recurrence?.daysOfTheWeek?.length &&
          selectedCampaign?.recurrence?.frequency === "week"
        ) {
          const selectedDays = daysOfTheWeek.map(({ label, value }) => ({
            label,
            value,
            checked:
              selectedCampaign?.recurrence?.daysOfTheWeek?.includes(value),
          }));

          setDaysOfTheWeek([...selectedDays]);
        }
      }

      setCampaign({
        name: selectedCampaign?.name,
        type: selectedCampaign?.type,
        notifications: selectedCampaign?.notifications || {
          push: false,
          email: false,
        },
        communication: {
          title: selectedCampaign?.title || undefined,
          message: selectedCampaign?.message || undefined,
          linkUrl: selectedCampaign?.link?.url || undefined,
          linkText: selectedCampaign?.link?.label || undefined,
          hasReact: !!selectedCampaign?.like || undefined,
          imageUrl: selectedCampaign?.imageUrl || undefined,
        },
        research: {
          question: selectedCampaign?.question || undefined,
          description: selectedCampaign?.description || undefined,
          scale: selectedCampaign?.scale || undefined,
          lowerLabel: selectedCampaign?.lowerLabel || undefined,
          upperLabel: selectedCampaign?.upperLabel || undefined,
          justification: selectedCampaign?.justification || undefined,
          anonymous: selectedCampaign?.anonymous || undefined,
        },
        groups: [],
      });
    }
  }, [selectedCampaign]);

  const hasDisabled = () => {
    if (!campaign?.name || !campaign?.type) return true;

    if (currentStep === 0) {
      if (campaign?.type === "communication") {
        if (
          !campaign?.communication?.title ||
          !campaign?.communication?.message
        )
          return true;
      } else {
        if (
          !campaign?.research?.question ||
          !campaign?.research?.description ||
          !campaign?.research?.lowerLabel ||
          !campaign?.research?.upperLabel ||
          !campaign?.research?.scale
        )
          return true;
      }

      return false;
    }

    if (currentStep === 1) {
      if (!campaign?.notifications?.push && !campaign?.notifications?.email)
        return true;
      return false;
    }

    if (currentStep === 2) {
      if (getEmployeesByCompanyIdLoading) return true;
      if (!selectedEmployees?.length) return true;
      if (
        campaign?.type !== "communication" &&
        campaign?.research?.anonymous &&
        selectedEmployees?.length < 3
      )
        return true;
      return false;
    }

    if (currentStep === 3) {
      if (!(sendInfo?.startDate?.date && sendInfo?.startDate?.period))
        return true;

      if (sendInfo?.recurrenceMode) {
        if (
          !(
            sendInfo?.recurrence?.interactionDeadlineConfig?.nextMode ||
            (sendInfo?.recurrence?.interactionDeadlineConfig?.calculatedDate
              ?.multiplier &&
              sendInfo?.recurrence?.interactionDeadlineConfig?.calculatedDate
                ?.period)
          ) &&
          campaign?.type !== "communication"
        )
          return true;

        if (
          !sendInfo?.endRecurrenceType ||
          !sendInfo?.recurrence?.repeat ||
          !sendInfo?.recurrence?.frequency
        )
          return true;

        if (
          sendInfo?.recurrence?.frequency === "week" &&
          !sendInfo?.recurrence?.daysOfTheWeek?.length
        )
          return true;

        if (
          !(
            sendInfo?.recurrence?.occurrenceLimit === -1 ||
            (sendInfo?.recurrence?.occurrenceLimit &&
              sendInfo?.recurrence?.occurrenceLimit > 0) ||
            sendInfo?.recurrence?.endDate
          )
        )
          return true;
      }
    }

    if (!campaign?.name) return true;

    if (campaign?.type === "communication") {
      if (!campaign?.communication?.title) return true;
      if (!campaign?.communication?.message) return true;
    }

    return false;
  };

  const { isLoading: createEngagementLoading, mutate: createEngagementMutate } =
    trpc?.createEngagement?.useMutation({
      onSuccess: () => setShowSuccessModal(!showSuccessModal),
      onError: (e: any) => {
        let msgError = "Ocorreu um erro, tente novamente mais tarde.";
        const error = e?.data?.error;

        if (error === "INVALID_START_DATE_ERROR")
          msgError = "Erro: Selecione uma data de envio válida.";
        if (error === "INVALID_PERIOD")
          msgError = "Erro: Selecione um período válido.";

        alert(msgError);
        console.log("err: ", e);
      },
    });

  const handleSubmitButtonClick = () => {
    const trackType = type === "communication" ? "communication" : "survey";
    segment({
      track: `company_engagement_${trackType}review_create${trackType}_clicked`,
    });

    if (
      getPeriod(dayjs()) === sendInfo?.startDate?.period &&
      dayjs()?.format("YYYY-MM-DD") ===
        dayjs(sendInfo?.startDate?.date)?.format("YYYY-MM-DD")
    ) {
      onSubmit("send");
    } else {
      onSubmit(selectedCampaign?.status === "send" ? "send" : "scheduled");
    }
  };

  const onSubmit = async (status: "draft" | "send" | "scheduled") => {
    try {
      let imageUrl = campaign?.communication?.imageUrl?.path
        ? {
            origin: campaign?.communication?.imageUrl?.origin,
            path: campaign?.communication?.imageUrl?.path,
          }
        : undefined;

      if (
        campaign?.communication?.file?.file &&
        campaign?.type === "communication"
      ) {
        const randomNumber = Math?.round(
          Math.random() * 100000000000000
        )?.toString();

        const file = campaign?.communication?.file?.file;
        const fileName =
          randomNumber +
          new Date()?.getTime()?.toString() +
          (file?.type?.includes("png") ? ".png" : ".jpeg");

        Object.defineProperty(file, "name", {
          writable: true,
          value: fileName,
        });

        const uploadResult = await uploadFile({
          file,
          fileName,
          folder: "campaign",
        });

        imageUrl = uploadResult?.path
          ? {
              path: uploadResult?.path,
              origin: uploadResult?.origin,
            }
          : undefined;
      }

      const employeesId = selectedEmployees?.length
        ? selectedEmployees?.map(({ _id }) => _id)
        : undefined;
      const groupsId = campaign?.groups?.length
        ? campaign?.groups?.map(({ _id }) => _id)
        : undefined;

      const link =
        campaign?.communication?.linkUrl && showSectionLink
          ? {
              label: "Acesse agora",
              url: campaign?.communication?.linkUrl,
            }
          : undefined;

      const recurrence: SendInfoInterface["recurrence"] = {
        status: status === "send" ? "progress" : "scheduled",
        repeat: sendInfo?.recurrence?.repeat,
        frequency: sendInfo?.recurrence?.frequency,
        daysOfTheWeek:
          sendInfo?.recurrence?.frequency === "week"
            ? sendInfo?.recurrence?.daysOfTheWeek
            : [],
        active: true,
        interactionDeadlineConfig:
          campaign?.type !== "communication"
            ? sendInfo?.recurrence?.interactionDeadlineConfig?.nextMode
              ? {
                  nextMode: true,
                }
              : {
                  calculatedDate: {
                    multiplier:
                      sendInfo?.recurrence?.interactionDeadlineConfig
                        ?.calculatedDate?.multiplier || 1,
                    period:
                      sendInfo?.recurrence?.interactionDeadlineConfig
                        ?.calculatedDate?.period || "day",
                  },
                }
            : undefined,
      };

      if (sendInfo?.endRecurrenceType) {
        if (sendInfo?.endRecurrenceType === "date") {
          recurrence["endDate"] = sendInfo?.recurrence?.endDate;
        } else {
          recurrence["occurrenceLimit"] = sendInfo?.recurrence?.occurrenceLimit;
        }
      }

      const interactionDeadline = sendInfo?.recurrenceMode
        ? undefined
        : sendInfo?.interactionDeadline?.length
        ? sendInfo?.interactionDeadline
        : undefined;

      if (progress) {
        updateRecurrenceEmployees({
          id: id || "",
          employees: employeesId || [],
          endDate: recurrence?.endDate,
          occurrenceLimit: recurrence?.occurrenceLimit,
        });
        return;
      }

      const body: any =
        campaign?.type === "communication"
          ? {
              _id: id,
              name: campaign?.name,
              type: campaign?.type,
              title: campaign?.communication?.title,
              message: campaign?.communication?.message,
              employees: employeesId,
              groups: groupsId || [],
              link: link,
              status: campaign?.status === "send" ? "send" : status,
              like: !!campaign?.communication?.hasReact,
              imageUrl: imageUrl,
              startDate: sendInfo?.startDate,
              recurrence: sendInfo?.recurrenceMode ? recurrence : undefined,
              notifications: campaign?.notifications,
              corporateEmails:
                selectedEmployees?.map(({ _id, corporateEmail }) => ({
                  _id,
                  email: corporateEmail || null,
                })) || [],
            }
          : {
              _id: id,
              name: campaign?.name,
              type: campaign?.type,
              scale:
                campaign?.type === "enps" ? "enps" : campaign?.research?.scale,
              question: campaign?.research?.question,
              description: campaign?.research?.description,
              lowerLabel: campaign?.research?.lowerLabel,
              upperLabel: campaign?.research?.upperLabel,
              employees: employeesId,
              groups: groupsId || [],
              justification: !!campaign?.research?.justification,
              anonymous: !!campaign?.research?.anonymous,
              status: campaign?.status === "send" ? "send" : status,
              startDate: sendInfo?.startDate,
              interactionDeadline,
              recurrence: sendInfo?.recurrenceMode ? recurrence : undefined,
              notifications: campaign?.notifications,
              corporateEmails:
                selectedEmployees?.map(({ _id, corporateEmail }) => ({
                  _id,
                  email: corporateEmail || null,
                })) || [],
            };

      createEngagementMutate(body);

      setCampaignStatus(status);
      setShowCorporateEmailModal(false);
    } catch (error: any) {
      setShowCorporateEmailModal(false);
      alert("Ocorreu um erro, tente novamente mais tarde.");
    }
  };

  const formatNumber = (number: number) =>
    new Intl.NumberFormat("de-DE").format(number);

  const getFileName = (imageUrl: string) => {
    const urlSplit = imageUrl?.split("/campaign/")[1];

    let result =
      urlSplit?.split(".png?")[0] || urlSplit?.split(".jpeg?")[0] || "";

    result = result?.replace(".png", "");
    result = result?.replace(".jpeg", "");

    result =
      result?.length > 5
        ? `${result?.slice(0, 10)}...${
            result?.includes("png") ? "png" : "jpeg"
          }`
        : result;

    return result || "";
  };

  const checkImageHeightAndWidth = (file: File): Promise<boolean> => {
    return new Promise((resolve, reject) => {
      try {
        const reader = new FileReader();

        reader.readAsDataURL(file);
        reader.onload = function (e) {
          const image: any = new Image();

          image.src = e?.target?.result;

          image.onload = function () {
            const height = this.height;
            const width = this.width;
            const result = height > 274 && width > 274;
            if (!result) alert("A resolução mínima deve ser 275 x 275px");
            resolve(result);
          };
        };
      } catch (error: any) {
        reject(error);
      }
    });
  };

  const setCroppedImage = async (action: "save" | "close") => {
    if (
      (action === "close" &&
        (croppedAreaPixels?.y === 0 || croppedAreaPixels?.x === 0)) ||
      action === "save"
    ) {
      const result = await getCroppedImage({
        fileName: campaign?.communication?.file?.file?.name,
        fileData: campaign?.communication?.file?.data,
        croppedAreaPixels,
      });

      setCampaign({
        ...campaign,
        communication: {
          ...campaign?.communication,
          file:
            (await checkImageHeightAndWidth(result.file)) && action === "save"
              ? result
              : undefined,
        },
      });
    }

    setCroppperModalVisible(!croppedAreaPixels);
  };

  const getPeriodOptions = (): Array<{
    label: string;
    value: "morning" | "afternoon" | "night";
  }> => {
    const currentPeriod = getPeriod(dayjs());

    if (
      dayjs()?.format("YYYY-MM-DD") ===
        dayjs(sendInfo?.startDate?.date)?.format("YYYY-MM-DD") &&
      !disabledRecurrence
    ) {
      if (currentPeriod === "afternoon")
        return [
          {
            label:
              getPeriod(dayjs()) === "afternoon" &&
              dayjs()?.format("YYYY-MM-DD") ===
                dayjs(sendInfo?.startDate?.date)?.format("YYYY-MM-DD")
                ? "Imediatamente"
                : "Tarde (12h-18h)",
            value: "afternoon",
          },
          {
            label:
              getPeriod(dayjs()) === "night" &&
              dayjs()?.format("YYYY-MM-DD") ===
                dayjs(sendInfo?.startDate?.date)?.format("YYYY-MM-DD")
                ? "Imediatamente"
                : "Noite (18h-22h)",
            value: "night",
          },
        ];

      if (currentPeriod === "night")
        return [
          {
            label:
              getPeriod(dayjs()) === "night" &&
              dayjs()?.format("YYYY-MM-DD") ===
                dayjs(sendInfo?.startDate?.date)?.format("YYYY-MM-DD")
                ? "Imediatamente"
                : "Noite (18h-22h)",
            value: "night",
          },
        ];
    }

    return [
      {
        label:
          getPeriod(dayjs()) === "morning" &&
          dayjs()?.format("YYYY-MM-DD") ===
            dayjs(sendInfo?.startDate?.date)?.format("YYYY-MM-DD")
            ? "Imediatamente"
            : "Manhã (8h-12h)",
        value: "morning",
      },
      {
        label:
          getPeriod(dayjs()) === "afternoon" &&
          dayjs()?.format("YYYY-MM-DD") ===
            dayjs(sendInfo?.startDate?.date)?.format("YYYY-MM-DD")
            ? "Imediatamente"
            : "Tarde (12h-18h)",
        value: "afternoon",
      },
      {
        label:
          getPeriod(dayjs()) === "night" &&
          dayjs()?.format("YYYY-MM-DD") ===
            dayjs(sendInfo?.startDate?.date)?.format("YYYY-MM-DD")
            ? "Imediatamente"
            : "Noite (18h-22h)",
        value: "night",
      },
    ];
  };

  useEffect(() => {
    setDropzoneElement(
      <Dropzone
        title="Imagem da comunicação"
        infoContent={
          <div>
            <p>Resolução mínima: 275 x 275 pixels</p>
            <p>Peso máximo: 10 MB</p>
          </div>
        }
        maxSize={10485760}
        accept={["png", "jpeg"]}
        onChange={(e) => {
          if (e?.length) {
            setCampaign({
              ...campaign,
              communication: {
                ...campaign?.communication,
                file: e[0],
              },
            });
            setCroppperModalVisible(true);
          }
        }}
        onFileSizeError={() => {
          alert("A imagem deve ser menor que 10 MB");
        }}
      />
    );
  }, [campaign]);

  useEffect(() => {
    if (campaign?.research?.scale && !answerFormat)
      setAnswerFormat(campaign?.research?.scale === "enps" ? "enps" : "rating");
  }, [campaign?.research?.scale]);

  useEffect(() => {
    if (answerFormat)
      setCampaign({
        ...campaign,
        type: answerFormat === "enps" ? "enps" : "research",
        research: {
          ...campaign?.research,
          scale:
            answerFormat === "enps"
              ? "enps"
              : campaign?.research?.scale !== "enps"
              ? campaign?.research?.scale
              : "number",
        },
      });
  }, [answerFormat]);

  const getCampaignType = useCallback(
    (lowerCaseMode?: boolean, pluralMode?: boolean) => {
      const text =
        type === "communication"
          ? pluralMode
            ? "Comunicações"
            : "Comunicação"
          : pluralMode
          ? "Pesquisas"
          : "Pesquisa";
      return lowerCaseMode ? text.toLocaleLowerCase() : text;
    },
    [type]
  );

  const disabledRecurrence =
    sendInfo?.recurrence?.status &&
    ["paused", "progress", "closed"]?.includes(sendInfo?.recurrence?.status);

  const getSegmenetFrequency = () => {
    if (sendInfo?.recurrence?.frequency === "day") return "daily";
    if (sendInfo?.recurrence?.frequency === "week") return "weekly";
    if (sendInfo?.recurrence?.frequency === "month") return "monthly";
    if (sendInfo?.recurrence?.frequency === "year") return "yearly";
    return;
  };

  const employeesWithoutCorporateEmailCount = useMemo(
    () =>
      selectedEmployees?.reduce((a, c) => a + (c?.corporateEmail ? 0 : 1), 0),
    [selectedEmployees]
  );

  const hasLoading = () => {
    if (id) {
      if (getEngagementByIdLoading) return true;
      if (!selectedCampaign?._id) return true;
      if (!campaign?.type) return true;
    }
    return false;
  };

  if (hasLoading())
    return (
      <FormContainer>
        <PageContainer>
          <SectionsContainer>
            <SectionSkeleton />
            <Divider />
            <DataGridSkeleton />
          </SectionsContainer>
        </PageContainer>
      </FormContainer>
    );

  return (
    <NewThemeProvider>
      <>
        <Header>
          <Stepper
            steps={[
              "Informações Básicas",
              "Configurações",
              "Destinatários",
              "Envio",
              "Revisão",
            ]}
            activeStep={currentStep}
            setActiveStep={setCurrentStep}
            disableClick
            style={{ cursor: "default" }}
          />
        </Header>
        <>
          <FormContainer>
            <PageContainer>
              {currentStep === 0 ? (
                <StepContainer>
                  <TitleContainer>
                    <Title>{`Criar ${getCampaignType()}`}</Title>
                    <Divider />
                    <AsideText
                      title={"Informações Básicas"}
                      message={`Adicione as informações básicas da sua ${getCampaignType(
                        true
                      )}, como nome e personalização.`}
                    />
                  </TitleContainer>

                  <SectionsContainer>
                    <Section
                      title={
                        campaign?.type === "communication"
                          ? "Nome e mensagem"
                          : "Nome e descrição"
                      }
                      message={`Dê um nome para identificar a ${getCampaignType(
                        true
                      )}, ${
                        campaign?.type === "communication"
                          ? "um título e escreva a mensagem"
                          : "um nome público e descreva o objetivo"
                      }.`}
                    >
                      <TextField
                        disabled={disabledRecurrence}
                        label={`Nome de identificação da ${getCampaignType(
                          true
                        )}`}
                        helperText={
                          "O nome ficará visível apenas para você na listagem da tabela."
                        }
                        fullWidth
                        onChange={(e) =>
                          setCampaign({
                            ...campaign,
                            name: e?.target?.value,
                          })
                        }
                        value={campaign?.name || ""}
                      />
                      {campaign?.type === "communication" ? (
                        <>
                          <Divider />
                          <TextField
                            disabled={disabledRecurrence}
                            label={"Título da mensagem"}
                            helperText={
                              campaign?.type === "communication"
                                ? "Exemplo: Papo com o CEO às 19h!"
                                : "O nome de exibição pública será exibido para os usuários."
                            }
                            fullWidth
                            onChange={(e) =>
                              setCampaign({
                                ...campaign,
                                communication: {
                                  ...campaign?.communication,
                                  title: e?.target?.value,
                                },
                              })
                            }
                            value={campaign?.communication?.title || ""}
                          />
                          <Divider />
                          <TextField
                            disabled={disabledRecurrence}
                            label={"Mensagem"}
                            fullWidth
                            onChange={(e) =>
                              setCampaign({
                                ...campaign,
                                communication: {
                                  ...campaign?.communication,
                                  message: e?.target?.value,
                                },
                              })
                            }
                            value={campaign?.communication?.message || ""}
                          />
                        </>
                      ) : (
                        <>
                          <Divider />
                          <TextField
                            disabled={disabledRecurrence}
                            label={"Descrição da pesquisa"}
                            fullWidth
                            onChange={(e) =>
                              setCampaign({
                                ...campaign,
                                research: {
                                  ...campaign?.research,
                                  description: e?.target?.value,
                                },
                              })
                            }
                            value={campaign?.research?.description || ""}
                          />
                        </>
                      )}
                    </Section>
                    <Divider />
                    <Divider />
                    {!["research", "enps"]?.includes(campaign?.type) ? (
                      <Section
                        title="Informações adicionais"
                        message="Adicione imagem e link na comunicação."
                      >
                        {/*
                        <Section>
                          <ToogleContainer>
                            <Toggle
                              disabled={disabledRecurrence}
                              checked={!!campaign?.communication?.hasReact}
                              onChange={() => {
                                if (!!!campaign?.communication?.hasReact) {
                                  segment({
                                    track: handleTrackNameByCampaignType(
                                      "company_engagement_communicationcontent_reactions_selected",
                                      type
                                    ),
                                  });
                                }
                                setCampaign({
                                  ...campaign,
                                  communication: {
                                    ...campaign?.communication,
                                    hasReact:
                                      !campaign?.communication?.hasReact,
                                  },
                                });
                              }}
                            />
                            <ToggleTextContainer>
                              <ReviewTitle variant="body3">
                                {"Permitir reações"}
                              </ReviewTitle>
                              <ReviewText variant="body4">
                                {
                                  "Selecione esta opção para permitir que as pessoas reajam às comunicações."
                                }
                              </ReviewText>
                            </ToggleTextContainer>
                          </ToogleContainer>
                        </Section> */}
                        {campaign?.communication?.file ||
                        campaign?.communication?.imageUrl?.signedUrl ? (
                          <FileItem
                            progress={100}
                            name={
                              campaign?.communication?.file?.file?.name ||
                              getFileName(
                                campaign?.communication?.imageUrl?.path
                              )
                            }
                            image={
                              campaign?.communication?.file?.data ||
                              `${campaign?.communication?.imageUrl?.signedUrl}`
                            }
                            onClick={() => {
                              segment({
                                track: handleTrackNameByCampaignType(
                                  "company_engagement_communicationinfos_excludeimage_clicked",
                                  type
                                ),
                              });
                              setCampaign({
                                ...campaign,
                                communication: {
                                  ...campaign?.communication,
                                  file: null,
                                  imageUrl: null,
                                },
                              });
                            }}
                          />
                        ) : disabledRecurrence ? (
                          <></>
                        ) : (
                          <DropzoneContainer
                            onMouseDown={() =>
                              segment({
                                track: handleTrackNameByCampaignType(
                                  "company_engagement_communicationinfos_selectimage_clicked",
                                  type
                                ),
                              })
                            }
                          >
                            {DropzoneElement}
                          </DropzoneContainer>
                        )}
                        <Divider />
                        <Section
                          title={"Inserir link"}
                          message={
                            "Selecione esta opção para inserir um link na mensagem"
                          }
                          headerComplement={
                            <Toggle
                              disabled={disabledRecurrence}
                              checked={!!showSectionLink}
                              onChange={() => {
                                if (!showSectionLink) {
                                  segment({
                                    track: handleTrackNameByCampaignType(
                                      "company_engagement_communicationinfos_addlink_clicked",
                                      type
                                    ),
                                  });
                                }
                                setShowSectionLink(!showSectionLink);
                              }}
                            />
                          }
                        >
                          {showSectionLink ? (
                            <>
                              <TextField
                                label={"Selecionar texto do link"}
                                fullWidth
                                value={"Acesse agora"}
                                disabled
                                onChange={(e) =>
                                  setCampaign({
                                    ...campaign,
                                    communication: {
                                      ...campaign?.communication,
                                      linkText: e?.target?.value,
                                    },
                                  })
                                }
                              />
                              <Divider />
                              <TextField
                                disabled={disabledRecurrence}
                                label={
                                  "Insira a URL (https://www.flashapp.com)"
                                }
                                fullWidth
                                onChange={(e) =>
                                  setCampaign({
                                    ...campaign,
                                    communication: {
                                      ...campaign?.communication,
                                      linkUrl: e?.target?.value,
                                    },
                                  })
                                }
                                value={campaign?.communication?.linkUrl || ""}
                              />
                            </>
                          ) : null}
                        </Section>
                        <ModalWebUi
                          header={
                            <CropperModalHeader>
                              <BorderIcon
                                onClick={() => {
                                  setCroppedImage("close");
                                  setCampaign({
                                    ...campaign,
                                    communication: {
                                      ...campaign?.communication,
                                      file: undefined,
                                    },
                                  });
                                  segment({
                                    track: handleTrackNameByCampaignType(
                                      "company_engagement_communicationinfos_exitimage_clicked",
                                      type
                                    ),
                                  });
                                }}
                                name={"IconX"}
                                fill="var(--color-neutral-dark4)"
                                color="var(--color-neutral-dark4)"
                                size={15}
                              />
                              <CropperModalTitle variant="headline6">
                                Recorte a imagem
                              </CropperModalTitle>
                              {/* <CropperModalSubTitle variant="body3">
                                Recorte a imagem
                              </CropperModalSubTitle> */}
                            </CropperModalHeader>
                          }
                          footer={
                            <CropperModalFooter>
                              <Button
                                variant="primary"
                                size="large"
                                onClick={() => {
                                  segment({
                                    track: handleTrackNameByCampaignType(
                                      "company_engagement_communicationinfos_saveimage_clicked ",
                                      type
                                    ),
                                  });
                                  setCroppedImage("save");
                                }}
                              >
                                Salvar
                              </Button>
                            </CropperModalFooter>
                          }
                          // title={<>{"Recorte a imagem"}</>}
                          open={cropperModalVisible}
                          onClose={() => navigate(`/engagement/${type}`)}
                        >
                          <Cropper
                            image={campaign?.communication?.file?.data}
                            onChange={(e) => setCroppedAreaPixels(e)}
                          />
                        </ModalWebUi>
                      </Section>
                    ) : (
                      <Section
                        title={
                          campaign?.type === "enps"
                            ? "Personalização da eNPS"
                            : "Personalização da pesquisa"
                        }
                        message={
                          "Escreva uma pergunta para enviar às pessoas. Depois, escolha o formato da resposta."
                        }
                      >
                        <TextField
                          disabled={disabledRecurrence}
                          label={"Texto da pergunta"}
                          helperText={
                            campaign?.type === "enps"
                              ? "Exemplo: Em uma escala de 0 a 10, qual a probabilidade de você recomendar o nosso produto para um amigo?"
                              : "Exemplo: Em uma escala de 1 a 5, qual a probabilidade de você recomendar o nosso produto para um amigo?"
                          }
                          fullWidth
                          onChange={(e) =>
                            setCampaign({
                              ...campaign,
                              research: {
                                ...campaign?.research,
                                question: e?.target?.value,
                              },
                            })
                          }
                          value={campaign?.research?.question || ""}
                        />
                        <Divider />
                        {campaign?.type !== "communication" ? (
                          <>
                            <SelectField
                              disabled={disabledRecurrence}
                              key={"research-type-field"}
                              label={"Formato da resposta"}
                              options={[
                                {
                                  label: `Escala de avaliação (1-5)`,
                                  value: "rating",
                                },
                                {
                                  label: `eNPS (0 - 10)`,
                                  value: "enps",
                                },
                              ]}
                              onSelectChange={(_: any, option) => {
                                const event = option?.value;

                                let track =
                                  "company_engagement_surveyinfos_binaryscale_selected";

                                if (event === "rating")
                                  track =
                                    "company_engagement_surveyinfos_ratingscale_selected";
                                if (event === "enps")
                                  track =
                                    "company_engagement_surveyinfos_enps_selected";

                                segment({
                                  track,
                                });

                                setAnswerFormat(event);
                              }}
                              value={
                                answerFormat
                                  ? answerFormat
                                  : campaign?.type &&
                                    id &&
                                    campaign?.research?.scale
                                  ? campaign?.type === "enps"
                                    ? "enps"
                                    : "rating"
                                  : id
                                  ? "rating"
                                  : undefined
                              }
                            />
                            {answerFormat && answerFormat !== "enps" ? (
                              <>
                                <Divider />
                                <ReviewTitle variant="headline8">
                                  Configurar escala
                                </ReviewTitle>
                                <Divider />
                                <ScaleForm
                                  disabled={disabledRecurrence}
                                  initialValue={
                                    campaign?.research?.scale === "enps"
                                      ? "number"
                                      : campaign?.research?.scale
                                  }
                                  onChange={(e) =>
                                    setCampaign({
                                      ...campaign,
                                      research: {
                                        ...campaign?.research,
                                        scale: e,
                                      },
                                    })
                                  }
                                />
                              </>
                            ) : null}
                          </>
                        ) : null}
                        <Divider />
                        <TextField
                          disabled={disabledRecurrence}
                          label={"Título da resposta mais baixa"}
                          helperText={
                            campaign?.type === "enps"
                              ? "Exemplo: Nada provável"
                              : "Exemplo: Péssimo"
                          }
                          fullWidth
                          onChange={(e) =>
                            setCampaign({
                              ...campaign,
                              research: {
                                ...campaign?.research,
                                lowerLabel: e?.target?.value,
                              },
                            })
                          }
                          value={campaign?.research?.lowerLabel || ""}
                        />
                        <Divider />
                        <TextField
                          disabled={disabledRecurrence}
                          label={"Título da resposta mais alta"}
                          helperText={
                            campaign?.type === "enps"
                              ? "Exemplo: Extremamente provável"
                              : "Exemplo: Ótimo"
                          }
                          fullWidth
                          onChange={(e) =>
                            setCampaign({
                              ...campaign,
                              research: {
                                ...campaign?.research,
                                upperLabel: e?.target?.value,
                              },
                            })
                          }
                          value={campaign?.research?.upperLabel || ""}
                        />
                        {campaign?.type !== "communication" &&
                        campaign?.research?.scale ? (
                          <>
                            <Divider />
                            <ReviewTitle variant="headline8">
                              Pré-visualização
                            </ReviewTitle>
                            <Divider />
                            <ScalePreview
                              ItemSize={100}
                              lowerLabel={campaign?.research?.lowerLabel}
                              upperLabel={campaign?.research?.upperLabel}
                              scale={campaign?.research?.scale}
                              type={campaign?.type}
                            />
                          </>
                        ) : null}
                        <Divider />
                        <Section
                          title={"Solicitar justificativa da resposta"}
                          message={
                            "Selecione esta opção para inserir um campo de comentário para a pessoa preencher de forma opcional."
                          }
                          headerComplement={
                            <Toggle
                              disabled={disabledRecurrence}
                              checked={!!campaign?.research?.justification}
                              onChange={() => {
                                if (!!!campaign?.research?.justification)
                                  segment({
                                    track:
                                      "company_engagement_surveyinfos_answerjustification_selected",
                                  });

                                setCampaign({
                                  ...campaign,
                                  research: {
                                    ...campaign?.research,
                                    justification:
                                      !!!campaign?.research?.justification,
                                  },
                                });
                              }}
                            />
                          }
                        />
                      </Section>
                    )}
                  </SectionsContainer>
                </StepContainer>
              ) : null}
              {currentStep === 1 ? (
                <>
                  <StepContainer>
                    <TitleContainer>
                      <Title>Criar Comunicação</Title>
                      <Divider />
                      <AsideText
                        title={"Configurações"}
                        message={
                          "Adicione as configurações da sua comunicação, como interações e notificações."
                        }
                      />
                    </TitleContainer>
                    <AccordionList>
                      {campaign?.type === "communication" ? (
                        <>
                          <Accordion
                            customHeader={
                              <>
                                <AccordionTitle variant="headline8">
                                  Interações
                                </AccordionTitle>
                                <AccordionDescription variant="body4">
                                  Habilite o botão abaixo caso deseje que a as
                                  pessoas possam interagir com a comunicação.
                                </AccordionDescription>
                              </>
                            }
                            children={
                              <Section>
                                <ToogleContainer>
                                  <Toggle
                                    disabled={disabledRecurrence}
                                    checked={
                                      !!campaign?.communication?.hasReact
                                    }
                                    onChange={() => {
                                      if (
                                        !!!campaign?.communication?.hasReact
                                      ) {
                                        segment({
                                          track: handleTrackNameByCampaignType(
                                            "company_engagement_communicationconfigurations_reactions_selected",
                                            type
                                          ),
                                        });
                                      }
                                      setCampaign({
                                        ...campaign,
                                        communication: {
                                          ...campaign?.communication,
                                          hasReact:
                                            !campaign?.communication?.hasReact,
                                        },
                                      });
                                    }}
                                  />
                                  <ToggleTextContainer>
                                    <ReviewTitle variant="body3">
                                      {"Permitir reações"}
                                    </ReviewTitle>
                                    <ReviewText variant="body4">
                                      {
                                        "Selecione esta opção para permitir que as pessoas reajam às comunicações."
                                      }
                                    </ReviewText>
                                  </ToggleTextContainer>
                                </ToogleContainer>
                              </Section>
                            }
                            variant={"default"}
                            defaultExpanded
                          />
                          <Divider />
                        </>
                      ) : (
                        <>
                          <Accordion
                            customHeader={
                              <>
                                <AccordionTitle variant="headline8">
                                  Resposta anônima
                                </AccordionTitle>
                                <AccordionDescription variant="body4">
                                  Habilite o botão abaixo caso deseje que a
                                  pesquisa seja anônima.
                                </AccordionDescription>
                              </>
                            }
                            children={
                              <Section
                                title={"Resposta anônima"}
                                message={
                                  "Selecione esta opção para que as respostas sejam registradas de forma anônima."
                                }
                                headerComplement={
                                  <Toggle
                                    disabled={disabledRecurrence}
                                    checked={!!campaign?.research?.anonymous}
                                    onChange={() => {
                                      if (!!!campaign?.research?.anonymous)
                                        segment({
                                          track:
                                            "company_engagement_surveyconfiguration_anonymousanswer_selected",
                                        });

                                      setCampaign({
                                        ...campaign,
                                        research: {
                                          ...campaign?.research,
                                          anonymous:
                                            !!!campaign?.research?.anonymous,
                                        },
                                      });
                                    }}
                                  />
                                }
                              />
                            }
                            variant={"default"}
                            defaultExpanded
                          />
                          <Divider />
                        </>
                      )}
                      <Accordion
                        customHeader={
                          <>
                            <Tag variant="paused">Obrigatório</Tag>
                            <AccordionTitle variant="headline8">
                              Notificações
                            </AccordionTitle>
                            <AccordionDescription variant="body4">
                              Selecione pelo menos uma opção de como deseja
                              notificar os destinatários.
                            </AccordionDescription>
                          </>
                        }
                        title="Notificações"
                        description="Selecione pelo menos uma opção de como deseja notificar os destinatários."
                        variant={"default"}
                        defaultExpanded
                        children={
                          <>
                            <Section>
                              <ToogleContainer>
                                <Checkbox
                                  disabled={disabledRecurrence}
                                  checked={campaign?.notifications?.push}
                                  onChange={() => {
                                    if (!campaign?.notifications?.push)
                                      segment({
                                        track: handleTrackNameByCampaignType(
                                          "company_engagement_communicationconfigurations_pushnotification_selected",
                                          type
                                        ),
                                      });

                                    setCampaign({
                                      ...campaign,
                                      notifications: {
                                        ...campaign?.notifications,
                                        push: !campaign?.notifications?.push,
                                      },
                                    });
                                  }}
                                />
                                <ToggleTextContainer>
                                  <ReviewTitle variant="body3">
                                    {"Push do aplicativo"}
                                  </ReviewTitle>
                                  <ReviewText variant="body4">
                                    {
                                      "O destinatário receberá uma notificação de push do aplicativo em suas notificações do celular."
                                    }
                                  </ReviewText>
                                </ToggleTextContainer>
                              </ToogleContainer>
                            </Section>
                            <Divider />
                            <Section>
                              <ToogleContainer>
                                <Checkbox
                                  disabled={disabledRecurrence}
                                  checked={campaign?.notifications?.email}
                                  onChange={() => {
                                    if (!campaign?.notifications?.email)
                                      segment({
                                        track: handleTrackNameByCampaignType(
                                          "company_engagement_communicationconfigurations_emailnotification_selected",
                                          type
                                        ),
                                      });

                                    setCampaign({
                                      ...campaign,
                                      notifications: {
                                        ...campaign?.notifications,
                                        email: !campaign?.notifications?.email,
                                      },
                                    });
                                  }}
                                />
                                <ToggleTextContainer>
                                  <ReviewTitle variant="body3">
                                    {"E-mail corporativo"}
                                  </ReviewTitle>
                                  <ReviewText variant="body4">
                                    {
                                      "O destinatário receberá um e-mail em seu e-mail corporativo cadastrado informando que possui uma nova comunicação."
                                    }
                                  </ReviewText>
                                </ToggleTextContainer>
                              </ToogleContainer>
                            </Section>
                          </>
                        }
                      />
                    </AccordionList>
                  </StepContainer>
                </>
              ) : null}
              {currentStep === 2 ? (
                <StepContainer flexColumn>
                  <TitleContainer>
                    <Title>{`Criar ${getCampaignType()}`}</Title>
                    <Divider />
                    <AsideText
                      title={"Destinatários"}
                      message={
                        "Selecione grupos ou escolha as pessoas manualmente."
                      }
                    />
                  </TitleContainer>
                  <Divider size="medium" />
                  <SectionsContainer>
                    {campaign?.research?.anonymous ? (
                      <>
                        <NotificationCard
                          title={
                            "O anonimato está habilitado, portanto, para garantir a segurança da informação a pesquisa deve ser enviada para pelo menos 3 pessoas."
                          }
                          description={""}
                          type="info"
                        />
                        <Divider size="small" />
                      </>
                    ) : null}
                    {campaign?.notifications?.email &&
                    employeesWithoutCorporateEmailCount ? (
                      <>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-start",
                          }}
                        >
                          <NotificationCard
                            title="Destinatários sem e-mails corporativos cadastrados"
                            description="Alguns destinatários não estão associados a um e-mail corporativo, impedindo o envio da comunicação por e-mail. Clique no link abaixo para atualizar os e-mails destes destinatários."
                            iconName="IconX"
                            type="error"
                            button={{
                              title: "Cadastrar e-mails",
                              onClick: () => {
                                onSubmit("draft");
                                navigate(`/employees`);
                              },
                            }}
                          />
                        </div>
                        <Divider size="small" />
                      </>
                    ) : null}
                    {getEmployeesByCompanyIdLoading ||
                    getGroupsByCompanyIdLoading ? (
                      <>
                        <SectionsContainer>
                          <SectionSkeleton />
                          <Divider />
                          <DataGridSkeleton />
                        </SectionsContainer>
                      </>
                    ) : (
                      <Section
                        title={"Seleção de pessoas"}
                        message={`Para quais pessoas essa ${getCampaignType(
                          true
                        )} será enviada?`}
                      >
                        <TransferListContainer>
                          <TransferList
                            disabled={selectedCampaign?.status === "send"}
                            showUserWithoutCorporateEmail={
                              !campaign?.notifications?.email
                            }
                            leftList={{
                              emptyStageMessage: employees?.filter(
                                ({ hidden }) => hidden
                              )?.length ? (
                                <EmptyStageMessageContainer>
                                  {"O filtro aplicado não apresenta resultados"}
                                </EmptyStageMessageContainer>
                              ) : (
                                <EmptyStageMessageContainer>
                                  {"Nenhuma pessoa encontrada."}
                                </EmptyStageMessageContainer>
                              ),
                              total: employees?.filter(({ hidden }) => !hidden)
                                ?.length,
                              title: `Todas as pessoas (${leftListCountSelected}/${formatNumber(
                                employees?.filter(({ hidden }) => !hidden)
                                  ?.length || 0
                              )})`,
                              data: employees,
                              setData: setEmployees,
                              onSearch: (text) => {
                                const filter = employees?.map((employee) => {
                                  if (employee?.name?.match(RegExp(text, "ig")))
                                    return { ...employee, hidden: false };
                                  return { ...employee, hidden: true };
                                });

                                setEmployees([...filter]);
                              },
                              customFilters: {
                                title: "Filtros",
                                sections: leftSections,
                                onCheck: (result) => {
                                  const { sectionId, options } = result;

                                  const sectionFilters = leftSections?.map(
                                    (section) => {
                                      if (section?.id === sectionId) {
                                        section.options = options;
                                      }
                                      return section;
                                    }
                                  );

                                  setLeftSections([...sectionFilters]);
                                },
                                onClear: () => {
                                  const sections = leftSections?.map(
                                    ({ options, ...rest }) => ({
                                      ...rest,
                                      options: options?.map((option) => ({
                                        ...option,
                                        checked: false,
                                      })),
                                    })
                                  );
                                  setLeftSections([...sections]);
                                },
                                onFilter: (result) => {
                                  const roleOptions = result[2]?.options;
                                  const roleIds = roleOptions
                                    ?.filter(({ checked }) => checked)
                                    ?.map(({ key }) => key);

                                  const departmentOptions = result[1]?.options;
                                  const departmentIds = departmentOptions
                                    ?.filter(({ checked }) => checked)
                                    ?.map(({ key }) => key);

                                  const options = result[0]?.options;
                                  const groupIds = options
                                    ?.filter(({ checked }) => checked)
                                    ?.map(({ key }) => key);

                                  if (
                                    !groupIds?.length &&
                                    !departmentIds?.length &&
                                    !roleIds?.length
                                  ) {
                                    setEmployees(
                                      employees?.map((employee) => ({
                                        ...employee,
                                        hidden: false,
                                      }))
                                    );
                                    return;
                                  }

                                  (async () => {
                                    const filteredEmployees: any = [];
                                    const result = groupIds?.map(
                                      (groupId) =>
                                        allGroups?.find(
                                          ({ _id }) => _id === groupId
                                        )?.employees
                                    );

                                    result?.forEach((employeeGroup) => {
                                      employeeGroup?.forEach(
                                        ({ _id, ...rest }) => {
                                          if (
                                            !filteredEmployees?.find(
                                              ({ _id: employeeId }) =>
                                                _id === employeeId
                                            ) &&
                                            !selectedEmployees?.find(
                                              ({ _id: employeeId }) =>
                                                _id === employeeId
                                            )
                                          )
                                            filteredEmployees?.push({
                                              _id,
                                              ...rest,
                                            });
                                        }
                                      );
                                    });

                                    const filter = employees?.map(
                                      (employee) => {
                                        if (
                                          filteredEmployees?.find(
                                            ({ _id }) => _id === employee?._id
                                          ) ||
                                          (departmentIds?.length &&
                                            allDepartments?.find(
                                              ({
                                                employees,
                                                _id: departmentId,
                                              }) =>
                                                employees?.includes(
                                                  employee?._id
                                                ) &&
                                                departmentIds?.includes(
                                                  departmentId
                                                )
                                            )) ||
                                          (roleIds?.length &&
                                            roles?.find(
                                              ({ employees, _id: roleId }) =>
                                                employees?.includes(
                                                  employee?._id
                                                ) && roleIds?.includes(roleId)
                                            ))
                                        )
                                          return { ...employee, hidden: false };
                                        return { ...employee, hidden: true };
                                      }
                                    );

                                    setEmployees([...filter]);
                                  })();
                                },
                                onSearch: ({ text, sectionId }) => {
                                  const sections = leftSections?.map(
                                    ({ id, title, options }) => {
                                      if (id === sectionId) {
                                        return {
                                          id,
                                          title,
                                          options: options?.map((option) => ({
                                            ...option,
                                            hidden: !option?.label?.match(
                                              RegExp(text, "ig")
                                            ),
                                          })),
                                        };
                                      }

                                      return { id, title, options };
                                    }
                                  );

                                  setLeftSections([...sections]);
                                },
                              },
                            }}
                            rightList={{
                              emptyStageMessage: selectedEmployees?.filter(
                                ({ hidden }) => hidden
                              )?.length ? (
                                <EmptyStageMessageContainer>
                                  {"O filtro aplicado não apresenta resultados"}
                                </EmptyStageMessageContainer>
                              ) : (
                                <EmptyStageMessageContainer>
                                  {`Pessoas movidas à ${getCampaignType(
                                    true
                                  )} aparecerão aqui.`}
                                </EmptyStageMessageContainer>
                              ),
                              total: selectedEmployees?.filter(
                                ({ hidden }) => !hidden
                              )?.length,
                              title: `Selecionados para a ${getCampaignType(
                                true
                              )} (${rightListCountSelected}/${formatNumber(
                                selectedEmployees?.filter(
                                  ({ hidden }) => !hidden
                                )?.length || 0
                              )})`,
                              data: selectedEmployees,
                              setData: setSelectedEmployees,
                              onSearch: (text) => {
                                const filter = selectedEmployees?.map(
                                  (employee) => {
                                    if (
                                      employee?.name?.match(RegExp(text, "ig"))
                                    )
                                      return { ...employee, hidden: false };
                                    return { ...employee, hidden: true };
                                  }
                                );

                                setSelectedEmployees([...filter]);
                              },
                            }}
                          />
                        </TransferListContainer>
                      </Section>
                    )}
                  </SectionsContainer>
                </StepContainer>
              ) : null}
              {currentStep === 3 ? (
                <StepContainer>
                  <TitleContainer>
                    <Title>{`Criar ${getCampaignType()}`}</Title>
                    <Divider />
                    <AsideText
                      title={"Envio"}
                      message={"Configure como deseja enviar."}
                    />
                  </TitleContainer>

                  <Section
                    title={"Configurações do envio"}
                    message={
                      "Escolha se o envio será realizado uma única vez ou configure a recorrência."
                    }
                  >
                    <FormRow>
                      <RadioField
                        disabled={disabledRecurrence}
                        label="Envio único"
                        checked={sendInfo?.recurrenceMode === false}
                        onChange={(v) => {
                          segment({
                            track: handleTrackNameByCampaignType(
                              "company_engagement_surveysend_singlesend_clicked",
                              type
                            ),
                          });
                          setSendInfo({ ...sendInfo, recurrenceMode: !v });
                        }}
                      />
                      <Space size="lg" />
                      <RadioField
                        disabled={disabledRecurrence}
                        label="Envio recorrente"
                        checked={sendInfo?.recurrenceMode === true}
                        onChange={(v) => {
                          segment({
                            track: handleTrackNameByCampaignType(
                              "company_engagement_surveysend_recurringsend_clicked",
                              type
                            ),
                          });
                          setSendInfo({ ...sendInfo, recurrenceMode: v });
                        }}
                      />
                    </FormRow>
                    {sendInfo?.recurrenceMode !== undefined ? (
                      <>
                        {sendInfo?.recurrenceMode ? (
                          <>
                            <Divider size="tiny" />
                            <LineSeparator />
                            <Divider size="tiny" />
                            <FormRow>
                              <CounterField
                                disabled={disabledRecurrence}
                                value={sendInfo?.recurrence?.repeat || 1}
                                onChange={(value) =>
                                  setSendInfo({
                                    ...sendInfo,
                                    recurrence: {
                                      ...(sendInfo?.recurrence || {}),
                                      repeat: value,
                                    },
                                  })
                                }
                                min={1}
                                labels={{
                                  left: "Repetir a cada",
                                }}
                              />
                              <Space size="lg" />
                              <InputContainer>
                                <SelectField
                                  disabled={disabledRecurrence}
                                  style={{ width: "100%" }}
                                  key={"frequency-field"}
                                  label={"Frequência"}
                                  fullWidth
                                  options={[
                                    {
                                      label: `Dia`,
                                      value: "day",
                                    },
                                    {
                                      label: `Semana`,
                                      value: "week",
                                    },
                                    {
                                      label: `Mês`,
                                      value: "month",
                                    },
                                    {
                                      label: `Ano`,
                                      value: "year",
                                    },
                                  ]}
                                  onSelectChange={(_: any, option) => {
                                    let track =
                                      "company_engagement_surveysend_recurringsend_frequency_daily_clicked";
                                    if (option?.value === "week")
                                      track =
                                        "company_engagement_surveysend_recurringsend_frequency_weekly_clicked";
                                    if (option?.value === "month")
                                      track =
                                        "company_engagement_surveysend_recurringsend_frequency_monthly_clicked";
                                    if (option?.value === "year")
                                      track =
                                        "company_engagement_surveysend_recurringsend_frequency_yearly_clicked";

                                    segment({
                                      track: handleTrackNameByCampaignType(
                                        track,
                                        type
                                      ),
                                    });

                                    setSendInfo({
                                      ...sendInfo,
                                      recurrence: {
                                        ...sendInfo?.recurrence,
                                        frequency: option?.value,
                                      },
                                    });
                                  }}
                                  value={sendInfo?.recurrence?.frequency}
                                />
                              </InputContainer>
                            </FormRow>
                            {sendInfo?.recurrence?.frequency === "week" ? (
                              <SectionField title="Selecione os dias da semana">
                                <DaysPicker
                                  disabled={disabledRecurrence}
                                  days={daysOfTheWeek}
                                  onChange={(days, day) => {
                                    if (day?.checked) {
                                      const track = `company_engagement_surveysend_recurringsend_${getSegmenetFrequency()}_${
                                        day?.value
                                      }_selected`;

                                      segment({
                                        track: handleTrackNameByCampaignType(
                                          track,
                                          type
                                        ),
                                      });
                                    }

                                    setDaysOfTheWeek(days);
                                  }}
                                />
                              </SectionField>
                            ) : null}
                            <Divider size="tiny" />
                            <LineSeparator />
                            <Divider size="tiny" />
                            <SectionField title="Início da recorrência">
                              <FormRow>
                                <InputContainer>
                                  <DatePicker
                                    disabled={disabledRecurrence}
                                    className="date-picker"
                                    fromDate={dayjs(new Date())}
                                    label="Data de envio"
                                    onDateChange={(
                                      value: Dayjs | undefined
                                    ) => {
                                      if (value) {
                                        setSendInfo({
                                          ...sendInfo,
                                          startDate: {
                                            ...sendInfo?.startDate,
                                            date: value?.format("YYYY-MM-DD"),
                                          },
                                          recurrence: {
                                            ...sendInfo?.recurrence,
                                            endDate:
                                              value?.diff(
                                                dayjs(
                                                  sendInfo?.recurrence?.endDate
                                                ),
                                                "days"
                                              ) >= 0
                                                ? value?.format("YYYY-MM-DD")
                                                : sendInfo?.recurrence?.endDate,
                                          },
                                        });
                                      }
                                    }}
                                    value={
                                      sendInfo?.startDate?.date
                                        ? dayjs(sendInfo?.startDate?.date)
                                        : dayjs()
                                    }
                                  />
                                </InputContainer>
                                <Space size="lg" />
                                <InputContainer>
                                  <SelectField
                                    disabled={disabledRecurrence}
                                    style={{ width: "100%" }}
                                    key={"period-field-1"}
                                    label={"Período"}
                                    fullWidth
                                    options={getPeriodOptions()}
                                    onSelectChange={(_: any, option) => {
                                      const track = `company_engagement_surveysend_recurringsend_${getSegmenetFrequency()}recurringstarts_${
                                        option?.label === "Imediatamente"
                                          ? "immediately"
                                          : option?.value
                                      }_clicked`;

                                      segment({
                                        track: handleTrackNameByCampaignType(
                                          track,
                                          type
                                        ),
                                      });

                                      setSendInfo({
                                        ...sendInfo,
                                        startDate: {
                                          ...sendInfo?.startDate,
                                          period: option?.value,
                                        },
                                      });
                                    }}
                                    value={sendInfo?.startDate?.period}
                                  />
                                </InputContainer>
                              </FormRow>
                            </SectionField>
                            <SectionField title="Fim da recorrência">
                              <FormRow>
                                <InputContainer>
                                  <RadioField
                                    label="Nunca termina"
                                    checked={
                                      sendInfo?.endRecurrenceType === "never"
                                    }
                                    onChange={(e) => {
                                      const track = `company_engagement_surveysend_recurringsend_${getSegmenetFrequency()}recurringends_never_selected`;

                                      segment({
                                        track: handleTrackNameByCampaignType(
                                          track,
                                          type
                                        ),
                                      });

                                      setSendInfo({
                                        ...sendInfo,
                                        endRecurrenceType: "never",
                                        recurrence: {
                                          ...sendInfo?.recurrence,
                                          occurrenceLimit: -1,
                                        },
                                      });
                                    }}
                                  />
                                  <Divider />
                                  <RadioField
                                    label="Termina em"
                                    checked={
                                      sendInfo?.endRecurrenceType === "date"
                                    }
                                    onChange={(e) => {
                                      const track = `company_engagement_surveysend_recurringsend_${getSegmenetFrequency()}recurringends_specificdate_selected`;

                                      segment({
                                        track: handleTrackNameByCampaignType(
                                          track,
                                          type
                                        ),
                                      });

                                      setSendInfo({
                                        ...sendInfo,
                                        endRecurrenceType: "date",
                                      });
                                    }}
                                  >
                                    <DatePicker
                                      style={{ width: 200 }}
                                      disabled={
                                        sendInfo?.endRecurrenceType === "date"
                                          ? false
                                          : true
                                      }
                                      className="date-picker"
                                      fromDate={
                                        disabledRecurrence
                                          ? dayjs()
                                          : dayjs(sendInfo?.startDate?.date)
                                      }
                                      label="Data"
                                      onDateChange={(
                                        value: Dayjs | undefined
                                      ) => {
                                        if (value) {
                                          setSendInfo({
                                            ...sendInfo,
                                            recurrence: {
                                              ...sendInfo?.recurrence,
                                              endDate:
                                                value?.format("YYYY-MM-DD"),
                                            },
                                          });
                                        }
                                      }}
                                      value={
                                        sendInfo?.recurrence?.endDate
                                          ? dayjs(sendInfo?.recurrence?.endDate)
                                          : dayjs()
                                      }
                                    />
                                  </RadioField>
                                  <Divider />
                                  <RadioField
                                    checked={
                                      sendInfo?.endRecurrenceType ===
                                      "occurrence"
                                    }
                                    onChange={() => {
                                      const track = `company_engagement_surveysend_recurringsend_${getSegmenetFrequency()}recurringends_endsafter_selected`;

                                      segment({
                                        track: handleTrackNameByCampaignType(
                                          track,
                                          type
                                        ),
                                      });

                                      setSendInfo({
                                        ...sendInfo,
                                        endRecurrenceType: "occurrence",
                                        recurrence: {
                                          ...sendInfo?.recurrence,
                                          occurrenceLimit: 2,
                                        },
                                      });
                                    }}
                                  >
                                    <CounterField
                                      disabled={
                                        sendInfo?.endRecurrenceType !==
                                        "occurrence"
                                      }
                                      value={
                                        sendInfo?.recurrence?.occurrenceLimit &&
                                        sendInfo?.recurrence?.occurrenceLimit >
                                          1
                                          ? sendInfo?.recurrence
                                              ?.occurrenceLimit
                                          : 2
                                      }
                                      onChange={(value) =>
                                        setSendInfo({
                                          ...sendInfo,
                                          recurrence: {
                                            ...sendInfo?.recurrence,
                                            occurrenceLimit: value,
                                          },
                                        })
                                      }
                                      min={2}
                                      labels={{
                                        left: "Termina após",
                                        right: "ocorrências",
                                      }}
                                    />
                                  </RadioField>
                                </InputContainer>
                              </FormRow>
                            </SectionField>
                            {campaign?.type === "communication" ? (
                              <></>
                            ) : (
                              <>
                                <Divider />
                                <SectionField
                                  title="Período de coleta de respostas"
                                  description="Configure até quando a pesquisa deve receber respostas. Após o limite, a pesquisa será fechada e novas respostas não serão aceitas."
                                >
                                  <Divider />
                                  <Typography
                                    variant="headline8"
                                    style={{
                                      color: "var(--color-neutral-dark3)",
                                      fontWeight: 700,
                                    }}
                                  >
                                    Aceitar respostas:{" "}
                                  </Typography>
                                  <Divider />
                                  <FormRow>
                                    <RadioField
                                      label="Até o próximo envio"
                                      checked={
                                        sendInfo?.recurrence
                                          ?.interactionDeadlineConfig
                                          ?.nextMode === true
                                      }
                                      onChange={(e) => {
                                        const track = `company_engagement_surveysend_recurringsend_${getSegmenetFrequency()}collectanswers_untilnextdelivery_selected`;

                                        segment({
                                          track: handleTrackNameByCampaignType(
                                            track,
                                            type
                                          ),
                                        });

                                        setSendInfo({
                                          ...sendInfo,
                                          recurrence: {
                                            ...sendInfo?.recurrence,
                                            interactionDeadlineConfig: {
                                              ...sendInfo?.recurrence
                                                ?.interactionDeadlineConfig,
                                              nextMode: e,
                                            },
                                          },
                                        });
                                      }}
                                    />
                                  </FormRow>
                                  <Divider />
                                  <RadioField
                                    checked={
                                      sendInfo?.recurrence
                                        ?.interactionDeadlineConfig
                                        ?.nextMode === false
                                    }
                                    onChange={(e) => {
                                      const track = `company_engagement_surveysend_recurringsend_${getSegmenetFrequency()}collectanswers_untilspecifictime_selected`;

                                      segment({
                                        track: handleTrackNameByCampaignType(
                                          track,
                                          type
                                        ),
                                      });

                                      setSendInfo({
                                        ...sendInfo,
                                        recurrence: {
                                          ...sendInfo?.recurrence,
                                          interactionDeadlineConfig: {
                                            ...sendInfo?.recurrence
                                              ?.interactionDeadlineConfig,
                                            nextMode: !e,
                                          },
                                        },
                                      });
                                    }}
                                  >
                                    <FormRow>
                                      <CounterField
                                        disabled={
                                          sendInfo?.recurrence
                                            ?.interactionDeadlineConfig
                                            ?.nextMode === true
                                        }
                                        labels={{
                                          left: "Até",
                                        }}
                                        value={
                                          sendInfo?.recurrence
                                            ?.interactionDeadlineConfig
                                            ?.calculatedDate?.multiplier || 1
                                        }
                                        min={1}
                                        onChange={(e) =>
                                          setSendInfo({
                                            ...sendInfo,
                                            recurrence: {
                                              ...sendInfo?.recurrence,
                                              interactionDeadlineConfig: {
                                                ...sendInfo?.recurrence
                                                  ?.interactionDeadlineConfig,
                                                calculatedDate: {
                                                  ...sendInfo?.recurrence
                                                    ?.interactionDeadlineConfig
                                                    ?.calculatedDate,
                                                  multiplier: e,
                                                },
                                              },
                                            },
                                          })
                                        }
                                      />
                                      <Space size="lg" />
                                      <InputContainer>
                                        <SelectField
                                          disabled={
                                            sendInfo?.recurrence
                                              ?.interactionDeadlineConfig
                                              ?.nextMode === true
                                          }
                                          style={{ width: "100%" }}
                                          key={"interaction-period-field-1"}
                                          label={"Período"}
                                          fullWidth
                                          options={[
                                            {
                                              label: "Dia",
                                              value: "day",
                                            },
                                            {
                                              label: "Semana",
                                              value: "week",
                                            },
                                            {
                                              label: "Mês",
                                              value: "month",
                                            },
                                          ]}
                                          onSelectChange={(_: any, option) => {
                                            setSendInfo({
                                              ...sendInfo,
                                              recurrence: {
                                                ...sendInfo?.recurrence,
                                                interactionDeadlineConfig: {
                                                  ...sendInfo?.recurrence
                                                    ?.interactionDeadlineConfig,
                                                  calculatedDate: {
                                                    ...sendInfo?.recurrence
                                                      ?.interactionDeadlineConfig
                                                      ?.calculatedDate,
                                                    period: option?.value,
                                                  },
                                                },
                                              },
                                            });
                                          }}
                                          value={
                                            sendInfo?.recurrence
                                              ?.interactionDeadlineConfig
                                              ?.calculatedDate?.period
                                          }
                                        />
                                      </InputContainer>
                                      <Space size="lg" />
                                      <PeriodLabel variant="body3">
                                        após a data de cada envio
                                      </PeriodLabel>
                                    </FormRow>
                                  </RadioField>
                                </SectionField>
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            <Divider size="tiny" />
                            <LineSeparator />
                            <Divider size="tiny" />
                            <SectionField
                              title={
                                campaign?.type === "communication"
                                  ? "Data de envio e período"
                                  : "Envio e prazo de respostas"
                              }
                              description={
                                "A pesquisa estará disponivel pra respostas durante o período abaixo."
                              }
                            >
                              <FormRow>
                                <InputContainer>
                                  <DatePicker
                                    className="date-picker"
                                    fromDate={dayjs(new Date())}
                                    label="Data de envio"
                                    onDateChange={(
                                      value: Dayjs | undefined
                                    ) => {
                                      if (value) {
                                        setSendInfo({
                                          ...sendInfo,
                                          startDate: {
                                            ...sendInfo?.startDate,
                                            date: value?.format("YYYY-MM-DD"),
                                          },
                                          interactionDeadline:
                                            sendInfo?.interactionDeadline
                                              ? value?.diff(
                                                  dayjs(
                                                    sendInfo?.interactionDeadline
                                                  ),
                                                  "days"
                                                ) >= 0
                                                ? value?.format("YYYY-MM-DD")
                                                : sendInfo?.interactionDeadline
                                              : undefined,
                                        });
                                      }
                                    }}
                                    value={
                                      sendInfo?.startDate?.date
                                        ? dayjs(sendInfo?.startDate?.date)
                                        : dayjs()
                                    }
                                  />
                                </InputContainer>
                                <Space size="lg" />
                                <InputContainer>
                                  <SelectField
                                    style={{ width: "100%" }}
                                    key={"period-field"}
                                    label={"Período"}
                                    fullWidth
                                    options={getPeriodOptions()}
                                    onSelectChange={(_: any, option) => {
                                      let track =
                                        "company_engagement_surveysend_singlesend_sendtime_morning_clicked";

                                      if (option?.value === "afternoon")
                                        track =
                                          "company_engagement_surveysend_singlesend_sendtime_afternoon_clicked";
                                      if (option?.value === "night")
                                        track =
                                          "company_engagement_surveysend_singlesend_sendtime_evening_clicked";
                                      if (option?.label === "Imediatamente")
                                        track =
                                          "company_engagement_surveysend_singlesend_sendtime_immediately_clicked";

                                      segment({
                                        track: handleTrackNameByCampaignType(
                                          track,
                                          type
                                        ),
                                      });

                                      setSendInfo({
                                        ...sendInfo,
                                        startDate: {
                                          ...sendInfo?.startDate,
                                          period: option?.value,
                                        },
                                      });
                                    }}
                                    value={sendInfo?.startDate?.period}
                                  />
                                </InputContainer>
                              </FormRow>
                              {campaign?.type === "communication" ? (
                                <></>
                              ) : (
                                <>
                                  <Divider />
                                  <FormRow>
                                    <InputContainer>
                                      <DatePicker
                                        className="date-picker"
                                        fromDate={dayjs(
                                          sendInfo?.startDate?.date ||
                                            new Date()
                                        )}
                                        label="Prazo de resposta"
                                        onDateChange={(
                                          value: Dayjs | undefined
                                        ) => {
                                          setSendInfo({
                                            ...sendInfo,
                                            interactionDeadline: value
                                              ? value?.format("YYYY-MM-DD")
                                              : undefined,
                                          });
                                        }}
                                        value={
                                          sendInfo?.interactionDeadline
                                            ? dayjs(
                                                sendInfo?.interactionDeadline
                                              )
                                            : undefined
                                        }
                                      />
                                    </InputContainer>
                                  </FormRow>
                                </>
                              )}
                            </SectionField>
                          </>
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                  </Section>
                </StepContainer>
              ) : (
                <></>
              )}
              {currentStep === 4 ? (
                <StepContainer>
                  <TitleContainer>
                    <Title>{`Criar ${getCampaignType()}`}</Title>
                    <Divider />
                    <AsideText
                      title={"Revisão"}
                      message={`Confira a prévia da sua ${getCampaignType(
                        true
                      )} e revise antes de enviá-la.`}
                    />
                  </TitleContainer>
                  <SectionsContainer>
                    {campaign?.type === "communication" ? (
                      <ReviewSection
                        title={"Pré-visualizar comunicação"}
                        subtitle={"Confira a prévia da sua comunicação."}
                        data={[
                          {
                            value: (
                              <ReviewContainer>
                                <ReviewContainerBetween>
                                  <ReviewTitle variant="body4">
                                    {campaign?.communication?.title}
                                  </ReviewTitle>
                                  <ReviewText variant="body4">
                                    {dayjs().format("DD/MM/YYYY HH:mm")}
                                  </ReviewText>
                                </ReviewContainerBetween>
                                <Divider size="tiny" />
                                <ReviewText variant="body4">
                                  {campaign?.communication?.message}
                                </ReviewText>
                                <Divider size="tiny" />
                                {campaign?.communication?.linkUrl &&
                                showSectionLink ? (
                                  <LinkButton variant="default">
                                    <a
                                      href={campaign?.communication?.linkUrl}
                                      target="_blank"
                                    >
                                      {"Acesse agora"}
                                    </a>
                                  </LinkButton>
                                ) : null}
                                {campaign?.communication?.file?.data ||
                                campaign?.communication?.imageUrl?.signedUrl ? (
                                  <>
                                    <Divider />
                                    <ImagePreview
                                      crossOrigin="anonymous"
                                      src={
                                        campaign?.communication?.file?.data ||
                                        `${campaign?.communication?.imageUrl?.signedUrl}`
                                      }
                                    />
                                  </>
                                ) : null}
                                {campaign?.communication?.hasReact ? (
                                  <>
                                    <Divider size="medium" />
                                    <TotalReactions
                                      total={100}
                                      reactionsCount={[4, 3, 2, 1]}
                                      hiddeLikes
                                    />
                                  </>
                                ) : null}
                              </ReviewContainer>
                            ),
                          },
                        ]}
                      />
                    ) : (
                      <ReviewSection
                        title={"Pré-visualizar pesquisa"}
                        subtitle={"Confira a prévia da sua pesquisa."}
                        data={[
                          {
                            value: (
                              <ReviewContainer>
                                <TagContainer>
                                  <Tag variant="neutral">
                                    {campaign?.research?.scale === "enps"
                                      ? "eNPS (0-10)"
                                      : "Escala de avialiação (1-5)"}
                                  </Tag>
                                  {campaign?.research?.anonymous ? (
                                    <Tag variant="neutral">
                                      {"Resposta anônima"}
                                    </Tag>
                                  ) : null}
                                </TagContainer>
                                <ReviewContainerBetween>
                                  <ReviewTitle variant="body4">
                                    {campaign?.research?.question}
                                  </ReviewTitle>
                                  <ReviewText variant="body4">
                                    {dayjs().format("DD/MM/YYYY HH:mm")}
                                  </ReviewText>
                                </ReviewContainerBetween>
                                <Divider size="tiny" />
                                <ScalePreview
                                  ItemSize={100}
                                  lowerLabel={campaign?.research?.lowerLabel}
                                  upperLabel={campaign?.research?.upperLabel}
                                  scale={campaign?.research?.scale}
                                  type={campaign?.type}
                                />
                                <Divider size="tiny" />
                                {campaign?.research?.justification ? (
                                  <>
                                    <Divider size="small" />
                                    <PreviewTextFieldContainer>
                                      <TextField
                                        label="Deixe seu comentário (opcional)"
                                        rows={5}
                                        fullWidth
                                        disabled
                                        value={""}
                                      />
                                    </PreviewTextFieldContainer>
                                  </>
                                ) : null}
                              </ReviewContainer>
                            ),
                          },
                        ]}
                      ></ReviewSection>
                    )}
                    <Divider />
                    <ReviewSection
                      title="Revisar informações básicas"
                      subtitle="Confira as informações básicas."
                      data={
                        campaign?.type === "communication"
                          ? [
                              {
                                label: "Nome de identificação da comunicação",
                                value: campaign?.name,
                              },
                              {
                                label: "Título da mensagem",
                                value: campaign?.communication?.title,
                              },
                              {
                                label: "Mensagem",
                                value: campaign?.communication?.message,
                              },
                              {
                                label: "Inserir link",
                                value:
                                  showSectionLink &&
                                  campaign?.communication?.linkUrl
                                    ? "Habilitado"
                                    : "Desabilitado",
                              },
                              {
                                label: "Texto do link",
                                value:
                                  showSectionLink &&
                                  campaign?.communication?.linkUrl
                                    ? "Acesse agora"
                                    : "",
                              },
                              {
                                label: "Link",
                                value: showSectionLink
                                  ? campaign?.communication?.linkUrl
                                  : undefined,
                              },
                              {
                                label: "Imagem",
                                value:
                                  campaign?.communication?.imageUrl
                                    ?.signedUrl || campaign?.communication?.file
                                    ? "Com imagem"
                                    : "Sem Imagem",
                              },
                            ]
                          : [
                              {
                                label: "Nome da pesquisa",
                                value: campaign?.name,
                              },
                              {
                                label: "Descrição",
                                value: campaign?.research?.description,
                              },
                              {
                                label: "Texto da pergunta",
                                value: campaign?.research?.question,
                              },
                              {
                                label: "Formato da resposta",
                                value:
                                  campaign?.research?.type === "enps"
                                    ? "eNPS (0-10)"
                                    : `Escala da avaliação (1-5)`,
                              },
                              {
                                label: "Justificativa da resposta",
                                value: campaign?.research?.justification
                                  ? "Habilitado"
                                  : "Desabilitado",
                              },
                            ]
                      }
                    />
                    <Divider />
                    <ReviewSection
                      title="Revisar configurações"
                      subtitle="Confira as configurações habilitadas."
                      data={[
                        ...(campaign?.type === "communication"
                          ? [
                              {
                                label: "Reações",
                                value: campaign?.communication?.hasReact
                                  ? "Habilitado"
                                  : "Desabilitado",
                              },
                            ]
                          : [
                              {
                                label: "Anonimato",
                                value: campaign?.research?.anonymous
                                  ? "Habilitado"
                                  : "Desabilitado",
                              },
                            ]),
                        {
                          label: "Notificações",
                          value:
                            campaign?.notifications?.push ||
                            campaign?.notifications?.email
                              ? `${
                                  campaign?.notifications?.push
                                    ? "Push do aplicativo"
                                    : ""
                                } ${
                                  campaign?.notifications?.email
                                    ? campaign?.notifications?.push
                                      ? "e e-mail-corporativo"
                                      : "E-mail corporativo"
                                    : ""
                                }`
                              : undefined,
                        },
                      ]}
                    />
                    <Divider />
                    <ReviewSection
                      title={"Revisar envio"}
                      subtitle={`Confira quando sua pesquisa será enviada.`}
                      data={[
                        {
                          value: <List data={sendInfo} />,
                        },
                      ]}
                    ></ReviewSection>
                    <Divider />
                    <ReviewSection
                      title={"Revisar destinatários"}
                      subtitle={`Confira os grupos e pessoas para quem a ${getCampaignType(
                        true
                      )} será enviada.`}
                      data={[
                        {
                          label: "Pessoas",
                          value: (
                            <>
                              <Divider size="tiny" />
                              <ReviewDataContainer>
                                {selectedEmployees
                                  ?.slice(0, 30)
                                  ?.map(({ _id, name }) => (
                                    <Tag key={_id} variant="item">
                                      {name}
                                    </Tag>
                                  ))}
                                {selectedEmployees?.length > 30 ? (
                                  <Tag variant="item">
                                    {`${selectedEmployees?.length - 30}...`}
                                  </Tag>
                                ) : null}
                              </ReviewDataContainer>
                            </>
                          ),
                        },
                      ]}
                    />
                  </SectionsContainer>
                </StepContainer>
              ) : null}
            </PageContainer>
          </FormContainer>
          <Modal
            title={"Pronto"}
            message={
              campaignStatus === "draft"
                ? "Seu rascunho foi salvo com sucesso 😄"
                : `Sua ${getCampaignType(true)} foi ${
                    campaignStatus === "send" ? "enviada" : "agendada"
                  } com sucesso. 😄`
            }
            open={showSuccessModal}
            rightButton={{
              label: `Voltar para ${getCampaignType(false, true)}`,
              onClick: () =>
                navigate(
                  `/engagement/${type === "communication" ? type : "survey"}`
                ),
            }}
            status="success"
            onClose={() =>
              navigate(
                `/engagement/${type === "communication" ? type : "survey"}`
              )
            }
          />
          <ModalV2
            title="Atenção!"
            subTitle={`${employeesWithoutCorporateEmailCount} destinatário(s) não possuem <br/> e-mail corporativo <br/> cadastrado`}
            description="Ao continuar, você está ciente de que essas pessoas não <br/> receberão a notificação por e-mail."
            onClose={() => setShowCorporateEmailModal(!showCorporateEmailModal)}
            closeButton
            iconStatus="alert"
            open={showCorporateEmailModal}
            iconName="IconAlertCircle"
            footer={{
              closeButton: {
                label: "Cancelar",
                onClick: () =>
                  setShowCorporateEmailModal(!showCorporateEmailModal),
                variant: "neutral",
                disabled: createEngagementLoading,
              },
              submitButton: {
                label: "Continuar",
                onClick: () => handleSubmitButtonClick(),
                size: "large",
                variant: "secondary",
                variantType: "error",
                disabled: createEngagementLoading,
              },
            }}
          />
        </>
      </>
      <FixedFooter
        leftContent={
          <LinkButtonsContainer>
            <LinkButton
              variant="default"
              greyMode
              onClick={() => {
                let track = "company_engagement_surveyinfos_exit_clicked";

                if (currentStep === 1)
                  track = "company_engagement_surveyconfiguration_exit_clicked";

                if (currentStep === 2)
                  track = "company_engagement_surveyrecipients_exit_clicked";

                if (currentStep === 3)
                  track = "company_engagement_surveysend_exit_clicked";

                if (currentStep === 4)
                  track = "company_engagement_surveyreview_exit_clicked";

                segment({
                  track: handleTrackNameByCampaignType(track, type),
                });

                navigate(
                  `/engagement/${type === "communication" ? type : "survey"}`
                );
              }}
            >
              {"Sair sem salvar"}
            </LinkButton>
            {campaign?.name &&
            campaign?.type &&
            !["scheduled", "send"]?.includes(selectedCampaign?.status) ? (
              <LinkButton
                greyMode
                variant="default"
                onClick={() => {
                  let track =
                    "company_engagement_surveyinfos_savedraft_clicked";

                  if (currentStep === 1)
                    track =
                      "company_engagement_surveyconfiguration_savedraft_clicked";

                  if (currentStep === 2)
                    track =
                      "company_engagement_surveyrecipients_savedraft_clicked";

                  if (currentStep === 3)
                    track = "company_engagement_surveysend_savedraft_clicked";

                  if (currentStep === 4)
                    track = "company_engagement_surveyreview_savedraft_clicked";

                  segment({
                    track: handleTrackNameByCampaignType(track, type),
                  });

                  onSubmit("draft");
                }}
                disabled={
                  !(campaign?.name && campaign?.type) || createEngagementLoading
                }
              >
                {"Sair e salvar rascunho"}
              </LinkButton>
            ) : null}
          </LinkButtonsContainer>
        }
        rightContent={
          <ButtonStepsContainer>
            <Button
              size="large"
              variant="secondary"
              onClick={() => {
                setCurrentStep(currentStep - 1);
              }}
              disabled={currentStep === 0}
            >
              <Icons name="IconArrowLeft" />
              {"Voltar"}
            </Button>
            <Space size="lg" />
            {currentStep < 4 ? (
              <Button
                size="large"
                variant="primary"
                onClick={() => {
                  let track =
                    "company_engagement_communicationinfos_next_clicked";

                  if (currentStep === 1)
                    track =
                      "company_engagement_communicationconfigurations_next_clicked";

                  if (currentStep === 2)
                    track =
                      "company_engagement_communicationrecipients_next_clicked";

                  if (currentStep === 3)
                    track = "company_engagement_communicationsend_next_clicked";

                  if (currentStep === 4)
                    track =
                      "company_engagement_communicationreview_next_clicked";

                  segment({
                    track: handleTrackNameByCampaignType(track, type),
                  });

                  setCurrentStep(currentStep + 1);
                }}
                disabled={hasDisabled()}
              >
                {"Continuar"} <Icons name="IconArrowRight" />
              </Button>
            ) : null}
            {currentStep === 4 ? (
              <Button
                size="large"
                variant="primary"
                onClick={() => {
                  if (
                    campaign?.notifications?.email &&
                    employeesWithoutCorporateEmailCount
                  ) {
                    setShowCorporateEmailModal(!showCorporateEmailModal);
                  } else {
                    handleSubmitButtonClick();
                  }
                }}
                disabled={createEngagementLoading || hasDisabled()}
              >
                {`Adicionar ${getCampaignType(true)}`}
              </Button>
            ) : null}
          </ButtonStepsContainer>
        }
      />
    </NewThemeProvider>
  );
};

export { CampaignForm };
