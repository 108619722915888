import styled from "styled-components";
import { Typography } from "@flash-tecnologia/hros-web-ui-v2";

const Container = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  overflow-y: auto;
`;

const CardsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow-y: auto;

  > div {
    width: 100%;
    margin: 0 10px;
  }

  > div:first-child {
    margin-left: 0;
  }

  > div:last-child {
    margin-right: 0;
  }

  @media screen and (max-width: 700px) {
    flex-direction: column;
    > div {
      margin: 0 0 20px 0;
    }
  }
`;

const Title = styled(Typography)`
  color: var(--color-neutral-30);
  margin-bottom: 10px;
`;

export { Container, CardsContainer, Title };
