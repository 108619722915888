import {
  Icons,
  Radio,
  TextField,
  LinkButton,
  Checkbox,
} from "@flash-tecnologia/hros-web-ui-v2";
import { Typography } from "@components/index";
import { Divider } from "@components/v2";
import {
  Container,
  FieldRow,
  FieldRowContainer,
  LinkButtonContainer,
  TrashButtonContainer,
} from "./styles";
import { OptionsInterface } from "./types";
import { generateId } from "@components/EngagementForm/common/components/generateId";

const Options = ({
  disabled,
  options,
  answerFormat,
  justification,
  onChange,
}: OptionsInterface) => {
  return (
    <Container>
      {options?.map(({ id, value }, i) => (
        <FieldRowContainer key={`${id}-${i}`}>
          <FieldRow>
            {answerFormat === "multiple-choice" ? (
              <Radio checked={false} disabled />
            ) : answerFormat === "select-box" ? (
              <Checkbox checked={false} disabled />
            ) : null}
            <TextField
              className="options-text-feild"
              label={`Opção ${i + 1}`}
              value={value || ""}
              disabled={value === "outro" || disabled}
              onChange={(e) =>
                onChange({
                  justification,
                  options: [
                    ...options?.map((o) =>
                      o.id === id ? { ...o, value: e?.target?.value || "" } : o
                    ),
                  ],
                })
              }
            />
            <TrashButtonContainer
              onClick={() =>
                disabled
                  ? null
                  : onChange({
                      justification,
                      options: options?.filter((o) => o?.id !== id),
                    })
              }
            >
              <Icons name="IconTrash" color="var(--color-neutral-40)" />
            </TrashButtonContainer>
          </FieldRow>
          {i !== options?.length - 1 ? <Divider spacing="xs1" /> : <></>}
        </FieldRowContainer>
      ))}
      {justification ? (
        <>
          <Divider spacing="xs1" />
          <FieldRow>
            {answerFormat === "multiple-choice" ? (
              <Radio checked={false} disabled />
            ) : answerFormat === "select-box" ? (
              <Checkbox checked={false} disabled />
            ) : null}
            <TextField
              className="options-text-feild"
              label="Outro"
              value=""
              disabled={true}
            />
            {disabled ? null : (
              <TrashButtonContainer
                onClick={() =>
                  onChange({
                    options,
                    justification: !justification,
                  })
                }
              >
                <Icons name="IconTrash" color="var(--color-neutral-40)" />
              </TrashButtonContainer>
            )}
          </FieldRow>
        </>
      ) : null}
      <Divider spacing="xs1" />
      <LinkButtonContainer>
        <LinkButton
          disabled={disabled}
          variant="default"
          onClick={() => {
            onChange({
              justification,
              options: [
                ...(options || []),
                {
                  id: generateId("option"),
                  value: "",
                },
              ],
            });
          }}
        >
          Adicionar nova opção
        </LinkButton>
        {answerFormat !== "dropdown" && !justification ? (
          <>
            <>
              <Divider spacing="xs1" type="horizontal" />
              <Typography
                variant="body3"
                color="var(--color-neutral-30)"
                fontWeight={600}
              >
                ou
              </Typography>
              <Divider spacing="xs1" type="horizontal" />
            </>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <LinkButton
                disabled={disabled}
                variant="neutral"
                onClick={() => {
                  if (justification) return;
                  onChange({
                    options,
                    justification: !justification,
                  });
                }}
              >
                Adicionar opção de “outro”
              </LinkButton>
            </div>
          </>
        ) : null}
      </LinkButtonContainer>
    </Container>
  );
};

export { Options };
