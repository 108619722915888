import styled from "styled-components";
import { Icons } from "@flash-tecnologia/hros-web-ui-v2";

const Body = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  width: 500px;
`;

const StatusIcon = styled(Icons)`
  fill: transparent !important;
`;

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 12px 40px;
  border-top: 1px solid var(--color-neutral-90);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`;

const StatusContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const TextContainer = styled.div`
  p {
    text-align: left;
  }
`;

export { Body, Footer, StatusIcon, TextContainer, StatusContainer };
