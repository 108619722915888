import { Icons, IconsProps } from "@flash-tecnologia/hros-web-ui-v2";
import { Typography } from "../../../../../../../..";
import {
  Container,
  LabelContainer,
  ScaleContainer,
  ValueContainer,
} from "./styles";
import { ScalePreviewInterface } from "./types";
import { ReactNode } from "react";

const ScalePreview = ({
  scaleRange,
  answerFormat,
  lowerLabel,
  upperLabel,
}: ScalePreviewInterface) => {
  const emojis: IconsProps["name"][] = [
    "IconMoodCry",
    "IconMoodSad",
    "IconMoodEmpty",
    "IconMoodSmileBeam",
    "IconMoodHappy",
  ];
  const scale = () => {
    const result: number | ReactNode[] = [];

    if (
      typeof scaleRange?.min === "number" &&
      typeof scaleRange?.max === "number"
    ) {
      const min = scaleRange?.min;
      const max = scaleRange?.max;

      for (let i = min; i <= max; i++) {
        if (answerFormat === "scale") result?.push(i);
        if (answerFormat === "heart") result?.push(<Icons name="IconHeart" />);
        if (answerFormat === "star") result?.push(<Icons name="IconStar" />);
        if (answerFormat === "emoji")
          result?.push(<Icons name={emojis[result?.length]} />);
      }
    }

    return result;
  };
  return (
    <Container>
      <ScaleContainer
        style={{ paddingBottom: upperLabel || lowerLabel ? "0" : "16px" }}
      >
        {scale()?.map((value, i) => {
          return (
            <ValueContainer key={`scale-preview-value-${i}`}>
              <Typography
                variant="body1"
                color="var(--color-neutral-20)"
                fontWeight={700}
              >
                {value}
              </Typography>
            </ValueContainer>
          );
        })}
      </ScaleContainer>
      {lowerLabel || upperLabel ? (
        <LabelContainer>
          <Typography variant="body4" color="var(--color-neutral-30)">
            {lowerLabel}
          </Typography>
          <Typography variant="body4" color="var(--color-neutral-30)">
            {upperLabel}
          </Typography>
        </LabelContainer>
      ) : null}
    </Container>
  );
};

export { ScalePreview };
