import {
  Modal as FlashModal,
  PDFViewer,
} from "@flash-tecnologia/hros-web-ui-v2";
import { Body } from "./styles";
import { ModalInterface } from "./types";

const Modal = ({ open, onClose, pdfUrl }: ModalInterface) => {
  return (
    <FlashModal.Root open={open} onClose={onClose}>
      <Body>
        <PDFViewer
          width={"100%"}
          height={"400px"}
          src={pdfUrl}
          options={{
            showToolbar: true,
            fit: "height",
          }}
        />
      </Body>
    </FlashModal.Root>
  );
};

export { Modal };
