import {
  CenterContainer,
  ENPSCharts,
  HeatMap,
  VerticalBarChart,
} from "@components/index";
import { ReviewTabInterface } from "./types";
import {
  Icons,
  Skeleton,
  Tooltip,
  SelectField,
  Tag,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";
import {
  ChartContainer,
  ChartSeparator,
  GraphContainer,
  GraphDescription,
  GraphHeader,
  SelectsContainer,
  StrategiesContainer,
  TagsWrapper,
  VerticalBarChartContainer,
  GraphImpactTextsWrapper,
} from "./styles";
import { GraphHorizontalBars } from "./GraphHorizontalBars";
import { useTheme } from "styled-components";
import { strategiesText } from "./strategiesTexts";
import { useSelectedCompany } from "@flash-tecnologia/hros-web-utility";

const strategyOptions = [
  {
    label: "Significado do trabalho",
    value: "engaja_job_meaning_actions",
  },
  {
    label: `Boas práticas de gestão`,
    value: "engaja_good_management_practices_actions",
  },
  {
    label: `Ambiente de trabalho positivo`,
    value: "engaja_positive_work_environment_actions",
  },
  {
    label: `Oportunidade de crescimento`,
    value: "engaja_growth_opportunity_actions",
  },
  {
    label: `Confiança na Alta Liderança`,
    value: "engaja_trust_in_top_leadership_actions",
  },
  {
    label: `Remuneração`,
    value: "engaja_compensation_and_benefits_actions",
  },
];

export default ({
  heatMapLoading,
  strategiesLoading,
  dimensions,
  columns,
  selectedSegment,
  selectedReviewType,
  onSetReviewType,
  onSelectSegment,
  enps,
  section,
  setSelectedStrategySection,
  selectedStrategySection,
}: ReviewTabInterface) => {
  const theme = useTheme() as any;
  const { selectedCompany } = useSelectedCompany();

  const options = [
    {
      label: `Dimensões do Engajamento`,
      value: "engagement-dimensions",
    },
    {
      label: `eNPS`,
      value: "enps",
    },
  ];

  if (
    [
      "FuXPPZ0P6KwR3UHydq7Ru", // prod  Demo AnaB
      "eV6Gye9bqvFhJWZ4khrKE", // prod  [Teste AnaB] Demonstrações
      "Q5ZtohPYg2NY0CqNtuDaF", // staging LMS v2
    ].includes(selectedCompany?.id)
  ) {
    options.push({
      label: `Estratégias`,
      value: "strategies",
    });
  }

  return (
    <>
      <SelectsContainer>
        <CenterContainer width="320px" style={{ gap: 8 }}>
          <SelectField
            style={{ maxWidth: "320px" }}
            fullWidth
            disabled={false}
            key="review-tab-select-1"
            label="Selecionar tipo de análise"
            options={options}
            onSelectChange={(_: any, option) => {
              const event = option?.value;
              onSetReviewType(event);
            }}
            value={selectedReviewType}
          />
          <Tooltip
            arrow
            placement="top"
            children={
              <div>
                <Icons
                  size={18}
                  name={"IconInfoCircle"}
                  fill={"transparent"}
                  color={"var(--color-neutral-dark3)"}
                />
              </div>
            }
            title={
              <div>
                {selectedReviewType === "engagement-dimensions"
                  ? "Apresenta a média de como os colaboradores se sentem em cada dimensão dos pilares da pesquisa."
                  : selectedReviewType === "strategies"
                  ? "Apresenta como os colaboradores avaliam o impacto das estratégias das ações de engajamento."
                  : "eNPS (Employee Net Promoter Score) avalia a lealdade dos colaboradores, medindo o quanto recomendam a empresa como um bom lugar para trabalhar. "}
              </div>
            }
          />
        </CenterContainer>
        {selectedReviewType === "engagement-dimensions" ? (
          <SelectField
            style={{ maxWidth: "194px" }}
            fullWidth
            disabled={false}
            key="review-tab-select-2"
            label={"Exibir por"}
            options={[
              {
                label: "Departamentos",
                value: "departments",
              },
              {
                label: `Grupos`,
                value: "groups",
              },
              {
                label: `Cargos`,
                value: "roles",
              },
            ]}
            onSelectChange={(_: any, option) => {
              const event = option?.value;
              onSelectSegment(event);
            }}
            value={selectedSegment}
          />
        ) : null}
        {selectedReviewType === "strategies" && (
          <SelectField
            style={{ maxWidth: "292px " }}
            fullWidth
            disabled={false}
            key="review-tab-select-2"
            label={"Exibir por"}
            options={strategyOptions}
            onSelectChange={(_: any, option) => {
              const event = option?.value;
              setSelectedStrategySection(event);
            }}
            value={selectedStrategySection}
          />
        )}
      </SelectsContainer>
      <ChartContainer
        hideBorder={selectedReviewType === "engagement-dimensions"}
      >
        {selectedReviewType === "engagement-dimensions" ? (
          heatMapLoading ? (
            <Skeleton
              height="400px"
              width="100%"
              style={{ borderRadius: "12px" }}
            />
          ) : (
            <HeatMap
              columns={columns}
              rows={dimensions}
              segment={selectedSegment}
            />
          )
        ) : null}
        {enps && selectedReviewType === "enps" ? (
          <>
            <ENPSCharts {...enps} />
            <ChartSeparator />
            <VerticalBarChartContainer>
              <VerticalBarChart
                lowerLabel={"Pouco provável"}
                upperLabel={"Muito provável"}
                enps={true}
                title="Gráfico respostas x notas"
                data={enps?.statistics || []}
              />
            </VerticalBarChartContainer>
          </>
        ) : null}
        {selectedReviewType === "strategies" && (
          <StrategiesContainer>
            <Typography variant="headline8">
              {
                strategyOptions?.find(
                  (item) => item.value === selectedStrategySection
                )?.label
              }
            </Typography>
            {strategiesLoading ? (
              <Skeleton
                height="400px"
                width="100%"
                style={{ borderRadius: "12px" }}
              />
            ) : (
              <>
                {section?.map((question) => {
                  const answers_count =
                    question?.question_answer_1_count +
                    question?.question_answer_2_count +
                    question?.question_answer_3_count +
                    question?.question_answer_4_count +
                    question?.question_answer_5_count;
                  return (
                    <GraphContainer>
                      <GraphHeader>
                        <TagsWrapper>
                          <Tag variant="primary">
                            Média: {question?.question_average}
                          </Tag>
                          {/* <Tag variant="primary">
                          Mercado: {question?.market_question_average}
                        </Tag> */}
                        </TagsWrapper>
                        <Typography
                          variant="caption"
                          variantColor={theme?.colors.neutral[40]}
                        >
                          {answers_count} respostas
                        </Typography>
                      </GraphHeader>
                      <GraphDescription>
                        <Typography
                          variant="body4"
                          variantColor={theme?.colors.neutral[30]}
                        >
                          {strategiesText[question?.question_reference_id]}
                        </Typography>
                      </GraphDescription>
                      <GraphHorizontalBars
                        data={[
                          { name: "1", amt: question?.question_answer_1_count },
                          { name: "2", amt: question?.question_answer_2_count },
                          { name: "3", amt: question?.question_answer_3_count },
                          { name: "4", amt: question?.question_answer_4_count },
                          { name: "5", amt: question?.question_answer_5_count },
                        ]}
                        settings={{
                          name: "quantidade",
                          color: "#FE2B8F",
                          showAll: false,
                        }}
                      />
                      <GraphImpactTextsWrapper>
                        <Typography
                          variant="body4"
                          variantColor={theme?.colors.neutral[30]}
                        >
                          Nenhum impacto
                        </Typography>
                        <Typography
                          variant="body4"
                          variantColor={theme?.colors.neutral[30]}
                        >
                          Impacto muito alto
                        </Typography>
                      </GraphImpactTextsWrapper>
                    </GraphContainer>
                  );
                })}
              </>
            )}
          </StrategiesContainer>
        )}
      </ChartContainer>
    </>
  );
};
