import { usePermissions } from "@flash-tecnologia/hros-web-utility";
import { User } from "src/context/UserProvider";
import Engagement from "src/models/engagement/EngagementModel";

export const useOwnershipValidation = (): ((
  user: User,
  engagement: Engagement
) => boolean) => {
  const permissions = usePermissions();

  const validate = (user, engagement) => {
    {
      const owners = engagement?.owners;
      const id = user?.id ?? user?._id;

      if (permissions?.isAdmin || owners === null || owners?.[0].id === "*")
        return true;

      const isOwner = owners?.map((owner) => owner.id)?.includes(id);

      const isCreator = engagement?.createdBy === id;

      if (isOwner || isCreator) {
        return true;
      } else {
        return false;
      }
    }
  };

  return validate;
};
