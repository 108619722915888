import { BoxAdornment } from "../BoxAdornment";

import { TextField, Toggle } from "@flash-tecnologia/hros-web-ui-v2";

const LinkBoxAdornment = ({ formik, disabled }) => {
  const { showSectionLink, link } = formik?.values;

  return (
    <BoxAdornment
      title={"Inserir link"}
      description={"Selecione esta opção para inserir um link na mensagem."}
      leftAdornment={
        <Toggle
          disabled={disabled}
          checked={!!showSectionLink}
          onChange={(e) =>
            formik.handleChange({
              target: {
                id: "showSectionLink",
                value: e?.target?.checked,
              },
            })
          }
        />
      }
      isActive={showSectionLink}
      activeComponent={
        <div style={{ display: "flex", width: "100%", marginTop: "20px" }}>
          <div
            style={{
              marginRight: "20px",
              visibility: "hidden",
              opacity: 0,
            }}
          >
            <Toggle disabled={true} />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <TextField
              label={"Selecionar texto do link"}
              fullWidth
              value={link?.label || ""}
              disabled={true}
              style={{
                marginBottom: "16px",
              }}
            />
            <TextField
              value={link?.url || ""}
              label={"Insira a URL (https://www.flashapp.com)"}
              fullWidth
              disabled={disabled}
              onChange={(e) => {
                formik.handleChange({
                  target: {
                    id: "link",
                    value: { ...link, url: e?.target?.value },
                  },
                });
              }}
              error={
                formik?.touched?.link?.url && Boolean(formik?.errors?.link?.url)
              }
              helperText={
                formik?.touched?.link?.url &&
                (formik?.errors?.link?.url as string)
              }
            />
          </div>
        </div>
      }
    />
  );
};

export { LinkBoxAdornment };
