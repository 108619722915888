import { Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";
import { TypographyInterface } from "./types";

const T = styled(Typography)<{
  color?: TypographyInterface["color"];
  fontWeight?: TypographyInterface["fontWeight"];
  minWidth?: string;
}>`
  color: ${({ color }) => color} !important;
  font-weight: ${({ fontWeight }) => fontWeight} !important;
  min-width: ${({ minWidth }) => minWidth} !important;
`;

export { T };
