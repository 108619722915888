import { Icons } from "@flash-tecnologia/hros-web-ui-v2";
import { Divider } from "../v2/Divider";
import { Container, Row, Label, InfoText } from "./styles";
import { StatisticRowInterface } from "./types";

const StatisticRow = ({
  data,
  leftContent,
  rightContent,
}: StatisticRowInterface) => (
  <Container>
    {leftContent ? <Row>{leftContent}</Row> : null}
    {data?.map(({ label, value, icon }, i) => (
      <Row key={`big-date-row-${i}`}>
        <Icons
          name={icon || "IconCalendar"}
          color="var(--color-neutral-50)"
          fill="transparent"
        />
        <Divider type="horizontal" spacing="xxs2" />
        <Label variant="body3">{label}</Label>
        <Divider type="horizontal" spacing="xxxs" />
        <InfoText variant="headline7">{value}</InfoText>
      </Row>
    ))}
    {rightContent ? <Row>{rightContent}</Row> : null}
  </Container>
);

export { StatisticRow };
