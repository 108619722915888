import { LinkButton } from "@flash-tecnologia/hros-web-ui-v2";
import { Container, Title, Description } from "./styled";

export const Content = ({
  title,
  description,
  headerElement,
  buttonLabel,
  buttonCallback,
}) => {
  return (
    <Container>
      {headerElement ? headerElement : null}
      <Title variant="headline7">{title}</Title>
      <Description variant="body3">{description}</Description>
      <div>
        <LinkButton variant="default" onClick={buttonCallback}>
          {buttonLabel}
        </LinkButton>
      </div>
    </Container>
  );
};
