import styled from "styled-components";

const TagContainer = styled.div`
  margin-left: 10px;
  margin-bottom: 10px;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 200px;

  > button {
    min-width: 174px !important;
  }
`;

export { TagContainer, ButtonContainer };
