import styled from "styled-components";

const Container = styled.div<{ width?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ width }) => width || "auto"};
`;

export { Container };
