import { Modal as ModalWebUi, Button } from "@flash-tecnologia/hros-web-ui-v2";
import { BorderIcon } from "../BorderIcon";
import { Header, Title, Message, Footer, Body, Gap } from "./styles";
const { Root: ModalE } = ModalWebUi;
import { ModalInterface } from "./types";

const Modal = ({
  id = "",
  title,
  message,
  open,
  onClose,
  leftButton,
  rightButton,
  status,
}: ModalInterface) => {
  return (
    <ModalE
      open={open}
      onClose={() => null}
      footer={
        <Footer>
          {leftButton ? (
            <Button
              size="large"
              variant="secondary"
              onClick={() => leftButton?.onClick()}
            >
              {leftButton?.label}
            </Button>
          ) : null}

          {rightButton ? (
            <Button
              size="large"
              variant="primary"
              onClick={() => rightButton?.onClick()}
            >
              {rightButton?.label}
            </Button>
          ) : null}
        </Footer>
      }
      header={
        <Header alertMode={status === "alert" ? true : false}>
          <BorderIcon
            name={status === "alert" ? "IconAlertCircle" : "IconMoodHappy"}
            size={70}
            fill={
              status === "alert"
                ? "var(--color-feedback-error-light1)"
                : "var(--color-secondary-light2)"
            }
            color={
              status === "alert"
                ? "var(--color-feedback-error-dark1)"
                : "var(--color-primary)"
            }
          />
          <BorderIcon
            onClick={onClose}
            name={"IconX"}
            fill="var(--color-neutral-dark4)"
            color="var(--color-neutral-dark4)"
            size={15}
          />
          <Title
            alertMode={status === "alert" ? true : false}
            variant="headline7"
          >
            {title}
          </Title>
        </Header>
      }
    >
      <Body>
        <Gap>
          <Message variant="body3">{message}</Message>
          <div id={id} />
        </Gap>
      </Body>
    </ModalE>
  );
};

export { Modal };
