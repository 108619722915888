import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;

  .step-config-accordion,
  .step-config-box-adornment {
    width: 100% !important;
  }
`;

const AccordionContent = styled.div`
  width: 100%;
  min-height: 150px;
`;

const LinkButtonContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  > button {
    width: 100%;
  }
`;

const ModalHeader = styled.div`
  height: 160px;
  padding: 0 75px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  border-bottom: 1px solid var(--color-neutral-90);
`;

const ModalFooter = styled.div`
  height: 100px;
  padding: 0 75px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid var(--color-neutral-90);
`;

const ModalBody = styled.div`
  width: 100%;
  min-height: 190px;

  > div {
    width: 100%;
  }
`;

const OverflowText = styled.div`
  width: 100%;
  max-width: 600px;
`;

const AccordionContainer = styled.div`
  width: 100%;
  position: relative;

  > div {
    border-top-left-radius: 8px !important;
    border-top-right-radius: 8px !important;
  }

  .accordion-selected {
    border-color: var(--color-primary) !important;
  }
`;

export {
  Container,
  AccordionContent,
  ButtonContainer,
  LinkButtonContainer,
  ModalHeader,
  ModalFooter,
  ModalBody,
  OverflowText,
  AccordionContainer,
};
