import { Tag } from "@flash-tecnologia/hros-web-ui-v2";
import { Typography } from "../Typography";
import { ChildrenContainer, Container, Header } from "./styles";
import { QuestionDetailBoxInterface } from "./types";

const QuestionDetailBox = ({
  children,
  answerFormat,
  totalAnswers,
}: QuestionDetailBoxInterface) => {
  const formats = {
    text: "Texto",
    date: "Data",
    "multiple-choice": "Múltipla escolha",
    "select-box": "Caixa de seleção",
    dropdown: "Lista suspensa",
    emoji: "Reação (Emoji)",
    heart: "Reação (Coração)",
    star: "Reação (Estrela)",
    scale: "Escala Numérica",
    enps: "eNPS",
  };

  return (
    <Container>
      <Header>
        {formats[answerFormat] ? (
          <Tag variant="gray">{formats[answerFormat]}</Tag>
        ) : (
          <></>
        )}
        <Typography
          variant="body3"
          color="var(--color-neutral-40)"
          fontWeight={600}
        >
          {totalAnswers} respostas
        </Typography>
      </Header>
      <ChildrenContainer>{children}</ChildrenContainer>
    </Container>
  );
};

export { QuestionDetailBox };
